<template>
  <div>
    <div class="mycource_list_nav">
      <div class="mycource_list_navleft">
        <!-- 课程类型筛选 -->
        <button
          class="nav-item"
          v-for="(item, index) in courseNavList"
          :key="index"
          @click="changeNav(item.type)"
          :class="courseType === item.type ? 'activity' : ''"
        >
          {{ item.name }}
        </button>
      </div>
      <div class="mycource_list_navright">
        <!-- 学科筛选 -->
        <el-dropdown trigger="click" class="mycource_list_subject">
          <span class="el-dropdown-link">
            {{ subjectText }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              @click.native="changeSubject(item, value)"
              v-for="(item, value) in subjectList"
              :key="item"
              :style="
                searchParams.subject_type === value ? 'color:#4A36FA;' : ''
              "
              >{{ item }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <!-- 课程状态筛选 -->
        <el-dropdown trigger="click" class="mycource_list_state">
          <span class="el-dropdown-link">
            {{ stateText }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, val) in stateList"
              :key="val"
              @click.native="changeState(item, val)"
              :style="
                searchParams.status === Number(val) ? 'color:#4A36FA;' : ''
              "
              >{{ item }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="card-list" v-if="courseList.length">
      <el-row :gutter="24">
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="12"
          :xl="12"
          v-for="item in courseList"
          :key="item.class_id"
        >
          <my-coursecard
            @click.native="viewDetails(item)"
            :subclass="item.subclass.length"
            :classProcess="item.classProcess"
            :classState="item.classState"
            :subject="String(item.subject)"
            :classLabel="courseType"
            :teacherlist="item.teachers_info.slice(0, 5)"
          ></my-coursecard>
        </el-col>
      </el-row>
      <div class="course-pagination">
        <el-pagination
          background
          :page-size="pageSizes"
          :current-page="currentPage"
          layout="prev, pager, next"
          :total="total"
          @current-change="getCourseList"
        >
        </el-pagination>
      </div>
    </div>
    <div class="empty-content" v-else>
      <empty-component
        :jumpLink="jumpLink"
        :text="currentPage === 2 ? '您暂无课程，可前往' : '您暂无课程'"
      ></empty-component>
    </div>
  </div>
</template>
<script>
import MyCourseCard from "@/components/course/MyCourseCard";
import { SUBJECT, COURSE_STUDY_STATE } from "@/utils/constants.js";
import api from "@/plugins/api";

export default {
  name: "CourseList",
  data() {
    return {
      subjectList: Object.assign({}, { "": "全部" }, SUBJECT),
      courseNavList: [
        { name: "直播课", type: 0 },
        { name: "讲座", type: 2 },
      ],
      stateList: Object.assign({}, { 0: "全部" }, COURSE_STUDY_STATE),
      subjectText: "学科",
      stateText: "课程状态",
      courseList: [],
      pageSizes: 6,
      currentPage: 1,
      courseType: 2,
      total: 0,
      searchParams: {
        subject_type: "",
        status: 0,
      },
      jumpLink: {
        href: "",
        text: "",
      },
      loadingList: false,
    };
  },
  components: {
    "my-coursecard": MyCourseCard,
  },
  methods: {
    viewDetails(subInfo) {
      this.$router.push({
        path: `/my-course/course-detail/${subInfo.class_id}`,
        query: {
          title: encodeURIComponent(subInfo.subject),
          course_type: subInfo.courseType,
        },
      });
    },
    // 改变学科
    changeSubject(name, val) {
      this.subjectText = name;
      this.searchParams.subject_type = val;
      this.getCourseList();
    },
    // 改变状态
    changeState(name, val) {
      if (this.loadingList) {
        return false;
      }
      this.stateText = name;
      this.searchParams.status = val;
      this.getCourseList();
    },
    // 切换课程类型
    changeNav(type) {
      if (this.loadingList) {
        return false;
      }
      this.courseType = type;
      this.getCourseList();
    },
    //处理课程学习状态
    handleSubclassStudyState(subList) {
      /*
      已完结 - 2
      未开始 - 3
      进行中 - 1
      */
      let now = parseInt(new Date().getTime() / 1000);
      let classState = 2;
      if (subList[0].begin_at > now) {
        classState = 3;
      } else if (
        subList[0].begin_at < now &&
        now < subList[subList.length - 1].end_at
      ) {
        classState = 1;
      }
      return classState;
    },
    // 处理课程学习进度
    handleSubclassStudyProcess(subList) {
      let classProcess = 0;
      let now = parseInt(new Date().getTime() / 1000);
      subList.forEach((item) => {
        if (item.begin_at < now) {
          classProcess++;
        }
      });
      return classProcess;
    },
    handleResponseData(type, data) {
      switch (type) {
        case 0:
          return {
            list: data.items || [],
            total: data.result,
          };
        case 2:
          return {
            list: data.data.items || [],
            total: data.data.total,
          };
      }
    },
    // 获取课程列表
    getCourseList(page = 1) {
      if (this.loadingList) {
        return false;
      }
      this.currentPage = page;
      let funArr = [
        api.getMyLiveCourse,
        api.getMyOfflineCourse,
        api.getMyBookingCourse,
      ];
      let fun = funArr[this.courseType];
      let params = {
        page_size: this.pageSizes,
        page: this.currentPage,
        subject_type: this.searchParams.subject_type,
        status: this.searchParams.status,
        course_belong: "开罗尔",
      };
      this.courseList = [];
      if (this.courseType === 2) {
        this.jumpLink = {
          href: process.env.VUE_APP_CHIRAL_URL + "/lecture-center",
          text: "讲座列表选择合适的课程",
        };
      } else {
        this.jumpLink = {
          href: "",
          text: "",
        };
      }
      this.loadingList = true;
      fun(params).then((res) => {
        let item = res.data;
        if (item.code === 1) {
          let newData = this.handleResponseData(this.courseType, item);
          let courseList = newData.list;
          courseList.forEach((e) => {
            e.courseType = this.courseType;
            e.classState = this.handleSubclassStudyState(e.subclass);
            e.classProcess = this.handleSubclassStudyProcess(e.subclass);
          });
          this.courseList = courseList;
          this.total = newData.total;
        } else {
          if (!this.courseType) {
            this.$message.error(res.data.msg);
          } else {
            this.$message.error(res.data.message);
          }
        }
        this.loadingList = false;
      });
    },
  },
  mounted() {
    const courseType = this.$route.query.course_type;
    if (courseType) {
      this.courseType = Number(courseType);
    }
    this.getCourseList();
  },
};
</script>
<style lang="scss" scoped>
.mycource_list_nav {
  width: 100%;
  height: 52px;
  background: white;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  padding: 0px 26px;
  .mycource_list_navleft {
    display: flex;
    .nav-item {
      padding: 12px 0px;
      font-size: 14px;
      border-bottom: 2px solid $avatar-color;
      color: rgba($chiral-color, 0.6);
      margin-right: 48px;
      font-weight: 500;
      cursor: pointer;
      background: $avatar-color;
    }
    @media (max-width: 767px) {
      .nav-item {
        margin-right: 0.2667rem;
      }
    }
    @media (min-width: 768px) and (max-width: 1199px) {
      .nav-item {
        margin-right: 10px;
      }
    }
    .activity {
      border-bottom: 2px solid $chiral-btn-color;
      color: $chiral-btn-color;
    }
  }
  .mycource_list_navright {
    display: flex;
    .mycource_list_subject {
      width: 84px;
      height: 28px;
      border-radius: 4px;
      border: 1px solid rgba(44, 45, 46, 0.3);
      text-align: center;
      line-height: 28px;
      margin-right: 16px;
      color: rgba(51, 51, 51, 0.6);
      cursor: pointer;
    }
    .mycource_list_state {
      cursor: pointer;
      width: 84px;
      height: 28px;
      border-radius: 4px;
      border: 1px solid rgba(44, 45, 46, 0.3);
      text-align: center;
      line-height: 28px;
      color: rgba(51, 51, 51, 0.6);
    }
  }
}
.card-list {
  margin-top: 24px;
  .course-pagination {
    text-align: center;
    margin-top: 20px;
    :deep .el-pagination.is-background .el-pager li:not(.disabled).active {
      background: $chiral-btn-color;
    }
  }
}
.empty-content {
  position: relative;
  height: 500px;
}
:deep .el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background: $avatar-color;
  color: $chiral-btn-color;
}
@media (max-width: 767px) {
  .mycource_list_nav {
    padding: 0px 16px;
  }
  .card-list {
    width: 343px;
    margin: auto;
    margin-top: 0.4267rem;
  }
}
</style>
