<template>
  <div class="addresses">
    <el-dialog
      custom-class="dialog-600"
      :title="(handle ? '编辑' : '新增') + '收货地址'"
      :visible.sync="visibleDialog"
      :close-on-click-modal="false"
      center
      @closed="closeDialog"
      :append-to-body="true"
      :width="isMobile ? '95%' : '50%'"
    >
      <p class="text-center">推荐您将学校设为自己的收货地址</p>
      <el-form
        class="form"
        :model="addresses"
        label-position="'right'"
        label-width="80px"
        ref="ruleForm"
      >
        <el-row>
          <el-col :xs="24" :sm="12">
            <el-form-item label="收件人:">
              <el-input
                class="inline-width"
                v-model="addresses.name"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12">
            <el-form-item label="手机号:">
              <el-input
                class="inline-width"
                v-model="addresses.mobile"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="省市地区:">
          <el-cascader
            v-model="cascadeData"
            class="max-width"
            :props="cascadeProps"
            placeholder="省/市/县"
            clearable
          />
        </el-form-item>
        <el-form-item label="详细地址:">
          <el-input
            class="max-width"
            v-model="addresses.address"
            placeholder="例：知春路73号北京大学附属中学高二274班"
            clearable
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="submit msg-btn" type="primary" @click="handleForm"
          >提交</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import api from "@/plugins/api";
import { isMobile } from "@/utils/utils";

export default {
  name: "Addresses",
  props: {
    callback: Function,
  },
  data() {
    return {
      addresses: {
        name: "",
        mobile: "",
        province: "",
        city: "",
        district: "",
        address: "",
      },
      cascadeProps: {
        lazy: true,
        lazyLoad: this.initProvince,
      },
      cascadeData: [],
      visibleDialog: false,
      handle: false,
    };
  },
  methods: {
    initProvince(node, resolve) {
      const { level, path = [] } = node;
      const func = ["getProvince", "getCities", "getDistricts"];
      api[func[path.length]](...path).then((res) => {
        const nodes = res.data.map((city) => {
          return {
            label: city.name,
            value: city.name,
            leaf: level >= 2,
          };
        });
        resolve(nodes);
      });
    },
    showDialog(item = {}) {
      if (JSON.stringify(item) !== "{}") {
        this.addresses = item;
        if (item.province && item.city && item.district) {
          this.cascadeData = [item.province, item.city, item.district];
        }
      } else {
        this.closeDialog();
      }
      this.visibleDialog = true;
    },
    handleForm() {
      if (!this.addresses.name) {
        this.$message.warning("收件人不正确");
        return false;
      }
      if (!this.addresses.mobile) {
        this.$message.warning("手机号不正确");
        return false;
      }
      if (this.cascadeData.length !== 3) {
        this.$message.warning("省市地区不正确");
        return false;
      } else {
        this.addresses.province = this.cascadeData[0];
        this.addresses.city = this.cascadeData[1];
        this.addresses.district = this.cascadeData[2];
      }
      if (!this.addresses.address) {
        this.$message.warning("详细地址不正确");
        return false;
      }
      this.submit(this.addresses);
    },
    submit(data) {
      api.handleAddress(data, this.handle ? "update" : "create").then((res) => {
        if (res.data.code === 1) {
          this.$message.success((this.handle ? "编辑" : "新增") + "成功");
          this.callback && this.callback(this.handle ? "update" : "create");
          this.visibleDialog = false;
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    deleteAddress(id) {
      this.$showDialog("确定要删除该地址？", () => {
        api.handleAddress({ id }, "delete").then((res) => {
          if (res.data.code === 1) {
            this.$message.success("删除成功");
            this.callback && this.callback("delete");
          } else {
            this.$message.warning(res.data.msg);
          }
        });
      });
    },
    closeDialog() {
      this.addresses = {
        name: "",
        mobile: "",
        province: "",
        city: "",
        district: "",
        address: "",
      };
    },
  },
  computed: {
    isMobile() {
      return isMobile();
    },
  },
};
</script>
<style lang="scss" scoped>
:deep .el-dialog__header {
  display: inline-block;
}
.dialog-600 {
  .form {
    padding: 10px 20px 0;

    .max-width {
      width: 430px;
    }

    .inline-width {
      max-width: 180px;
    }
  }

  @media (max-width: 767px) {
    & {
      .el-dialog__body {
        padding: 10px;
      }

      .dialog-600 .form {
        padding: 10px 0 0 0;

        .max-width {
          width: auto;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .el-dialog {
    .form {
      .max-width {
        width: 100%;
      }
    }
  }
}
</style>
