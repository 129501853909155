class Request {
  constructor() {
    try {
      this.init();
    } catch (e) {
      console.log(e);
    }
  }

  init() {
    this.xht = null;
    if (window.XMLHttpRequest) {
      this.xht = new window.XMLHttpRequest();
    } else if (window.ActiveXObject) {
      this.xht = new window.ActiveXObject();
    } else {
      throw String("Your browser does not support XMLHttpRequest");
    }
  }

  ajax(method, url, data = {}) {
    var request = this.xht;
    return new Promise(function (resolve, reject) {
      request.onreadystatechange = () => {
        if (request.readyState === 4) {
          if (request.status === 200) {
            resolve({
              data: data.html
                ? request.responseText
                : JSON.parse(request.responseText),
            });
          } else {
            reject(request.status);
          }
        }
      };
      request.open(method, url);
      request.send(data);
    });
  }
}

export default Request;
