<template>
  <div id="quiz-index" class="chiral-quiz-detail" ref="view"></div>
</template>
<script>
import "zhixin-libs/libs/quiz/quizdetail.css";
import * as lib from "zhixin-libs/libs/quiz/quizdetail/index.js";
import $ from "jquery";
import Vue from "vue";

export default {
  data() {
    return {
      isMobileStyle: false,
    };
  },
  methods: {},
  mounted() {
    this.isMobileStyle = /Android|webOS|iPhone|iPod|BlackBerry/i.test(
      navigator.userAgent
    );
    let promise;
    if (this.isMobileStyle) {
      promise = Promise.all([import("forum-editor/lib/EditorMobile")]);
    } else {
      import("forum-editor/lib/theme_package/Editor.css");
      import("forum-editor/lib/theme_package/highlight.css");
      import("forum-editor/lib/theme_package/katex.css");
      promise = Promise.all([
        import("forum-editor/lib/highlight"),
        import("forum-editor/lib/Editor"),
        import("forum-editor/lib/iView"),
        import("forum-editor/lib/katex"),
        import("forum-editor/lib/theme_package/iView.css"),
      ]);
    }
    var controller = lib.default.Controller;
    var APP = lib.default.APP;
    APP.Vue = Vue;
    APP.promise = promise;
    let { par, origin, id, index, subject } = this.$route.params;
    APP.userInfoModel.subject = subject;
    controller(
      par,
      origin,
      id,
      index,
      {
        APP: APP,
        requestUrl: process.env.VUE_APP_ZHIXIN_URL + "/v1",
      },
      "quizNew"
    ).then((res) => {
      $(this.$refs.view).html(res.$el);
    });
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.chiral-quiz-detail {
  // margin: 0px -10px;
  padding-bottom: 76px;
  font-size: 14px;
  min-height: 500px;
}
</style>
<style lang="scss">
.discuss-send-edit {
  .preview {
    h2 {
      font-size: 16px;
    }
  }
}
#quiz-index .btn:focus,
#quiz-index .btn.focus,
#quiz-index .btn:active:focus,
#quiz-index .btn:active.focus,
#quiz-index .btn.active:focus,
#quiz-index .btn.active.focus {
  outline: none !important;
}
#question_set_new .quiz-footer-bar #quiz-footer .footer-answer {
  background-color: $chiral-btn-color !important;
}
</style>
