<template>
  <div class="booking-guide">
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :width="width"
    >
      <div class="booking-content">
        <i class="chiral fa-icon_home_clear modal-closer" @click="close"></i>
        <div class="booking-container">
          <img :src="successImg" alt="success" class="success-logo" />
          <img
            v-if="!isMobile"
            :src="successImg2"
            alt="success"
            class="success-logo2"
          />
          <img :src="rightLogoImg" alt="success" class="right-logo" />
          <div class="success-text">
            <img :src="checkGreenImg" alt="success" />
            <h3 class="title">讲座{{ courseInfo.action }}成功</h3>
          </div>
          <div class="success-tips">可在 「 我的课程 」中查看</div>
          <div class="qrcode">
            <img :src="courseInfo.qr_code" alt="" />
          </div>
          <div class="tips2">
            {{
              courseInfo.service_type === 1
                ? "添加老师微信可加入专属学习群"
                : "加班主任微信，接收重要学习提醒"
            }}
          </div>
          <div class="tips3">截图保存图片，微信识别二维码</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import checkGreenImg from "@/assets/img/live/check_green@2x.png";
import successImg from "@/assets/img/live/success@2x.png";
import successImg2 from "@/assets/img/live/success2@2x.png";
import rightLogoImg from "@/assets/img/live/right-logo@2x.png";
export default {
  name: "BookingGuide",
  data() {
    return {
      checkGreenImg,
      dialogVisible: false,
      width: "600px",
      isMobile: document.body.clientWidth < 1200,
      successImg,
      successImg2,
      rightLogoImg,
      courseInfo: {
        qr_code: "",
        action: "",
        service_type: 1, // 1是班群 2是班主任
      },
    };
  },
  methods: {
    close() {
      this.dialogVisible = false;
    },
    show(data, params) {
      this.dialogVisible = true;
      this.courseInfo = {
        qr_code: data.qr_code,
        action: params.action.indexOf("报名") > -1 ? "报名" : "预约",
        service_type: data.service_type,
      };
    },
  },
  mounted() {
    this.width = this.isMobile ? "311px" : "600px";
  },
};
</script>
<style lang="scss" scoped>
.booking-guide {
  :deep .el-dialog__header {
    display: none;
  }
  :deep .el-dialog__wrapper {
    background: rgba($color: #000000, $alpha: 0.4);
  }
  :deep .el-dialog__body {
    padding: 0px;
  }
  :deep .el-dialog {
    background: initial;
  }
  .booking-content {
    position: relative;
    background: #44cd84;
    border-radius: 8px;
    padding: 64px 17px 16px 15px;
    .modal-closer {
      position: absolute;
      right: -15px;
      top: -15px;
      font-size: 30px;
      text-align: center;
      color: white;
      cursor: pointer;
    }
    .booking-container {
      background: white;
      padding: 32px 0px 16px;
      border-radius: 8px;
      position: relative;
      .success-logo {
        width: 145px;
        position: absolute;
        top: -56px;
      }
      .success-logo2 {
        width: 118px;
        position: absolute;
        top: -28px;
        left: 163px;
      }
      .right-logo {
        position: absolute;
        right: 0px;
        width: 160px;
        top: -58px;
      }
      .success-text {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 24px;
          height: 24px;
          margin-right: 12px;
        }
        .title {
          font-size: 24px;
          font-weight: 500;
          color: #333333;
          line-height: 32px;
          padding: 0px;
        }
      }
      .success-tips {
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: rgba($color: #2c2d2e, $alpha: 0.6);
        line-height: 20px;
        margin: 10px 0px 24px;
      }
      .qrcode {
        display: flex;
        justify-content: center;
        img {
          width: 120px;
          height: 120px;
        }
      }
      .tips2 {
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        color: #fa6400;
        line-height: 22px;
        margin: 24px 0px 10px;
      }
      .tips3 {
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
      }
    }
  }
  @media (max-width: 1199px) {
    .booking-content {
      padding-top: 87px;
      .booking-container {
        .right-logo {
          right: -10px;
          top: -76px;
        }
      }
    }
  }
}
</style>
