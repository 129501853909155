export const m = {
  // SideMenu.vue
  Dashboard: "仪表盘",
  General: "常用设置",
  User: "用户管理",
  Announcement: "公告管理",
  System_Config: "系统配置",
  Judge_Server: "判题服务器",
  Prune_Test_Case: "测试用例",
  Problem: "问题",
  Problem_List: "问题列表",
  Create_Problem: "增加题目",
  Export_Import_Problem: "导入导出题目",
  Contest: "比赛&练习",
  Contest_List: "比赛列表",
  Create_Contest: "创建比赛",
  // User.vue
  User_User: "用户",
  Import_User: "导入用户",
  Generate_User: "生成用户",
  // User.vue-dialog
  User_Info: "用户信息",
  User_Username: "用户名",
  User_Real_Name: "真实姓名",
  User_Email: "用户邮箱",
  User_New_Password: "用户密码",
  User_Type: "用户类型",
  Problem_Permission: "问题权限",
  Two_Factor_Auth: "双因素认证",
  Is_Disabled: "是否可用",
  // Announcement.vue
  General_Announcement: "公告",
  Announcement_Title: "标题",
  Announcement_Content: "内容",
  Announcement_Status: "状态",
  Announcement_Push: "推送",
  // Conf.vue
  SMTP_Config: "SMTP Config",
  Server: "服务器",
  Port: "端口",
  Email: "邮箱",
  Password: "授权码",
  Website_Config: "Web Config",
  Base_Url: "Base Url",
  Name: "名称",
  Shortcut: "简称",
  Footer: "页脚",
  Allow_Register: "是否允许注册",
  Submission_List_Show_All: "显示全部题目的提交",
  // JudgeServer.vue
  Judge_Server_Token: "判题服务器接口",
  Judge_Server_Info: "判题服务器",
  IP: "IP",
  Judger_Version: "判题机版本",
  Service_URL: "服务器URL",
  Last_Heartbeat: "上一次心跳",
  Create_Time: "创建时间",
  // PruneTestCase
  Test_Case_Prune_Test_Case: "精简测试用例",
  // Problem.vue
  Display_ID: "Display ID",
  Title: "题目",
  Description: "描述",
  Input_Description: "输入描述",
  Output_Description: "输出描述",
  Samples_Explanation: "样例解释",
  Time_Limit: "时间限制",
  Memory_limit: "内存限制",
  Difficulty: "难度",
  Visible: "是否可见",
  Languages: "可选编程语言",
  Input_Samples: "输入样例",
  Output_Samples: "输出样例",
  Add_Sample: "添加样例",
  Code_Template: "代码模板",
  Special_Judge: "Special Judge",
  Use_Special_Judge: "使用Special Judge",
  Special_Judge_Code: "Special Judge Code",
  SPJ_language: "SPJ language",
  Compile: "编译",
  TestCase: "测试用例",
  Type: "测试类型",
  Input: "输入",
  Output: "输出",
  Score: "分数",
  Hint: "提示",
  Source: "来源",
  Problem_Source: "题目来源",
  Problem_Difficulty: "题目难度",
  Algorithm_Tags: "算法标签",
  // Contest.vue
  ContestTitle: "标题",
  ContestDescription: "描述",
  Contest_Start_Time: "开始时间",
  Contest_End_Time: "结束时间",
  Contest_Password: "密码",
  Contest_Rule_Type: "规则",
  Real_Time_Rank: "实时Rank",
  Contest_Status: "状态",
  Allowed_IP_Ranges: "允许的IP范围",
  Analysis_File: "解析文件",
  CIDR_Network: "CIDR Network",
  // Dashboard.vue
  Last_Login: "最后登录状态",
  System_Overview: "系统状况",
  DashBoardJudge_Server: "判题服务器",
  HTTPS_Status: "HTTPS 状态",
  Force_HTTPS: "强制 HTTPS",
  CDN_HOST: "CDN HOST",
  ReviewDetails: "回顾查看提交详情",
};
