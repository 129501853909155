const cookie = {
  setCookie (name, value, expiredays, domain) {
    const exdate = new Date()
    exdate.setTime(exdate.getTime() + expiredays)
    exdate.setDate(exdate.getDate() + expiredays)
    document.cookie = name + '=' + escape(value) + ((expiredays == null) ? '' : ';expires=' + exdate.toGMTString()) + ((domain == null) ? '' : ';domain=' + domain) + ';path=/'
  },
  getCookie (name) {
    let arr = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
    const reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
    // eslint-disable-next-line
    if (arr = document.cookie.match(reg)) {
      return (arr[2])
    } else {
      return null
    }
  },
  delCookie (name, domain) {
    const exp = new Date()
    exp.setTime(exp.getTime() - 1)
    const cval = cookie.getCookie(name)
    if (cval != null) {
      document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;' + ((domain == null) ? '' : 'domain=' + domain) + ';path=/'
    }
  }
}

export default cookie
