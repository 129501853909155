<template>
  <div>
    <div
      class="course-detail"
      v-loading="loading"
      element-loading-text="拼命加载中"
    >
      <div class="course-top">
        <div class="content">
          <div class="detail-back" @click="back">
            <img src="@/assets/img/live/fanhui@2x.png" alt="返回" />
            <span>返回</span>
          </div>
          <div class="course-info">
            <div class="course-cover" v-if="!isPadAndMbile">
              <img
                class="course-img"
                :src="
                  classInfo.cover || require('@/assets/img/live/course.png')
                "
                alt="课程图片"
              />
            </div>
            <div class="course">
              <!-- 课程信息 -->
              <h1>{{ classInfo.subject }}</h1>
              <p class="subtitle">{{ classInfo.short_desp }}</p>
              <div class="course-time">
                <i class="chiral fa-time"></i>
                <span class="course-date"
                  >{{ classInfo.begin_at | formatDate }} -
                  {{ classInfo.end_at | formatDate }}</span
                >
                <span class="course-progress"
                  >已进行{{ subStudyProcess }}/{{
                    classInfo.subclass_count
                  }}</span
                >
              </div>
              <!-- 教师列表 -->
              <div class="course-teacher-list">
                <span
                  v-for="item in classInfo.teachers_info"
                  :key="item.id"
                  class="teacher-item"
                >
                  <i class="chiral fa-teacher"></i>
                  <span class="teacher-name">{{ item.teacher_name }}</span>
                  <!-- <i class="chiral fa-chevron-right"></i> -->
                </span>
              </div>
              <!-- 按钮及价格区域 -->
              <buy-course-area
                :classInfo="classInfo"
                :classPurchase="classPurchase"
                :subStudyProcess="subStudyProcess"
                :groupTag="groupTag"
                :notPurchaseNum="notPurchaseNum"
                :buyBtnText="buyBtnText"
                :gropPriceList="classInfo.group_ladder_price"
                :groupItems="groupItems"
                :userGroup="userGroup"
              />
              <!-- 拼团列表--课程不可观看、未拼团中、展示拼团订单 -->
              <group-items
                v-if="
                  !classPurchase &&
                  !userGroup.group_id &&
                  groupItems.length &&
                  classInfo.can_open
                "
                :groupItems="groupItems"
                @goGroup="goGroupHandle"
              />
            </div>
          </div>
          <!-- 课程详情和章节导航 -->
          <div class="course-nav" :class="detailNavFixed ? 'nav-fixed' : ''">
            <div v-for="(item, index) in navList" :key="index">
              <div
                class="nav-item"
                v-if="item.show"
                :class="index === navType ? 'activity' : ''"
                @click="navType = index"
              >
                <i class="chiral" :class="item.icon"></i>
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content mobile-detail-content">
        <div class="course-subclass">
          <!-- 课程详情 -->
          <div
            class="subclass-info"
            v-show="!navType"
            v-html="classInfo.desp"
          ></div>
          <!-- 课程章节 -->
          <div class="subclass-info subclass-list" v-show="navType === 1">
            <div
              class="subclass-item"
              v-for="(item, index) in classInfo.subclass"
              :key="item.subclass_id"
            >
              <div class="sub-title">
                <span class="title">{{ index + 1 }}.{{ item.subject }}</span>
                <span class="info" v-if="item.desp">{{ item.desp }}</span>
                <span
                  class="tag"
                  :class="
                    subclassBtnText(item) === 1 || subclassBtnText(item) === 3
                      ? 'tag-visable'
                      : ''
                  "
                  >{{ courseState[subclassBtnText(item)] }}</span
                >
                <span class="tag tag-zhibo">直播</span>
              </div>
              <div class="sub-bottom">
                <!-- 左侧 -->
                <div class="sub-time">
                  <div class="time-item">
                    <i class="chiral fa-time"></i
                    >{{ item.begin_at | formatSubclassBeginTime }}-{{
                      item.end_at | formatSubclassEndTime
                    }}&nbsp;{{ item.begin_at | formatWeek }}
                  </div>
                  <div class="time-item">
                    <i class="chiral fa-teacher"></i>
                    <span>{{ item.teachers }}</span>
                  </div>
                  <div>
                    <!-- 免费课有就展示有限期，付费课购买后才展示 -->
                    <span
                      v-if="
                        (!classInfo.price && item.deadline_at) ||
                        (classInfo.price && classPurchase && item.deadline_at)
                      "
                      >观看有效期至{{ item.deadline_at | formatPlayBack }}</span
                    >
                  </div>
                </div>
                <!-- 右侧 -->
                <div class="subclass-right">
                  <!-- 没有购买的付费小课 -->
                  <div v-if="item.price && !item.purchase">
                    <span v-if="groupTag" class="subclass-price">
                      原价¥{{ item.price / 100 }}
                      {{ subGroupPrice(item.subclass_id) }}
                    </span>
                    <span v-else class="subclass-price"
                      >¥{{ item.price / 100 }}</span
                    >
                  </div>
                  <!-- 免费课或已购买 -->
                  <button
                    v-if="!item.price || item.purchase"
                    class="course-btn"
                    @click="toLiveRoom(item)"
                    :class="subclassBtnText(item) < 3 ? 'viable' : ''"
                  >
                    {{ courseStateBtn[subclassBtnText(item)] }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- 移动端 常见问题 -->
          <div class="subclass-info" v-show="navType === 2">
            <div class="problem-list">
              <h3>常见问题</h3>
              <div
                class="problem"
                v-for="(item, index) in problemList"
                :key="index"
              >
                <div
                  v-if="!item.onlyShowGroup || (groupTag && item.onlyShowGroup)"
                >
                  <div class="pro-top">
                    <img src="@/assets/img/live/wenti@2x.png" alt="常见问题" />
                    <div>{{ item.title }}</div>
                  </div>
                  <div>{{ item.content }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 常见问题 -->
          <div class="problem-list" v-if="!isPadAndMbile">
            <h3>常见问题</h3>
            <div
              class="problem"
              v-for="(item, index) in problemList"
              :key="index"
            >
              <div
                v-if="!item.onlyShowGroup || (groupTag && item.onlyShowGroup)"
              >
                <div class="pro-top">
                  <img src="@/assets/img/live/wenti@2x.png" alt="常见问题" />
                  <div>{{ item.title }}</div>
                </div>
                <div>{{ item.content }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 移动端购买区域 -->
      <mobile-buy-area
        v-if="isMobile && !classPurchase"
        :buyBtnText="buyBtnText"
        :classInfo="classInfo"
        :groupTag="groupTag"
        :userGroup="userGroup"
        :gropPriceList="classInfo.group_ladder_price"
        :notPurchaseNum="notPurchaseNum"
      />
      <booking-guide ref="bookingGuide"></booking-guide>
    </div>
    <div id="confirm-order-container"></div>
  </div>
</template>
<script>
import Vue from "vue";
import MobileBuyArea from "@/views/course/components/MobileBuyArea.vue";
import BuyCourseArea from "@/views/course/components/BuyCourseArea.vue";
import GroupItems from "@/views/course/components/GroupItems.vue";
import {
  PROBLEM_LIST,
  COURSE_STATE,
  COURSE_STATE_BTN,
} from "@/utils/constants.js";
import api from "@/plugins/api";
import { isMobile } from "@/utils/utils";
import booking from "@/mixins/booking";
import BookingGuide from "@/components/course/BookingGuide";
import Confirm from "@/components/confirm-order/index";
Vue.use(Confirm);
export default {
  name: "CourseDetail",
  components: {
    "buy-course-area": BuyCourseArea,
    "booking-guide": BookingGuide,
    "group-items": GroupItems,
    "mobile-buy-area": MobileBuyArea,
  },
  mixins: [booking],
  data() {
    return {
      loading: false,
      isMobile: isMobile(),
      isPadAndMbile: document.body.clientWidth < 1200,
      problemList: PROBLEM_LIST,
      courseStateBtn: COURSE_STATE_BTN,
      courseState: COURSE_STATE,
      navList: [
        { name: "课程详情", icon: "fa-kechengxiangq", show: true },
        { name: "课程章节", icon: "fa-a-CombinedShape", show: true },
        {
          name: "常见问题",
          icon: "fa-changjianwenti",
          show: document.body.clientWidth < 1200,
        },
      ],
      navType: 0,
      classInfo: {},
      classPurchase: false, //课程是否可观看tag
      notPurchaseNum: null, //未购买章节数
      groupTag: false, //是否是团购
      groupItems: [], //拼团列表
      userGroup: {}, //该用户参团信息
      subStudyProcess: 0, //小课学习进度
      userSubclass: [],
      //
      lastTime: 0,
      detailNavFixed: false,
      isFistLoading: false,
    };
  },
  methods: {
    // 去参团
    goGroupHandle(id) {
      this.booking("goGroup", id);
    },
    back() {
      this.$router.push({ path: "/lecture-center" });
    },
    toLiveRoom(subObj) {
      if (!subObj.price && !subObj.purchase) {
        this.$chiralConfirmMessage(
          `请先${this.buyBtnText.slice(2)}课程`,
          () => {}
        );
        return false;
      }
      let courseState = this.subclassBtnText(subObj);
      if (courseState < 3) {
        let liveState = courseState === 1 ? "live" : "replay";
        this.$router.push({
          path: `/live/${this.classInfo.class_id}/${subObj.subclass_id}/${liveState}`,
        });
      }
    },
    //滚动置顶课程章节
    scrollFunc() {
      var nowTime = Date.now();
      if (nowTime - this.lastTime) {
        this.lastTime = nowTime;
        let navHeight = document.querySelector(".nav-bar").offsetHeight;
        let courseTopHeight =
          document.querySelector(".course-top").offsetHeight;
        const scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
        this.detailNavFixed = scrollTop >= navHeight + courseTopHeight;
      }
    },
    //处理课程详情
    handleClassInfo(res) {
      if (res.data.code === 1) {
        let item = res.data.data?.class_info || {};
        //循环每个小课 判断小课购买状态， 默认没有买purchase为0
        let subclass = item.subclass || [];
        subclass.forEach((sub) => {
          sub.purchase = 0;
          for (let i = 0; i < this.userSubclass.length; i++) {
            if (sub.subclass_id === this.userSubclass[i].subclass_id) {
              sub.purchase = 1;
              break;
            }
          }
        });
        this.classInfo = item;
        this.subStudyProcess = this.subclassStudyProcess();
        this.handleClassPurchase(item);
        if (!this.isFistLoading) {
          window.addEventListener("scroll", this.scrollFunc, true);
          this.$nextTick(() => {
            //设置小课列表导航吸顶时的宽度
            let subclassNavWidth = "";
            if (this.isMobile) {
              subclassNavWidth = "100%";
            } else {
              subclassNavWidth =
                document.querySelector(".subclass-info").offsetWidth + "px";
            }
            document.querySelector(".course-nav").style.width =
              subclassNavWidth;
          });
          this.isFistLoading = true;
        }
      } else {
        this.loading = false;
        this.isFistLoading = true;
        this.$chiralConfirmMessage("没有此课程", () => {
          this.$router.push({ path: "/lecture-center" });
        });
      }
    },
    // 处理课程购买状态
    handleClassPurchase(item) {
      this.classPurchase = this.userSubclass.length === item.subclass.length;
      if (item.price) {
        // 付费课--购买了部分章节
        if (!this.classPurchase && this.userSubclass.length != 0) {
          this.notPurchaseNum = item.subclass.length - this.userSubclass.length;
        }
        this.groupTag = this.isGroup(item);
        if (this.groupTag) {
          this.getGroupsInfo();
        }
      } else {
        // 免费课-过期后可重新报名
        if (this.classPurchase) {
          this.classPurchase = !this.isExistOverdueFreeCourse(
            item.price,
            this.userSubclass
          );
        }
      }
      this.loading = false;
    },
    // 获取大课团购信息
    getGroupsInfo() {
      api.getGroupsInfo({ class_id: this.$route.params.id }).then((res) => {
        if (res.data.code === 1) {
          this.userGroup = res.data?.user_group || {};
          if (res.data?.items?.length) {
            this.groupItems = res.data.items.map((item) => {
              item.avatar = item.members_info[0].avatar;
              item.name = item.members_info[0].name;
              item.memberNum = item.members_info.length;
              return item;
            });
          }
        }
      });
    },
    // 是否是团购
    isGroup(item) {
      const now = parseInt(new Date().getTime() / 1000);
      return (
        item.group_set === 1 &&
        (item.group_end_time == 0 || now < item.group_end_time)
      );
    },
    // 用户购买的小课是否有过期的
    isExistOverdueFreeCourse(totalPrice, subclasses) {
      let state = false;
      if (!totalPrice) {
        const now = parseInt(new Date().getTime() / 1000);
        state = subclasses.some((item) => {
          return item.deadline_at && item.deadline_at < now;
        });
      }
      return state;
    },
    //用户买的小课列表
    handleUserSubclass(res) {
      if (res.data.code === 1) {
        this.userSubclass = res.data.data || [];
      } else {
        console.log("error");
      }
    },
    async handleAllFunc(fun) {
      try {
        let res = await fun;
        return {
          res,
          code_self: 1,
        };
      } catch (err) {
        return {
          err,
          code_self: 0,
        };
      }
    },
    // 获取课程信息
    initClassInfo() {
      this.loading = true;
      let classId = this.$route.params.id;
      let apiArray = [
        api.getClassInfo({ class_id: classId }),
        api.getUserSubclass({ class_id: classId, goods_type: 2 }),
      ];
      Promise.all(apiArray.map((item) => this.handleAllFunc(item)))
        .then((res) => {
          let [info, sub] = res;
          if (sub.code_self) {
            this.handleUserSubclass(sub.res);
          }
          if (info.code_self) {
            this.handleClassInfo(info.res);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log("error", error);
        });
    },
    subclassBtnText(subObj) {
      /*
        返回状态
        直播(进行中)  1
        回放         2
        直播(未开始)  3
        过期         4 
      */
      let sub_begin = subObj.begin_at;
      let sub_end = subObj.end_at;
      let sub_deadline = subObj.deadline_at;
      let now = parseInt(new Date().getTime() / 1000);
      if (sub_deadline && sub_deadline < now) {
        return 4;
      } else if (now > sub_end) {
        return 2;
      } else if (now + 1800 >= sub_begin && now <= sub_end) {
        return 1;
      } else {
        return 3;
      }
    },
    // 小课进度
    subclassStudyProcess() {
      let subclassList = this.classInfo.subclass || [];
      let now = parseInt(new Date().getTime() / 1000);
      let process = subclassList.filter((item) => {
        return item.begin_at < now;
      });
      return process.length;
    },
    // 获取小课团购价
    subGroupPrice(subclass_id) {
      let price = "";
      const groupInfo = this.classInfo.subclass_group_info;
      if (groupInfo && groupInfo.length) {
        price =
          "拼团价¥" +
          groupInfo.filter((item) => {
            return item.subclass_id === subclass_id;
          })[0].group_price /
            100;
      }
      return price;
    },
  },
  computed: {
    buyBtnText() {
      if (!this.classInfo.class_id) {
        return "立即报名";
      }
      let item = this.classInfo;
      let btnText = "立即报名";
      let courseType = item.price ? "pay" : "free"; //付费课pay 免费课free
      if (
        courseType === "free" &&
        this.subStudyProcess < this.classInfo.subclass_count
      ) {
        return "立即预约";
      }
      return btnText;
    },
  },
  mounted() {
    this.initClassInfo();
  },
  destroyed() {
    //取消监听滚动置顶课程章节
    window.removeEventListener("scroll", this.scrollFunc, true);
  },
};
</script>
<style lang="scss" scoped>
.content {
  min-height: 100px;
  .detail-back {
    padding: 14px 0px;
    display: flex;
    align-items: center;
    font-size: 14px;
    width: fit-content;
    cursor: pointer;
    img {
      width: 22px;
      height: 22px;
    }
    span {
      margin-left: 10px;
    }
  }
  @media (max-width: 767px) {
    .detail-back {
      padding: 0.2933rem 0px 0.2933rem 0;
      font-size: 0.3733rem;
      img {
        width: 0.5333rem;
        height: 0.5333rem;
      }
    }
  }
  .course-info {
    padding: 25px 0px 21px;
    display: flex;
    color: $chiral-color;
    .course-cover {
      width: 40.96%;
    }
    .course-img {
      // width: 40.96%;
      width: 100%;
      display: block;
      border-radius: 4px;
      // height: fit-content;
    }
    .course {
      padding: 0px 0px 0px 41px;
      position: relative;
      // height: 500px;
      flex: 1;
      h1 {
        font-size: 24px;
        font-weight: 500;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        height: 23px;
      }
      .subtitle {
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        margin: 16px 0px 36px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        height: 16px;
        color: rgba($color: $chiral-color, $alpha: 0.5);
      }
      .course-time {
        display: flex;
        align-items: center;
        .course-date {
          margin: 0px 8px;
          font-size: 16px;
          color: #2c2d2e;
          line-height: 16px;
        }
        .course-progress {
          padding: 4px 8px;
          font-size: 12px;
          font-weight: 500;
          color: $chiral-btn-color;
          line-height: 14px;
          background: #f4f2ff;
          border-radius: 2px;
        }
      }
      .course-teacher-list {
        margin-top: 20px;
        font-size: 16px;
        font-weight: 400;
        color: #2c2d2e;
        line-height: 16px;
        .teacher-item {
          display: inline-block;
          margin-right: 15px;
          margin-bottom: 10px;
        }
        @media (max-width: 767px) {
          .teacher-item {
            i {
              font-size: 0.32rem;
            }
          }
        }
        .teacher-name {
          margin-left: 8px;
        }
      }
    }
    @media (max-width: 767px) {
      .course {
        padding: 0px;
        h1 {
          font-size: 0.4267rem;
          line-height: 0.64rem;
        }
        .subtitle {
          font-size: 0.32rem;
          line-height: 0.4267rem;
          margin: 0.32rem 0px 0.4rem;
        }
        .course-time {
          .course-date {
            font-size: 0.32rem;
          }
          i {
            font-size: 0.32rem;
          }
        }
        .course-teacher-list {
          margin-top: 0.3733rem;
          font-size: 0.32rem;
        }
      }
    }
    @media (min-width: 768px) and (max-width: 1199px) {
      .course {
        padding: 0px;
      }
    }
  }
  @media (max-width: 767px) {
    .course-info {
      padding: 0.16rem 0px 0.2133rem;
    }
  }
  .course-nav {
    display: flex;
    .nav-item {
      margin-right: 34px;
      font-size: 18px;
      font-weight: 400;
      color: rgba($color: $chiral-week-color, $alpha: 0.4);
      line-height: 18px;
      padding: 12px 22px;
      cursor: pointer;
    }
    @media (max-width: 767px) {
      .nav-item {
        font-size: 0.32rem;
        padding: 0.2133rem 0px;
        i {
          font-size: 0.32rem;
        }
      }
    }
    .activity {
      border-bottom: 4px solid $chiral-btn-color;
      color: $chiral-week-color;
    }
  }
  .nav-fixed {
    position: fixed;
    top: 0px;
    background: $avatar-color;
    z-index: 2;
  }
}
.course-top {
  width: 100%;
  background: white;
}
.course-subclass {
  display: flex;
  margin-top: 8px;
  padding-bottom: 40px;
  .subclass-info {
    flex: 1;
    min-height: 300px;
    background: $avatar-color;
    margin-right: 8px;
    padding: 24px;
    font-size: 14px;
    width: 100%;
    word-break: break-all;
    :deep p {
      margin-bottom: 20px !important;
      vertical-align: middle;
    }
    :deep a {
      color: #4196e6;
      text-decoration: none;
    }
    :deep img {
      max-width: 100%;
    }
  }
  .sub-bottom {
    display: flex;
    justify-content: space-between;
  }
  .course-btn {
    padding: 4px 12px;
    border: 1px solid;
    background: $avatar-color;
    border-radius: 4px;
    color: $chiral-unchecked-color;
    border-color: $chiral-unchecked-color;
    cursor: auto;
  }
  .viable {
    color: $chiral-btn-color;
    border-color: $chiral-btn-color;
    cursor: pointer;
  }
  .subclass-right {
    display: flex;
    align-items: center;
  }
  .subclass-price {
    font-size: 14px;
    color: #808d9b;
  }
  @media (max-width: 767px) {
    .subclass-info {
      width: 100%;
      margin-right: 0px;
      padding: 0.6133rem 0.4267rem 0.6133rem 0.4267rem;
    }
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    .subclass-info {
      .problem-list {
        width: 100%;
      }
    }
  }
  .subclass-list {
    color: $chiral-week-color;
    .subclass-item {
      border-bottom: 1px solid #f1f6fc;
      padding-bottom: 24px;
      margin-bottom: 27px;
    }
    .sub-title {
      margin-bottom: 25px;
      .title {
        font-size: 18px;
        font-weight: 400;
        line-height: 18px;
        margin-right: 16px;
      }
      .info {
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        margin-right: 12px;
      }
    }
    @media (max-width: 767px) {
      .sub-title {
        margin-bottom: 0.2133rem;
        .title {
          margin-right: 0.2667rem;
          font-size: 0.4267rem;
        }
        .info {
          margin-right: 0.2667rem;
          font-size: 0.32rem;
        }
      }
    }
    .sub-time {
      display: flex;
      position: relative;
      .time-item {
        margin-right: 41px;
        i {
          margin-right: 8px;
        }
      }
    }
    @media (max-width: 767px) {
      .sub-time {
        flex-direction: column;
        .time-item {
          margin: 0.1867rem 0px 0.2933rem;
        }
        .course-btn {
          width: fit-content;
          height: fit-content;
          bottom: -0.1067rem;
          right: 0px;
          top: auto;
          transform: none;
          padding: 0.1067rem 0.24rem;
        }
      }
    }
    @media (min-width: 768px) and (max-width: 1199px) {
      .sub-time {
        .course-btn {
          right: 0px;
        }
      }
    }
    //小课状态的标签
    .tag {
      padding: 0px 2px;
      font-size: 12px;
      border: 1px solid #adb8cc;
      color: #9da6b8;
      border-radius: 2px;
      margin-right: 11px;
      display: inline-block;
    }
    .tag-visable {
      border-color: $chiral-free-color;
      color: $chiral-free-color;
    }
    // .tag-playback {
    //   border-color: $chiral-btn-color;
    //   color: $chiral-btn-color;
    // }
    .tag-zhibo {
      border-color: $chiral-free-color;
      background: $chiral-free-color;
      color: $avatar-color;
    }
    //
  }
  .problem-list {
    width: 290px;
    background: $avatar-color;
    padding: 24px 10px 24px 24px;
    color: $chiral-week-color;
    height: fit-content;
    min-height: 300px;
    h3 {
      font-size: 18px;
      font-weight: 400;
      line-height: 18px;
      margin-bottom: 10px;
    }
    .problem {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 40px;
      .pro-top {
        display: flex;
        font-size: 15px;
        font-weight: 500;
        line-height: 21px;
        margin-bottom: 4px;
        img {
          width: 18px;
          height: 18px;
          margin-right: 2px;
        }
      }
    }
    .problem:nth-last-child(1) {
      margin-bottom: 0px;
    }
  }
  //移动端的常见问题
  @media (max-width: 767px) {
    .problem-list {
      width: auto;
      padding: 0px;
      .problem {
        margin-bottom: 0.5333rem;
      }
    }
  }
}
@media (max-width: 767px) {
  .mobile-detail-content {
    width: 100%;
  }
}
</style>
