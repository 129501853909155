<template>
  <!-- 刷题公告 -->
  <div class="notice-wrap" v-if="!!noticeContent">
    <p class="notice-title">刷题公告</p>
    <p v-html="noticeContent" class="notice-con"></p>
  </div>
</template>

<script>
export default {
  name: "Notice",
  props: {
    noticeContent: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.notice-wrap {
  width: 316px;
  padding: 0 16px 27px;
  border-radius: 8px;
  background-color: #fff;
  background-image: url("../../assets/img/brash-questions/notice.png");
  background-repeat: no-repeat;
  background-size: 100%;
  box-shadow: 0px 2px 4px 0px rgba($color: $chiral-btn-color, $alpha: 0.1);
  font-family: PingFangSC-Regular, PingFang SC;
  .notice-title {
    font-size: 14px;
    font-weight: 500;
    color: $chiral-btn-color;
    line-height: 23px;
    letter-spacing: 1px;
    padding: 28px 0 51px 152px;
  }
  .notice-con {
    font-size: 14px;
    font-weight: 400;
    color: $chiral-color;
    line-height: 21px;
  }
}
@media (max-width: 767px) {
  .notice-wrap {
    padding: 0 30px 19px;
  }
}
</style>
