import { request } from "./request";
const gatewayApi = process.env.VUE_APP_GATEWAY_URL;
const zhixinApi = process.env.VUE_APP_ZHIXIN_URL;

// 我的订单
// 订单列表
export const getPagedOrders = (params) =>
  request(`${gatewayApi}/v3/charge/pagedOrders`, "get", { params });
// 订单详情
export const queryOrderNew = (params) => {
  return request(`${gatewayApi}/v3/charge/queryOrderNew`, "get", { params });
};
// 取消订单
export const cancelOrder = (data) => {
  return request(`${zhixinApi}/v1/charge/cancel`, "post", { data });
};
// 获取退款信息
export const getRefundAmount = (params) => {
  return request(`${zhixinApi}/v1/Charge/getRefundAmount`, "get", { params });
};
// 退款
export const refund = (data) => {
  return request(`${zhixinApi}/v1/Charge/refund`, "post", { data });
};
// 保存快递单号
export const saveExpressNo = (data) => {
  return request(`${zhixinApi}/v1/Charge/saveExpressNo`, "post", { data });
};
