<template>
  <div class="live-content">
    <liveroom-module
      v-if="classId"
      :class-id="classId"
      :sub-id="subId"
      :is-replay="isReplay"
      @live-info="getLiveInfo"
      :course-belong="'chiral'"
      :track="() => {}"
    ></liveroom-module>
  </div>
</template>
<script>
import Vue from "vue";
// import $ from "jquery";
import Message from "lingdongliang-module/lib/Message.js";
// import OpenApp from "lingdongliang-module/lib/OpenApp";
// import "lingdongliang-module/lib/theme_package/OpenApp.css";
import { LiveroomModule, SMSLogin } from "lingdongliang-module";
import fullscreen from "vue-fullscreen";

Vue.use(LiveroomModule);
Vue.use(SMSLogin);
Vue.use(Message);
Vue.use(fullscreen);

export default {
  name: "LiveRoom",
  data() {
    return {
      classId: 0,
      subId: 0,
      isReplay: false,
      isMobileStyle: document.body.clientWidth < 768,
    };
  },
  mounted() {
    this.classId = Number(this.$route.params.classId);
    this.subId = Number(this.$route.params.subId);
    this.isReplay = this.$route.params.action === "replay";
    console.log("log", this.classId);
  },
  methods: {
    // track: function ({ node, props }) {
    //   this.zhuGeEventTrack(node, props);
    // },
    getLiveInfo() {
      // const params = {
      //   websiteOwnership: "ldl",
      //   openParams: {
      //     classId: this.classId,
      //     subclassId: this.subId,
      //     isLive: !this.isReplay,
      //     price: Number(liveInfo.price),
      //     deadline_at: liveInfo.deadline_at,
      //   },
      //   openType: "live",
      //   track: this.track,
      // };
      // if (this.isMobileStyle) {
      //   OpenApp.createArouseApp(params);
      // }
    },
  },
  destroyed() {
    // if (this.isMobileStyle) {
    //   OpenApp.removeArouseApp();
    // }
    // $(window).off("scroll", this.scrollFunc);
  },
};
</script>
<style lang="scss" scoped>
.live-content {
  font-size: 14px;
  :deep #live-chat {
    margin-left: 8px;
    .wrap-input {
      background: $avatar-color;
      border-top: 1px solid rgba($color: $chiral-color, $alpha: 0.08);
      .text-wrap textarea {
        background: rgba($color: $chiral-color, $alpha: 0.03);
      }
    }
    .el-tab-pane {
      padding: 20px 16px 10px;
    }
  }
  :deep #chat {
    .input-wrapper {
      background: $avatar-color;
    }
  }
}
:deep .content {
  min-height: 0px !important;
  width: auto !important;
}
.el-tabs__active-bar {
  background-color: $chiral-btn-color;
}
@media (min-width: 300px) and (max-width: 767px) {
  .container {
    padding: 0;
    margin-top: 0 !important;
  }
}
.gift-wrap input {
  margin-top: 0;
}
</style>
