export const m = {
  // Problem.vue
  Descriptions: "题目描述",
  Inputs: "输入描述",
  Outputs: "输出描述",
  Sample_Input: "样例输入",
  Sample_Output: "样例输出",
  Hint: "提示",
  Source: "题目来源",
  Status: "状态",
  Information: "题目信息",
  Time_Limit: "时间限制",
  Memory_Limit: "内存限制",
  Created: "出题人",
  Level: "难度",
  Score: "分数",
  Tags: "标签",
  Show: "显示",
  // About.vue
  Result_Explanation: "结果解释",
  Pending_Juding: "您的解答将很快被测评，请等待结果。",
  Compile_Error: "无法编译您的源代码，点击链接查看编译器的输出。",
  Accepted: "您的解题方法是正确的。",
  Wrong_Answer: "您的程序输出结果与判题程序的答案不符。",
  Runtime_Error:
    "您的程序异常终止，可能的原因是：段错误，被零除或用非0的代码退出程序。",
  Time_Limit_Exceeded:
    "您的程序使用的CPU时间已超出限制（Java 有三倍的时间限制），可能的原因是：代码复杂度过高或内部有死循环等问题存在。",
  Real_Limit_Exceeded:
    "您的程序使用的进程时间已超出限制（进程时间是CPU时间的三倍），可能的原因是：读入或输出被卡。",
  Memory_Limit_Exceeded: "程序实际使用的内存已超出限制。",
  System_Error: "糟糕，判题程序出了问题。请报告给管理员。",
  // FAQ.vue
  Frequently_Asked_Questions: "常见问题",
  // ContestDetail.vue
  Problems: "题目",
  Announcements: "公告",
  Submissions: "提交信息",
  Rankings: "Rank排名",
  Overview: "概要",
  Admin_Helper: "管理员助手",
  // ContestProblemList.vue
  Problems_List: "问题列表",
  Contest_Home: "比赛首页",
  Mine_Submit: "我的提交",
  All_Submit: "所有提交",
  Contest_Rank: "排名",
  Contest_Analysis: "解析",
  Statement: "声明",
  // NavBar.vue
  Home: "首页",
  NavProblems: "问题",
  Contests: "比赛",
  PersonalRecord: "个人战绩",
  PersonalInformation: "个人信息",
  QuestionBank: "题库",
  MeterRecord: "评测记录",
  NavStatus: "状态",
  Rank: "排名",
  ACM_Rank: "ACM 排名",
  OI_Rank: "OI 排名",
  About: "关于我们",
  Judger: "判题解释",
  FAQ: "常见问题",
  Login: "登录/注册",
  Register: "注册",
  MyHome: "我的主页",
  MySubmissions: "我的提交",
  Settings: "我的设置",
  Management: "后台管理",
  Logout: "退出",
  // announcements.vue
  Refresh: "搜索",
  Back: "返回",
  No_Announcements: "暂无公告",
  // Setting.vue
  Profile: "个人信息设置",
  Account: "账号设置",
  Security: "安全设置",
  // AccoutSetting.vue
  ChangePassword: "更改密码",
  ChangeEmail: "更改邮箱",
  Update_Password: "更新密码",
  // ProfileSetting.vue
  Avatar_Setting: "头像设置",
  Profile_Setting: "基本信息",
  Profile_Mail: "邮寄地址",
  Profile_Shown: "主页信息展示",
  Address: "详细地址",
  School: "学校",
  UserName: "用户",
  Province: "省份",
  Profile_PCD: "省/市/区",
  Profile_Grade: "年级",
  Email: "邮箱",
  Mobile: "手机号",
  Address_Name: "收件人姓名",
  Address_Mobile: "收件人电话",
  City: "市",
  District: "区/县",
  ShowContestReCord: "比赛记录",
  // SecuritySettig
  Sessions: "登录记录",
  Two_Factor_Authentication: "双因素认证",
  // Login.vue
  LoginUsername: "用户名",
  LoginPassword: "密码",
  TFA_Code: "双因素认证号码",
  No_Account: "还没账号，立即注册!",
  Forget_Password: "忘记密码",
  UserLogin: "登录",
  // Register.vue
  RegisterUsername: "用户名",
  RegisterMobile: "手机号",
  AccountNumber: "账号",
  Email_Address: "电子邮箱",
  RegisterPassword: "密码",
  Password_Again: "确认密码",
  Captcha: "验证码",
  SMSCaptcha: "短信验证码",
  UserRegister: "注册",
  Already_Registed: "已经注册？现在登录!",
  // ResetPassword.vue and ApplyResetPassword.vue
  Reset_Password: "重置密码",
  RPassword: "密码",
  RPassword_Again: "确认密码",
  RCaptcha: "验证码",
  ApplyEmail: "电子邮箱",
  Send_Password_Reset_Email: "发送重置密码到邮箱",
  // UserHome.vue
  UserHomeSolved: "已解决数量",
  UserHomeserSubmissions: "提交次数",
  UserHomeScore: "分数",
  List_Solved_Problems: "已解决问题的列表",
  UserHomeIntro: "这个家伙太懒了，还没有做题呢...",
  // 新加的
  Config: "确定",
  To_Sign_Up: "立即报名",
  Review: "回顾",
  Rating: "评分",
  ContestRecords: "参与比赛",
  ContestInstructions: "比赛说明",
  CurrentlyRanked: "即时排名",
  Sample: "示例",
  FeedBack: "问题反馈",
  TimeEating: "耗时",
  TotalScore: "总分",
  CommitTime: "时间",
  Language: "语言",
  MemoryEat: "内存",
  System_Message: "系统消息",
  EnterContest: "进入比赛",
  UpdateUserName: "请先填写用户名",
  Live: "直播",
  PWDLogin: "密码登录",
  QuickLogin: "验证码登录",
  GetVerificationVode: "获取验证码",
  SendSuccess: "发送成功",
  GraphicCaptchasRequired: "需要图形验证码",
  VerifyException: "验证异常，请重试!",
  InputMobile: "请输入手机号",
  InputMobileError: "手机号码格式错误",
  MobileError: "手机号码错误",
  InputPWD: "请输入密码",
  RegisterPWDTip: "8-40个字符，必须包含数字和字母，区分大小写",
  PWDMinLength: "密码最小的长度为8",
  PWDMaxLength: "密码最大的长度为40",
  TwoDifferentPWSD: "两次密码不同",
  MobileCodeRequired: "请输入验证码",
  MobileCodeLength: "验证码长度为4",
  ValidateFields: "请验证错误字段",
  AccountRequired: "请填写手机号或邮箱",
  RegisterAccountRequired: "支持手机号或邮箱作为账号进行注册",
  AccountError: "账号格式错误，请检查",
  MobileFormatError: "手机号格式错误，请重新填写",
  EmailFormatError: "邮箱格式错误，请重新填写",
  Title: "标题",
  TimeLimit: "时间限制",
  MemoryLimit: "内存限制",
  NewPassword: "新密码",
  UpdatePassword: "修改密码",
  InputNewPWD: "请输入新密码",
  ShowMyRank: "显示我的排名",
  ShowSchool: "显示学校信息",
  SubInfo: "双击显示用户提交信息",
  ProblemNumber: "题目编号",
  problem_nav: "做题",
  problem_solution: "题解",
  user_solution: "我的题解",
  All: "全部",
  PHY: "物理",
  MATH: "数学",
  BIO: "生物",
  CHEM: "化学",
};
