<!-- 订单列表 -->
<template>
  <div class="content orders-wrap">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane
        v-for="item in tabList"
        :key="item.state"
        :label="item.value"
        :name="item.state.toString()"
      >
        <span v-if="item.state === 1" slot="label">
          {{ item.value }} <i class="unpaid-num">{{ ordersUnpaid }}</i></span
        >
        <span v-if="item.state === 2" slot="label">
          {{ item.value }} <i class="charged-num">{{ ordersCharged }}</i></span
        >
        <div
          class="orders-content"
          v-loading.lock="loading"
          element-loading-text="拼命加载中"
        >
          <slot v-if="orderList.length">
            <order-item
              v-for="item in orderList"
              :key="item.order_no"
              :item="item"
              @cancelPay="handleCancelPay"
            ></order-item>
          </slot>
          <el-empty
            v-else
            description="暂无订单"
            :image="require('../../assets/img/orders/order-empty.png')"
          ></el-empty>
        </div>
      </el-tab-pane>
    </el-tabs>
    <div v-if="currentPage === totalPages" class="no-more">
      ---没有更多了---
    </div>
  </div>
</template>

<script>
import OrderItem from "@/views/my-order/components/OrderItem.vue";
import { getPagedOrders } from "@/api/my-order.js";
import { GROUP_ACTIVITY } from "@/utils/constants";

export default {
  name: "OrderList",
  components: { "order-item": OrderItem },
  data() {
    return {
      activeName: "0",
      tabList: [
        {
          state: 0,
          value: "全部订单",
        },
        {
          state: 1,
          value: "待支付",
        },
        {
          state: 2,
          value: "已支付",
        },
      ],
      ordersCharged: 0,
      ordersUnpaid: 0,
      loading: false,
      orderList: [],
      currentPage: 1,
      pageSize: 10,
      state: 0,
      totalPages: 0,
      loadFinish: true,
    };
  },
  methods: {
    handleCancelPay() {
      this.getOrderList();
    },
    // 监听滚动--加载下一页
    handleScroll() {
      const scrollHeight = document.body.scrollHeight;
      const clientHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      const scrollTop =
        window.scrollY ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      const offsetBottom = scrollHeight - clientHeight - scrollTop;
      if (
        offsetBottom < 440 &&
        !this.loadFinish &&
        this.currentPage < this.totalPages
      ) {
        this.loadFinish = true;
        this.getOrderList(this.currentPage + 1);
      }
    },
    getOrderList(page = 1) {
      this.loading = true;
      let params = {
        page: page,
        page_size: this.pageSize,
        state: this.state,
      };
      getPagedOrders(params)
        .then((res) => {
          if (res.code === 1) {
            this.ordersCharged = res.data.orders_charged || 0;
            this.ordersUnpaid = res.data.orders_unpaid || 0;
            this.currentPage = page;
            this.totalPages = Math.ceil(res.data.total / this.pageSize);
            let list = res.data.list || [];
            list.forEach((item) => {
              // 团购已支付但未成团超时
              if (
                item.various &&
                item.various.length &&
                item.various[0].activity_id &&
                item.various[0].activity_type === GROUP_ACTIVITY.GROUP &&
                item.various[0].activity_info.state === 3
              ) {
                item.state = 9;
              }
            });
            if (page === 1) {
              this.orderList = list;
            } else {
              this.orderList = this.orderList.concat(list);
            }
          } else {
            this.$message.error(res.message);
          }
          this.loading = false;
          this.$nextTick(() => {
            this.loadFinish = false;
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleClick(tab) {
      this.state = Number(tab.name);
      this.getOrderList();
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
    // 获取订单列表
    this.getOrderList();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
};
</script>

<style lang="scss" scoped>
.orders-wrap {
  .el-tabs {
    padding-top: 20px;
    .unpaid-num {
      color: #ef4d3c;
      font-style: normal;
    }
    .charged-num {
      color: $chiral-btn-color;
      font-style: normal;
    }
    ::v-deep .el-tabs__item.is-active {
      font-size: 18px;
      font-weight: 500;
    }
  }
  .orders-content {
    min-height: 684px;
    .el-empty {
      height: 684px;
    }
  }
  .no-more {
    font-size: 14px;
    color: #c8c9cc;
    text-align: center;
    line-height: 30px;
    padding-bottom: 20px;
  }
}
</style>
