<!-- 登录/注册页外层 -->
<template>
  <div class="login-page-wrap">
    <div class="login-cont">
      <img
        v-if="!isMobile"
        class="login-pic"
        src="@/assets/img/login/login-pic.png"
        alt="picture"
      />
      <!-- 表单 -->
      <div class="login-from">
        <div class="logo-cont">
          <img src="@/assets/img/login/logo.png" alt="logo" />
          <p class="info">开罗尔 — 连接你与竞赛的桥梁</p>
        </div>
        <div class="form-title">
          <el-button
            v-for="item in formTitle"
            :key="item.id"
            type="text"
            class="toggle-login btn-text"
            :class="activeId === item.id ? 'active' : ''"
            @click.stop="changeActive(item.id)"
          >
            {{ item.title }}
          </el-button>
        </div>
        <!-- 表格区 -->
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { isMobile } from "@/utils/utils";
export default {
  name: "LoginPageWrap",
  props: {
    // form标题
    formTitle: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isMobile: isMobile(),
      activeId: 1,
    };
  },
  methods: {
    changeActive(id) {
      this.activeId = id;
      this.$emit("change-active", id);
    },
  },
};
</script>

<style lang="scss" scoped>
.login-page-wrap {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../assets/img/login/login-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .login-cont {
    display: flex;
    justify-content: space-between;
    min-width: 1370px;
    height: 770px;
    background: #ffffff;
    border-radius: 29px;
  }
  .login-from {
    width: 336px;
    margin: 150px 190px 0 0;
  }
  .logo-cont {
    width: fit-content;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #161515;
    line-height: 20px;
    img {
      margin: 0px 0px 15px;
      width: 336px;
    }
  }
  .form-title {
    text-align: center;
    margin: 44px 0 40px;
    button.toggle-login:last-child {
      margin-right: 0;
    }
    button.toggle-login {
      margin-right: 78px;
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      color: rgba(22, 21, 21, 0.5);
      line-height: 25px;
      height: auto;
      background: transparent;
      padding: 0;
      &.active {
        position: relative;
        color: #161515;
        font-weight: 600;
      }
      &.active::after {
        position: absolute;
        left: 50%;
        top: 90%;
        transform: translate(-50%, 0);
        border-radius: 1px;
        content: "";
        display: block;
        width: 66px;
        height: 4px;
        border-radius: 2px;
        background: rgba($color: $chiral-btn-color, $alpha: 0.5);
      }
    }
  }
}
// 适配
@media (max-width: 1370px) {
  .login-page-wrap {
    overflow-x: scroll;
    overflow-y: visible;
  }
}
@media (max-width: 767px) {
  .login-page-wrap {
    min-width: 100%;
    display: block;
    background: #fff;
    .login-cont {
      display: block;
      min-width: 100%;
      height: 100%;
      border-radius: 0px;
    }
    .login-pic {
      display: none;
    }
    .login-from {
      padding: 1.8rem 0.5rem 0 0.5rem;
      margin: 0;
      width: 100%;
    }
    .logo-cont {
      font-size: 0.32rem;
      text-align: center;
      margin: auto;
      img {
        width: 6.55rem;
        height: auto;
      }
    }
    .form-title {
      margin: 0.8rem 0 0.8rem;
      button.toggle-login {
        margin-right: 2rem;
      }
    }
  }
}
</style>
