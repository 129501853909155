<template>
  <div
    class="content order-detail OrderDetail"
    v-loading="loading"
    element-loading-text="拼命加载中"
  >
    <!-- 订单信息 -->
    <div class="order-xz" style="position: relative">
      <!-- 订单状态 -->
      <p class="order-state">
        <i class="chiral fa-gou" v-if="order.state === 2"></i>
        <i
          class="chiral fa-gantan"
          v-if="order.state !== 2"
          :style="order.state === 1 ? 'color:#FFA000' : 'color:#C1C1C1'"
        ></i>
        <span class="paid" v-if="order.state === 2">已支付</span>
        <span v-else-if="order.state === 1" style="color: #ffa000">待支付</span>
        <span v-else-if="order.state === 6" style="color: #c1c1c1">已取消</span>
        <span v-else-if="order.state === 4" style="color: #c1c1c1">已退款</span>
        <span v-else style="color: #c1c1c1">已超时</span>
      </p>
      <p class="order-number">订单号：{{ order.order_no }}</p>
      <p class="order-time">
        下单时间：
        {{ moment(order.order_at * 1000).format("YYYY-MM-DD HH:mm:ss") }}
      </p>
      <!-- 操作 -->
      <div class="phone_btn">
        <el-button
          v-if="order.state === 1"
          type="primary"
          class="order-nobtn"
          @click="goPay(order.order_no)"
        >
          立即支付(剩余&nbsp;{{ payCountDown(timestamp) }})
        </el-button>
        <div
          v-if="order.state === 1"
          class="cancel"
          @click="cancelPay(order.order_no)"
        >
          取消订单
        </div>
      </div>
    </div>
    <!-- 收货信息 -->
    <div class="order-address-info" v-if="hasAddress">
      <h3 class="order-title"><span class="front-line"></span>收货信息</h3>
      <div class="order-address-content">
        <p>
          <span>{{ order.receiver }}</span> <span>{{ order.mobile }}</span>
        </p>
        <p>
          <span>{{ order.province }}</span>
          <span>{{ order.city }}</span>
          <span>{{ order.district }}</span>
        </p>
        <p>
          {{ order.address }}
        </p>
      </div>
    </div>
    <!-- 课程信息 -->
    <h3 class="order-title"><span class="front-line"></span>课程清单</h3>
    <div class="order-detail-classinfo-container new">
      <div class="order-detail-classinfo-goodsinfo">
        <div class="order-detail-classinfo-goodsinfo-thead">
          <div>课程名称</div>
          <div class="text-center">课程开始结束时间</div>
          <div class="text-center">价格</div>
          <div class="text-right">
            <span style="padding: 0 28px">操作</span>
          </div>
        </div>
        <div class="order-detail-classinfo-new-tbody">
          <slot v-for="item in order.classList">
            <div
              :key="item.class_id"
              class="class-list-wrap"
              :class="{
                'union-container':
                  item.union_id !== 0 && item.activity_type === 2,
              }"
            >
              <div
                class="union-header-info clearfix"
                v-if="item.union_id !== 0 && item.activity_type === 2"
              >
                <span class="sprite-lbshen lianbao-tag pull-left"></span>
                <span class="union-header-link pull-left">
                  {{ item.youhuiInfo }}</span
                >
                <span
                  v-if="item.material_count"
                  class="union-header-gift pull-right"
                >
                  <i class="chiral fa-huangzeng"></i>联报赠品*{{
                    item.material_count
                  }}
                </span>
              </div>
              <div
                v-for="(item, index) in item.various"
                :key="index"
                :class="
                  'singleCourse five ' +
                  (item.activity_id !== 0 && item.activity_type === 2
                    ? 'union'
                    : 'general')
                "
              >
                <div v-if="!isMobile">
                  <img
                    class="course-img"
                    @click="goCourseDetail(item)"
                    :src="item.cover || require('@/assets/img/live/course.png')"
                    alt="课程图片"
                  />
                </div>
                <div class="classInfo">
                  <h4>
                    {{ item.subject }}
                    <span v-if="item.goods_type === 13">| 教材费</span>
                    <span v-else>| 共{{ item.subclasses_count }}节</span>
                  </h4>
                  <div v-if="isMobile" class="show-mobile time-block">
                    {{ item.duration && item.duration.date }}
                  </div>
                  <!-- 材料信息 -->
                  <div
                    v-if="item.material_count"
                    class="gift-block"
                    data-id="<%= el.material_temp_id %>"
                  >
                    <i class="chiral fa-zeng"></i>教材&福利*{{
                      item.material_count
                    }}
                  </div>
                </div>
                <!-- 课程有有效期 -->
                <div v-if="!isMobile" class="hidden-xs-only">
                  <div v-if="item.goods_type !== 13">
                    {{ item.duration && item.duration.date }}
                  </div>
                </div>
                <!-- 价格 -->
                <div class="course-price">
                  <slot
                    v-if="order.isGroup || item.isLianBao || item.coupon_id > 0"
                  >
                    <span class="delete-price" v-if="item.goods_type !== 13"
                      >¥{{ Number((item.total_price / 100).toFixed(2)) }}</span
                    ><br />
                  </slot>
                  <span
                    :class="{
                      'group-price': order.isGroup,
                      'union-price': item.isLianBao,
                      'general-price': !order.isGroup && !item.isLianBao,
                    }"
                    >¥{{ item.real_price }}</span
                  >
                  <slot v-if="order.isGroup || item.isLianBao">
                    <br /><span
                      :class="{
                        'group-tag': order.isGroup,
                        'union-tag': item.isLianBao,
                      }"
                      v-if="item.goods_type !== 13"
                      >{{ order.isGroup ? "团购价" : "联报价" }}</span
                    >
                  </slot>
                  <slot v-if="item.subclasses_count !== item.various.length">
                    <br /><span class="left-tag"
                      >剩余{{ item.various.length }}章节</span
                    >
                  </slot>
                </div>
                <!-- 操作 -->
                <div class="btn-group">
                  <div>
                    <el-button
                      v-if="
                        item.state === 2 &&
                        item.activity_type === 1 &&
                        item.activity_id &&
                        item.activity_info.state === 1
                      "
                      type="danger"
                      class="share-group-btn"
                      @click="shareGroup(item.activity_id)"
                      size="mini"
                      >分享完成拼团</el-button
                    >
                    <el-button
                      v-if="canWatchClass(item)"
                      type="primary"
                      @click="goMyCourse(item)"
                      size="mini"
                    >
                      去看课
                    </el-button>
                    <div
                      v-if="canRefund(item)"
                      class="order-refund"
                      @click="getRefundAmount(item.order_no)"
                    >
                      申请退款
                    </div>
                    <div
                      v-if="item.state === 4 && item.refund_money"
                      class="order-refund"
                    >
                      已退款¥{{ item.refund_money / 100 }}
                    </div>
                    <div
                      v-if="item.state === 8"
                      class="order-refund has-refund"
                    >
                      退款中
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </slot>
        </div>
      </div>
    </div>
    <!-- 价格信息 -->
    <div class="order-count clearfix">
      <table>
        <tbody>
          <tr>
            <td class="text">合计金额：</td>
            <td class="price">¥{{ order.total_price }}</td>
          </tr>
          <tr>
            <td class="text">活动优惠：</td>
            <td class="price">
              -¥{{
                Math.max(
                  Number(
                    (
                      order.total_price -
                      order.real_price -
                      order.total_coupon_price
                    ).toFixed(2)
                  ),
                  0
                )
              }}
            </td>
          </tr>
          <tr>
            <td class="text">礼券优惠：</td>
            <td class="price">-¥{{ order.total_coupon_price }}</td>
          </tr>
          <tr class="">
            <td class="text">应付金额：</td>
            <td
              class="price truly-price"
              :class="{ 'group-price': order.isGroup }"
            >
              ¥{{ order.real_price }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- 付款信息 -->
    <div class="table-info" v-if="order.state === 2">
      <div>交易流水号：{{ order.payment_no }}</div>
      <div>支付时间：{{ order.charge_at }}</div>
      <div>
        支付金额：<span :style="groupState ? 'color:#EF4D3C' : 'color:#FFA000'"
          >¥{{ order.real_price }}</span
        >&nbsp;({{ order.channel }})
      </div>
    </div>
    <RefundHandle ref="RefundHandle" :refundSuccess="getOrderInfo" />
  </div>
</template>

<script>
import orderHandle from "@/mixins/order-handle";
import { isMobile } from "@/utils/utils";
import $ from "jquery";
import { cancelOrder, queryOrderNew } from "@/api/my-order.js";
import moment from "moment";
import { STATE_MAP, productTypeMap } from "@/utils/constants";
import RefundHandle from "@/views/my-order/components/RefundHandle.vue";

export default {
  name: "OrderDetail",
  mixins: [orderHandle],
  data: function () {
    return {
      isMobile: isMobile(),
      moment,
      orderNo: "", //订单号
      timestamp: "",
      stateMap: STATE_MAP,
      order: {
        goods_type: 7,
      },
      loading: false,
      coupon_id: 0,
      goods_type: 2,
      material_arr: [],
      showLianbao: false,
      goods_id: 0,
      order_id: 0,
    };
  },
  components: { RefundHandle },
  methods: {
    // 跳转我的课程页面
    goMyCourse(course) {
      switch (course.course_belong) {
        case "开罗尔":
          this.$router.push({
            path: `/my-course/course-detail/${course.class_id}`,
          });
          break;
        case "质心官网":
          window.open(
            `${process.env.VUE_APP_ZHIXIN_OFFICIAL_URL}/page/mineLives/${course.class_id}`
          );
          break;
        case "质心灵动量":
          window.open(
            `${process.env.VUE_APP_ZHIXIN_LDL_URL}/mycourse-detail/${course.class_id}/live`
          );
          break;
        default:
          break;
      }
    },
    showLianBaoInfo(item) {
      for (let i = 0; i < item.various.length; i++) {
        if (item.various[i].goods_type === 5) {
          this.goods_id = item.various[0].goods_id;
          this.order_id = item.various[0].order_no;
          this.showLianbao = true;
          return;
        }
      }
    },
    closeLianBaoModal() {
      this.showLianbao = false;
    },
    getRefundAmount(orderNo) {
      this.$refs.RefundHandle.getRefundAmount(
        orderNo,
        $.extend(true, {}, this.order)
      );
    },
    // 取消订单
    cancelPay(orderNo) {
      this.$showDialog("确认取消订单", () => {
        cancelOrder({ order_no: orderNo }).then((res) => {
          if (res.code === 1) {
            this.$message.success("取消订单成功");
            this.getOrderInfo();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    // 获取订单详情
    getOrderInfo() {
      this.loading = true;
      queryOrderNew({ order_no: this.orderNo })
        .then((res) => {
          if (res.code === 1 && res.data) {
            if (res.data.product_type === productTypeMap.SVIP) {
              this.$router.push({ name: "NotFound" });
              return false;
            }
            this.order = this.processData(res.data || {});
            // 未支付
            if (this.order.state === 1) {
              this.startTimer();
            }
          } else {
            this.$message.error(res.message);
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getClassDutation(info) {
      const now = moment();
      const beginAt = moment(info.begin_at * 1000);
      const endAt = moment(info.end_at * 1000);
      let time = "";
      let date = "";
      if (info.time_cycle) {
        const timeCycle = JSON.parse(info.time_cycle);
        time = `${timeCycle[0]?.begin_at}-${
          timeCycle[timeCycle.length - 1]?.end_at
        }`;
      }
      if (beginAt.year() !== now.year() || beginAt.year() !== endAt.year()) {
        date = `${beginAt.format("YYYY.MM.DD")} - ${endAt.format(
          "YYYY.MM.DD"
        )}`;
      } else {
        date = `${beginAt.format("MM月DD日")} - ${endAt.format("MM月DD日")}`;
      }
      return { date, time };
    },
    //付款方式
    changeOrigin(obj) {
      // 主、子订单号相同，说明是单独下单的课程外面包了一层
      if (
        obj.various.length === 1 &&
        obj.order_no === obj.various[0].order_no
      ) {
        obj.channel = obj.various[0].channel.includes("ali")
          ? "支付宝"
          : obj.various[0].channel.includes("wx")
          ? "微信"
          : obj.various[0].channel.includes("zxb")
          ? "质心币"
          : "其他";
        obj.charge_at = moment((obj.various[0].charge_at || 0) * 1000).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        obj.payment_no = obj.payment_no || obj.various[0].payment_no;
      } else {
        if (Number(obj.state) === 2) {
          obj.channel = obj.channel.includes("ali")
            ? "支付宝"
            : obj.channel.includes("wx")
            ? "微信"
            : obj.channel.includes("zxb")
            ? "质心币"
            : "其他";
          obj.charge_at = moment((obj.charge_at || 0) * 1000).format(
            "YYYY-MM-DD HH:mm:ss"
          );
        }
      }
    },
    // 处理订单数据
    processData(order) {
      const orderInfo = JSON.parse(JSON.stringify(order));
      orderInfo.real_price = Number((orderInfo.real_price / 100).toFixed(2));
      this.changeOrigin(orderInfo);
      // 优惠价
      orderInfo.total_coupon_price = 0;
      orderInfo.various.forEach((el) => {
        el.real_price = Number((el.real_price / 100).toFixed(2));
        // 课程开始结束时间
        el.duration = this.getClassDutation({
          begin_at: el.begin_at,
          time_cycle: el.time_cycle,
          end_at: el.end_at,
        });
        // 是否可以退款
        let canRefund = el.can_refund;
        // 团购课
        if (el.activity_type === 1 && el.activity_info) {
          canRefund =
            canRefund &&
            el.activity_info.can_refund &&
            el.activity_info.state - 0 !== 1;
        }
        if (el.coupon_id > 0) {
          orderInfo.total_coupon_price += el.coupon_price;
        }
        el.canRefund = canRefund;
        el.isStation = el.course_belong === "chiral";
        el.isLianBao = el.union_id !== 0 && Number(el.activity_type) === 2;
      });
      orderInfo.isGroup = Number(orderInfo.various[0].activity_type) === 1;
      orderInfo.total_coupon_price = Number(
        (orderInfo.total_coupon_price / 100).toFixed(2)
      );
      orderInfo.total_price = Number((orderInfo.total_price / 100).toFixed(2));
      if (
        orderInfo.address_snapshot &&
        JSON.stringify(orderInfo.address_snapshot) !== "{}"
      ) {
        orderInfo.receiver = orderInfo.address_snapshot.receiver;
        orderInfo.mobile = orderInfo.address_snapshot.mobile;
        orderInfo.province = orderInfo.address_snapshot.province;
        orderInfo.city = orderInfo.address_snapshot.city;
        orderInfo.district = orderInfo.address_snapshot.district;
        orderInfo.address = orderInfo.address_snapshot.address;
      }
      this.setUnionInfo(orderInfo);
      return orderInfo;
    },
    // 处理小课信息
    setUnionInfo(obj) {
      obj.various.forEach((el) => {
        this.setMaterialInfo(el);
      });
      this.sortByDate(obj.various);
      obj.classList = [
        {
          union_id: 0,
          activity_type: 0,
          various: obj.various,
        },
      ];
    },
    resetGroup(classes) {
      const arrOut = [];
      for (let i = 0; i < classes.length; i++) {
        if (i === 0) {
          if (classes[0].union_id === 0) {
            arrOut.push({
              union_id: 0,
              various: [JSON.parse(JSON.stringify(classes[0]))],
            });
          } else {
            arrOut.push(JSON.parse(JSON.stringify(classes[0])));
          }
        } else {
          if (classes[i].union_id !== arrOut[arrOut.length - 1].union_id) {
            if (classes[i].union_id === 0) {
              arrOut.push({
                union_id: 0,
                various: [JSON.parse(JSON.stringify(classes[i]))],
              });
            } else {
              arrOut.push(JSON.parse(JSON.stringify(classes[i])));
            }
          } else {
            arrOut[arrOut.length - 1].various.push(
              JSON.parse(JSON.stringify(classes[i]))
            );
          }
        }
      }
      return arrOut;
    },
    gatherGroup(truth, faker, orderAt) {
      truth = JSON.parse(JSON.stringify(truth));
      const a = faker.find((el) => {
        return el.union_id === truth[0].union_id;
      });
      a.class_list = Array.isArray(a.class_list) ? a.class_list : [];
      a.gift_list = Array.isArray(a.gift_list) ? a.gift_list : [];
      if (truth.length !== a.class_list.length) {
        truth.forEach((el) => {
          el.union_id = 0;
        });
        return {
          notUnion: true,
          class_list: truth,
        };
      }
      if (a.begin_at > orderAt) {
        truth.forEach((el) => {
          el.union_id = 0;
        });
        return {
          notUnion: true,
          class_list: truth,
        };
      }
      const b = JSON.parse(JSON.stringify(a.class_list));
      let maxIncart = 0;
      for (let i = 0; i < b.length; i++) {
        const c = truth.find((el) => {
          return el.class_id - 0 === b[i].class_id - 0;
        });
        b[i] = c;
        maxIncart = c.in_cart_at > maxIncart ? c.in_cart_at : maxIncart;
      }
      return {
        union_id: a.union_id,
        various: b,
        notUnion: false,
        in_cart_at: maxIncart,
        // material_info: a.materials_info,
        title: a.title,
        discount_type: a.discount_type,
        activity_type: 2,
        activity_id: a.union_id,
        discount_val: a.discount_val,
        material_info: a.gift_list,
      };
    },
    sortByDate(data) {
      data.sort(function (a, b) {
        const a1 = a.in_cart_at;
        const a2 = b.in_cart_at;
        return a1 - a2 > 0 ? -1 : 1;
      });
    },
    // 处理资料信息
    setMaterialInfo(obj) {
      if (!obj.material_info || obj.material_info.length === 0) return;
      let a = 0;
      obj.material_info.forEach((el) => {
        el.quantity = el.quantity ? el.quantity : el.num;
        el.name = el.name ? el.name : el.subject;
        a += Number(el.quantity);
      });
      obj.material_temp_id = this.material_arr.length;
      this.material_arr.push({
        infos: obj.material_info,
      });
      obj.material_count = a;
    },
    // 支付倒计时
    startTimer() {
      const payEndTime = Number(this.order.order_at) + 30 * 60;
      this.timestamp =
        Math.floor(payEndTime) - Math.floor(new Date().getTime() / 1000);
      if (Number(this.timestamp) < 1) {
        this.order.state = 3;
      }
      this.timer = setInterval(() => {
        if (Number(this.timestamp) < 1) {
          this.order.state = 3;
          clearInterval(this.timer);
        } else {
          this.timestamp -= 1;
        }
      }, 1000);
    },
    getMyCourseDetail(item, flag) {
      const hash = flag ? "class-detail" : "mycourse-detail";
      return (
        "/" +
        hash +
        "/" +
        item.class_id +
        (Number(item.goods_type) === 5 ? "/offline" : "/live")
      );
    },
    goCourse(item, flag) {
      if (Number(item.goods_type) === 13) {
        return false;
      }
      const hash = flag ? "livereg" : "mineLives";
      if (item.isStation || Number(item.goods_type) === 5) {
        this.$router.push({ path: this.getMyCourseDetail(item, flag) });
      } else {
        window.open(
          `${process.env.VUE_APP_ZHIXIN_URL}/#${hash}/${item.class_id}`
        );
      }
    },
    // 分享团购
    showGroup() {
      this.$router.push({
        path: "/groupActivity/" + this.order.various[0].activity_info.group_id,
        query: { isPaided: true },
      });
    },
    // 是否能看课
    canWatchClass(item) {
      return (
        ((item.state === 8 || item.state === 4) && item.can_watch) ||
        (item.state === 2 &&
          (!this.groupState || (this.groupState && this.groupSuccess === 2)))
      );
    },
    canRefund(item) {
      return (
        item.real_price &&
        item.canRefund &&
        item.state === 2 &&
        (!this.groupState || (this.groupState && this.groupSuccess === 2))
      );
    },
  },
  computed: {
    // 是否为团购
    groupState() {
      return this.order.various.some((item) => {
        return (
          item.goods_type === 2 && item.activity_id && item.activity_type === 1
        );
      });
    },
    // 组团是否成功 1为拼团中  2为成功
    groupSuccess() {
      let orderInfo = {};
      this.order.various.forEach((item) => {
        if (
          item.goods_type === 2 &&
          item.activity_id &&
          item.activity_type === 1
        ) {
          orderInfo = item;
        }
      });
      if (orderInfo.activity_info) {
        return Number(orderInfo.activity_info.state);
      } else {
        return 2;
      }
    },
    hasAddress() {
      return (
        this.order.various &&
        this.order.various.length &&
        this.order.various[0].address_id > 0
      );
    },
  },
  created() {
    this.orderNo = this.$route.params.orderNo;
    this.getOrderInfo();
  },
  beforeDestroy() {
    this.timer && clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
@import "@/views/my-order/css/order-detail.scss";
.order-detail {
  max-width: 1140px;
  margin: auto;
  padding-top: 30px;
  padding-bottom: 20px;

  .order-xz {
    border-radius: 2px;
    border: 1px solid rgba(51, 51, 51, 0.1);
    margin-bottom: 30px;
    box-sizing: border-box;
    padding: 19px 29px 19px 32px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 24px;

    .order-number,
    .order-time {
      font-size: 14px;
      font-weight: 400;
      color: rgba(51, 51, 51, 0.8);
    }

    .order-state {
      font-size: 20px;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 20px;
      .paid {
        color: $chiral-btn-color;
      }
      i {
        font-size: 22px;
        margin-right: 12px;
      }
    }

    .order-nobtn {
      float: right;
      margin-top: 16px;
    }

    .order-nobtn {
      position: absolute;
      top: 30px;
      right: 35px;
    }

    .cancel {
      position: absolute;
      right: 90px;
      top: 92px;
      color: rgba(51, 51, 51, 0.4);
      cursor: pointer;
    }
    .fa-gou {
      color: $chiral-btn-color;
    }
  }

  .order-title {
    padding: 0;
    margin: 0 0 16px;
    position: relative;
    font-size: 16px;
    font-weight: 500;
    color: #333;
    line-height: 16px;
    text-indent: 0.5em;

    .front-line {
      position: absolute;
      left: 0;
      top: 0;
      width: 2px;
      height: 100%;
      background: $chiral-btn-color;
    }
  }

  .order-address-info {
    padding-top: 8px;
    margin-bottom: 32px;

    .order-address-content {
      margin: 0;
      padding: 21px 24px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(51, 51, 51, 0.8);
      line-height: 14px;
      border: 1px solid rgba(51, 51, 51, 0.1);
      border-radius: 2px;
      display: flex;

      p > span {
        margin-right: 20px;
      }
    }
  }

  .table-info {
    text-align: right;
    font-size: 14px;
    font-weight: 400;
    color: rgba(51, 51, 51, 0.6);
    line-height: 14px;
    margin-bottom: 20px;

    > div {
      display: inline-block;
      margin-bottom: 5px;
      margin-right: 40px;
    }
  }

  .class-list-wrap {
    margin-bottom: 16px;
  }

  .order-count {
    padding: 16px 32px 8px;
    background: rgba(51, 51, 51, 0.03);
    border-radius: 2px;
    margin-bottom: 24px;

    table {
      float: right;
      border: none;
      margin: 0;
      padding: 0;

      td {
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        padding: 8px 0;
        border: none;
      }

      .text {
        color: rgba(51, 51, 51, 0.6);
      }

      .price {
        text-align: right;
        color: rgba(51, 51, 51, 0.8);
      }

      .truly-price {
        padding: 16px 0 16px 16px;
        font-size: 22px;
        font-weight: 500;
        color: #ffa000;
        line-height: 22px;
      }
    }
  }

  .order-refund {
    font-size: 14px;
    font-weight: 400;
    color: rgba(51, 51, 51, 0.8);
    line-height: 14px;
    padding: 10px 5px;

    &.has-refund {
      padding: 10px 2px;
    }
  }

  .fa-huangzeng {
    color: rgb(255, 160, 0);
    margin-right: 5px;
  }

  .fa-zeng {
    color: rgb(0, 199, 191);
    margin-right: 5px;
  }

  .order-count table .truly-price {
    &.group-price {
      color: #ef4d3c;
    }
  }
  .course-img,
  .order-refund {
    cursor: pointer;
  }

  .share-group-btn {
    background-color: #ef4d3c;
  }
}
.clearfix {
  &::after {
    display: table;
    content: " ";
    clear: both;
  }
}

@media (max-width: 767px) {
  .order-detail {
    padding-top: 0.53rem;
    .order-xz {
      border: none;
      padding: 0;
      overflow: hidden;
      margin: 16px 0 21px 0;

      .phone_btn {
        text-align: right;
        > div,
        button {
          position: static;
          display: inline-block;
        }
      }
      .phone_btn .cancel {
        margin-top: 24px;
        margin-right: 10px;
      }
    }

    .order-address-info .order-address-content.xs {
      display: block;
    }
  }
  .order-address-content {
    flex-direction: column;
    p {
      line-height: 0.67rem;
    }
  }
  .order-nobtn {
    font-size: 12px !important;
  }
  .classInfo {
    flex: 450;
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    align-items: flex-start;

    h4 {
      margin: 0;
      font-size: 16px;

      font-weight: 500;
      color: rgba(51, 51, 51, 1);
      line-height: 16px;

      span {
        font-size: 12px;

        font-weight: 400;
        color: rgba(51, 51, 51, 0.5);
        transform: translateY(-1px);
        display: inline-block;
      }
    }

    .gift-block {
      font-size: 14px;

      font-weight: 400;
      color: rgba(51, 51, 51, 0.6);
      padding: 4px 8px;
      background: rgba(51, 51, 51, 0.03);
      display: flex;
      align-items: center;
      border-radius: 4px;
      line-height: 18px;

      svg {
        margin-right: 6px;
      }
    }
  }
  .course-price {
    display: block !important;
    flex: auto !important;
    text-align: center;
    font-size: 14px;

    .left-tag {
      font-size: 12px;

      font-weight: 400;
      color: rgba(51, 51, 51, 0.6);
      line-height: 14px;
      padding: 2px;
      background: rgba(51, 51, 51, 0.06);
    }

    .delete-price {
      font-size: 14px;

      font-weight: 400;
      color: rgba(51, 51, 51, 0.3);
      line-height: 14px;
      padding: 0;
      background: transparent;
      text-decoration: line-through;
    }

    .group-price {
      font-size: 14px;

      font-weight: 400;
      color: rgba(239, 77, 60, 1);
      line-height: 14px;
      margin-top: 6px;
      padding: 0;
      background: transparent;
    }

    .group-tag {
      display: inline-block;
      margin-top: 8px;
      font-size: 12px;

      font-weight: 400;
      color: rgba(239, 77, 60, 1);
      line-height: 14px;
      padding: 2px;
      background: rgba(239, 77, 60, 0.12);
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 50%;
        margin-left: -5px;
        width: 0;
        margin-top: -6px;
        top: 0;
        @include triangle(6px, 10px, top, rgba(239, 77, 60, 0.12));
      }
    }

    .general-price {
      font-size: 14px;

      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 14px;
      padding: 0;
      background: transparent;
    }

    .union-price {
      font-size: 14px;

      font-weight: 400;
      color: rgba(255, 160, 0, 1);
      line-height: 14px;
    }

    .union-tag {
      display: inline-block;
      margin-top: 4px;
      font-size: 12px;

      font-weight: 400;
      color: $brand-warning;
      line-height: 14px;
      padding: 2px 6px;
      background: rgba(255, 160, 0, 0.12);
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 50%;
        margin-left: -5px;
        width: 0;
        margin-top: -6px;
        top: 0;
        @include triangle(6px, 10px, top, rgba(255, 160, 0, 0.11));
      }
    }
  }
  .btn-group > div {
    display: flex;
    margin-right: 0;
    max-height: 0.93rem;
    .el-button {
      font-size: 0.32rem;
      padding: 0.27rem;
    }
  }
}
</style>
