<template>
  <div class="content">
    <subject-nav
      :defaultSubject="subject"
      @change="changeSubject"
    ></subject-nav>
    <div class="quiz-breakpoint" v-if="echoData && !isMobile">
      <div class="title">最近刷题</div>
      <div class="quiz-info">
        <span class="quiz-info-title"
          >{{ echoData.question_title }}
          <span class="echoData-num">第{{ echoData.question_number }}题</span>
          <span class="echoData-id">{{ echoData.question_id }}</span>
        </span>
        <a
          :href="`${echoData.href}/${echoData.question_number}/${echoData.question_subject}`"
          >继续刷题</a
        >
      </div>
    </div>
    <div v-if="isMobile">
      <notice :noticeContent="noticeContent"></notice>
    </div>
    <div class="chiral-quiz-list">
      <div class="quiz-container">
        <div class="list-group-top">
          <span class="group-top-title">
            <i class="fa fa-more-live" style="vertical-align: 0px"></i>
            题库章节目录</span
          >
          <el-switch
            v-model="quizSwitch"
            @change="hiddenNoQuestion"
            :inactive-text="switchText"
            :width="30"
            active-color="#42C027"
          >
          </el-switch>
        </div>
        <div ref="view"></div>
      </div>
      <div class="brash-questions" v-if="!isMobile">
        <notice :noticeContent="noticeContent"></notice>
        <wrong-book
          :total_num="total_num"
          :num="num"
          :wrongList="wrongList"
          @change="changeKnowledge"
          :defaultActiveId="activeKowledgeId"
          @click.native="fnGoLink"
        ></wrong-book>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getWrongQuota,
  getQuestionNum,
  getSkillTree,
} from "@/api/wrongQuestions.js";
import "zhixin-libs/libs/quiz/quizlist.css";
import * as lib from "zhixin-libs/libs/quiz/quizlist/index.js";
import $ from "jquery";
import { mapActions, mapGetters } from "vuex";
import notice from "@/components/brash-questions/Notice.vue";
import wrongBook from "@/components/brash-questions/WrongBook.vue";
import subjectNav from "@/components/subject-nav/Main.vue";
import api from "@/plugins/api";
import { isMobile } from "@/utils/utils";
import cookie from "@/plugins/cookie";
import { SUBJECT } from "@/utils/constants.js";

export default {
  data() {
    return {
      noticeContent: "", //公告内容
      view: null,
      subject: "PHY",
      quizSwitch: true,
      isMobile: isMobile(),
      echoData: null,
      total_num: 500, //错题本容量
      num: 0, //错题数
      wrongList: [], //错题列表
      activeKowledgeId: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.sensorsTrack();
    });
  },
  computed: {
    ...mapGetters(["isAuthenticated", "user"]),
    switchText() {
      return this.quizSwitch ? "有题章节" : "无题章节";
    },
  },
  methods: {
    ...mapActions(["getProfile"]),
    sensorsTrack() {
      if (window.sensors?.track) {
        window.sensors.track("NavigationBarView", {
          XueKe: SUBJECT[this.subject],
          ButtonName: "刷题",
        });
      }
    },
    init() {
      this.initQuiz();
      this.getBoard(this.subject);
      // 获取错题本容量
      this.fnGetWrongQuota();
      // 获取错题列表
      this.fnGetWrongList();
    },
    fnGoLink() {
      this.$router.push({
        path: `/wrong-questions?sub=${this.subject}`,
      });
    },
    // 跳转错题本页面
    changeKnowledge(kowledgeId) {
      this.activeKowledgeId = kowledgeId;
      this.$router.push({
        path: `/wrong-questions?sub=${this.subject}&kid=${kowledgeId}`,
      });
    },
    fnGetWrongList() {
      let params = {
        site_belong: "chiral",
        subject_type: this.subject,
      };
      // 获取错题数
      getQuestionNum(params).then((numList) => {
        if (numList.code == 1) {
          if (JSON.stringify(numList.item) === "{}") {
            this.wrongList = [];
            return;
          }
          let params2 = {
            site_belong: "开罗尔",
            subject_type: this.subject,
          };
          //获取知识点
          getSkillTree(params2).then((res) => {
            if (res.code == 1) {
              this.wrongList = res.skill_tree.nodes.filter((item) => {
                for (let key in numList.item) {
                  if (item.id === Number(key)) {
                    item.wrongNum = numList.item[key];
                    return item.id === Number(key);
                  }
                }
              });
            }
          });
        }
      });
    },
    fnGetWrongQuota() {
      getWrongQuota({
        site_belong: "chiral",
        subject_type: this.subject,
      }).then((res) => {
        if (res.code == 1) {
          this.total_num = res.item.total_num;
          this.num = res.item.num;
        }
      });
    },
    initQuiz() {
      var controller = lib.default.Controller;
      var APP = lib.default.APP;
      APP.NotLogin = !this.isAuthenticated;
      APP.notLogin = () => {
        if (!this.isAuthenticated) {
          this.$router.push({
            path: "/login",
            query: { preRoute: this.$route.fullPath },
          });
        }
      };
      APP.requestUrl = process.env.VUE_APP_ZHIXIN_URL + "/v1";
      this.view = controller({
        APP: APP,
        requestUrl: process.env.VUE_APP_ZHIXIN_URL + "/v1",
      });
      $(this.$refs.view).html(this.view.$el);
    },
    // 获取公告
    getBoard(subject) {
      let params = {
        subject_type: subject,
        site_belong: "chiral",
      };
      this.noticeContent = "";
      api.getBoard(params).then((res) => {
        if (res.data.code === 1) {
          this.noticeContent = res.data.board.content;
        }
      });
    },
    changeSubject(subject) {
      this.quizSwitch = true;
      this.subject = subject;
      this.sensorsTrack();
      this.view.changeSubject(subject);
      this.getBoard(subject);
      this.view.hiddenNoQuestion(this.quizSwitch);
      // 获取错题本容量
      this.fnGetWrongQuota();
      // 获取错题列表
      this.fnGetWrongList();
    },
    hiddenNoQuestion() {
      this.view.hiddenNoQuestion(!this.quizSwitch);
    },
    // 最近刷题
    echoBreakpointRecord() {
      this.echoData = null;
      if (!this.user && cookie.getCookie("quiz-subject")) {
        var echoData = JSON.parse(
          decodeURIComponent(cookie.getCookie("quiz-subject"))
        );
        this.echoData = echoData;
      }
    },
    initSubjectValue() {
      this.subject = window.sessionStorage.getItem("back-quizlist") || "PHY";
    },
  },
  mounted() {
    this.initSubjectValue();
    this.echoBreakpointRecord();
    if (this.user) {
      this.init();
    }
  },
  watch: {
    user(newValue) {
      if (newValue) {
        this.init();
      }
    },
  },
  components: {
    notice,
    "wrong-book": wrongBook,
    "subject-nav": subjectNav,
  },
};
</script>
<style lang="scss" scoped>
.notice-wrap,
.book-wrap {
  width: auto;
}
.course-nav {
  margin: 38px 0px 25px;
}
.chiral-quiz-list {
  font-size: 14px;
  margin-top: 10px;
  display: flex;
}
// 最近刷题
.quiz-breakpoint {
  background: #fff;
  border-radius: 8px;
  .title {
    padding: 19px 0px 11px 16px;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    line-height: 20px;
  }
  .quiz-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px 28px 16px;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    line-height: 20px;
    .echoData-num,
    .echoData-id {
      color: rgba(0, 0, 0, 0.6);
    }
    .echoData-num {
      margin: 0px 30px 0px 42px;
    }
    a {
      padding: 6px 20px;
      background: #4a36fa;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
      border: none;
      cursor: pointer;
    }
  }
}
.quiz-container {
  flex: 1;
  position: relative;
  :deep .el-switch__label.is-active {
    color: #969696;
  }
  .list-group-top {
    padding: 19px 19px 13px 18px;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    line-height: 20px;
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 8px 8px 0px 0px;
    .group-top-title {
      flex: 1;
    }
  }
  @media (max-width: 767px) {
    .list-group-top {
      background: rgba(51, 51, 51, 0.07);
    }
  }
}
.brash-questions {
  width: 316px;
  padding-left: 16px;
  :deep .notice-wrap {
    margin-bottom: 10px;
  }
}
:deep .el-switch .el-switch__label {
  color: #969696;
}
</style>
<style>
#quiz-index div.quiz-left {
  float: none;
  width: 100%;
}
#quiz-index div.quiz-right {
  display: none;
}
#quiz-index .list-group {
  padding: 7px 0px 50px 0px;
}
.fa-icon_three-level:before {
  color: #9390f3 !important;
}
#quiz-index .list-group-item .base-line {
  border-radius: 8px;
  line-height: 45px;
}
#quiz-index .btn-info,
#quiz-index .btn-info:hover,
#quiz-index .btn-info:active:hover {
  background-color: #4a36fa;
  border-color: #4a36fa;
}
@media (min-width: 992px) {
  #quiz-index li.list-group-item {
    padding: 0px 21px;
  }
}
</style>
