<template>
  <div class="content">
    <div class="home">
      <!-- 轮播图 -->
      <div class="swiper-container" v-if="isConfigSwiper">
        <el-skeleton v-if="!configData.swiperList.length" animated>
          <template slot="template">
            <el-skeleton-item
              variant="image"
              :style="[{ height: skeleton.swiperHeight + 'px' }]"
            />
          </template>
        </el-skeleton>
        <swiper
          v-else
          :options="swiperOption"
          ref="mySwiper"
          class="swiper-container"
          :style="[{ 'min-height': skeleton.swiperHeight + 'px' }]"
        >
          <swiper-slide
            v-for="(item, index) in configData.swiperList"
            :key="index"
          >
            <picture>
              <source
                type="image/webp"
                :jump_url="item.href"
                onload
                :srcset="item.image + '?x-oss-process=image/format,webp'"
              />
              <img
                :src="item.image"
                :class_id="item.class_id"
                :jump_url="item.href"
                :alt="item.title"
              />
            </picture>
          </swiper-slide>
          <div
            class="swiper-button-prev"
            slot="button-prev"
            v-show="configData.swiperList.length > 1"
          ></div>
          <div
            class="swiper-button-next"
            slot="button-next"
            v-show="configData.swiperList.length > 1"
          ></div>
          <div
            class="swiper-pagination"
            slot="pagination"
            v-show="configData.swiperList.length > 1"
          ></div>
        </swiper>
      </div>
      <!-- 讲座列表 -->
      <div class="study-plan" v-if="isConfigCourse">
        <h1><span class="title">近期讲座</span></h1>
        <p class="subtitle">建立全新学习思维</p>
        <div class="plan-list">
          <el-row>
            <el-col
              :xs="12"
              :sm="12"
              :md="12"
              :lg="6"
              :xl="6"
              v-for="(item, index) in configData.courseList"
              :key="index"
            >
              <div
                class="plan-card"
                @click="
                  jumpUrl(
                    {
                      href: `${chiralUrl}/course-detail/${item.class_id}`,
                    },
                    'chiral_recommend_lectures'
                  )
                "
              >
                <img
                  class="animate__animated animate__delay-0.5s"
                  v-lazyload="{
                    imgUrl: item.image,
                    animate: 'animate__fadeIn',
                  }"
                  alt="讲座"
                />
                <div class="course-info">
                  <h2>{{ item.classInfo.subject }}</h2>
                  <div class="teacher-info">
                    <span v-if="item.classInfo.teachers_info.length">{{
                      item.classInfo.teachers_info[0].teacher_name
                    }}</span
                    ><span
                      class="tag"
                      :class="
                        item.classInfo.teachers_info.length &&
                        item.classInfo.teachers_info[0].labels
                          ? ''
                          : 'no-tag'
                      "
                      >{{
                        item.classInfo.teachers_info[0].labels.slice(0, 8) ||
                        "-"
                      }}</span
                    >
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- 竞赛资讯 -->
      <div class="study-counseling" v-if="configData.counselingList.length">
        <h1><span class="title">竞赛资讯</span></h1>
        <p class="subtitle">竞赛圈的新闻小报</p>
        <div class="counseling-list">
          <img
            v-if="!isPadAndMbile"
            @click="
              jumpUrl({
                href: `${checkedCounseling.href}`,
              })
            "
            :src="checkedCounseling.image"
            alt="资讯"
          />
          <ul class="counseling-info">
            <li
              @mouseenter="checkCounseling(item)"
              v-for="item in configData.counselingList"
              :key="item.id"
              @click="
                jumpUrl({
                  href: `${item.href}`,
                })
              "
            >
              <div class="date">
                <p class="day">{{ item.date | formatDay }}</p>
                <p>{{ item.date | formatYearMonth }}</p>
              </div>
              <div>
                <h2 :class="checkedCounseling.id === item.id ? 'activity' : ''">
                  {{ item.title }}
                </h2>
                <div class="desc">{{ item.desc }}</div>
                <div v-if="isMobile" class="m-date">
                  <i class="chiral fa-time"></i
                  ><span>{{ item.date | formatDate }}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import api from "@/plugins/api";
import qs from "qs";
import { isMobile } from "@/utils/utils";

export default {
  name: "RecommendCenter",
  data() {
    let that = this;
    return {
      chiralUrl: process.env.VUE_APP_CHIRAL_URL,
      isMobile: isMobile(),
      isPadAndMbile: document.body.clientWidth < 1200,
      isConfigSwiper: true,
      isConfigCourse: true,
      configUtmData: [],
      checkedCounseling: {},
      configData: {
        swiperList: [],
        courseList: [],
        counselingList: [],
      },
      swiperOption: {
        slidesPerView: "auto",
        pagination: {
          el: ".swiper-pagination",
        },
        grabCursor: true,
        loop: false,
        speed: 1000,
        clickable: true,
        preventClicks: false,
        preventClicksPropagation: false,
        navigation: {
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next",
        },
        on: {
          click: function (e) {
            let jumpUrl = e.target.getAttribute("jump_url");
            let classId = e.target.getAttribute("class_id");
            if (Number(classId)) {
              that.jumpUrl(
                {
                  href: `${process.env.VUE_APP_CHIRAL_URL}/course-detail/${classId}`,
                },
                "chiral_recommend_swiper"
              );
            } else if (jumpUrl) {
              that.jumpUrl({ href: jumpUrl });
            }
          },
        },
      },
      skeleton: {
        swiperHeight: "",
      },
    };
  },
  components: {
    swiper,
    swiperSlide,
  },
  methods: {
    //跳转逻辑
    jumpUrl(configObj, utmType) {
      let url = configObj.href;
      let utmObj = this.configUtmData[utmType];
      if (utmObj) {
        delete utmObj.type;
        url = `${url}?${qs.stringify(utmObj)}`;
      }
      window.open(url);
    },
    //获取欢迎页配置和utm参数配置
    getConfig() {
      Promise.all([api.getChiralrecommend(), api.getRecommendUtmInfo()]).then(
        (res) => {
          let [con, utm] = res;
          this.handleConfig(con);
          this.handleUtm(utm);
        }
      );
    },
    //处理欢迎页配置
    handleConfig(res) {
      let item = res.data;
      if (item.code === 1) {
        let handleData = this.groupConfigData(item.data || []);
        let classInfos = item.class_infos;
        this.configData = {
          swiperList: handleData[1] || [],
          courseList: handleData[3] || [],
          counselingList: handleData[4] || [],
        };
        for (let k in handleData[3]) {
          handleData[3][k].classInfo = classInfos[handleData[3][k].class_id];
        }
        this.checkedCounseling = this.configData.counselingList[0] || {};
        this.isConfigSwiper = Boolean(this.configData.swiperList.length);
        this.isConfigCourse = Boolean(this.configData.courseList.length);
        this.initSwiper();
      }
    },
    initSwiper() {
      if (this.configData.swiperList.length > 1) {
        this.swiperOption.autoplay = {
          delay: 3000,
          disableOnInteraction: false,
        };
        this.swiperOption.loop = true;
      }
    },
    //处理utm参数配置
    handleUtm(res) {
      let item = res.data;
      if (item.code === 1) {
        this.configUtmData = item.data || [];
      }
    },
    //配置数据分组
    groupConfigData(dataList) {
      let list = JSON.parse(JSON.stringify(dataList));
      let newConfig = {};
      list.forEach((item) => {
        newConfig[item.type] = newConfig[item.type] || [];
        newConfig[item.type].push(item);
      });
      return newConfig;
    },
    initSkeleton() {
      let contentWidth = document.querySelector(".content").offsetWidth;
      this.skeleton.swiperHeight = contentWidth * 0.38;
      this.skeleton.planHeight = contentWidth * 0.4;
    },
    checkCounseling(obj) {
      this.checkedCounseling = obj;
    },
  },
  mounted() {
    this.getConfig();
    this.$nextTick(() => {
      this.initSkeleton();
    });
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/font/YouSheBiaoTiHei.css";
.home {
  padding: 17px 0px 114px;
  img {
    width: 100%;
  }
  .swiper-container {
    img {
      display: block;
      width: 100%;
    }
  }
  // 学习规划
  .study-plan {
    text-align: center;
    padding: 62px 0px 0px;
    h1 {
      font-size: 30px;
      font-weight: 600;
      color: $chiral-text-color;
      line-height: 42px;
      letter-spacing: 1px;
      .title {
        position: relative;
      }
      .title::before,
      .title::after {
        content: "";
        width: 28px;
        height: 2px;
        background: #d8d8d8;
        border-radius: 10px;
        opacity: 0.43;
        position: absolute;
        bottom: 50%;
      }
      .title::before {
        transform: translate(-40px, -50%);
      }
      .title::after {
        transform: translate(10px, -50%);
      }
    }
    .subtitle {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 1px;
      color: rgba($color: $chiral-text-color, $alpha: 0.5);
    }
    .plan-list {
      margin: 0px -11px;
      padding-top: 50px;
    }
    .plan-card {
      margin: 0px 11px 30px 11px;
      box-shadow: 0px 0px 19px 0px #f4f1ff;
      border-radius: 9px;
      text-align: left;
      cursor: pointer;
      img {
        border-radius: 9px 9px 0px 0px;
        width: 100%;
        display: block;
      }
      h2 {
        font-size: 18px;
        font-weight: 500;
        color: $chiral-text-color;
        line-height: 30px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        height: 60px;
      }
      .course-info {
        padding: 15px 9px 18px;
        background: $avatar-color;
        border-radius: 0px 0px 9px 9px;
      }
      .teacher-info {
        display: flex;
        align-items: center;
        margin-top: 19px;
        font-size: 16px;
        font-weight: 400;
        color: #555555;
        line-height: 22px;
        .tag {
          margin-left: 10px;
          padding: 2px 5px;
          background: $chiral-tag-color;
          font-size: 12px;
          font-weight: 400;
          color: $chiral-btn-color;
          line-height: 17px;
          border-radius: 4px;
        }
        .no-tag {
          color: $avatar-color;
          background: $avatar-color;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .study-plan {
      padding: 0.8rem 0px 0px;
      h1 {
        font-size: 0.5333rem;
        font-weight: 600;
        color: #0d0c26;
        line-height: 0.7467rem;
      }
      .title::after,
      .title::before {
        display: none;
      }
      .subtitle {
        font-size: 0.3733rem;
        font-weight: 400;
        line-height: 0.5333rem;
      }
      .plan-list {
        padding-top: 0.6933rem;
        .plan-card {
          margin-bottom: 0.8rem;
          .course-info {
            padding: 0.24rem 0.16rem;
            h2 {
              font-size: 0.3733rem;
              font-weight: 500;
              line-height: 0.5333rem;
              height: 1.0667rem;
            }
            .teacher-info {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              font-size: 0.32rem;
              font-weight: 400;
              color: rgba($color: $chiral-text-color, $alpha: 0.5);
              line-height: 0.4533rem;
              margin-top: 0.1333rem;
            }
            span {
              padding: 0.0533rem 0.1333rem;
            }
            .tag {
              margin-left: 0px;
              margin-top: 0.1867rem;
            }
          }
        }
      }
    }
  }
  // 咨询
  .study-counseling {
    text-align: center;
    padding: 62px 0px 0px;
    h1 {
      font-size: 30px;
      font-weight: 600;
      color: $chiral-text-color;
      line-height: 42px;
      letter-spacing: 1px;
      .title {
        position: relative;
      }
      .title::before,
      .title::after {
        content: "";
        width: 28px;
        height: 2px;
        background: #d8d8d8;
        border-radius: 10px;
        opacity: 0.43;
        position: absolute;
        bottom: 50%;
      }
      .title::before {
        transform: translate(-40px, -50%);
      }
      .title::after {
        transform: translate(10px, -50%);
      }
    }
    .subtitle {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 1px;
      color: rgba($color: $chiral-text-color, $alpha: 0.5);
    }
    .counseling-list {
      display: flex;
      margin-top: 55px;
      img {
        width: 48.77%;
        display: block;
        border-radius: 6px;
        cursor: pointer;
      }
      .counseling-info {
        margin-left: 24px;
        text-align: left;
        width: 100%;
        li {
          padding: 0px 19px 30px 10px;
          display: flex;
          border-bottom: 1px solid #d8d8d8;
          margin-bottom: 56px;
          align-items: center;
          cursor: pointer;
          h2 {
            font-size: 18px;
            font-weight: 500;
            color: $chiral-text-color;
            line-height: 25px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            margin-bottom: 8px;
          }
          .activity {
            color: $chiral-btn-color;
          }
          .date {
            // width: 55px;
            font-size: 14px;
            font-family: YouSheBiaoTiHei;
            color: #999999;
            line-height: 18px;
            margin-right: 20px;
            text-align: center;
            .day {
              font-size: 18px;
              font-family: YouSheBiaoTiHei;
              color: #666666;
              line-height: 23px;
            }
          }
          .desc {
            font-size: 14px;
            font-weight: 400;
            color: rgba($color: $chiral-text-color, $alpha: 0.6);
            line-height: 20px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            height: 40px;
          }
        }
        li:nth-last-child(1) {
          margin-bottom: 0px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .study-counseling {
      padding: 0px;
      h1 {
        font-size: 0.5333rem;
        font-weight: 600;
        color: #0d0c26;
        line-height: 0.7467rem;
      }
      .title::after,
      .title::before {
        display: none;
      }
      .subtitle {
        font-size: 0.3733rem;
        font-weight: 400;
        line-height: 0.5333rem;
      }
      .counseling-list {
        margin-top: 0.56rem;
        .date {
          display: none;
        }
        .counseling-info {
          margin-left: 0px;
          li {
            border-bottom: none;
            padding: 0.2933rem 0.4rem 0.1867rem 0.4267rem;
            box-shadow: 0px 2px 4px 0px #f4f1ff;
            border-radius: 0.24rem;
            margin-bottom: 0.32rem;
            h2 {
              font-size: 0.3733rem;
              font-weight: 500;
              line-height: 0.5333rem;
            }
            .desc {
              font-size: 0.32rem;
              font-weight: 400;
              line-height: 0.3733rem;
              height: 0.7467rem;
            }
            .m-date {
              margin-top: 0.1867rem;
              i {
                font-size: 12px;
                margin-right: 0.24rem;
              }
              font-size: 0.32rem;
              font-weight: 400;
              color: #999999;
              line-height: 16px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .home {
    padding: 0.4267rem 0px 0.9067rem;
  }
}
</style>
