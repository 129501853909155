/* eslint-disable */
export const JUDGE_STATUS = {
  '-2': {
    name: 'Compile Error',
    short: 'CE',
    color: 'yellow',
    type: 'warning'
  },
  '-1': {
    name: 'Wrong Answer',
    short: 'WA',
    color: 'red',
    type: 'error'
  },
  '0': {
    name: 'Accepted',
    short: 'AC',
    color: 'green',
    type: 'success'
  },
  '1': {
    name: 'Time Limit Exceeded',
    short: 'TLE',
    color: 'red',
    type: 'error'
  },
  '2': {
    name: 'Real Time Limit Exceeded',
    short: 'TLE',
    color: 'red',
    type: 'error'
  },
  '3': {
    name: 'Memory Limit Exceeded',
    short: 'MLE',
    color: 'red',
    type: 'error'
  },
  '4': {
    name: 'Runtime Error',
    short: 'RE',
    color: 'red',
    type: 'error'
  },
  '5': {
    name: 'System Error',
    short: 'SE',
    color: 'red',
    type: 'error'
  },
  '6': {
    name: 'Pending',
    color: 'yellow',
    type: 'warning'
  },
  '7': {
    name: 'Judging',
    color: 'blue',
    type: 'info'
  },
  '8': {
    name: 'Partial Accepted',
    short: 'PAC',
    color: 'blue',
    type: 'info'
  },
  '9': {
    name: 'Submitting',
    color: 'yellow',
    type: 'warning'
  }
}

export const STATES = {
  KAITUAN: 1,
  PINTUAN_SUCCESS: 2,
  PINTUAN_FAIL: 3
}

export const LOGIN_TYPE = {
  LOGIN: 'LOGIN',
  REGISTER: 'REGISTER'
}
export const CONTEST_STATUS = {
  'NOT_START': '1',
  'UNDERWAY': '0',
  'ENDED': '-1',
  'RELEASED': -2
}
export const STATE_MAP = {
  INITIAL_STATE: 0,
  NOT_PAY: 1,
  HAS_PAYED: 2,
  TIMEOUT: 3,
  HAS_REFUNDED: 4,
  PAY_BY_COUPON: 5,
  HAS_CANCELED: 6,
  HAS_TRANSFERED: 7,
  PENDING: 8
}
export const GROUP_ACTIVITY = {
  GROUP: 1,
  LIAN_BAO: 2
}
export const MOD_SHARE_KEY = 'c7ebab27b974'

export const CONTEST_STATUS_REVERSE = {
  '1': {
    name: 'Not Started',
    color: 'yellow'
  },
  '0': {
    name: 'Underway',
    color: 'green'
  },
  '-1': {
    name: 'Ended',
    color: 'red'
  }
}

export const RULE_TYPE = {
  ACM: 'ACM',
  OI: 'OI'
}

export const CONTEST_TYPE = {
  PUBLIC: 'Public',
  PRIVATE: 'Password Protected'
}

export const USER_TYPE = {
  REGULAR_USER: 'Regular User',
  ADMIN: 1,
  SUPER_ADMIN: 'Super Admin',
  Discuss_ADMIN: 'Discuss Admin'
}

export const FUNC_ROLE = {
  ContestEditor: 'ContestEditor',
  ProblemEditor: 'ProblemEditor',
  DiscussionReviewer: 'DiscussionReviewer'
}

export const PROBLEM_PERMISSION = {
  NONE: 'None',
  OWN: 'Own',
  ALL: 'All'
}

export const STORAGE_KEY = {
  AUTHED: 'authed',
  PROBLEM_CODE: 'problemCode',
  languages: 'languages'
}

export function buildProblemCodeKey (problemID, contestID = null) {
  if (contestID) {
    return `${STORAGE_KEY.PROBLEM_CODE}_${contestID}_${problemID}`
  }
  return `${STORAGE_KEY.PROBLEM_CODE}_NaN_${problemID}`
}

export const GOOGLE_ANALYTICS_ID = 'UA-72702716-2'

export const DISCUSS_SUBJECT = {
  1: '数学',
	2: '物理',
	3: '化学',
	4: '生物',
	5: '全部',
}

export const SUBJECT = {
  PHY: "物理",
  BIO: "生物",
  CHEM: "化学",
  MATH: "数学",
  ENG: "英语",
};

export const PROBLEM_LIST = [
  {
    title:'开罗尔客服电话是多少？',
    content:'客服电话：400-0356256，工作时间10:00-19:00，周六日不休。'
  },
  {
    onlyShowGroup:true,//只团购时显示
    title:'拼团流程是什么？',
    content:'课程详情页面选择开团按钮 -> 完成支付开团成功 -> 拼团有效期内满足规则的人数参团并付款成功即为拼团成功，可在我的课程中看到所购买课程。'
  },
  {
    onlyShowGroup:true,
    title:'怎么算拼团成功？',
    content:'在拼团有效期内找到满足人数的好友参团，并付款成功，即为拼团成功。'
  },
  {
    onlyShowGroup:true,
    title:'如果拼团失败，如何退款？',
    content:'在拼团有效期内未达到拼团人数，即为拼团失败。失败后，支付金额一般会在原渠道退回，到帐时间以微信、支付宝等支付平台为准。'
  },
  {
    onlyShowGroup:true,
    title:'已参与的拼团可以随时撤销吗？',
    content:'开团或参与团购并付款后，不可撤销拼团，在拼团有效期结束后如拼团失败，拼团会自动取消并退款。'
  },
  {
    onlyShowGroup:true,
    title:'已原价购买课程，是否还能参与拼团？',
    content:'课程只能购买一次，原价购买课程后，不可再更改为拼团方式。'
  },
  {
    title: '讲座课程观看有效期是多久？',
    content: '若预约讲座为回放，则从预约之日起1个月内有效；若预约讲座直播未开始，则从讲座直播之日起1个月内有效。在有效期内可无限次回看。到期后，讲座将无法继续观看学习，需要重新预约讲座。建议大家合理安排时间及时观看。'
  },
  {
    title:'直播课程与回放课程有什么区别？',
    content:'在直播课程中，同学们可以随时提问，老师和助教会进行答疑。回放课程为直播录制的回放视频。为了达到最好的学习效果，建议同学们尽可能参与直播课程。'
  },
]

export const COURSE_STATE_BTN = {
  1:'进入直播',//课程进行中
  2:'看回放',
  3:'进入直播',//课程还未开始
  4:'已过期',
  5:'立即报名',
  6:'已过期 重新报名',//只限免费课
}
export const COURSE_STATE = {
  1:'进行中',
  2:'已结束',
  3:'未开始',
  4:'已结束',//小课若已过期展示成已结束
}

export const COURSE_STUDY_STATE = {
  1:"进行中",
  2:"已完结",
  3:"未开始"
}
export const ORDER_STATE = {
  1:"待支付",
  2:"已支付",
  3:"已超时",
  4:"已退款",
  5:"优惠券兑换",
  6:"已取消",
  7:"",
  8:"退款中",
  9:"团购已失效"
}

export const productTypeMap = {
  ONLINE_CLASS: 1,
  BIGLIVE_CLASS: 2,
  SUBLIVE_CLASS: 3,
  QUARK: 4, // 夸克
  OFFLINE_CLASS: 5,
  VARIOUS_LIVE_CLASS: 7,
  ONLINE_CLASS_BOARDING: 9,
  MULTI_ORDER: 10,
  ZXB: 11, // 质心币
  PROTON_RECHARGE: 12,
  SVIP: 15//会员卡
}

export const COURSE_BELONG ={
  ZHIXIN_OFFICIAL: "质心官网",
  ZHIXIN_LDL: "质心灵动量",
  CHIRAL: "开罗尔",
}
