import Vue from "vue";
import axios from "axios";
import qs from "qs";
import Request from "@/plugins/Request";

Vue.prototype.$http = axios;
axios.defaults.baseURL = process.env.VUE_APP_PROXY_URL + "/v1/";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

const gatewayApi = process.env.VUE_APP_GATEWAY_URL;
// const gatewayApi = "http://yapi.upho2015.com/mock/32/v3";
const zhixinApi = process.env.VUE_APP_ZHIXIN_URL;
// const adminApi = process.env.VUE_APP_ADMIN_URL
const passportApi = process.env.VUE_APP_PASSPORT_URL;
const staticApi = process.env.VUE_APP_STATIC_URL;
// const navigationApi = process.env.VUE_APP_NAVIGATION_URL
// const yapiApi = "https://yapi.upho2015.com/mock/17";

export default {
  getUserInfo(params) {
    return ajax(`${gatewayApi}/v3/user/getInfo`, "get", { params });
  },
  getSms(params) {
    return ajax(`${passportApi}/Sms/get`, "get", { params });
  },
  registerApi(json) {
    return ajax(`${passportApi}/v3/register/register`, "post", { json });
  },
  loginByCode(json) {
    return ajax(`${passportApi}/v3/auth/quickLogin`, "post", { json });
  },
  loginByPassword(json) {
    return ajax(`${passportApi}/v3/auth/access`, "post", { json });
  },
  resetPassword(json) {
    return ajax(`${passportApi}/v3/register/resetPassword`, "post", { json });
  },
  logout(json) {
    return ajax(`${gatewayApi}/v3/passport/logout`, "post", { json });
  },
  getChiralwelcome() {
    return ajax(`${zhixinApi}/Chiralwelcome/getInfo`, "get");
  },
  getChiralrecommend() {
    return ajax(`${zhixinApi}/Chiralrecommend/getInfo`, "get");
  },
  getUtmInfo() {
    return ajax(`${zhixinApi}/Chiralwelcome/getUtmInfo`, "get");
  },
  getRecommendUtmInfo() {
    return ajax(`${zhixinApi}/Chiralrecommend/getUtmInfo`, "get");
  },
  getLectureList(params) {
    return ajax(`${zhixinApi}/v1/Live/search`, "get", { params });
  },
  getClassLabels() {
    return ajax(`${zhixinApi}/v1/Labels/getClassLabels`, "get");
  },
  // 课程详情页
  getClassInfo(params) {
    return ajax(`${zhixinApi}/Live/getClassInfo`, "get", { params });
  },
  getUserSubclass(params) {
    return ajax(`${gatewayApi}/v3/subclass/getUserSubclass`, "get", { params });
  },
  getGroupsInfo(params) {
    return ajax(`${zhixinApi}/v1/Live/getGroupsInfo`, "get", { params });
  },
  liveBooking(params) {
    return ajax(`${zhixinApi}/Live/booking`, "get", { params });
  },
  //我的课程获取直播课
  getMyLiveCourse(params) {
    return ajax(`${zhixinApi}/v1/Live/getUserLiveClass`, "get", { params });
  },
  //我的课程获取面授课
  getMyOfflineCourse(params) {
    return ajax(`${gatewayApi}/v1/offline/myOfflineCourse`, "get", { params });
  },
  //我的课程获取讲座课
  getMyBookingCourse(params) {
    return ajax(`${gatewayApi}/v3/booking/getUserBooking`, "get", { params });
  },
  //获取日历购买的小课
  getMyAllCourse(params) {
    return ajax(`${gatewayApi}/v3/subclass/getUserScheduleSubclass`, "get", {
      params,
    });
  },
  // 我的课程-获取学习计划
  getUserStudyCalendar(params) {
    return ajax(`${gatewayApi}/v3/subclass/getUserStudyCalendar`, "get", {
      params,
    });
  },
  // 我的课程-获取是否有学习计划
  hasUserStudyCalendar() {
    return ajax(`${gatewayApi}/v3/subclass/hasUserStudyCalendar`, "get");
  },
  //获取页面渠道参数
  getUtmconfig(params) {
    return ajax(`${zhixinApi}/v1/Utmconfig/get`, "get", { params });
  },
  // 获取知识点树
  getSkillTree(params) {
    return ajax(`${zhixinApi}/v1/SkillTree/get`, "get", { params });
  },
  // 获取知识点
  getLearning(params) {
    return ajax(`${zhixinApi}/v1/Learning/getCoursesInfo`, "get", {
      params,
    });
  },
  // 获取知识点视频信息
  getLearningVideo(params) {
    return ajax(`${zhixinApi}/v1/Courses/getCourse`, "get", {
      params,
    });
  },
  // 上报知识点学习
  videoProgress(json) {
    return ajax(`${zhixinApi}/v1/Courses/saveVideoProgress`, "post", { json });
  },
  // 获取公告
  getBoard(params) {
    return ajax(`${zhixinApi}/v1/Question/getBoard`, "get", {
      params,
    });
  },
  //获取收货地址
  getAddress(params) {
    return ajax(`${zhixinApi}/v1/Address/get`, "get", {
      params,
    });
  },
  handleAddress(data, handle) {
    return ajax(`${zhixinApi}/v1/Address/${handle}`, "post", {
      data,
    });
  },
  // 设置默认地址
  setDefault(data) {
    return ajax(`${zhixinApi}/v1/Address/setDefault`, "post", { data });
  },
  getProvince() {
    return rawRequest(`${staticApi}/json/schools/provs`, "get");
  },
  getCities(prov) {
    return rawRequest(`${staticApi}/json/schools/${prov}/cities`, "get");
  },
  getDistricts(prov, city) {
    return rawRequest(
      `${staticApi}/json/schools/${prov}/${city}/districts`,
      "get"
    );
  },
  calCartPrice(json) {
    return ajax(`${gatewayApi}/v1/cart/calCartPrice`, "post", { json });
  },
  queryOrder(params) {
    return ajax(`${gatewayApi}/v3/charge/queryOrderNew`, "get", { params });
  },
  charge(data) {
    return ajax(`${zhixinApi}/v1/Charge/charge`, "post", { data });
  },
  weChatShare(params) {
    return ajax(`${zhixinApi}/v1/wechatshare/share`, "get", { params });
  },
  getGroupInfoByGroupId(params) {
    return ajax(`${gatewayApi}/v1/marketing/getGroupInfoByGroupId`, "get", {
      params,
    });
  },
  creatOrder(json) {
    return ajax(`${gatewayApi}/v3/charge/create`, "post", { json });
  },
};

function ajax(url, method, options) {
  const { params = {}, data = {}, json } = options || {};
  return new Promise((resolve, reject) => {
    axios.defaults.withCredentials = true;
    axios.defaults.headers["X-Requested-With"] = "XMLHttpRequest";
    axios.defaults.headers["X-Site-Belong"] = "chiral";
    if (
      params.msgUrl ||
      data.msgUrl ||
      url.indexOf("upload-images") > -1 ||
      url.indexOf("/admin/") > -1
    ) {
      delete params.msgUrl;
      delete data.msgUrl;
      delete axios.defaults.headers["X-Requested-With"];
      // delete axios.defaults.headers["X-Site-Belong"];
    }
    axios({ url, method, params, data: json || qs.stringify(data) }).then(
      (res) => {
        resolve(res);
      },
      (res) => {
        reject(res);
      }
    );
  });
}
function rawRequest(url, method, options) {
  return new Request().ajax(method, url, options);
}
