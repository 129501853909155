export default {
  methods: {
    // 跳转课程详情
    goCourseDetail(course) {
      switch (course.course_belong) {
        case "开罗尔":
          this.$router.push({
            path: `/course-detail/${course.class_id}`,
          });
          break;
        case "质心官网":
          window.open(
            `${process.env.VUE_APP_ZHIXIN_OFFICIAL_URL}/page/livereg/${course.class_id}`
          );
          break;
        case "质心灵动量":
          window.open(
            `${process.env.VUE_APP_ZHIXIN_LDL_URL}/class-detail/${course.class_id}/live`
          );
          break;
        default:
          break;
      }
    },
    // 支付倒计时处理
    payCountDown(timestamp) {
      const mm = Math.floor(timestamp / 60);
      const ss = timestamp % 60;
      return (
        (String(mm).length > 1 ? mm : "0" + mm) +
        ":" +
        (String(ss).length > 1 ? ss : "0" + ss)
      );
    },
    // 去支付
    goPay(orderNo) {
      this.$router.push({
        path: `/pay/${orderNo}`,
      });
    },
    // 分享团购
    shareGroup(activity_id) {
      this.$router.push({
        path: `/group-activity/${activity_id}?isPaided=true`,
      });
    },
  },
};
