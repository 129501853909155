<template>
  <div class="agreement container">
    <section class="text-center">
      <div class="container">
        <a :href="chiralUrl">
          <img class="header-img" src="@/assets/img/chirallogo@2x.png" alt="" />
          <!-- <h1 class="header-title">开罗尔</h1> -->
        </a>
      </div>
    </section>
    <div class="agreement-content">
      <h1>用户注册服务协议</h1>
      <br />
      <p>
        <b>协议细则</b>
      </p>
      <h2>一、本软件服务条款的确认和接纳</h2>
      <p>
        成都灵动量教育科技有限公司为软件开发及平台管理者（以下简称“本软件”）在此特别提醒您（用户）在注册成为用户之前，请认真阅读本用户协议（以下简称“协议”），确保您充分理解本协议中各条款，本服务协议即在您和本软件之间产生法律效力。请您务必在注册之前认真阅读全部服务协议内容，如有任何疑问，可向本软件咨询。无论您事实上是否在注册之前认真阅读了本服务协议，只要您点击协议正本下方的"注册"按钮并按照本软件注册程序成功注册为用户，您的行为仍然表示您同意并签署了本服务协议。
      </p>
      <p>
        “用户”是指本软件相关服务的使用人，在本服务协议中更多称呼为“您”。“用户”是指本软件相关服务的使用人，在本服务协议中更多称呼为“您”。
      </p>
      <h2>二、有关个人资料</h2>
      <p>用户同意：</p>
      <p>（1）提供及时、详尽及准确的个人资料。</p>
      <p>（2）同意接收来自本软件的信息。</p>
      <p>
        （3）不断更新注册资料，符合及时、详尽准确的要求。所有原始键入的资料将引用为注册资料。
      </p>
      <h2>三、用户在本软件平台上不得发布下列违法信息</h2>
      <p>（1）反对宪法所确定的基本原则的；</p>
      <p>（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
      <p>（3）损害国家荣誉和利益的；</p>
      <p>（4）煽动民族仇恨、民族歧视，破坏民族团结的；</p>
      <p>（5）破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
      <p>（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
      <p>（7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
      <p>（8）侮辱或者诽谤他人，侵害他人合法权益的；</p>
      <p>（9）含有法律、行政法规禁止的其他内容的。</p>
      <h2>四、用户个人信息保护</h2>
      <p>
        您在申请本服务过程中，需要填写一些必要的信息，请保持这些信息的真实、准确、合法、有效并注意及时更新，以便本软件向您提供及时有效的帮助，或更好地为您提供服务。根据相关法律法规和政策，请您填写真实的身份信息。若您填写的信息不完整或不准确，则可能无法使用本服务或在使用过程中受到限制。您理解并同意，为了向您提供服务，需要在法律允许的范围内向其他用户展示您的注册信息。
      </p>
      <h2>五、用户的帐号和安全性</h2>
      <p>
        您一旦注册成功成为用户，将对其帐户中的所有活动和事件负全责。您同意若发现任何非法使用用户帐号或安全漏洞的情况，请立即通知本软件。
      </p>
      <h2>六、拒绝提供担保</h2>
      <p>
        您明确同意信息服务的使用由用户个人承担风险。本软件不担保服务不会受中断，对服务的及时性，安全性，出错发生都不作担保，但会在能力范围内，避免出错。
      </p>
      <h2>七、信息的储存及限制</h2>
      <p>
        本软件有判定用户的行为是否符合本软件服务条款的要求和精神的权利，如果用户违背本软件服务条款的规定，本软件有权中断其服务的帐号。
      </p>
      <h2>八、用户管理</h2>
      <p>用户必须遵循：</p>
      <p>（1）使用信息服务不作非法用途。</p>
      <p>（2）不干扰或混乱网络服务。</p>
      <p>
        （3）遵守所有使用服务的网络协议、规定、程序和惯例。用户的行为准则是以因特网法规，政策、程序和惯例为根据的。
      </p>
      <h2>九、知识产权声明</h2>
      <p>
        （1）本软件在本服务中提供的内容包括但不限于：文字表述及其组合、图标、图饰、图标、色彩界面设计、版面框架、有关数据、印刷材料、或电子文档等均受中华人民共和国著作权法、商标法、专利法、反不正当竞争法和相应的国际条约以及其他知识产权法律法规的保护，除涉及第三方授权的软件或技术外，本软件享有上述知识产权。侵害前述权利之一方需承担法律责任。
      </p>
      <p>
        （2）
        除另有特别声明外，本软件提供本服务时所依托软件的著作权、专利权及其他知识产权均归成都灵动量所有。
      </p>
      <h2>十、保障</h2>
      <p>
        用户同意保障和维护本网站全体成员的利益，负责支付由用户使用超出服务范围引起的律师费用，违反服务条款的损害补偿费用，其它人使用用户的电脑、帐号和其它知识产权的追索费。
      </p>
      <h2>十一、其它</h2>
      <p>
        （1）
        您使用本服务即视为您已阅读并同意受本条款的约束。本软件有权在必要时修改本条款。您可以在相关服务页面查阅最新版本的条款。本条款变更后，如果您继续使用本软件服务，即视为您已接受修改后的协议。如果您不接受修改后的协议，应当停止使用本软件。
      </p>
      <p>
        （2）因本协议或与其有关的任何争议应提交本软件公司所在地有管辖权的人民法院提请裁决。
      </p>
      <p>
        （3）本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "Agreement",
  data() {
    return {
      chiralUrl: process.env.VUE_APP_CHIRAL_URL,
    };
  },
};
</script>
<style scoped src="@/assets/css/zx-agreement.css"></style>

<style scoped>
.container {
  text-align: center;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
.container:after,
.container:before {
  display: table;
  content: " ";
}
.container:after {
  clear: both;
}
p,
h2 {
  text-align: left;
}
p {
  line-height: 24px;
  font-weight: 400;
}
</style>
