<template>
  <div>
    <div class="detail-title">
      <div class="detail-top content" v-if="classSubject">
        <div class="detail-back" @click="back">
          <img src="@/assets/img/live/fanhui@2x.png" alt="返回" />
        </div>
        <span>{{ classSubject }}</span>
      </div>
    </div>
    <div class="my-course content">
      <div class="course-container">
        <router-view @classInfo="classInfo"></router-view>
      </div>
      <div class="calendar" v-if="!isMobile">
        <my-calendar></my-calendar>
      </div>
    </div>
  </div>
</template>
<script>
import MyCalendar from "@/components/course/MyCalendar";
import { isMobile } from "@/utils/utils";

export default {
  name: "MyCourse",
  data() {
    return {
      isMobile: isMobile(),
      classSubject: "",
    };
  },
  methods: {
    back() {
      this.$router.push({
        path: "/my-course/course-list",
        query: {
          course_type: this.courseType,
        },
      });
    },
    getCourseSubject() {
      // this.classSubject = decodeURIComponent(this.$route.query.title || "");
      this.courseType = this.$route.query.course_type || "2";
    },
    classInfo(info) {
      this.classSubject = info.data.data.class_info.subject;
    },
  },
  components: {
    "my-calendar": MyCalendar,
  },
  mounted() {
    this.getCourseSubject();
  },
  watch: {
    $route(nVal) {
      this.classSubject = "";
      if (nVal.name === "MyCourseDetail") {
        this.getCourseSubject();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  min-height: 0px;
}
.my-course {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  .course-container {
    flex: 1;
    position: relative;
    margin-right: 24px;
  }
}
.detail-title {
  width: 100%;
  background: $avatar-color;
  .detail-top {
    display: flex;
    padding: 17px 0px;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: $chiral-color;
    line-height: 16px;
  }
  img {
    width: 22px;
    height: 22px;
    margin-right: 15px;
    cursor: pointer;
  }
}
@media (max-width: 767px) {
  .detail-title {
    .detail-top {
      padding: 0.4267rem 10px;
      font-size: 0.3733rem;
    }
  }
}
.calendar {
  width: 320px;
}
@media (max-width: 767px) {
  .content {
    width: 100%;
  }
  .my-course {
    margin-top: 0px;
    .course-container {
      width: 100%;
      margin-right: 0px;
    }
  }
}
</style>
