<template>
  <div class="nav-bar">
    <!-- 欢迎页导航 -->
    <el-row v-if="navType === 'weclome-nav'">
      <el-col :span="24">
        <div class="chiral-menu">
          <el-row class="chiral-menu-row">
            <el-col :span="18">
              <div class="logo-name">
                <a :href="chiralUrl">
                  <img src="@/assets/img/chirallogo@2x.png" alt="logo" />
                </a>
                <ul class="nav" v-if="!isMobile">
                  <router-link class="activity" tag="li" to="/recommend-center"
                    >进入首页</router-link
                  >
                  <router-link class="activity" tag="li" to="/download-app"
                    >开罗尔App下载</router-link
                  >
                </ul>
              </div>
            </el-col>
            <el-col :span="6">
              <!-- <div v-if="isAuthenticated" class="user-content">
                <el-dropdown trigger="click" @command="handleMenuBehavior">
                  <span class="el-dropdown-link">
                    <img class="avatar" :src="user.avatar" alt="头像" />
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="logout"
                      >退出登录</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div> -->
              <div class="user-content">
                <button @click="login" class="login">登录</button>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <!-- 官网导航 -->
    <el-row v-if="navType === 'normal-nav'">
      <el-col :span="24">
        <div class="chiral-menu normal-nav">
          <el-row class="chiral-menu-row">
            <el-col :span="18">
              <div class="logo-name">
                <a :href="chiralUrl">
                  <img src="@/assets/img/chirallogo@2x.png" alt="logo" />
                </a>
                <ul class="nav">
                  <router-link
                    :class="
                      $route.name === item.name ||
                      $route.meta.routeCheckedName === item.name
                        ? 'activity'
                        : ''
                    "
                    tag="li"
                    :to="item.path"
                    v-for="(item, index) in navList"
                    :key="index"
                  >
                    {{ item.title }}</router-link
                  >
                </ul>
              </div>
            </el-col>
            <el-col :span="6">
              <div v-if="isAuthenticated" class="user-content">
                <router-link
                  class="my-course-enter"
                  tag="a"
                  :to="{ path: '/my-course/course-list' }"
                  >我的课程</router-link
                >
                <el-dropdown trigger="click" @command="handleMenuBehavior">
                  <span class="el-dropdown-link">
                    <img
                      class="avatar"
                      :src="user.avatar || defaultAvatar"
                      alt="头像"
                    />
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="OrderList"
                      >我的订单</el-dropdown-item
                    >
                    <el-dropdown-item command="logout"
                      >退出登录</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div v-else class="user-content">
                <button @click="login" class="login">登录</button>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <!-- h5导航 -->
    <el-row v-if="navType === 'h5-nav'">
      <el-col :span="24">
        <div class="chiral-menu h5-nav">
          <el-row class="chiral-menu-row">
            <el-col :span="14">
              <div class="logo-name">
                <i class="el-icon-s-fold" @click="drawer = true"></i>
                <span>{{ $route.meta.routeTitle }}</span>
              </div>
            </el-col>
            <el-col :span="10">
              <div v-if="isAuthenticated" class="user-content">
                <router-link
                  class="my-course-enter"
                  tag="a"
                  :to="{ path: '/my-course/course-list' }"
                  >我的课程</router-link
                >
                <el-dropdown trigger="click" @command="handleMenuBehavior">
                  <span class="el-dropdown-link">
                    <img
                      class="avatar"
                      :src="user.avatar || defaultAvatar"
                      alt="头像"
                    />
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="OrderList"
                      >我的订单</el-dropdown-item
                    >
                    <el-dropdown-item command="logout"
                      >退出登录</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div v-else class="user-content">
                <button @click="login" class="login">登录</button>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <el-drawer
      title=""
      :with-header="false"
      :visible.sync="drawer"
      direction="ltr"
    >
      <div class="drawer-top">
        <img class="logo" src="@/assets/img/chirallogo@2x.png" alt="logo" />
        <img
          class="close"
          src="@/assets/img/close@2x.png"
          alt="关闭"
          @click="drawer = false"
        />
      </div>
      <ul class="drawer-nav">
        <router-link
          tag="li"
          :to="item.path"
          :class="$route.name === item.name ? 'activity' : ''"
          v-for="item in navList"
          :key="item.name"
          @click.native="drawer = false"
        >
          {{ item.title }}
        </router-link>
      </ul>
    </el-drawer>
  </div>
</template>

<script>
// import api from '@/plugins/api'
import { mapActions, mapGetters } from "vuex";
import { isMobile } from "@/utils/utils";

export default {
  name: "NavBar",
  data() {
    return {
      isMobile: isMobile(),
      defaultAvatar:
        "https://img-avatar.eduzhixin.com/Test/avatar/e6608053c55492c6da3ecd446ef12bde/s.jpg?x-oss-process=style/avatar&time=1663213959",
      chiralUrl: process.env.VUE_APP_CHIRAL_URL,
      activityNav: "",
      navList: [
        { title: "首页", name: "RecommendCenter", path: "/recommend-center" },
        { title: "课程", name: "LectureCenter", path: "/lecture-center" },
        { title: "刷题", name: "QuizList", path: "/quiz-list" },
        { title: "知识点", name: "Knowledge", path: "/knowledge" },
        { title: "开罗尔App下载", name: "DownloadApp", path: "/download-app" },
      ],
      drawer: false,
    };
  },
  components: {},
  methods: {
    ...mapActions(["getProfile"]),
    logout() {
      this.$store.dispatch("clearProfile");
    },
    handleMenuBehavior(methodsName) {
      if (methodsName === "OrderList")
        return this.$router.push({ name: methodsName });
      this.$options.methods[methodsName].call(this);
    },

    login() {
      this.$router.push({
        path: "/login",
        query: { preRoute: this.$route.fullPath },
      });
    },
  },
  mounted() {
    this.getProfile();
  },
  computed: {
    ...mapGetters(["isAuthenticated", "user"]),
    /*导航样式类型
      欢迎页 weclome-nav
      官网 normal-nav
      h5官网 h5-nav
    */
    navType() {
      if (this.$route.name === "Home") {
        return "weclome-nav";
      } else if (this.isMobile) {
        return "h5-nav";
      }
      return "normal-nav";
    },
  },
  watch: {
    // '$route'(oldValue,newValue){
    // }
  },
};
</script>
<style lang="scss" scoped>
.nav-bar {
  padding: 13px 0px;
  background: $tooltip-color;
  border-bottom: 1px solid #e4e5ed;
  :deep .el-drawer {
    width: 240px !important;
  }
  button {
    margin: 0;
    padding: 0;
    border: 1px solid transparent;
    outline: none;
    background: none;
    cursor: pointer;
  }
  .user-content {
    line-height: 1;
    float: right;
    display: flex;
    align-items: center;
    .my-course-enter {
      margin-right: 30px;
      font-size: 14px;
      font-weight: 500;
      color: $chiral-login-btn;
      line-height: 14px;
      cursor: pointer;
    }
    @media (max-width: 767px) {
      .my-course-enter {
        margin-right: 0.1333rem;
      }
    }
    .avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      cursor: pointer;
    }
    button {
      padding: 6px 18px;
      border-radius: 18px;
      border: 1px solid $chiral-login-btn;
      color: rgba($color: $chiral-btn-color, $alpha: 0.7);
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
    }
  }
}
@media (max-width: 767px) {
  .nav-bar {
    padding: 0.48rem 0.5867rem 0.4267rem 0.4267rem;
  }
}
.chiral-menu {
  margin: 0 auto;
  position: relative;
  .chiral-menu-row {
    display: flex;
    align-items: center;
  }
  .logo-name {
    display: flex;
    img {
      width: 165px;
      display: block;
    }
    .nav {
      display: flex;
      margin-left: 39px;
      align-items: center;
      li {
        font-size: 16px;
        line-height: 28px;
        cursor: pointer;
        margin-right: 78px;
        color: $chiral-nav-text-color;
      }
      .activity {
        color: $chiral-text-color;
        font-weight: 500;
        position: relative;
      }
    }
  }
}
.normal-nav {
  .activity::after {
    content: "";
    width: 20px;
    height: 4px;
    background: $chiral-btn-color;
    border-radius: 3px;
    position: absolute;
    bottom: -4px;
    left: 50%;
    transform: translate(-50%, 0px);
  }
}
@media screen and (max-width: 767px) {
  .chiral-menu {
    width: 100%;
    min-width: auto;
  }
  .h5-nav {
    .logo-name {
      display: flex;
      align-items: center;
      font-size: 0.4533rem;
      font-weight: 500;
      color: #333333;
      // line-height: 0.64rem;
      span {
        margin-left: 0.32rem;
      }
    }
  }
}
@media (min-width: 768px) {
  .chiral-menu {
    width: 750px;
    .logo-name .nav li {
      margin-right: 44px;
    }
  }
}
@media (min-width: 1200px) {
  .chiral-menu {
    width: 1140px;
    .logo-name .nav li {
      margin-right: 78px;
    }
  }
}
.drawer-nav {
  // padding-top: 1.2rem;
  font-size: 0.4267rem;
  li {
    padding: 0.4rem 1.0667rem;
    text-align: center;
    font-size: 0.4rem;
    font-weight: 500;
    color: $chiral-color;
    line-height: 0.56rem;
  }
  .activity {
    background: #f9f9fb;
  }
}
.drawer-top {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.6533rem;
  margin-bottom: 0.6667rem;
  position: relative;
  .logo {
    width: 3.2rem;
  }
  .close {
    width: 0.32rem;
    height: 0.32rem;
    position: absolute;
    right: 0.32rem;
  }
}
.el-dropdown-menu {
  padding: 2px;
}
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background: $avatar-color;
  color: $chiral-btn-color;
}
</style>
