<template>
  <div class="blank-page">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {},
};
</script>
<style lang="scss">
.blank-page {
  background: white;
}
</style>
