export const m = {
  // Problem.vue
  Descriptions: '題目描述',
  Inputs: '輸入描述',
  Outputs: '輸出描述',
  Sample_Input: '範例輸入',
  Sample_Output: '範例輸出',
  Hint: '提示',
  Source: '題目來源',
  Status: '狀態',
  Information: '題目資訊',
  Time_Limit: '時間限制',
  Memory_Limit: '記憶體限制',
  Created: '出題者',
  Level: '難度',
  Score: '分數',
  Tags: '標籤',
  Show: '顯示',
  // About.vue
  Result_Explanation: '結果說明',
  Pending_Juding: '您的答案即將進行評分，請等待結果。',
  Compile_Error: '無法編譯您的原始碼，請點選連結以檢視編譯器的輸出。',
  Accepted: '您的解題方法是正確的。',
  Wrong_Answer: '您程式的輸出結果與標準程式的答案不符。',
  Runtime_Error: '您的程式異常終止，可能的原因是: 記憶體區段錯誤、被零除或結束程式時傳回非 0 的值。',
  Time_Limit_Exceeded: '您的程序使用的CPU時間已超出限制（Java 有三倍的時間限制），可能的原因是：代碼復雜度過高或內部有死循環等問題存在。',
  Real_Limit_Exceeded: '您的程序使用的進程時間已超出限制（進程時間是CPU時間的三倍），可能的原因是：讀入或輸出被卡。',
  Memory_Limit_Exceeded: '程式實際使用的記憶體已超出限制。',
  System_Error: 'Judge 系統發生錯誤。請回報系統管理員。',
  // FAQ.vue
  Frequently_Asked_Questions: '常見問題',
  // ContestDetail.vue
  Problems: '題目',
  Announcements: '公告',
  Submissions: '提交資訊',
  Rankings: '排名',
  Overview: '概要',
  Admin_Helper: '管理員助手',
  // ContestProblemList.vue
  Problems_List: '試題列表',
  Contest_Home: '比賽首頁',
  Mine_Submit: '我的提交',
  All_Submit: '所有提交',
  Contest_Rank: '排名',
  Contest_Analysis: '解析',
  Statement: '聲明',

  // NavBar.vue
  Home: '首頁',
  NavProblems: '問題',
  Contests: '比賽',
  PersonalRecord: '個人戰績',
  PersonalInformation: '個人信息',
  QuestionBank: '題庫',
  MeterRecord: '評測記錄',
  NavStatus: '狀態',
  Rank: '排名',
  ACM_Rank: 'ACM 排名',
  OI_Rank: 'OI 排名',
  About: '關于我們',
  Judger: '判題解釋',
  FAQ: '常見問題',
  Login: '登入',
  Register: '註冊',
  MyHome: '我的首頁',
  MySubmissions: '我的提交',
  Settings: '我的設定',
  Management: '後台管理',
  Logout: '登出',
  // announcements.vue
  Refresh: '检索',
  Back: '返回',
  No_Announcements: '暫無公告',
  // Setting.vue
  Profile: '個人資訊設定',
  Account: '帳號設定',
  Security: '安全設定',
  // AccoutSetting.vue
  ChangePassword: '更改密碼',
  ChangeEmail: '更改 E-mail',
  Update_Password: '更新密碼',
  // ProfileSetting.vue
  Avatar_Setting: '大頭貼設定',
  Profile_Setting: '基本信息',
  Profile_Mail: '郵寄地址',
  Profile_Shown: '主頁信息展示',
  Address: '詳細地址',
  School: '學校',
  UserName: '用戶',
  RegisterMobile: '手機號',
  AccountNumber: '賬號',
  Province: '省份',
  Profile_PCD: '省/市/區',
  Profile_Grade: '年级',
  Email: '郵箱',
  Mobile: '手機號',
  Address_Name: '收件人姓名',
  Address_Mobile: '收件人電話',
  City: '市',
  District: '區/縣',
  ShowContestReCord: '比賽紀錄',
  // SecuritySettig
  Sessions: '登入記錄',
  Two_Factor_Authentication: '兩步驟驗證',
  // Login.vue
  LoginUsername: '使用者名稱',
  LoginPassword: '密碼',
  TFA_Code: '兩步驟驗證碼',
  No_Account: '沒有帳號，立即註冊!',
  Forget_Password: '忘記密碼',
  UserLogin: '登入',
  // Register.vue
  RegisterUsername: '使用者名稱',
  Email_Address: 'E-mail',
  RegisterPassword: '密碼',
  Password_Again: '確認密碼',
  Captcha: '驗證碼',
  UserRegister: '註冊',
  Already_Registed: '已經註冊? 現在登入!',
  // ResetPassword.vue and ApplyResetPassword.vue
  Reset_Password: '重設密碼',
  RPassword: '密碼',
  RPassword_Again: '確認密碼',
  RCaptcha: '驗證碼',
  SMSCaptcha: '短信驗證碼',
  ApplyEmail: 'E-mail',
  Send_Password_Reset_Email: '傳送密碼重設 E-mail',
  // UserHome.vue
  UserHomeSolved: '已解題數量',
  UserHomeserSubmissions: '提交次數',
  UserHomeScore: '分數',
  List_Solved_Problems: '已完成題目的列表',
  UserHomeIntro: '這個使用者尚未解題...',
  // 新加
  Config: '確定',
  To_Sign_Up: '立即報名',
  Review: '回顧',
  Rating: '評分',
  ContestRecords: '比賽紀錄',
  ContestInstructions: '比賽說明',
  CurrentlyRanked: '即時排名',
  Sample: '示例',
  FeedBack: '問題反饋',
  TimeEating: '耗時',
  TotalScore: '總分',
  CommitTime: '時間',
  Language: '語言',
  MemoryEat: '記憶體',
  System_Message: '系統消息',
  EnterContest: '進入比賽',
  UpdateUserName: '請先填寫用戶名',
  Live: '直播',
  PWDLogin: '密碼登錄',
  QuickLogin: '驗證碼登錄',
  GetVerificationVode: '獲取驗證碼',
  SendSuccess: '發送成功',
  GraphicCaptchasRequired: '需要圖形驗證碼',
  VerifyException: '驗證異常，請重試!',
  InputMobile: '請輸入手機號',
  InputMobileError: '手機號碼格式錯誤',
  MobileError: '手機號碼錯誤',
  InputPWD: '請輸入密碼',
  RegisterPWDTip: '8-40個字符，必須包含數字和字母，區分大小寫',
  PWDMinLength: '密碼最小的長度為8',
  PWDMaxLength: '密碼最大的長度為40',
  TwoDifferentPWSD: '兩次密碼不同',
  MobileCodeRequired: '請輸入驗證碼',
  MobileCodeLength: '驗證碼長度為4',
  ValidateFields: '請驗證錯誤字段',
  AccountRequired: '請填寫手機號或郵箱',
  RegisterAccountRequired: '支持手機號或郵箱作為賬號進行註冊',
  AccountError: '賬號格式錯誤，請檢查',
  MobileFormatError: '手機號格式錯誤，請重新填寫',
  EmailFormatError: '郵箱格式錯誤，請重新填寫',
  Title: '標題',
  TimeLimit: '時間限制',
  MemoryLimit: '內存限制',
  NewPassword: '新密碼',
  UpdatePassword: '修改密碼',
  InputNewPWD: '請輸入新密碼',
  ShowMyRank: '顯示我的排名',
  ShowSchool: '顯示學校資訊',
  SubInfo: '按兩下顯示用戶提交資訊',
  ProblemNumber: '題目編號',
  problem_nav: '做題',
  problem_solution: '題解',
  user_solution: '我的題解'
}
