<template>
  <div class="content">
    <div class="home">
      <!-- 轮播图 -->
      <div class="swiper-container" v-if="isConfigSwiper">
        <el-skeleton v-if="!configData.swiperList.length" animated>
          <template slot="template">
            <el-skeleton-item
              variant="image"
              :style="[{ height: skeleton.swiperHeight + 'px' }]"
            />
          </template>
        </el-skeleton>
        <swiper
          v-else
          :options="swiperOption"
          ref="mySwiper"
          class="swiper-container"
          :style="[{ 'min-height': skeleton.swiperHeight + 'px' }]"
        >
          <swiper-slide
            v-for="(item, index) in configData.swiperList"
            :key="index"
          >
            <picture>
              <source
                type="image/webp"
                :jump_url="item.href"
                onload
                :srcset="item.image + '?x-oss-process=image/format,webp'"
              />
              <img :src="item.image" :jump_url="item.href" :alt="item.title" />
            </picture>
          </swiper-slide>
          <div
            class="swiper-button-prev"
            slot="button-prev"
            v-show="configData.swiperList.length > 1"
          ></div>
          <div
            class="swiper-button-next"
            slot="button-next"
            v-show="configData.swiperList.length > 1"
          ></div>
          <div
            class="swiper-pagination"
            slot="pagination"
            v-show="configData.swiperList.length > 1"
          ></div>
        </swiper>
      </div>
      <!-- 学习规划 -->
      <div
        class="study-plan"
        v-if="isConfigPlan"
        :style="[{ 'min-height': skeleton.planHeight + 'px' }]"
      >
        <h1>学习规划课程</h1>
        <p class="subtitle">在开罗尔定制属于你的学习方案</p>
        <div class="plan-list">
          <div
            class="plan-card"
            v-for="(item, index) in configData.studyPlan"
            :key="index"
          >
            <div class="plan-img">
              <img
                class="animate__animated animate__delay-0.5s"
                v-lazyload="{
                  imgUrl: item.image,
                  animate: 'animate__fadeIn',
                }"
                alt="学习规划"
              />
              <!--   -->
            </div>
            <button @click="jumpUrl(item)">立即咨询</button>
          </div>
        </div>
      </div>
      <!-- 推荐好课 -->
      <div class="recommend" v-if="configData.recommendList.length">
        <h1>推荐好课</h1>
        <p class="subtitle">免费的讲座课程，随时随地学习</p>
        <div class="recommend-list">
          <div
            class="card card-first"
            @mouseenter="hoverCounse(5, configData.recommendList[0])"
            @mouseleave="hoverCounse()"
          >
            <img
              class="animate__animated"
              v-lazyload="{
                imgUrl: configData.recommendList[0].image,
                animate: isPadAndMbile
                  ? 'animate__fadeIn'
                  : 'animate__fadeInLeft',
              }"
              alt="讲座"
              @click="recommendJump(configData.recommendList[0], 'img')"
            />
            <button
              class="animate__animated animate__fadeIn"
              v-show="btnShow === 5"
              @click="recommendJump(configData.recommendList[0])"
            >
              查看课程
            </button>
          </div>
          <div class="card card-group">
            <div class="card-row">
              <div
                class="card-el"
                v-for="(item, index) in configData.recommendList.slice(1)"
                :key="index"
                @mouseenter="hoverCounse(index + 1, item)"
                @mouseleave="hoverCounse()"
              >
                <img
                  class="card-items animate__animated"
                  v-lazyload="{
                    imgUrl: item.image,
                    animate: isPadAndMbile
                      ? 'animate__fadeIn'
                      : 'animate__fadeInRight',
                  }"
                  @click="recommendJump(item, 'img')"
                  :alt="item.title"
                />
                <button
                  class="animate__animated animate__fadeIn"
                  @click="recommendJump(item)"
                  v-show="btnShow === index + 1"
                >
                  查看课程
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 互动教学平板 -->
      <div class="interaction">
        <h1>Chiral-互动教学平板</h1>
        <div class="interaction-info">
          <img
            v-if="!isPadAndMbile"
            class="chiral-pad-img animate__animated"
            v-lazyload="{
              imgUrl: require(`../assets/img/welcome/pad_img.png`),
              animate: isPadAndMbile
                ? 'animate__fadeIn'
                : 'animate__slideInLeft',
            }"
            alt="互动平板"
          />
          <img
            v-if="isPadAndMbile"
            class="chiral-pad-img animate__animated"
            v-lazyload="{
              imgUrl: require(`../assets/img/welcome/h5/pad_img_h5.png`),
              animate: isPadAndMbile
                ? 'animate__fadeIn'
                : 'animate__slideInLeft',
            }"
            alt="互动平板"
          />
          <div>
            <div
              class="info-item animate__animated"
              v-lazyload="{
                animate: isPadAndMbile
                  ? 'animate__fadeIn'
                  : 'animate__slideInRight',
              }"
            >
              <div class="info-container">
                <h2>适应时代的学习载体</h2>
                <p class="info">
                  互动式学习平板，覆盖学生学习、练习、测试、交流等各种学习场景，弥补了线上学习的信息偏差，使学习竞赛变得更轻便、更快捷、更自由
                </p>
                <div class="moblie-img-rotae">
                  <img
                    src="@/assets/img/welcome/interaction1.png"
                    alt="学习平板"
                  />
                </div>
              </div>
            </div>
            <div
              class="info-item2 animate__animated"
              v-lazyload="{
                animate: isPadAndMbile
                  ? 'animate__fadeIn'
                  : 'animate__slideInRight',
              }"
            >
              <div class="info-container">
                <h2>PAD硬件参数</h2>
                <div class="info">
                  <div class="info-list">
                    <div class="info-list-item">
                      屏幕 <span class="sign">10.5</span> 英寸
                    </div>
                    <div class="info-list-item">
                      分辨率<span class="sign">1920×1280</span>
                    </div>
                    <div class="info-list-item">
                      CPU<span class="sign"> 64位 8核 </span>处理器
                    </div>
                  </div>
                  <div>
                    电池 <span class="sign">8000 毫安</span> 可支持整天学习需要
                  </div>
                </div>
                <img
                  src="@/assets/img/welcome/interaction2.png"
                  alt="学习平板"
                />
              </div>
            </div>
            <div
              class="info-item3 animate__animated"
              v-lazyload="{
                animate: isPadAndMbile
                  ? 'animate__fadeIn'
                  : 'animate__slideInRight',
              }"
            >
              <div class="info-container">
                <h2>独特的前置学习记录功能</h2>
                <p class="info">
                  老师会在了解学生的学习情况的基础上给出对应的学习规划。并且可自动前置到桌面上，打开平板即可直接学习对应进度
                </p>
                <img
                  src="@/assets/img/welcome/interaction3.png"
                  alt="学习平板"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 关于开罗尔 -->
      <div class="about">
        <h1>关于开罗尔</h1>
        <div
          class="video-contaniner animate__animated"
          :class="isPadAndMbile ? 'animate__fadeIn' : 'animate__fadeInLeft'"
        >
          <img
            v-if="!isPadAndMbile"
            @click="dialogVideoVisible = true"
            alt="播放按钮"
            src="../assets/img/welcome/vedio@2x.png"
          />
          <video
            class="animate__animated"
            :controls="isPadAndMbile"
            poster="https://static.eduzhixin.com/cdn/chiral/video/chiral.mp4?x-oss-process=video/snapshot,t_1000"
            v-lazyload="{
              imgUrl:
                'https://static.eduzhixin.com/cdn/chiral/video/chiral.mp4',
              animate: isPadAndMbile
                ? 'animate__fadeIn'
                : 'animate__fadeInLeft',
            }"
          ></video>
        </div>
        <div class="about-info-container">
          <img
            v-if="!isPadAndMbile"
            class="about-img animate__animated"
            v-lazyload="{
              imgUrl: require(`../assets/img/welcome/about.png`),
              animate: isPadAndMbile
                ? 'animate__fadeIn'
                : 'animate__fadeInRight',
            }"
            alt="关于开罗尔"
          />
          <div
            class="about-info animate__animated"
            v-lazyload="{
              animate: isPadAndMbile
                ? 'animate__fadeIn'
                : 'animate__fadeInRight',
            }"
          >
            <h2>关于开罗尔</h2>
            <p>
              开罗尔是与学科竞赛衔接内容的综合。在开罗尔体系下的学习，可以帮助学生明确竞赛科目的选择、对竞赛体系产生概念化的认知、扎实竞赛必须的前置知识基础并完善竞赛知识储备，使学生更顺利地走上竞赛人生。
            </p>
            <p>
              “开罗尔”竞赛预科产品，根据学生所在年龄层，更新了长达60小时先导课，120小时预备轮，156小时新手轮，222小时基础轮，可帮助所有年龄层，对科学感兴趣的同学，拥有更加丝滑的学习体验。
            </p>
            <p>开罗尔 — 连接你与竞赛的桥梁</p>
          </div>
          <img
            v-if="isPadAndMbile"
            class="about-img animate__animated"
            src="../assets/img/welcome/h5/about_h5.png"
            alt="关于开罗尔"
          />
        </div>
      </div>
      <!--  -->
      <el-dialog
        width="70%"
        class="watch-video"
        title=""
        :visible.sync="dialogVideoVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        @opened="playVideo"
      >
        <video
          v-if="dialogVideoVisible"
          width="100%"
          controls
          ref="aboutVideo"
          poster="https://static.eduzhixin.com/cdn/chiral/video/chiral.mp4?x-oss-process=video/snapshot,t_1000"
        >
          <source
            src="https://static.eduzhixin.com/cdn/chiral/video/chiral.mp4"
            type="video/mp4"
          />
        </video>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import "animate.css";
import { isMobile } from "@/utils/utils";
import api from "@/plugins/api";
// import qs from "qs";

export default {
  name: "Home",
  data() {
    let that = this;
    return {
      isMobile: isMobile(),
      isPadAndMbile: document.body.clientWidth < 1200,
      dialogVideoVisible: false,
      swiperList: [],
      configData: {
        swiperList: [],
        studyPlan: [],
        recommendList: [],
      },
      configUtmData: [],
      isConfigSwiper: true,
      isConfigPlan: true,
      swiperOption: {
        slidesPerView: "auto",
        pagination: {
          el: ".swiper-pagination",
        },
        grabCursor: true,
        loop: false,
        speed: 1000,
        clickable: true,
        preventClicks: false,
        preventClicksPropagation: false,
        navigation: {
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next",
        },
        on: {
          click: function (e) {
            let jumpUrl = e.target.getAttribute("jump_url");
            if (jumpUrl) {
              that.jumpUrl({ href: jumpUrl });
            }
          },
        },
      },
      skeleton: {
        swiperHeight: "",
      },
      btnShow: "",
    };
  },
  components: {
    swiper,
    swiperSlide,
  },
  methods: {
    hoverCounse(index, obj) {
      this.btnShow = "";
      if (obj && obj.href && !this.isPadAndMbile) {
        this.btnShow = index;
      }
    },
    recommendJump(obj, type) {
      //pc点击图片按钮跳转，pad和移动端 点击图片跳转
      if (
        (!this.isPadAndMbile && !type && obj.href) ||
        (this.isPadAndMbile && type === "img" && obj.href)
      ) {
        window.open(obj.href);
      }
    },
    //跳转逻辑
    jumpUrl(configObj) {
      let url = configObj.href;
      // let utmObj = this.configUtmData[utmType];
      // if (utmObj) {
      //   delete utmObj.type;
      //   url = `${url}?${qs.stringify(utmObj)}`;
      // }
      window.open(url);
    },
    //打开弹窗，自动播放视频
    playVideo() {
      let aboutVideo = this.$refs.aboutVideo;
      if (aboutVideo) {
        if (aboutVideo.paused) aboutVideo.play();
        else aboutVideo.pause();
      }
    },
    //获取欢迎页配置和utm参数配置
    getConfig() {
      Promise.all([api.getChiralwelcome(), api.getUtmInfo()]).then((res) => {
        let [con, utm] = res;
        this.handleConfig(con);
        this.handleUtm(utm);
      });
    },
    //处理欢迎页配置
    handleConfig(res) {
      let item = res.data;
      if (item.code === 1) {
        let handleData = this.groupConfigData(item.data || []);
        this.configData = {
          swiperList: handleData[1] || [],
          studyPlan: handleData[2] || [],
          recommendList: handleData[3] || [],
        };
        this.isConfigSwiper = Boolean(this.configData.swiperList.length);
        this.isConfigPlan = Boolean(this.configData.studyPlan.length);
        this.initSwiper();
      }
    },
    //处理utm参数配置
    handleUtm(res) {
      let item = res.data;
      if (item.code === 1) {
        this.configUtmData = item.data || [];
      }
    },
    //配置数据分组
    groupConfigData(dataList) {
      let list = JSON.parse(JSON.stringify(dataList));
      let newConfig = {};
      list.forEach((item) => {
        newConfig[item.type] = newConfig[item.type] || [];
        newConfig[item.type].push(item);
      });
      return newConfig;
    },
    initSwiper() {
      if (this.configData.swiperList.length > 1) {
        this.swiperOption.autoplay = {
          delay: 3000,
          disableOnInteraction: false,
        };
        this.swiperOption.loop = true;
      }
    },
    initSkeleton() {
      let contentWidth = document.querySelector(".content").offsetWidth;
      this.skeleton.swiperHeight = contentWidth * 0.38;
      this.skeleton.planHeight = contentWidth * 0.4;
    },
  },
  mounted() {
    this.getConfig();
    this.$nextTick(() => {
      this.initSkeleton();
    });
  },
};
</script>
<style lang="scss" scoped>
.home {
  padding: 17px 0px 114px;
  img {
    width: 100%;
  }
  .swiper-container {
    img {
      display: block;
      width: 100%;
    }
  }
  // 学习规划
  .study-plan {
    text-align: center;
    padding: 52px 0px 0px;
    h1 {
      margin-bottom: 10px;
      font-size: 30px;
      font-weight: 600;
      color: $chiral-text-color;
    }
    .subtitle {
      font-size: 14px;
      font-weight: 400;
      color: rgba($color: $chiral-text-color, $alpha: 0.5);
    }
    .plan-list {
      margin: 0px -6px;
      padding-top: 30px;
      display: flex;
      justify-content: center;
      .plan-card {
        width: 23.95%;
        margin: 0px 6px;
        border-radius: 9px;
        .plan-img {
          img {
            border-radius: 9px;
            display: block;
            width: 100%;
          }
        }
        button {
          padding: 7px 32px;
          color: $tooltip-color;
          font-size: 18px;
          font-weight: 600;
          text-align: center;
          background: $chiral-btn-color;
          margin-top: 30px;
          border-radius: 4px;
        }
      }
    }
    @media (max-width: 767px) {
      .plan-list {
        flex-wrap: wrap;
      }
    }
  }
  @media (max-width: 767px) {
    .study-plan {
      padding: 0.72rem 0px 0px;
      h1 {
        font-size: 0.5333rem;
        font-weight: 600;
        line-height: 0.7467rem;
      }
      .plan-list {
        margin: 0px;
        justify-content: space-between;
        .plan-card {
          width: 48%;
          margin: 0px 0px 0.4267rem;
          button {
            font-size: 0.4267rem;
            padding: 0.1867rem 0.7467rem;
            margin-top: 0.4267rem;
          }
        }
      }
    }
  }
  // 推荐好课
  .recommend {
    text-align: center;
    margin-top: 8px;
    padding-top: 90px;
    h1 {
      margin-bottom: 10px;
      font-size: 30px;
      font-weight: 600;
      color: $chiral-text-color;
    }
    .subtitle {
      font-size: 14px;
      font-weight: 400;
      color: rgba($color: $chiral-text-color, $alpha: 0.5);
    }
    .recommend-list {
      display: flex;
      margin-top: 47px;
      .card {
        width: 50%;
        position: relative;
        img {
          width: 100%;
          border-radius: 8px;
        }
      }
      .card-first {
        button {
          position: absolute;
          bottom: 24px;
          left: 50%;
          transform: translate(-50%, 0px);
          font-size: 18px;
          font-weight: 600;
          color: #ffffff;
          line-height: 25px;
          background: #4a36fa;
          padding: 7px 32px;
          border-radius: 4px;
        }
      }
      .card-group {
        justify-content: flex-end;
        padding-left: 22px;
        .card-row {
          display: flex;
          flex-wrap: wrap;
          margin: 0px -10px;
          .card-el {
            position: relative;
            width: 50%;
            padding: 0px 10px;
            .card-items {
              width: 100%;
              display: block;
            }
            button {
              position: absolute;
              bottom: 44px;
              border-radius: 4px;
              left: 50%;
              transform: translate(-50%, 0px);
              font-size: 18px;
              /* font-family: PingFangSC-Semibold, PingFang SC; */
              font-weight: 600;
              color: #ffffff;
              line-height: 25px;
              background: #4a36fa;
              padding: 7px 32px;
            }
          }
          .card-el:nth-child(3),
          .card-el:nth-child(4) {
            button {
              bottom: 20px;
            }
          }
        }
        .card-el:nth-child(1),
        .card-el:nth-child(2) {
          .card-items,
          .card-items {
            margin-bottom: 23px;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    .recommend {
      margin-top: 0px;
      padding-top: 1.6rem;
      // min-height: 15.2rem;
      h1 {
        font-size: 0.5333rem;
      }
      .recommend-list {
        flex-direction: column;
        .card-group {
          padding-left: 0px;
        }
        .card {
          width: 100%;
        }
      }
    }
  }
  // 互动教学平板
  .interaction {
    min-height: 100px;
    margin-top: 138px;
    text-align: center;
    h1 {
      margin-bottom: 48px;
      font-size: 30px;
      font-weight: 600;
      color: $chiral-text-color;
    }
    .interaction-info {
      position: relative;
      .info-item,
      .info-item2,
      .info-item3 {
        position: absolute;
        top: 0px;
        right: 69px;
        width: 566px;
        background: rgba(160, 143, 255, 0.12);
        border-radius: 8px;
        backdrop-filter: blur(8px);
        padding: 10px 11px 10px 9px;
        text-align: left;
        .info-container {
          background: $avatar-color;
          box-shadow: 0px 0px 12px 0px rgba(171, 175, 209, 0.32);
          border-radius: 8px;
          padding: 18px 47px 17px 43px;
          position: relative;
          h2 {
            font-size: 18px;
            font-weight: 500;
            color: $chiral-text-color;
            line-height: 25px;
            margin-bottom: 8px;
            position: relative;
          }
          h2::after {
            content: "";
            width: 11px;
            height: 11px;
            background: #8b7eff;
            position: absolute;
            left: -18px;
            bottom: 6px;
          }
          .info {
            font-size: 14px;
            font-weight: 400;
            color: rgba($color: $chiral-text-color, $alpha: 0.6);
            line-height: 20px;
          }
          .info-list {
            display: flex;
            .info-list-item {
              margin-right: 32px;
            }
          }
          @media (max-width: 767px) {
            .info-list {
              flex-wrap: wrap;
              .info-list-item {
                margin-right: 12px;
                margin-bottom: 4px;
              }
            }
          }
          .sign {
            color: $chiral-btn-color;
            font-weight: 500;
          }
          img {
            width: 53px;
            position: absolute;
            right: 0px;
            top: 50%;
            transform: translate(0, -50%);
          }
        }
      }
      .info-item2 {
        top: 148px;
        right: 43px;
        background: none;
        backdrop-filter: none;
        .info-container {
          img {
            transform: none;
            bottom: 0px;
            top: auto;
            width: 80px;
          }
        }
      }
      .info-item3 {
        right: 0px;
        top: 298px;
        .info-container {
          img {
            transform: none;
            bottom: 0px;
            top: auto;
            width: 74px;
          }
        }
      }
    }
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    .interaction {
      .interaction-info {
        .info-item,
        .info-item2,
        .info-item3 {
          position: static;
          width: 100%;
          backdrop-filter: none;
          margin-bottom: 16px;
          .info-container {
            padding: 18px 25px 17px 43px;
            img {
              width: 33px;
            }
          }
        }
        .info-item2 {
          background: rgba(160, 143, 255, 0.12);
          .info-container {
            img {
              width: 50px;
            }
          }
        }
        .info-item3 {
          .info-container {
            img {
              width: 50px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    .interaction {
      margin-top: 1.6rem;
      min-height: 200px;
      h1 {
        margin-bottom: 0.6933rem;
      }
      .interaction-info {
        .info-item,
        .info-item2,
        .info-item3 {
          position: static;
          width: 100%;
          backdrop-filter: none;
          margin-bottom: 16px;
          .info-container {
            padding: 18px 25px 17px 43px;
            img {
              width: 33px;
            }
          }
        }
        .info-item2 {
          background: rgba(160, 143, 255, 0.12);
          .info-container {
            img {
              width: 50px;
            }
          }
        }
        .info-item3 {
          .info-container {
            img {
              width: 50px;
            }
          }
        }
      }
    }
  }
  // 关于开罗尔
  .about {
    margin-top: 66px;
    text-align: center;
    position: relative;
    overflow: hidden;
    min-height: 200px;
    h1 {
      margin-bottom: 22px;
      font-size: 30px;
      font-weight: 600;
      color: $chiral-text-color;
    }
    .about-info-container {
      position: relative;
      .about-info {
        color: rgba($color: $chiral-text-color, $alpha: 0.6);
        h2 {
          font-size: 40px;
          font-weight: 500;
          color: $chiral-text-color;
          line-height: 56px;
          position: relative;
          padding-left: 18px;
          margin-bottom: 18px;
        }
        h2:after {
          content: "";
          width: 11px;
          height: 13px;
          background: #8b7eff;
          display: block;
          position: absolute;
          bottom: 13px;
          left: 0px;
        }
        position: absolute;
        right: 97px;
        width: 412px;
        top: 70px;
        font-size: 14px;
        text-align: left;
        line-height: 25px;
        p {
          margin-bottom: 19px;
        }
        p:nth-last-child(1) {
          color: #1d63ff;
        }
      }
      @media (min-width: 768px) and (max-width: 1199px) {
        .about-info {
          font-size: 30px;
          width: 78%;
          line-height: 46px;
          top: 84px;
          bottom: auto;
          z-index: 2;
        }
      }
      @media (max-width: 767px) {
        .about-info {
          bottom: 0px;
          top: auto;
          z-index: 2;
          right: auto;
          left: 50%;
          transform: translate(-50%, 0px);
          width: 279px;
          top: 41px;
          h2 {
            font-size: 24px;
            margin-bottom: 0px;
          }
          p {
            font-size: 12px;
            margin-bottom: 0px;
          }
        }
      }
    }
    .video-contaniner {
      position: relative;
      width: 49.67%;
      position: absolute;
      left: 0;
      bottom: 54px;
      display: inherit;
      max-height: 319px;
      z-index: 1;
      img {
        cursor: pointer;
        position: absolute;
        width: 60px;
        height: 60px;
        top: 50%;
        left: 50%;
        z-index: 1;
        transform: translate(-50%, -50%);
      }
      video {
        object-fit: fill;
        width: 100%;
        border-radius: 5px;
      }
    }
    @media (min-width: 768px) and (max-width: 1199px) {
      .video-contaniner {
        position: static;
        width: 100%;
        max-height: fit-content;
      }
    }
  }
  @media (max-width: 767px) {
    .about {
      margin-top: 1.5467rem;
      h1 {
        font-size: 0.5333rem;
        margin-bottom: 0.5867rem;
      }
      .video-contaniner {
        position: relative;
        bottom: auto;
        width: 100%;
      }
    }
  }
}
@media (max-width: 767px) {
  .home {
    padding: 0.4267rem 0px 1.6267rem;
  }
}
</style>
<style lang="scss">
.swiper-container {
  border-radius: 16px;
  .swiper-pagination-bullets {
    width: auto;
    left: 50%;
    transform: translate(-50%, 0);
    display: inline-flex;
  }
  .swiper-button-prev {
    left: 40px;
  }
  .swiper-button-next {
    right: 40px;
  }
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
    width: 64px;
    height: 64px;
  }
  .swiper-button-prev::after,
  .swiper-button-next::after {
    background: url("../assets/img/swiper/pre.png");
    background-size: 100% 100%;
    width: 64px;
    height: 64px;
    font-family: none;
    display: inline-block;
    content: "";
  }
  .swiper-button-next {
    transform: rotate(180deg);
  }
  .swiper-pagination-bullet {
    width: 30px;
    height: 2px;
    border-radius: 1px;
  }
  .swiper-pagination-bullet-active {
    background: $btn-default-bg;
  }
}
@media (max-width: 767px) {
  .swiper-container {
    border-radius: 5px;
  }
}
@media (min-width: 1200px) {
  .swiper-container:hover {
    .swiper-button-next,
    .swiper-button-prev {
      display: block;
      width: 64px;
      height: 64px;
    }
  }
}

.watch-video {
  .el-dialog {
    margin-top: 0px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .el-dialog__header {
    padding: 0px;
    .el-dialog__title {
      display: none;
    }
    button {
      top: -40px;
      right: -40px;
      font-size: 30px;
      i {
        color: $component-background;
      }
    }
  }
  .el-dialog__body {
    padding: 0px;
    video {
      display: block;
    }
  }
}
</style>
