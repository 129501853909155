import moment from "moment";
import Vue from "vue";

let week = {
  0: "周日",
  1: "周一",
  2: "周二",
  3: "周三",
  4: "周四",
  5: "周五",
  6: "周六",
};

function formatDate(times) {
  return moment(times * 1000).format("YYYY.MM.DD");
}
function formatDay(times) {
  return moment(times).format("DD");
}
function formatYearMonth(times) {
  return moment(times).format("YYYY.MM");
}
function formatPlayBack(times) {
  return moment(times * 1000).format("YYYY年MM月DD日HH:mm");
}
function formatSubclassBeginTime(times) {
  return moment(times * 1000).format("MM月DD日HH:mm");
}
function formatSubclassEndTime(times) {
  return moment(times * 1000).format("HH:mm");
}

function formatWeek(times) {
  return week[moment(times * 1000).format("d")];
}
// 时间戳转换时间
function formatTimestamp(times) {
  return moment(times * 1000).format("YYYY.MM.DD HH:mm:ss");
}

function formatSelfTime(times, format) {
  return moment(times * 1000).format(format);
}

let filters = {
  formatDate,
  formatDay,
  formatYearMonth,
  formatPlayBack,
  formatSubclassBeginTime,
  formatSubclassEndTime,
  formatWeek,
  formatTimestamp,
  formatSelfTime,
};

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

export default filters;
