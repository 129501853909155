<template>
  <div :style="[{ backgroundColor: bColor }]">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"> </router-view>
    <!-- <router-view /> -->
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    bColor() {
      return this.$route.meta.backgroudColor || "";
    },
    key() {
      return this.$route.path + Math.random();
    },
  },
};
</script>
