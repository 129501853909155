import types from "../types";
import api from "@/plugins/api";
import i18n from "@/i18n";
import cookie from "@/plugins/cookie";
// import { STORAGE_KEY, USER_TYPE, PROBLEM_PERMISSION } from '~/plugins/constants'
// import { USER_TYPE, FUNC_ROLE } from "@/plugins/constants";
import { Message } from "element-ui";

const userInfo = {
  state: {
    profile: null,
  },
  mutations: {
    [types.CHANGE_PROFILE](state, { profile }) {
      state.profile = profile;
      if (profile && profile.user_id) {
        if (window && window.sensors) {
          window.sensors.login(profile.user_id);
        }
      }
      if (profile.language) {
        i18n.locale = profile.language;
      }
      // storage.set(STORAGE_KEY.AUTHED, !!profile.user)
    },
  },
  actions: {
    getProfile({ commit }) {
      let params = {
        product_name: "开罗尔",
      };
      api.getUserInfo(params).then((res) => {
        commit(types.CHANGE_PROFILE, {
          profile: res.data.code === 1 ? res.data.data : {},
        });
      });
    },
    clearProfile({ commit }) {
      api.logout({ product_name: "开罗尔" }).then((res) => {
        if (res.data.code === 1) {
          cookie.setCookie("Authorization", "");
          commit(types.CHANGE_PROFILE, {
            profile: {},
          });
          window.location.href = "/recommend-center";
        } else {
          Message.error(res.data.message);
        }
        // storage.clear()
      });
    },
  },
};
export default userInfo;
