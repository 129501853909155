<template>
  <div class="empty-container">
    <img class="empty-icon" src="@/assets/img/empty.png" alt="空" />
    <p class="empty-info">{{ text || tips }}</p>
    <a class="empty-info empty-link" :href="jumpLink.href" v-if="jumpLink.href">
      {{ jumpLink.text }}
    </a>
  </div>
</template>
<script>
export default {
  name: "Empty",
  props: {
    jumpLink: {
      type: Object,
      default: () => {
        return {
          href: "",
          text: "",
        };
      },
    },
    text: {
      type: String,
    },
  },
  data() {
    return {
      tips: "暂无课程",
    };
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.content {
  background: $avatar-color;
  margin-bottom: 20px;
  border-radius: 2px;
}
.empty-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  .empty-icon {
    width: 69px;
    margin-bottom: 11px;
  }
  .empty-info {
    font-size: 18px;
    font-weight: 400;
    color: rgba($color: $chiral-color, $alpha: 0.5);
    line-height: 25px;
  }
  .empty-link {
    color: rgba($color: $chiral-btn-color, $alpha: 0.8);
  }
  @media (max-width: 767px) {
    .empty-info {
      font-size: 0.3733rem;
    }
  }
}
</style>
