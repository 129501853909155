<template>
  <div class="agreement container">
    <section class="text-center">
      <div class="container">
        <a :href="chiralUrl">
          <img class="header-img" src="@/assets/img/chirallogo@2x.png" alt="" />
          <!-- <h1 class="header-title">开罗尔</h1> -->
        </a>
      </div>
    </section>
    <div class="agreement-content">
      <h1>用户个人信息保护政策</h1>
      <h3>版本生效日期：2022年 10月 17日</h3>
      <br />
      <p>
        本《用户个人信息保护政策》（以下简称“<b>本政策</b>”）适用于开罗尔官网所有的产品及服务（以下简称“我们”），您通过平台的产品及服务而接入的其他第三方产品或服务除外；<b
          >如您使用平台提供的某项产品或服务有其单独的个人信息保护政策，该服务对应的个人信息保护政策将优先适用，并与本政策一起构成一份完整的政策。</b
        >
      </p>
      <br />
      <p>
        <b>
          平台希望通过本政策向您说明平台如何收集、使用、保护您的个人信息，以及您管理个人信息的方式。请您务必在点击同意本政策之前，审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款，以及以粗体标识的内容，您应重点阅读；如您不同意本政策中的任何条款，您应立即停止访问或使用平台产品或服务。
        </b>
        如您对政策有任何疑问，可按照本政策列明方式与我们取得联系。
      </p>
      <br />
      <p>
        <b>
          当您点击确认同意本政策或使用平台产品或服务，即表示您已充分阅读、理解且接受本政策的全部内容，并与我们达成一致，本政策对您具有法律约束力。
        </b>
      </p>
      <br />
      <p>
        <b>本政策将帮助您了解以下内容：</b>
      </p>
      <br />
      <div class="mar-l-15">
        <p><b>一、平台如何收集和使用您的个人信息</b></p>
        <br />
        <p><b>二、平台如何保护和保存您的个人信息</b></p>
        <br />
        <p><b>三、平台如何处理未成年人的个人信息</b></p>
        <br />
        <p><b>四、我们如何使用Cookie和同类技术</b></p>
        <br />
        <p><b>五、本政策如何更新</b></p>
        <br />
        <p><b>六、与平台联系</b></p>
        <br />
        <p><b>七、其他</b></p>
      </div>

      <h2>一、平台如何收集和使用您的个人信息</h2>
      <p>
        在您使用平台的产品或服务时，我们可能需要收集和使用您的个人信息包括如下两种：
      </p>
      <br />
      <p>
        1.为实现向您提供平台的产品或服务的基本功能，您须授权平台收集、使用的必要的信息。如您拒绝提供相应信息，您将无法正常使用平台的产品或服务；
      </p>
      <br />
      <p>
        2.为实现向您提供平台产品或服务的扩展功能，您可选择单独同意或不同意平台收集、使用的信息。如您拒绝提供，您将无法正常使用相关扩展功能或无法达到平台拟达到的功能效果，不会影响您使用平台的基本功能。
      </p>
      <br />
      <p>平台在此特别提醒您：</p>
      <div class="mar-l-15">
        <p>
          1.平台致力于打造多样的产品和服务以满足您的需求。
          <b
            >因平台向您提供的产品和服务种类众多，且不同用户选择使用的具体产品或服务范围存在差异，故基本/扩展功能及对应收集使用的个人信息类型、范围会有所区别，请以具体的产品或服务功能为准。</b
          >
        </p>
        <br />
        <p>
          <b
            >2.为给您带来更好的产品和服务体验，平台持续努力改进产品、服务及技术，随之平台可能会不时推出新的或优化后的功能，可能需要收集、使用新的个人信息或变更个人信息使用目的或方式。如发生上述情形，平台将通过更新本政策、弹窗、页面提示等方式另行向您详细说明，并重新征得您的同意，仅在征得您明示同意后才开始收集、使用。</b
          >
          在此过程中，如果您有任何疑问、意见或建议的，您可通过本政策列明的联系方式与平台联系，我们会尽快回复。
        </p>
        <br />
        <p><b>3.帮助您完成支付</b></p>
        <br />
        <p>
          您在开罗尔官网支付时，您可以选择开罗尔官网的第三方支付机构（如微信支付、支付宝支付）所提供的支付服务。支付功能本身并不收集您的个人信息，
          <b
            >但平台需要将您的开罗尔官网订单信息及对账信息与这些支付机构共享以确认您的支付指令并完成支付。</b
          >
        </p>
        <br />
        <p><b>4.订单管理</b></p>
        <br />
        <p>
          为展示您账号的订单信息，平台会收集您在使用平台服务过程中产生的订单信息用于向您展示及便于您对订单进行管理。
          <b
            >您在开罗尔官网生成的订单中，将可能包含您的身份信息、联络信息、地址信息、支付信息等，这些都属于敏感信息，请您谨慎向他人展示或对外提供，</b
          >
          如开罗尔官网需对外提供订单信息时，将取得您的授权，并尽到合理商业注意义务对您的信息进行去标识化处理以在最大化保护您的个人信息同时实现信息可用性。
        </p>
        <br />
        <p>
          为能向您提供更优质的服务，同时为能确认交易状态及为您提供售后与争议解决服务，
          <b
            >经您授权后平台会通过您选择的交易对象和交易直接涉及的第三方信息系统、支付机构等收集与交易进度相关的您的交易、支付相关信息，</b
          >
          以便于平台处理您的订单并保证服务的顺利完成，或者更好地预防诈骗、刷单等恶意行为。
        </p>
        <br />
        <p><b>5. 为您提供安全保障</b></p>
        <br />
        <p>
          为预防、发现、调查欺诈、侵权、危害安全、非法或违反与平台或与平台关联公司的协议、政策或规则的行为，<b
            >平台可能收集或整合您的用户信息、服务使用信息、设备信息取得您授权或依据法律共享的信息。</b
          >
        </p>
        <br />
        <p>
          平台通过不断提升的技术手段加强安装在您的设备的软件的安全能力，以防您的个人信息泄露，为了保障向您提供的服务的安全稳定运营，预防病毒、木马程序和其他恶意程序、网站，我们会收集关于您使用产品、服务以及使用方式的信息并将这些信息进行关联，这些信息包括：
        </p>
        <br />
        <p>
          <b>设备信息：</b
          >平台会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的
          <b
            >设备相关信息（设备型号、操作系统版本、设备设置、软硬件特征信息）。</b
          >
        </p>
      </div>
      <h2>（二）收集和使用个人信息的其他规则</h2>
      <p>
        1.开罗尔官网收集及使用上述信息的目的是为了更好地经营开罗尔官网产品和服务，平台将基于本政策未载明的其他特定目的收集您的信息时，会事先征求您的同意。如平台停止运营开罗尔官网网校产品或服务，平台将及时停止继续收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，对所持有的个人信息进行删除或匿名化处理。
      </p>
      <br />
      <p><b>2.征得授权同意的例外</b></p>
      <br />
      <p>
        根据相关法律法规规定及国家标准，以下情形中，我们可能会依法收集并使用您的个人信息无需征得您的同意:
      </p>
      <br />
      <div class="mar-l-15">
        <p>(1)与国家安全、国防安全直接相关的;</p>
        <br />
        <p>(2)与公共安全、公共卫生、重大公共利益直接相关;</p>
        <br />
        <p>(3)与刑事侦查、起诉、审判和判决执行等直接相关的;</p>
        <br />
        <p>
          (4)出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的;
        </p>
        <br />
        <p>(5)所收集的个人信息是您自行向社会公众公开的;</p>
        <br />
        <p>
          (6)从合法公开披露的信息中收集个人信息的，如：合法的新闻报道、政府信息公开等渠道;
        </p>
        <br />
        <p>(7)根据您的要求签订和履行合同所必须的;</p>
        <br />
        <p>
          (8)用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现或处置产品或服务的故障;
        </p>
        <br />
        <p>(9)我们履行法律法规规定的义务相关的；</p>
        <br />
        <p>(10)法律法规规定的其他情形。</p>
      </div>
      <h2>（三）个人信息使用方式</h2>
      <p>
        收集您的信息是为了向您提供服务及提升服务质量，为了实现这一目的，我们将以以下方式使用您的信息或您的信息将用于下列用途：
      </p>
      <br />
      <p>
        1.
        我们会根据本隐私政策的内容，为实现我们向您提供您使用的开罗尔官网产品或服务，并维护、改进、优化这些服务及服务体验，对所收集的个人信息进行使用；如平台需将收集到的相关信息用于新的目的、范围或实际传输方式与本协议约定不一致时，平台将以
        【弹窗提示】 方式通知到您并取得您的明示同意。
      </p>
      <br />
      <p>
        2.为预防、发现、调查欺诈、侵权、危害安全、非法或违反与平台或与平台关联公司的协议、政策或规则的行为，保护您、其他用户或公众，平台或平台关联公司的合法权益，平台可能使用或整合您的用户信息、服务使用信息、设备信息、日志信息以及平台关联公司、合作伙伴取得您授权或依据法律共享的信息，来综合判断您账号及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
      </p>
      <br />
      <p>
        3.平台还可能会根据您提供的联系方式，通过短信、电话或您同意的其他方式联系您，向您推荐您可能感兴趣的商品及平台提供的产品或服务。如您拒绝接受短信或电话营销，您可以根据短信或电话中的指示退订平台的短信或电话营销信息。
      </p>
      <br />
      <p>
        您可以在电话中明确表示拒绝或根据短信内容提示，来拒绝接收平台给您拨打电话或发送短信。您知悉，短信退订可能会收取一定的通信费，该费用由您自行向运营商支付。
      </p>
      <br />
      <p>
        4.
        为统计我们的产品/服务使用情况，我们会对经过技术处理的用户数据进行汇总、分析和使用，并与第三方共享处理后的统计信息。我们会通过安全加密的技术处理方式以及其他方式保障信息接收方无法重新识别特定个人。
      </p>
      <br />
      <p>5. 经您许可的其他用途。</p>
      <br />
      <p>
        请您理解，我们向您提供的产品与/或服务将不断更新变化。如果您选择使用本隐私政策中尚未列明的其他产品与/或服务时，我们会在收集您的个人信息前通过协议、页面提示等方式向您详细说明信息收集的目的、方式、范围并征求您的同意。若您不同意提供前述信息，您可能无法使用该项产品与/或服务，但不影响您使用现有产品与/或服务。
      </p>
      <h2>（四）公开披露</h2>
      <p>平台仅会在以下情况下，公开披露您的个人信息：</p>
      <br />
      <p>
        1.获得您明确同意或基于您的主动选择，平台可能会公开披露您的个人信息；
      </p>
      <br />
      <p>
        2.如果平台确定您出现违反法律法规或严重违反开罗尔官网相关协议规则的情况，或为保护开罗尔官网及其关联公司用户或公众的人身财产安全免遭侵害，平台可能依据法律法规或开罗尔官网相关协议规则征得您同意的情况下披露关于您的个人信息，包括相关违规行为以及开罗尔官网已对您采取的措施。
      </p>
      <h2>（五） 公开披露个人信息时事先征得授权同意的例外</h2>
      <p>以下情形中，公开披露您的个人信息无需事先征得您的授权同意：</p>
      <br />
      <p>1.与个人信息控制者履行法律法规规定的义务相关的；</p>
      <br />
      <p>2.与国家安全、国防安全直接相关的；</p>
      <br />
      <p>3 与公共安全、公共卫生、重大公共利益直接相关的；</p>
      <br />
      <p>4 与刑事侦查、起诉、审判和判决执行等直接相关的；</p>
      <br />
      <p>
        5
        出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；
      </p>
      <br />
      <p>6 个人信息主体自行向社会公众公开的个人信息；</p>
      <br />
      <p>
        7
        从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
      </p>
      <h2>二、平台如何保护和保存您的个人信息</h2>
      <P><b>（一）个人信息的保护措施</b></P>
      <br />
      <p>
        1.平台会采取各种预防措施来保护您的个人信息，以保障您的个人信息免遭丢失、盗用和误用，以及被擅自取阅、披露、更改或销毁。为确保您个人信息的安全，平台有严格的信息安全规定和流程，并有专门的信息安全团队在公司内部严格执行上述措施。
      </p>
      <br />
      <p>
        2.平台会采取合理可行的措施，尽力避免收集无关的个人信息，并在限于达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期限或在法律的允许期限内。
      </p>
      <br />
      <p>
        3.如发生个人信息安全事件后，平台将按照法律法规的要求并最迟不迟于30
        个自然日内向您告知：安全事件的基本情况和可能的影响、平台已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况平台将以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，平台会采取合理、有效的方式发布公告。同时，平台还将按照监管部门要求，上报个人信息安全事件的处置情况。
      </p>
      <br />
      <p>
        4.由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全，我们将尽力确保您提供给我们的个人信息的安全性。请您知悉并理解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。因此，我们强烈建议您采取积极措施保护个人信息的安全，包括但不限于使用复杂密码、定期修改密码、不将自己的账号密码等个人信息透露给他人。
      </p>
      <h2>（二）个人信息的保存</h2>
      <p>
        1.平台将以以下形式储存收集到的相关个人信息：收集和产生的个人信息，存储在中华人民共和国境内。同时只会在达成本政策所述目的所需的期限内保留您的个人信息，除非法律有强制的存留要求。例如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年；平台判断前述期限的标准包括：
      </p>
      <div class="mar-l-15">
        <p>
          1.1完成与您相关的交易目的、维护相应交易及业务记录、应对您可能的查询或投诉；
        </p>
        <br />
        <p>1.2保证平台为您提供服务的安全和质量；</p>
        <br />
        <p>1.3是否存在保留期限的其他特别约定。</p>
        <br />
      </div>
      <p>
        在您的个人信息超出保留期限后，平台会根据适用法律的要求删除您的个人信息，或使其匿名化处理（如您为未成年人，在您的个人信息超出保留期间后，平台会按照相关法律法规要求对您的个人信息进行相应处理）。如因特殊情形无法按照相关要求删除的，平台将对该部分事项向您进行说明。
      </p>
      <br />
      <p>我们使用的第三方SDK服务</p>
      <p>
        为了实现本隐私政策中的声明功能，我们可能会接入第三方服务商提供的SDK或其他类似应用程序，并在您的同意下将我们在后台状态下获取了用户【IMSI,ANDROID
        ID,MAC】信息并依照本隐私政策手机的个人信息（包括IMEI、Serial、SIM卡IMSI识别码、MAC地址、ANDROID
        ID、拨打电话和管理通话、设备机型、操作系统及版本、客户端版本、设备分辨率、包名、设备设置、进程及必要的应用列表、唯一设备标识符等软硬件特征等信息）并分享给第三方服务商，以完善课程服务和用户体验。目前，我们接入的第三方服务商主要包括：
      </p>
      <br />
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="sdk" label="SDK名称"> </el-table-column>
        <el-table-column prop="name" label="公司名称"> </el-table-column>
        <el-table-column prop="info" label="涉及个人信息"> </el-table-column>
        <el-table-column prop="md" label="使用目的"> </el-table-column>
        <el-table-column prop="gw" label="第三方官网"> </el-table-column>
      </el-table>
      <br />
      <p>权限：android.permission.REQUEST_INSTALL_PACKAGES</p>
      <p>业务用途：当安装包下载时，实现应用内自升级</p>
      <br />
      <p>
        如上所述服务由相关的第三方负责运营。您使用这些第三方服务（包括您向这些第三方提供的任何个人信息），须受第三方自己的服务条款及个人信息保护声明（非本政策）的约束，您要仔细阅读其条款。我们仅会出于正当、必要、特定的目的共享您的信息。我们会要求他们履行相关保密义务并采取相应的措施。
      </p>
      <br />
      <h2>三、平台如何处理未成年人的个人信息</h2>
      <p><b> 平台非常重视对未成年人个人信息的保护。 </b></p>
      <br />
      <p>
        <b
          >根据相关法律法规的规定，如您是18周岁以下的未成年人，在使用平台功能或服务前，应在您的监护人监护、指导下共同阅读并自主决定是否同意本政策，并由您的监护人帮助您完成注册流程，以便您能使用平台功能或服务。</b
        >
      </p>
      <br />
      <p>
        <b
          >特别地，如果您是14周岁以下儿童的，在使用平台功能或服务前，只有在征得监护人对本政策的同意后，14周岁以下的儿童方可使用平台提供的功能或服务。为加强对儿童个人信息的保护，除遵循本政策项下的其他规定外，平台将遵循正当必要、知情同意、目的明确、安全保障、依法利用的原则，根据国家相关法律法规及本政策的规定保护儿童的个人信息。如您对您所监护的未成年人的个人信息保护有相关疑问或权利请求时，请通过本政策中披露的联系方式与平台联系。平台会在合理的时间内处理并回复您。如监护人发现相关未成年人个人信息为未成年人自行填写，需要进行修改或删除处理的，请随时与我们联系。</b
        >
      </p>
      <br />
      <h2>四、我们如何使用Cookie和同类技术</h2>
      <p>
        当您浏览本网站或App时，某些信息可能也会被动收集，包括您的网络通信协议地址（”IP地址”）、浏览器类型、域名、访问时间、设备ID以及操作系统等。
        我们也会使用
        “Cookies”和包括统一资源定位器（URL）在内的导航数据，来收集您的访问日期和时间，以及您搜索和浏览的信息。
        收集这些类型的信息可以让我们的产品和服务为您提供更有针对性的帮助。在我们向您发送的电子邮件中，有的会包含”点击URL”，基于方便您的考虑，
        当您点击这些URL时，您将通过我们的网络服务器到达目标网站。通过追踪点击率，能帮助我们确定您对特定主题的兴趣，还能帮助我们衡量发送用户通信的有效性。
      </p>
      <br />
      <p>
        “IP地址”
        是在您使用网络时向您的电脑自动分配的数字。在某些情况下，您的IP地址在浏览不同会话时是保持不变的；
        但是如果您使用的是消费者网络访问提供商，您的IP地址在不同会话时可能不同。我们仅追踪和会话Cookies相连的IP地址，用来分析我们网站的流量情况。
      </p>
      <br />
      <p>
        “Cookies”是在您浏览一个网站时，网站向您的电脑硬盘驱动器发送的一个小型信息片段。我们可能使用会话Cookies（一旦关闭网页浏览器便会失效）
        和持久性Cookies（会一直保存在您的电脑上直到您删除它们）判断注册用户是否已经登录，提升服务/产品质量及优化用户体验。如您不希望个人信息保存在
        Cookie 中， 您可对浏览器进行配置，选择禁用 Cookie 功能。禁用 Cookie
        功能后，可能影响您访问开罗尔或不能充分取得开罗尔提供的服务。
      </p>
      <br />
      <p>
        开罗尔不会将 Cookie
        用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookie。
        您可以清除计算机上保存的所有 Cookie，大部分网络浏览器都设有阻止 Cookie
        的功能。
      </p>
      <br />
      <p>
        开罗尔在提供服务的过程中，可能需要您开通一些设备权限，例如通知、相册、相机、手机通讯录、蓝牙等访问权限。您也可以在设备的设置功能中随时选择关闭部分或者全部权限，
        从而拒绝开罗尔收集相应的个人信息。在不同设备中，权限显示方式及关闭方式可能有所不同，具体请参考设备及系统开发方说明或指引。
      </p>
      <br />
      <h2>五、本政策如何更新</h2>
      <p>
        开罗尔官网有权在必要时修改本政策条款，并以应用内部弹窗方式通知到您。但未经您明确同意，我们不会削减您依据本政策所应享有的权利。您可以随时在本平台查阅修改后的最新版本政策。如您不同意修改后的政策，您有权终止对平台的授权。本政策更新后，如果您继续使用相应平台功能，即视为您已接受修改后的政策。
      </p>
      <br />
      <p>
        对于重大变更，平台还会提供更为显著的通知（包括平台会通过应用内部弹窗的方式进行意见征集、公告通知甚至向您提供弹窗提示）。
      </p>
      <br />
      <p>本政策所指的重大变更包括但不限于：</p>
      <br />
      <div class="mar-l-15">
        <p>
          1.
          平台的服务模式发生重大变化。如处理用户信息的目的、用户信息的使用方式等；
        </p>
        <br />
        <p>
          2.
          平台在控制权、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
        </p>
        <br />
        <p>3. 用户信息共享、转让或公开披露的主要对象发生变化；</p>
        <br />
        <p>
          4. 平台负责处理用户信息安全的责任部门、联络方式及投诉渠道发生变化时；
        </p>
        <br />
        <p>5. 用户信息安全影响评估报告表明存在高风险时。</p>
      </div>
      <H2>六、与平台联系</H2>
      <P
        >当您有个人信息保护相关问题或其他的投诉举报、建议等，可以通过如下方式与平台联系，平台将尽快审核所涉及内容，并于15日内对于您的问题、投诉举报、建议进行回复：</P
      >
      <br />
      <P>1.您可以随时拨打我们的客服电话400-0356256与我们联系；</P>
      <P
        >2.平台设立了个人信息保护专职部门，您可以通过邮箱chiralproduct@eduzhixin.com与其联系；</P
      >

      <H2>七、其他</H2>
      <P
        >1.本政策所有条款的标题仅为阅读方便，不作为本政策涵义解释或限制的依据。</P
      >
      <br />
      <P
        >2.本政策任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本政策其余条款的有效性及可执行性。</P
      >
      <br />
      <P>
        3.本政策可能存在包括中文、英文在内的多种语言的版本，如果存在中文版本与其他语言的版本相冲突的地方，以中文版本为准。</P
      >
      <br />
      <P
        >4.针对某些特定的产品及服务，平台还将制定特定的隐私政策，并在向您提供这些特定的产品及服务之前加以说明。如相关特定的隐私政策与本政策不一致的，适用该特定隐私政策。</P
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "Policy",
  data() {
    return {
      chiralUrl: process.env.VUE_APP_CHIRAL_URL,
      tableData: [
        {
          sdk: "com.huawei.hms(华为;华为推送)",
          name: "华为软件技术有限公司",
          info: "设备信息（OAID、AndroidID）、网络类型、设备型号、应用信息（应用名、应用版本号)",
          md: "定向推送消息",
          gw: "https://developer.huawei.com/consumer/cn/",
        },
        {
          sdk: "com.tencent.bugly(Bugly; 腾讯Bugly)",
          name: "腾讯科技（深圳）有限公司",
          info: "设备信息(IMSI,MAC地址,AndroidID)、Crash环境、网络信息、日志信息",
          md: "用于检测App稳定性并进行故障诊断、崩溃上报，以便帮助用户快速解决异常情况",
          gw: "https://bugly.qq.com/v2/",
        },
        {
          sdk: "com.sensorsdata.analytics(SensorsAnalytics;神策)",
          name: "神策网络科技（北京）有限公司",
          info: "您在页面上的操作、页面内容的展示信息、手机运营商、网络信息、OAID",
          md: "为统计分析您使用我们的产品或服务时的行为情况，您的兴趣偏好行为，做精准渠道匹配和应用内推广，已改善平台推荐算法、异常功能问题追踪及修复功能体验。",
          gw: "https://www.sensorsdata.cn",
        },
        {
          sdk: "com.umeng(友盟)",
          name: "友盟同欣（北京）科技有限公司、北京锐讯灵通科技有限公司",
          info: "设备标识符（IMEI、Android ID、Serial、IMSI、OPENUDID、GUID、ICCID）、MAC地址、WLAN接入点、设备传感器数据",
          md: "向您提供便捷的用户登录方式，分享内容到第三方APP（微博，微信，QQ）",
          gw: "https://www.umeng.com/",
        },
        {
          sdk: "com.huawei.agconnect(华为;华为联运应用)",
          name: "华为软件技术有限公司",
          info: "设备信息（OAID、AndroidID）、网络类型、设备型号、应用信息（应用名、应用版本号)",
          md: "定向推送消息",
          gw: "https://developer.huawei.com/consumer/cn/",
        },
        {
          sdk: "com.aliyun(阿里云;阿里push;阿里推送;)",
          name: "阿里云计算有限公司",
          info: "网络信息、手机运营商、系统版本、bssid",
          md: "提供图片、html页面、素材、视频、音频、上传文件的云存储能力、日志上传及分析、域名解析功能。",
          gw: "https://www.aliyun.com/",
        },
      ],
    };
  },
};
</script>
<style scoped src="@/assets/css/zx-agreement.css"></style>

<style scoped>
.container {
  text-align: center;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
.container:after,
.container:before {
  display: table;
  content: " ";
}
.container:after {
  clear: both;
}
p,
h2 {
  text-align: left;
}
p {
  line-height: 24px;
  font-weight: 400;
}
</style>
