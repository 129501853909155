import Vue from "vue";
import Vuex from "vuex";
import userInfo from "@/store/modules/userInfo";
import getters from "@/store/getters";
// 直播间
import user from "lingdongliang-module/lib/user";
import live from "lingdongliang-module/lib/live";
import question from "lingdongliang-module/lib/question";
import shopping from "lingdongliang-module/lib/shopping";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    userInfo,
    user,
    live,
    question,
    shopping,
  },
  getters,
});
