export default {
  data() {
    return {
      phone: "",
      password: "",
      phoneTip: "",
      passTip: "",
    };
  },
  methods: {
    bindInputPhone() {
      this.phone = this.phone.replace(/[^\d]/g, "");
      if (this.phone) {
        this.phoneTip = "";
      }
    },
    bindInputPass() {
      this.password = this.password
        .replace(/[\u4E00-\u9FA5]/g, "")
        .replace(/\s+/g, "");
      if (this.password) {
        this.passTip = "";
      }
    },
    toPreRoute() {
      let preRoute = this.$route.query?.preRoute;
      if (preRoute && preRoute !== "/") {
        this.$router.push({ path: preRoute });
      } else {
        this.$router.push({ path: "/recommend-center" });
      }
    },
  },
};
