<template>
  <div class="side-operation">
    <div style="font-weight: 500">客服投诉</div>
    <div class="margin-top">投诉电话：</div>
    <a href="tel:400-0356256">400-0356256</a>
    <div class="margin-top">客服电话：</div>
    <a href="tel:400-0356256">400-0356256</a>
    <div class="work-time margin-top">工作时间：</div>
    <div class="work-time">10:00-19:00</div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.side-operation {
  border-radius: 4px;
  padding: 16px 12px;
  font-size: 12px;
  background: #fff;
  margin-top: 11px;
  text-align: left;
  box-shadow: 0 2px 8px 0 rgba(139, 152, 169, 0.19);
  position: fixed;
  right: 20px;
  bottom: 200px;
  z-index: 1;
  a {
    color: red;
    padding: 0;
  }
  .work-time {
    color: rgba(51, 51, 51, 0.6);
  }
  .margin-top {
    margin-top: 12px;
  }
}
</style>
