<template>
  <div id="app">
    <nav-bar v-if="layout === 'default-page'"></nav-bar>
    <component :is="layout"> </component>
    <chiral-footer v-if="layout === 'default-page'"></chiral-footer>
    <side-operation v-if="!isMobile"></side-operation>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
import SideOperation from "@/components/side-operation/Main";
import { isMobile } from "@/utils/utils";

export default {
  data() {
    return {
      isMobile: isMobile(),
      default_layout: "default-page",
    };
  },
  computed: {
    layout() {
      return this.$route.meta.layout || this.default_layout;
    },
  },
  components: {
    "nav-bar": NavBar,
    "chiral-footer": Footer,
    "side-operation": SideOperation,
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/main.scss";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  background: $chiral-background-color;
}
.content,
.live-content {
  // padding-top: 24px;
  margin: 0 auto;
  flex: 1 0 auto;
  width: 100%;
  position: relative;
  @media (max-width: 767px) {
    width: 343px;
    min-height: calc(100vh - 116px);
  }
  @media (min-width: 768px) {
    width: 750px;
    min-height: calc(100vh - 236px);
  }
  @media (min-width: 1200px) {
    width: 1140px;
    min-height: calc(100vh - 300px);
  }
}
html {
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}
.el-button--primary {
}
.el-button--primary:hover,
.el-button--primary:focus {
  background: $chiral-btn-color !important;
  border-color: $chiral-btn-color !important;
  color: $avatar-color;
}
.el-button--primary.is-disabled,
.el-button--primary.is-disabled:active,
.el-button--primary.is-disabled:focus,
.el-button--primary.is-disabled:hover {
  color: $avatar-color !important;
  background-color: rgba($color: $chiral-color, $alpha: 0.1) !important;
  border-color: #eaeaea !important;
}
</style>
