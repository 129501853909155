import "@/plugins/mob-share";
import { MOD_SHARE_KEY } from "@/utils/constants.js";
import api from "@/plugins/api";

/*
  手机号校验
  @param number: 手机号  isReg:是否检验手机号规则
*/
export function vailPhone(number, isReg = true) {
  let flag = false;
  const myreg = /^1[3456789]{1}\d{9}$/;
  if (number.length !== 11) {
    flag = false;
  } else if (isReg && !myreg.test(number)) {
    flag = false;
  } else {
    flag = true;
  }
  return flag;
}

export function vailPassWord(str) {
  let flag = false;
  const myreg = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/;
  if (str.length < 8 || str.length > 40) {
    flag = false;
  } else if (!myreg.test(str)) {
    flag = false;
  } else {
    flag = true;
  }
  return flag;
}

export function parse(search) {
  const searchStr = decodeURIComponent(search);
  if (searchStr) {
    const result = {};
    const arr = searchStr.split("&");
    for (let i = 0; i < arr.length; i++) {
      const [key, value] = arr[i].split("=");
      result[key] = value;
    }
    return result;
  } else {
    return {};
  }
}

export function isMobile() {
  return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
}

export function isWeChat() {
  return navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1;
}

export function mobShare(platform = "weixin", params = {}, callback) {
  const defaults = {
    url: window.location.href,
    title: `知识点名称`,
    description: "开罗尔 — 连接你与竞赛的桥梁",
    pic: "https://cdn.eduzhixin.com/chiral/img/logo.png",
  };
  const data = Object.assign({}, defaults, params);
  window.mobShare.config({
    appkey: MOD_SHARE_KEY,
    params: data,
    callback: callback,
  });
  window.mobShare(platform).send();
}

export function clipboard(content, callback) {
  var input = document.createElement("input");
  input.setAttribute("readonly", "readonly");
  input.setAttribute("value", content);
  document.body.appendChild(input);
  input.select();
  input.setSelectionRange(0, 9999);
  if (document.execCommand) {
    document.execCommand("copy");
    callback();
  }
  document.body.removeChild(input);
}

export function isMqq() {
  return navigator.userAgent.toLowerCase().indexOf(" qq/") !== -1;
}

export function pageShare(wx, params = {}) {
  const defaults = {
    url: window.location.href,
    title: `质心教育,物理${process.env.VUE_APP_JIANGSAI},生物${process.env.VUE_APP_JIANGSAI},化学${process.env.VUE_APP_JIANGSAI},自主招生全面辅导`,
    desc: `学${process.env.VUE_APP_JIANGSAI}，到质心！`,
    pic: "https://static.eduzhixin.com/images/share/logo.png",
  };
  const data = Object.assign({}, defaults, params);
  api.weChatShare(data).then(
    (res) => {
      if (res.data.result === 1) {
        weChatShare(wx, data);
      } else {
        console.log(res.data.msg);
      }
    },
    (_) => {
      console.log(_);
    }
  );
}

function weChatShare(wx, params) {
  wx.config({
    debug: false,
    appId: params.appId,
    timestamp: params.timestamp,
    nonceStr: params.noncestr,
    signature: params.signature,
    jsApiList: [
      "onMenuShareTimeline",
      "onMenuShareAppMessage",
      "onMenuShareQQ",
      "onMenuShareQZone",
    ],
  });
  wx.error(function (res) {
    console.log(res);
  });
  wx.ready(function () {
    wx.onMenuShareTimeline(params);
    wx.onMenuShareAppMessage(params);
    wx.onMenuShareQQ(params);
    wx.onMenuShareQZone(params);
  });
}
