<template>
  <div class="my-course-detail">
    <div class="sub-nav">
      <slot v-if="studiedSubList.length && notStudySubList.length">
        <div
          class="nav-item"
          :class="index === navActivity ? 'activity' : ''"
          v-for="(item, index) in navList"
          :key="item"
          @click="changeSubclassStateList(index)"
        >
          {{ item }}
        </div>
      </slot>
      <div class="nav-item activity" v-else>全部课程</div>
    </div>
    <div class="subclass-info subclass-list">
      <div
        class="subclass-item"
        v-for="item in courseSubclass"
        :key="item.subclass_id"
      >
        <div class="sub-title">
          <span class="title">{{ item.subject }}</span>
          <span class="info" v-if="item.desp">{{ item.desp }}</span>
        </div>
        <div class="sub-time">
          <div class="time-item">
            <i class="chiral fa-time"></i
            >{{ item.begin_at | formatSubclassBeginTime }}-{{
              item.end_at | formatSubclassEndTime
            }}&nbsp;{{ item.begin_at | formatWeek }}
          </div>
          <div class="time-item">
            <i class="chiral fa-teacher"></i>
            <span>{{ item.teachers }}</span>
          </div>
          <div>
            <span v-if="item.deadline_at"
              >观看有效期至{{ item.deadline_at | formatPlayBack }}</span
            >
          </div>
          <button
            class="course-btn"
            :class="
              subclassBtnText(item) < 3 || subclassBtnText(item) >= 5
                ? 'viable'
                : ''
            "
            @click="toLiveRoom(item)"
          >
            {{ courseStateBtn[subclassBtnText(item)] }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/plugins/api";
import { COURSE_STATE_BTN } from "@/utils/constants.js";

export default {
  name: "CourseDetail",
  data() {
    return {
      navList: ["待上的课", "已上的课"],
      courseStateBtn: COURSE_STATE_BTN,
      navActivity: 0,
      classInfo: {},
      userSubclass: {}, //购买的课
      studiedSubList: [], //已上的课
      notStudySubList: [], //待上的课
      courseSubclass: [],
    };
  },
  methods: {
    initClassInfo() {
      let classId = this.$route.params.id;
      Promise.all([
        api.getClassInfo({ class_id: classId }),
        api.getUserSubclass({ class_id: classId, goods_type: 2 }),
      ]).then((res) => {
        let [info, sub] = res;
        this.handleUserSubclass(sub);
        this.handleClassInfo(info);
        this.$emit("classInfo", info);
      });
    },
    //用户买的小课列表
    handleUserSubclass(res) {
      if (res.data.code === 1) {
        this.userSubclass = res.data.data || [];
      }
    },
    //课程详情
    handleClassInfo(res) {
      if (res.data.code === 1) {
        let item = res.data.data;
        let subclass = item.class_info.subclass || [];
        let now = parseInt(new Date().getTime() / 1000);
        //循环每个小课 判断小课购买状态， 默认没有买purchase为0
        subclass.forEach((sub) => {
          sub.purchase = 0;
          for (let i = 0; i < this.userSubclass.length; i++) {
            if (sub.subclass_id === this.userSubclass[i].subclass_id) {
              sub.purchase = 1;
              break;
            }
          }
          //将开始和未开始的小课分开
          if (now > sub.end_at) {
            this.studiedSubList.push(sub);
          } else {
            this.notStudySubList.push(sub);
          }
        });
        // 已上完的小课 倒序排列
        if (this.studiedSubList.length && this.notStudySubList.length) {
          this.studiedSubList.reverse();
          this.courseSubclass = this.notStudySubList;
        } else {
          if (!this.notStudySubList.length) {
            // 已上完的小课 倒序排列
            subclass.reverse();
          }
          this.courseSubclass = subclass;
        }
        this.classInfo = item.class_info;
      } else {
        this.$chiralConfirmMessage("没有此课程", () => {
          this.$router.push({ path: "/lecture-center" });
        });
      }
    },
    //切换导航
    changeSubclassStateList(index) {
      this.navActivity = index;
      this.courseSubclass = index ? this.studiedSubList : this.notStudySubList;
    },
    //按钮文案
    subclassBtnText(subObj) {
      /*
        返回状态
        直播(进行中)  1
        回放         2
        直播(未开始)  3
        过期         4 
        没有购买      5 
        过期，可重新报名      6
      */
      let sub_begin = subObj.begin_at;
      let sub_end = subObj.end_at;
      let sub_deadline = subObj.deadline_at;
      let now = parseInt(new Date().getTime() / 1000);
      if (!subObj.purchase) {
        return 5;
      } else if (sub_deadline && sub_deadline < now) {
        if (subObj.price) {
          return 4;
        } else {
          return 6;
        }
      } else if (now > sub_end) {
        return 2;
      } else if (now + 1800 >= sub_begin && now <= sub_end) {
        return 1;
      } else {
        return 3;
      }
    },
    toLiveRoom(subObj) {
      let courseState = this.subclassBtnText(subObj);
      if (courseState < 3) {
        let liveState = courseState === 1 ? "live" : "replay";
        this.$router.push({
          path: `/live/${this.classInfo.class_id}/${subObj.subclass_id}/${liveState}`,
        });
      }
      // 过期课--跳转课程详情
      else {
        this.$router.push({
          path: `/course-detail/${subObj.class_id}`,
        });
      }
    },
  },
  mounted() {
    this.initClassInfo();
  },
};
</script>
<style lang="scss" scoped>
.my-course-detail {
  width: 100%;
  background: $avatar-color;
  box-sizing: border-box;
  .sub-nav {
    background: $chiral-background-color;
    display: flex;
    width: 100%;
    .nav-item {
      padding: 16px 48px;
      font-size: 14px;
      font-weight: 400;
      color: $chiral-week-color;
      background: $avatar-color;
      line-height: 14px;
      border-top: 2px solid $avatar-color;
      cursor: pointer;
    }
    .activity {
      border-top: 2px solid $chiral-btn-color;
    }
  }
  @media (max-width: 767px) {
    .sub-nav {
      background: $avatar-color;
    }
  }
  .subclass-info {
    flex: 1;
    background: $avatar-color;
    margin-right: 8px;
    padding: 24px;
    font-size: 14px;
    width: 100%;
    word-break: break-all;
    :deep p {
      margin-bottom: 20px !important;
      vertical-align: middle;
    }
    :deep a {
      color: #4196e6;
      text-decoration: none;
    }
    :deep img {
      max-width: 100%;
    }
  }
  @media (max-width: 767px) {
    .subclass-info {
      width: 100%;
      margin-right: 0px;
      padding: 0.6133rem 0.4267rem 0.6133rem 0.4267rem;
    }
  }
  .subclass-list {
    color: $chiral-week-color;
    .subclass-item {
      border-bottom: 1px solid #f1f6fc;
      padding-bottom: 24px;
      margin-bottom: 27px;
    }
    .sub-title {
      margin-bottom: 25px;
      .title {
        font-size: 18px;
        font-weight: 500;
        line-height: 18px;
        margin-right: 16px;
      }
      .info {
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        margin-right: 12px;
      }
    }
    @media (max-width: 767px) {
      .sub-title {
        margin-bottom: 0.2133rem;
        .title {
          margin-right: 0.2667rem;
          font-size: 0.4267rem;
        }
        .info {
          margin-right: 0.2667rem;
          font-size: 0.32rem;
        }
      }
    }
    .sub-time {
      display: flex;
      position: relative;
      .time-item {
        margin-right: 41px;
        i {
          margin-right: 8px;
        }
      }
      .course-btn {
        position: absolute;
        right: 24px;
        padding: 4px 12px;
        border: 1px solid;
        background: $avatar-color;
        border-radius: 4px;
        top: 50%;
        transform: translate(0px, -50%);
        color: $chiral-unchecked-color;
        border-color: $chiral-unchecked-color;
        cursor: auto;
      }
      .viable {
        color: $chiral-btn-color;
        border-color: $chiral-btn-color;
        cursor: pointer;
      }
    }
    @media (max-width: 1199px) {
      .sub-time {
        flex-direction: column;
        .time-item {
          margin: 0.1867rem 0px 0.2933rem;
        }
        .course-btn {
          width: fit-content;
          height: fit-content;
          bottom: -0.1067rem;
          right: 0px;
          top: auto;
          transform: none;
          padding: 0.1067rem 0.24rem;
        }
      }
    }
    @media (min-width: 768px) and (max-width: 1199px) {
      .sub-time {
        .course-btn {
          right: 0px;
        }
      }
    }
    //小课状态的标签
    .tag {
      padding: 0px 2px;
      font-size: 12px;
      border: 1px solid #adb8cc;
      color: #9da6b8;
      border-radius: 2px;
      margin-right: 11px;
      display: inline-block;
    }
    .tag-visable {
      border-color: $chiral-free-color;
      color: $chiral-free-color;
    }
    // .tag-playback {
    //   border-color: $chiral-btn-color;
    //   color: $chiral-btn-color;
    // }
    .tag-zhibo {
      border-color: $chiral-free-color;
      background: $chiral-free-color;
      color: $avatar-color;
    }
    //
  }
}
@media (max-width: 767px) {
  .my-course-detail {
    margin-top: 0.3733rem;
  }
}
</style>
