<template>
  <div class="cart-entry">
    <div class="table-cell hidden-ipad col-2">
      <img
        :src="item.cover | defaultCover"
        width="170"
        height="96"
        :alt="item.subject"
      />
    </div>
    <div class="table-cell col-3" style="padding-left: 32px">
      <div class="strong hidden-xs">
        <a class="text-ellipse" href="javascript:;">{{ item.subject }}</a>
      </div>
      <div class="strong visible-xs">
        <a href="javascript:;">{{ item.subject }}</a>
      </div>
      <div class="visible-xs">
        <span :class="{ duration: item.duration && item.duration.time }">
          {{ item.duration && item.duration.date }}
          {{ item.duration && item.duration.time }}
        </span>
        <span class="chapters">| 共{{ item.chapters.length }}节</span>
      </div>
      <div class="teacher">授课老师：{{ item.teachers }}</div>
      <div
        v-if="item.materials && item.materials.length"
        class="ziliao entry-ziliao"
      >
        <span
          ><i class="chiral fa-zeng"></i>&nbsp;&nbsp;教材&福利*{{
            item.materialsCount
          }}</span
        >
      </div>
    </div>
    <div class="table-cell col-5 hidden-xs" style="padding-left: 32px">
      <div>
        <span :class="{ duration: !(item.duration && item.duration.time) }">
          {{ item.duration && item.duration.date }}
        </span>
        <span :class="{ duration: item.duration && item.duration.time }">
          {{ item.duration && item.duration.time }}
        </span>
        <span class="chapters">| 共 {{ item.chapters.length }} 节</span>
      </div>
    </div>
    <!-- price -->
    <div class="table-cell col-2 cart-price-box" style="padding-left: 32px">
      <slot v-if="isGroup">
        <div class="visible-xs">
          <span class="origin-price delete-price"
            >￥{{ Number((item.price / 100).toFixed(2)) }}</span
          >
          <span class="group-price"
            >￥{{ Number((item.left_group_price / 100).toFixed(2)) }}</span
          >
          <span class="badge">团购价</span>
        </div>
        <div class="hidden-xs">
          <div class="origin-price delete-price">
            {{ Number((item.price / 100).toFixed(2)) }}
          </div>
          <div class="group-price">
            ￥{{ Number((item.left_group_price / 100).toFixed(2)) }}
          </div>
          <div class="badge">团购价</div>
        </div>
      </slot>
      <div
        v-else
        class="price-wrapper"
        :style="item.couponPrice ? 'text-align: right;' : ''"
      >
        <div
          v-if="item.deletedPrice && item.deletedPrice !== item.needPrice"
          style="margin-bottom: 7px"
        >
          <span class="origin-price delete-price"
            >￥{{ Number((item.deletedPrice / 100).toFixed(2)) }}</span
          >
          <span v-if="item.left_sub_count" class="badge badge-warning"
            >剩余{{ item.left_sub_count }}章节</span
          >
        </div>
        <span class="active-price"
          >￥{{ Number((item.needPrice / 100).toFixed(2)) }}</span
        >
        <!-- <div v-if="item.isUnion" style="margin-top: 7px">
          <span class="badge union-price-tag" style="width: 40px; padding: 3px"
            >联报价</span
          >
        </div> -->
      </div>
      <div style="clear: both; text-align: right">
        <span
          style="
            padding: 2px;
            font-size: 12px;
            background: rgba(198, 205, 218, 0.3);
            color: rgba(51, 51, 51, 0.5);
            border-radius: 2px;
          "
          v-if="item.material_goods_id > 0"
          >含{{ item.material_goods_price }}元教材费</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isGroup: Boolean,
    item: Object,
  },
  filters: {
    defaultCover(src) {
      return src || "http://image.eduzhixin.com/img/live/default-cover.png";
    },
  },
};
</script>
<style lang="scss">
.cart-entry {
  font-size: 14px;
  font-weight: 400;
  color: $primary-color;
  display: table;
  padding: 32px;
  padding-left: 32px;
  width: 100%;
  border: 1px solid rgba(51, 51, 51, 0.1);
  border-bottom-color: transparent;
  box-sizing: border-box;
  > div {
    box-sizing: border-box;
  }

  @media screen and (max-width: 767px) {
    padding-top: 16px;
    padding-bottom: 8px;
    .cart-price-box {
      text-align: right;
    }
  }

  &-selected {
    background: rgba(0, 199, 191, 0.04);
  }

  &.invalid {
    position: relative;
    background: rgba(51, 51, 51, 0.04);

    &::after {
      position: absolute;
      content: " ";
      width: 100%;
      left: 0;
      z-index: 1;
      top: 0;
      height: 100%;
      background: rgba(255, 255, 255, 0.7);
    }
  }

  & + .cart-entry {
    border-top: 1px solid rgba(51, 51, 51, 0.1);
    // margin-top: 12px;
  }

  .strong {
    font-size: 16px;
    font-family: SFProDisplay-Medium, SFProDisplay;
    font-weight: 500;
  }

  .teacher {
    color: rgba(51, 51, 51, 0.8);
  }

  .chapters {
    color: rgba(51, 51, 51, 0.5);
    font-size: 12px;
    line-height: 14px;
    position: relative;
    top: -1px;
  }

  .table-cell {
    position: relative;
    vertical-align: top;
    display: table-cell;
    img {
      border-radius: 4px;
    }

    a {
      color: #333;
    }

    a {
      @media screen and (min-width: 768px) {
        width: 95%;
        display: inline-block;
        max-width: 337px;
      }
    }

    &.col-2 {
      width: 170px;
      max-height: 96px;
    }

    &.col-3 {
      width: 337px;
    }

    &.col-4 {
      width: 204px;
    }

    &.col-5 {
      // width: 134px;
    }

    @media screen and (max-width: 767px) {
      line-height: 1.8;
      &.col-1 {
        position: absolute;
        // top: 50%;
        left: 20px;
        width: auto;
      }
      display: block;
      &.col-2,
      &.col-3,
      &.col-4,
      &.col-5,
      &.col-6 {
        width: 100%;
      }
    }
  }

  .zhixin-checkbox-wrapper {
    display: inline-block;
    width: 20px;
  }

  .zhixin-checkbox-wrapper .check {
    width: 18px;
    height: 18px;
  }

  .select-button {
    width: 82px;
    text-align: center;
    vertical-align: middle;
  }

  .delete {
    cursor: pointer;
  }
}
.cart-entry:last-child {
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}
.table-cell {
  position: relative;
  vertical-align: top;
  .delete-price {
    text-decoration: line-through;
    color: rgba(51, 51, 51, 0.3);
  }
  .active-price {
    color: #ffa000;
  }
  .group-price {
    color: #ef4d3c;
  }
  img {
    border-radius: 4px;
  }

  a {
    color: #333;
  }

  a {
    @media screen and (min-width: 768px) {
      width: 95%;
      display: inline-block;
      max-width: 337px;
    }
  }

  &.col-2 {
    width: 170px;
    max-height: 96px;
  }

  &.col-3 {
    width: 337px;
  }

  &.col-4 {
    width: 204px;
  }

  &.col-5 {
    // width: 134px;
  }

  @media screen and (max-width: 767px) {
    line-height: 1.8;
    &.col-1 {
      position: absolute;
      // top: 50%;
      left: 20px;
      width: auto;
    }
    display: block;
    &.col-2,
    &.col-3,
    &.col-4,
    &.col-5,
    &.col-6 {
      width: 100%;
    }
  }
}
</style>
