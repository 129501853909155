<template>
  <div id="confirm-order-page" class="content" v-if="showOrder">
    <div class="page-title">
      <img
        class="inline-block"
        src="@/assets/img/confirm-order/logo@2x.png"
        alt="logo"
      />
      <span class="inline-block">确认订单信息</span>
    </div>
    <!-- 团购须知 -->
    <div
      class="border-wrapper"
      v-if="isGroup"
      style="padding: 32px 0px 0px 0px; border: 0"
    >
      <p class="text-dark-subject">团购须知</p>
      <div class="order-xz">
        <p>
          1.
          拼团有效期内满足规则的人数参团并付款成功即为拼团成功，可在我的课程中看到所购买课程。
        </p>
        <p>
          2.
          开团或参与团购并付款后，不可撤销拼团，在拼团有效期结束后如拼团失败，拼团会自动取消并退款。
        </p>
        <p>
          3.
          拼团失败后，支付金额一般会在原渠道退回，到帐时间以微信、支付宝等支付平台为准。
        </p>
      </div>
    </div>
    <!-- 收货地址 -->
    <div class="border-wrapper" v-if="needAddress">
      <p class="text-dark-subject" style="margin-right: 10px">请选择收货地址</p>
      <span class="tips"
        >为保证及时收到资料，建议您将收货地址设置为学校地址。</span
      >
      <div class="address-entry-add" v-if="addresses.length < 7">
        <button class="btn btn-text address-action" @click="addAddresses({})">
          新增收货地址
        </button>
      </div>
      <slot v-for="(item, index) in addresses">
        <div
          @click="setDefault(item.id)"
          :key="index + 1"
          v-if="Number(item.is_default) === 1 || index === 0 || showedAddresses"
          :class="
            'address-entry' + (Number(item.is_default) === 1 ? ' default' : '')
          "
        >
          <div class="triangle" v-if="Number(item.is_default) === 1">
            <i class="fa-zhixin fa-check" style="color: #fff"></i>
          </div>
          <div class="table-cell desp-ellipse pad-left-65" style="width: 96px">
            {{ item.name }}
          </div>
          <div class="table-cell" style="width: 91px">{{ item.mobile }}</div>
          <div class="table-cell" style="padding-left: 80px; max-width: 250px">
            {{ item.province }}{{ item.city }}{{ item.district }}
            {{ item.address }}
          </div>
          <div class="table-cell text-right" style="width: 130px">
            <span
              class="text-primary address-action"
              @click="addAddresses(item)"
              >编辑</span
            >
            <span
              class="text-primary address-action"
              @click="delAddresses(item.id)"
              >删除</span
            >
          </div>
        </div>
      </slot>
      <div
        @click="showedAddresses = !showedAddresses"
        :class="'address-entry-more' + (showedAddresses ? ' rotate' : '')"
        v-if="addresses.length > 1"
      >
        <span class="sublist-label">更多地址</span>
        <i class="chiral fa-xiala"></i>
      </div>
    </div>
    <!-- 课程清单 -->
    <div class="border-wrapper" style="padding: 32px 0; border: 0">
      <p class="text-dark-subject" style="padding: 0 32px 0 8px">课程清单</p>
      <div class="confirm-order" style="margin: 0">
        <div
          class="live-list-container"
          style="font-size: 14px; display: block"
          v-if="courseList.length"
        >
          <ConfirmItem
            v-for="(item, index) in courseList"
            :item="item"
            :key="index"
            :isGroup="isGroup"
          />
        </div>
      </div>
    </div>
    <!-- 价格显示 -->
    <div class="text-right primary-info" style="margin-top: 0">
      <div class="selected-address-block" style="background-color: transparent">
        <div class="price-item">
          合计金额：<span class="price-statistics">
            ¥{{ priceStatistics.original_price }}
          </span>
        </div>
        <div class="price-item">
          活动优惠：<span class="price-statistics">
            -¥{{ priceStatistics.event_discounts }}
          </span>
        </div>
        <!-- <div class="price-item">
          礼券优惠：<span class="price-statistics">
            -¥{{ priceStatistics.gift_certificate_discount }}
          </span>
        </div> -->
      </div>
      <div class="selected-address-block">
        <div>
          实付金额：<span class="text-price active-price"
            >¥{{ priceStatistics.cart_price }}</span
          >
        </div>
        <slot v-if="needAddress && addresses.length && address">
          <div>
            邮寄地址：{{ address.province }} {{ address.city }}
            {{ address.district }} {{ address.address }}
          </div>
          <div>收件人：{{ address.name }} {{ address.mobile }}</div>
        </slot>
      </div>
    </div>
    <div class="text-right">
      <button
        class="btn btn-lg btn-confirm-order"
        data-need="true"
        style="padding: 12px 48px"
        @click="submitOrder"
      >
        提交订单
      </button>
    </div>
    <div class="cannot-refund-bottom text-right">
      <i class="fa-zhixin text-warning fa-exclamation-circle"></i
      >所报课程中含有回放课程，回放课程不支持退款和换课！
    </div>
    <!-- 新增编辑收货弹窗 -->
    <Addresses ref="Addresses" :callback="handleAddresses" />
  </div>
</template>
<script>
import api from "@/plugins/api";
import Addresses from "./Addresses";
import ConfirmItem from "./ConfirmItem";
import moment from "moment";
import qs from "qs";
import { GROUP_ACTIVITY } from "@/utils/constants";

export default {
  data() {
    return {
      moment,
      showOrder: false,
      addresses: [], // 收货地址列表信息
      needAddress: false, //是否需要邮寄
      showedAddresses: false, //查看更多地址
      address: null, //默认地址信息
      addressId: "", //默认地址信息id
      priceStatistics: {
        original_price: 0, //合计金额
        cart_price: 0, //实际金额
        event_discounts: 0, //活动优惠金额
        gift_certificate_discount: 0, //礼券优惠金额
      },
      courseList: [], //课程清单
      isGroup: false,
    };
  },
  mounted() {
    this.getAddresses();
  },
  methods: {
    // 处理计算价格参数
    handleCartPriceParams() {
      let params = [];
      this.isGroup = Boolean(this.groupInfo);
      this.classList.forEach((item) => {
        if (this.groupInfo) {
          //处理团购订单计算价格参数
          let group = Object.assign(
            {
              goods_id: String(item.goods_id),
              goods_type: item.goods_type,
            },
            this.groupInfo
          );
          params.push(group);
        } else {
          //处理付费课程订单计算价格参数
          params.push({
            goods_id: String(item.goods_id),
            goods_type: item.goods_type,
          });
        }
      });
      return params;
    },
    // 计算价格
    calCartPrice() {
      let params = {
        compute: true,
        coupon_id: 0,
        goods_items: this.handleCartPriceParams(),
      };
      api.calCartPrice(params).then((res) => {
        if (res.data.code === 1) {
          let item = res.data.data;
          this.priceStatistics.original_price =
            Number(item.original_price) / 100;
          this.priceStatistics.cart_price = Number(item.cart_price) / 100;
          this.handleCourseList(item);
          if (this.isGroup) {
            this.priceStatistics.event_discounts = (
              this.priceStatistics.original_price -
              this.priceStatistics.cart_price
            ).toFixed(2);
          }
        }
      });
    },
    // 处理课程清单列表
    handleCourseList(calPrice) {
      let courseList = JSON.parse(JSON.stringify(this.classList));
      let list = courseList.map((item) => {
        //划掉的价格
        let deletedPrice = 0;
        // 实际支付的价格
        let needPrice = 0;
        // 团购原价
        let price = 0;
        // 团购价
        let left_group_price = 0;
        // 剩余章节数
        let left_sub_count = 0;
        calPrice.goods_discounts.forEach((goods) => {
          let isHavePartCourse =
            item.subclass.length - goods.chapter_info.length;
          //剩余章节的价格
          if (this.isGroup) {
            price = goods.original_price;
            left_group_price = goods.real_price;
          } else if (isHavePartCourse) {
            needPrice = goods.real_price;
            deletedPrice = item.price;
            left_sub_count = goods.chapter_info.length;
          } else if (goods.goods_id === item.goods_id) {
            // 使用优惠券购买课程价格会变化
            if (goods.original_price === goods.real_price) {
              needPrice = goods.real_price;
            } else {
              needPrice = goods.real_price;
              deletedPrice = goods.original_price;
            }
          }
        });
        return {
          goods_id: item.goods_id,
          goods_type: item.goods_type,
          need_address: item.need_address,
          cover:
            item.cover ||
            "https://image.eduzhixin.com/img/live/default-cover.png",
          subject: item.subject,
          duration: {
            date:
              moment(item.begin_at * 1000).format("YYYY.MM.DD") +
              " - " +
              moment(item.end_at * 1000).format("YYYY.MM.DD"),
            time:
              moment(item.begin_at * 1000).format("HH:mm") +
              " - " +
              moment(item.end_at * 1000).format("HH:mm"),
          },
          deletedPrice,
          needPrice,
          teachers: item.teachers,
          materials: item.material_info,
          materialsCount: item.material_info ? item.material_info.length : 0,
          chapters: item.subclass,
          left_sub_count,
          price,
          left_group_price,
        };
      });
      this.courseList = list;
      this.needAddress = courseList.some((item) => {
        return item.need_address;
      });
    },
    submitOrder() {
      const json = {
        coupon_id: 0,
        invited_id: 0,
        from_source: this.from_source,
        url_param: window.location.href.split("?")[1],
        goods_items: [],
      };
      for (let index = 0; index < this.courseList.length; index++) {
        let item = this.courseList[index];
        let obj = {
          goods_id: String(item.goods_id),
          goods_type: item.goods_type,
        };
        // 处理团购订单
        if (this.isGroup) {
          obj.activity_type = 1;
          obj.activity_id = this.groupInfo.activity_id || 0;
          obj.group_num = this.groupInfo.group_num;
        } else {
          obj.activity_type = 0;
          obj.activity_id = 0;
        }
        if (item.need_address && !this.addressId) {
          this.$message.warning("请选择邮寄地址");
          return false;
        } else if (item.need_address) {
          obj.address_id = Number(this.addressId);
        }
        json.goods_items.push(obj);
      }

      const dataOpt = qs.parse(window.location.search.substring(1));
      const paramJson = {
        url: window.location.href,
        url_param: qs.stringify(Object.assign(dataOpt)),
        utm_source: dataOpt.utm_source || "",
        utm_campaign: dataOpt.utm_campaign || "",
        utm_medium: dataOpt.utm_medium || "",
        utm_term: dataOpt.utm_term || "",
        utm_content: dataOpt.utm_content || "",
      };
      json.param_json = JSON.stringify(paramJson);
      api.creatOrder(json).then((res) => {
        if (res.data.code === 1) {
          // 处理0元团购订单
          if (this.isGroup && this.priceStatistics.cart_price === 0) {
            this.handleZeroYuanGroup(res.data.data.order_no, dataOpt);
          } else {
            window.location.href = `${process.env.VUE_APP_DOMAIN}/pay/${res.data.data.order_no}${window.location.search}`;
          }
        } else {
          this.$message.warning(res.data.message);
        }
      });
    },
    // 处理0元团购订单
    handleZeroYuanGroup(orderNo, dataOpt) {
      api
        .queryOrder({
          order_no: orderNo,
        })
        .then(
          (res) => {
            if (res.data.code === 1) {
              let order = res.data.data.various[0];
              if (
                order.activity_info &&
                order.activity_type === GROUP_ACTIVITY.GROUP
              ) {
                let urlSearch = Object.assign({ isPaided: true }, dataOpt);
                window.location.href = `${
                  process.env.VUE_APP_DOMAIN
                }/group-activity/${order.activity_info.group_id}${
                  urlSearch ? "?" + qs.stringify(urlSearch) : ""
                }`;
              }
            } else {
              this.$message.warning(res.data.msg);
            }
          },
          () => {}
        );
    },
    setDefault(id) {
      if (id === this.addressId) return;
      api.setDefault({ id }).then((res) => {
        if (res.data.code === 1) {
          this.showedAddresses = false;
          this.addresses.forEach((addr) => {
            addr.is_default = Number(addr.id) === Number(id) ? 1 : 0;
          });
          this.sortAddresses();
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    //收货地址排序
    sortAddresses() {
      this.addresses.sort(function (a, b) {
        return Number(b.is_default) - Number(a.is_default);
      });
      if (this.addresses.length) {
        const address = this.addresses.filter((item) => {
          return Number(item.is_default) === 1;
        });
        this.addressId = address.length ? address[0].id : "";
        this.address = address[0];
      }
    },
    // 新增地址
    addAddresses(item) {
      this.$refs.Addresses.handle = JSON.stringify(item) !== "{}";
      this.$refs.Addresses.showDialog(Object.assign({}, item));
    },
    // 删除地址
    delAddresses(id) {
      this.$refs.Addresses.deleteAddress(id);
    },
    //获取地址
    getAddresses() {
      api.getAddress().then((res) => {
        if (res.data.code === 1) {
          this.addresses = res.data.data.addresses || [];
          this.sortAddresses();
        }
      });
    },
    // 编辑、新增、删除地址的回调
    handleAddresses(type) {
      if (type === "delete") {
        this.addressId = "";
      }
      this.getAddresses();
    },
  },
  components: {
    Addresses,
    ConfirmItem,
  },
  watch: {
    classList(nVal) {
      if (nVal.length) {
        this.calCartPrice();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin transform($transforms) {
  transform: $transforms;
}
@mixin transition($args...) {
  transition: $args;
}
#confirm-order-page {
  padding-top: 30px;
  font-size: 14px;
  .page-title {
    font-size: 24px;
    font-weight: 400;
    color: #333333;
    line-height: 33px;
    display: flex;
    align-items: center;
    img {
      margin-right: 16px;
      width: 186px;
    }
  }
  @media (max-width: 767px) {
    .page-title {
      font-size: 16px;
      img {
        width: 120px;
      }
    }
  }
  .border-wrapper {
    position: relative;
    border-radius: 2px 2px 0 0;
    padding: 32px;
    border: 1px solid rgba(51, 51, 51, 0.1);
    margin-top: 40px;
    &:last-child {
      border-radius: 0 0 2px 2px;
    }

    .sublist-label {
      margin-right: 11px;
    }
    .text-dark-subject {
      font-size: 14px;
      font-weight: 500;
      color: rgba(51, 51, 51, 1);
      line-height: 14px;
      margin-bottom: 15px;
      padding-left: 8px;
      border-left: 2px solid #6c5ce7;
      display: inline-block;
    }

    .tips {
      display: inline-block;
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 160, 0, 1);
    }
    &.confirm-coupon {
      padding: 32px 0 0;
      border: 0;
      .text-dark-subject {
        padding: 0 32px 0 8px;
      }
      .all-coupon {
        margin-left: 12px;
        color: rgba(51, 51, 51, 0.6);
        cursor: pointer;
        font-weight: unset;
        i {
          color: #979797;
          vertical-align: baseline;
        }
      }
      .confirm-order {
        margin: 0;
        border: 1px solid rgba(51, 51, 51, 0.06);
        padding: 18px 32px;
        .del-coupon {
          position: absolute;
          right: -9px;
          top: -14px;
          z-index: 2;
          font-size: 18px;
          color: #cecece;
          cursor: pointer;
          background: #fff;
        }
        @media (max-width: 767px) {
          & {
            padding: 18px 24px;
          }
        }
        #coupon-empty {
          img {
            width: 55px;
            margin-left: 8px;
          }
          p {
            margin-top: 10px;
            margin-bottom: 0;
            color: rgba(51, 51, 51, 0.4);
          }
        }
      }
    }
    .address-entry-add {
      position: absolute;
      top: 32px;
      right: 32px;
      button {
        border: none;
        padding: 0;
        height: auto;
        background: transparent;
        width: auto;
        color: #4196e6;
      }
    }
    .order-xz {
      border-radius: 2px;
      border: 1px solid rgba(51, 51, 51, 0.1);
      margin: 16px 0px 40px 0px;
      box-sizing: border-box;
      padding: 19px 29px 19px 32px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 24px;
    }
  }
  //   收货地址
  .address-entry {
    cursor: pointer;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    border: 1px solid rgba(51, 51, 51, 0.1);
    position: relative;
    display: table;
    width: 100%;
    vertical-align: center;
    padding-left: 65px;
    margin-bottom: 15px;
    box-sizing: border-box;

    .table-cell {
      display: table-cell;
      height: 45px;
      vertical-align: middle;
      text-align: left;
      padding: 0 5px;
      .address-action {
        font-size: 14px;
        font-weight: 400;
        color: #4196e6;
        line-height: 20px;
        margin-right: 32px;
      }
      @media screen and (max-width: 767px) {
        display: block;
        width: 100% !important;
        height: auto;
        padding: 0 !important;
        line-height: 1.5;
        margin-bottom: 10px;
        text-align: left !important;
      }

      &:last-child {
        text-align: center;
      }
    }

    .triangle {
      display: none;
      position: absolute;
      width: 0;
      height: 0;
      top: 0;
      left: 0;
      border-top: 45px solid #6c5ce7;
      border-right: 45px solid transparent;

      .fa-zhixin {
        color: #fff;
        position: absolute;
        right: -18px;
        z-index: 5;
        bottom: 25px;
        font-size: 10px;
      }
    }

    &.default {
      border: 1px solid #6c5ce7;

      .triangle {
        display: block;
      }
    }

    &-more {
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(51, 51, 51, 0.6);
      line-height: 20px;

      .fa-v2 {
        font-size: 11px;
        color: rgba(51, 51, 51, 0.4);
        margin: -3px 0 0 5px;
      }

      &.rotate .fa-v2 {
        @include transform(rotateX(180deg));
        @include transition(all 0.4s ease);
      }

      cursor: pointer;
    }

    &-add {
      position: absolute;
      top: 32px;
      right: 32px;

      .btn-text {
        border: none;
        padding: 0;
        height: auto;
        background: transparent;
        width: auto;
        color: #4196e6;
      }
    }
  }
  // 统计价格
  .text-right {
    .selected-address-block {
      background: rgba(51, 51, 51, 0.03);
      border-radius: 1px;
      padding: 16px 32px;
      color: rgba(51, 51, 51, 0.6);

      .price-item {
        line-height: 1;
        margin-top: 16px;
        &:first-child {
          margin-top: 0;
        }
      }
      .active-price {
        display: inline-block;
        min-width: 100px;
        color: #ffa000;
        font-size: 22px;
      }

      .price-statistics {
        color: rgba(51, 51, 51, 0.8);
        font-weight: 500;
        min-width: 100px;
        display: inline-block;
      }
    }
    .btn-confirm-order {
      margin: 16px 0px 8px;
      color: #fff;
      font-weight: bold;
      background-color: #6c5ce7;
      border-color: #6c5ce7;
    }
  }
  .cannot-refund-bottom {
    margin-bottom: 39px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .fa-exclamation-circle {
      color: rgba(250, 203, 88, 1);
      margin-right: 9px;
    }
  }
}
</style>
