<template>
  <div class="class_process_card">
    <div class="class_type" v-if="classLabel === 1">
      <div class="class_offtypeText">面授</div>
    </div>
    <div class="class_type" v-else>
      <div class="class_ontypeText">直播</div>
    </div>
    <div class="card_title">
      <div
        class="state_success"
        :class="
          classState === 1
            ? 'state-jx'
            : classState === 2
            ? 'state-wj'
            : 'stategrey'
        "
      >
        {{ courseStudyState[classState] }}
      </div>
      <div>{{ subject }}</div>
    </div>
    <div class="teacher">
      <div v-for="(item, index) in teacherlist" :key="index">
        <div class="teacherimg">
          <img :src="teacherPicture(item.picture, item.gender)" alt="" />
        </div>
        <div class="teachername">{{ item.teacher_name }}</div>
      </div>
    </div>
    <div class="class_process">
      <el-progress
        :show-text="false"
        :stroke-width="8"
        :percentage="(100 * classProcess) / subclass"
        status="success"
      ></el-progress>
      <div class="class_process_sum" v-if="classLabel != 1">
        已进行{{ classProcess }}/{{ subclass }}课次
      </div>
      <div v-else class="class_process_sum">线下面授</div>
    </div>
  </div>
</template>
<script>
import { COURSE_STUDY_STATE } from "@/utils/constants.js";

export default {
  props: {
    teacherlist: Array,
    subclass: Number,
    subject: String,
    classState: Number,
    classProcess: Number,
    classLabel: Number,
  },
  name: "MyCourseCard",
  data() {
    return {
      stateStyle: 0, // 0是未开始  1是进行中  2是结束
      courseStudyState: COURSE_STUDY_STATE,
    };
  },
  methods: {
    teacherPicture(picture, gender) {
      if (picture) {
        return picture;
      }
      return gender === 1
        ? "//cdn.eduzhixin.cn/cdn/img/live/teacher-nan.png"
        : "//cdn.eduzhixin.cn/cdn/img/live/teacher-nv.png";
    },
  },
};
</script>
<style lang="scss">
.class_process_card {
  background: white;
  height: 249px;
  border-radius: 6px;
  box-shadow: 0px 3px 5px 0px rgba(213, 219, 227, 0.37);
  position: relative;
  box-sizing: border-box;
  padding: 41px 24px 24px;
  margin-bottom: 24px;
  cursor: pointer;
  .el-progress.is-success .el-progress-bar__inner {
    background-color: $chiral-btn-color;
  }
  .class_process {
    box-sizing: border-box;
    padding: 0px 6px 0px 0px;
    .class_process_sum {
      font-size: 12px;
      font-weight: 400;
      color: rgba(51, 51, 51, 0.6);
      text-align: right;
      margin-top: 7px;
    }
  }
  .teacher {
    margin: 13px 3px 23px 0px;
    display: flex;
    overflow: hidden;
    div {
      display: flex;
      flex-direction: column;
      margin-right: 3px;
    }
    .teacherimg {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      margin-right: 20px;
    }
    @media (max-width: 767px) {
      .teacherimg {
        margin-right: 0.2667rem;
      }
    }
    .teachername {
      margin-top: 9px;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      color: rgba(51, 51, 51, 0.8);
      width: 48px;
      height: 16px;
      overflow: hidden;
    }
    img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }
  }
  .card_title {
    text-align: left;
    height: 50px;
    // margin-left: 16px;
    line-height: 25px;
    font-size: 18px;
    font-weight: 600;
    color: $chiral-color;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-indent: 48px;
    position: relative;
    .state_success {
      text-indent: 0px;
      width: 44px;
      height: 20px;
      font-size: 12px;
      font-weight: 500;
      border-radius: 4px;
      text-align: center;
      line-height: 20px;
      position: absolute;
      top: 2px;
      color: rgba($color: $chiral-color, $alpha: 0.4);
      background: rgba($color: $chiral-color, $alpha: 0.04);
    }
    .state-wj {
      color: $chiral-btn-color;
      background: $chiral-course-tag-color;
    }
    .state-jx {
      background: rgba($chiral-free-color, 0.1);
      color: $chiral-free-color;
    }
  }
  .class_type {
    width: 65px;
    height: 65px;
    overflow: hidden;
    position: absolute;
    top: 0px;
    right: 0px;
    .class_ontypeText {
      font: bold 15px Sans-Serif;
      transform: rotate(45deg);
      color: #333;
      text-align: center;
      position: relative;
      padding: 2px 20px;
      left: -11px;
      top: 11px;
      width: 100px;
      background-color: $chiral-course-tag-color;
      color: #6a6340;
      font-size: 12px;
      color: $chiral-btn-color;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
    }
    .class_offtypeText {
      font: bold 15px Sans-Serif;
      transform: rotate(45deg);
      text-align: center;
      position: relative;
      padding: 2px 20px;
      left: -11px;
      top: 11px;
      width: 100px;
      background-color: rgba(255, 160, 0, 0.1);
      color: rgba(255, 160, 0, 1);
      // -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
      // box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
      font-size: 12px;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
    }
  }
}
</style>
