<!-- 用户协议 -->
<template>
  <div class="user-agreement">
    <span>登录即代表同意开罗尔</span>
    <a class="dis-link" target="_blank" :href="`${staticWebSite}/agreement`">
      《用户协议》
    </a>
    <span>和</span>
    <a class="dis-link" target="_blank" :href="`${staticWebSite}/policy`">
      《隐私政策》
    </a>
    <div v-if="autoRegTip" class="use r-agreement-text">
      未注册账号登录后将自动注册
    </div>
  </div>
</template>
<script>
export default {
  name: "UserAgreement",
  props: {
    autoRegTip: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      staticWebSite: process.env.VUE_APP_CHIRAL_URL,
    };
  },
};
</script>
<style lang="scss">
// 用户协议
.user-agreement {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: #afafaf;
  line-height: 17px;
  white-space: nowrap;
  .dis-link {
    display: inline;
    color: $chiral-btn-color;
  }
  &-text {
    margin: 4px 0;
  }
}
</style>
