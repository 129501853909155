import qs from "qs";
import api from "../plugins/api";
import { mapGetters } from "vuex";
import { isMobile, clipboard } from "@/utils/utils";

export default {
  methods: {
    booking(type, id) {
      if (!this.user.user_id) {
        this.login();
        return false;
      }
      if (!this.classInfo.online) {
        this.$chiralConfirmMessage("此课程已下线");
        return false;
      }
      switch (type) {
        case "free":
          // 免费课报名
          this.freeClassSignUp();
          break;
        case "narmalPay":
          // 普通付费课||团购课原价报名
          this.goConfirm();
          break;
        case "byGroup":
          // 团购
          if (this.gropPriceList.length > 1) {
            this.$refs["groupList"].showGroupList();
          } else {
            this.goConfirm({
              group_num: this.classInfo.group_num,
              activity_type: 1,
            });
          }
          break;
        case "shareGroup":
          this.shareGroup();
          break;
        case "goGroup":
          // 去参团
          this.goConfirm({
            activity_id: id,
            activity_type: 1,
          });
          break;
        default:
          break;
      }
    },
    // 团购阶梯下单
    groupToOrder(num) {
      this.goConfirm({ group_num: num, activity_type: 1 });
    },
    // 下单
    goConfirm(groupInfo = null) {
      document.querySelector(".course-detail").style.display = "none";
      this.$confirmOrder(
        [this.classInfo],
        "confirm-order-container",
        groupInfo,
        3
      );
    },
    // 分享拼团
    shareGroup() {
      let shareHref = `${process.env.VUE_APP_CHIRAL_URL}/group-activity/${this.userGroup.group_id}?isPaided=true`;
      // utm参数处理
      const dataOpt = this.$route.query;
      if (JSON.stringify(dataOpt) !== "{}") {
        const utmParams = {
          utm_source: dataOpt.utm_source || "",
          utm_campaign: dataOpt.utm_campaign || "",
          utm_medium: dataOpt.utm_medium || "",
          utm_content: dataOpt.utm_content || "",
        };
        shareHref += `&utm_campaign=${utmParams.utm_campaign}&utm_content=${utmParams.utm_content}&utm_medium=${utmParams.utm_medium}&utm_source=${utmParams.utm_source}`;
      }
      if (isMobile()) {
        const content = `【仅剩 ${this.userGroup.rest_num} 个名额】我 ${
          this.userGroup.group_price / 100
        } 元拼了原价 ${this.classInfo.price / 100} 元的课程《${
          this.classInfo.subject
        }》，点击链接参团一起享受优惠 ${shareHref}`;
        const _this = this;
        clipboard(content, () => {
          _this.$chiralAutoMessage(
            "拼团链接复制成功，打开微信或 QQ 可粘贴并发送给好友。",
            2000
          );
        });
      } else {
        this.$refs["shareDialog"].show(shareHref);
      }
    },
    login() {
      this.$showLoginDialog();
    },
    freeClassSignUp() {
      let ids = [];
      this.classInfo.subclass.forEach(function (item) {
        ids.push(item.subclass_id);
      });
      let classParams = {
        class_id: this.classInfo.class_id,
        grade: this.classInfo.grade,
        subject_type: this.classInfo.subject_type,
      };
      const dataOpt = this.$route.query || {};
      const urlParam = Object.assign(dataOpt, classParams);
      const paramJson = {
        url: window.location.href,
        url_param: qs.stringify(urlParam),
        utm_source: dataOpt.utm_source || "",
        utm_campaign: dataOpt.utm_campaign || "",
        utm_medium: dataOpt.utm_medium || "",
        utm_term: dataOpt.utm_term || "",
        utm_content: dataOpt.utm_content || "",
      };
      const params = {
        nocode: 1,
        is_not_landing: 1,
        mobile: this.user.mobile,
        product_id: JSON.stringify(ids),
        param_json: JSON.stringify(paramJson),
        product_name: "开罗尔",
        register_method: "",
        grade: "",
        register_position: "课程详情页",
      };
      api.liveBooking(params).then((res) => {
        if (res.data.code === 1) {
          this.$parent.initClassInfo();
          //讲座预约引流
          if (this.classInfo.qr_code) {
            let params = {
              action: this.buyBtnText,
            };
            this.$parent.$refs["bookingGuide"].show(this.classInfo, params);
          } else {
            this.$chiralAutoMessage(res.data.msg, 2000);
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
};
