<!-- 拼团列表 -->
<template>
  <div class="group-items">
    <div
      v-for="item in groupItems.slice(0, length)"
      :key="item.id"
      class="group-info"
    >
      <div class="user-name">
        <img :src="item.avatar" alt="用户头像" />
        <span>{{ item.name }}</span>
      </div>
      <div class="group-center">
        <p>
          拼团价<span>¥{{ item.price / 100 }}</span>
        </p>
        <p>
          还差<span>{{ item.group_num - item.memberNum }}</span
          >人拼团成功
        </p>
      </div>
      <count-down
        :endTime="item.group_end_time"
        countdownText="剩余"
        :type="2"
      />
      <el-button size="mini" @click="goGroup(item.group_id)">去参团</el-button>
    </div>
    <p v-if="!showMore" @click="showMoreGroup">
      查看更多拼团<i class="fa fa-angle-down"></i>
    </p>
    <p v-else>
      <span v-if="groupItems.length === 1">没有更多了！</span>
      <span @click="closeMoreGroup" v-else
        >收起订单<i class="fa fa-angle-up"></i
      ></span>
    </p>
  </div>
</template>

<script>
import CountDown from "@/views/course/components/CountDown.vue";
export default {
  name: "groupItems",
  components: {
    "count-down": CountDown,
  },
  props: {
    groupItems: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      length: 1, //默认展示一条
      showMore: false,
    };
  },
  methods: {
    goGroup(id) {
      this.$emit("goGroup", id);
    },
    showMoreGroup() {
      this.length = this.groupItems.length;
      this.showMore = true;
    },
    closeMoreGroup() {
      this.length = 1;
      this.showMore = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.group-items {
  max-width: 580px;
  padding: 16px 16px 0;
  background: #fbfbfd;
  border-radius: 2px;
  margin-top: 20px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .group-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
    .user-name {
      display: flex;
      align-items: center;
      img {
        border-radius: 50%;
        width: 32px;
        margin-right: 5px;
      }
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 80px;
      }
    }
    .group-center {
      display: flex;
      align-items: center;
    }
    .group-center p {
      margin-right: 12px;
    }
    .group-center span {
      color: #ef4d3c;
    }
    .el-button {
      background: #ffeaea;
      font-size: 14px;
      color: #e53650;
      border: none;
    }
  }
}
.group-items > p {
  cursor: pointer;
  text-align: right;
  line-height: 30px;
  color: rgba(47, 48, 49, 0.48);
  i.fa {
    margin-left: 5px;
  }
}
@media (max-width: 767px) {
  .group-items {
    padding: 0.43rem 0.13rem;
    font-size: 12px;
    .group-info .user-name span {
      width: 0.96rem;
    }
    .group-center {
      flex-direction: column;
      align-items: flex-start !important;
      margin-right: 0;
    }
    .el-button {
      padding: 0.13rem;
    }
  }
}
</style>
