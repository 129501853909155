<!-- 团购倒计时 -->
<template>
  <div class="countdown">
    <div class="count-text" v-if="countdownText && textShow">
      {{ countdownText }}
    </div>
    <div class="count-time" v-html="content"></div>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      content: "",
      timer: null,
      textShow: true,
    };
  },
  props: {
    endTime: {
      type: Number,
      default: 0,
    }, //结束时间
    countdownText: {
      type: String,
      default: "",
    }, //倒计时前文案
    endText: {
      type: String,
      default: "已结束",
    },
    type: {
      type: Number,
      default: 1,
    }, //1: 00天00时00分00秒  2：00：00：00
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    callback: {
      type: Function,
      default() {
        return function () {};
      },
    },
  },
  mounted() {
    this.startInterval(this.endTime, this.type);
  },
  methods: {
    countDown(timestamp, type = 1) {
      if (!timestamp || timestamp < 1) return;
      const nowTime = new Date();
      const endTime = new Date(timestamp * 1000);
      const t = endTime.getTime() - nowTime.getTime();
      if (t > 0) {
        const day = Math.floor(t / 86400000);
        let hour = Math.floor((t / 3600000) % 24);
        let min = Math.floor((t / 60000) % 60);
        let sec = Math.floor((t / 1000) % 60);
        hour = hour < 10 ? "0" + hour : hour;
        min = min < 10 ? "0" + min : min;
        sec = sec < 10 ? "0" + sec : sec;
        // const dayHour = Math.floor(t / 86400000) * 24 + Math.floor((t / 3600000) % 24);
        let format = "";
        if (day > 0) {
          if (type === 1) {
            format = `<span>${day}</span>天<span>${hour}</span>小时<span>${min}</span>分<span>${sec}</span>秒`;
          } else {
            format = `${day}天${hour}:${min}:${sec}`;
          }
        }
        if (day <= 0 && hour > 0) {
          if (type === 1) {
            format = `<span>${hour}</span>小时<span>${min}</span>分<span>${sec}</span>秒`;
          } else {
            format = `${hour}:${min}:${sec}`;
          }
        }
        if (day <= 0 && hour <= 0) {
          if (type === 1) {
            format = `<span>${min}</span>分<span>${sec}</span>秒`;
          } else {
            format = `${min}:${sec}`;
          }
        }
        this.content = format;
      } else {
        this.stopInterval();
      }
    },
    stopInterval() {
      this.textShow = false;
      clearInterval(this.timer);
      this.content = this.endText;
      this._callback();
    },
    startInterval(timestamp, type) {
      this.countDown(timestamp, type);
      this.timer = setInterval(() => {
        this.countDown(timestamp, type);
      }, 1000);
    },
    _callback() {
      if (this.callback && this.callback instanceof Function) {
        this.callback(this.item);
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
<style lang="scss">
.countdown {
  display: flex;
  align-items: center;
  .count-time {
    span {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ef4d3c;
      line-height: 18px;
      margin: 0 2px 0 8px;
    }
  }
}
</style>
