<!-- 密码登录 -->
<template>
  <div class="loginPass-components">
    <el-form class="form-container">
      <el-form-item
        class="phone-wrap"
        :class="{ 'el-form-item-error': !!phoneTip }"
      >
        <el-input
          v-model="phone"
          class="form-control phone"
          placeholder="请填写手机号"
          maxlength="11"
          @input="bindInputPhone"
        />
        <div class="el-form-item-error-tip" v-show="phoneTip">
          {{ phoneTip }}
        </div>
      </el-form-item>
      <el-form-item
        :class="{ 'el-form-item-error': !!passTip }"
        class="pass-form-item"
      >
        <el-input
          v-model="password"
          :type="passFlag ? 'password' : 'text'"
          class="form-control"
          placeholder="请填写密码"
          maxlength="40"
          @input="bindInputPass"
        />
        <img
          v-show="!passFlag"
          class="icon-view-tag"
          src="@/assets/img/login/icon-view-open.png"
          alt=""
          @click="passFlag = !passFlag"
        />
        <img
          v-show="passFlag"
          class="icon-view-tag"
          src="@/assets/img/login/icon-view-close.png"
          alt=""
          @click="passFlag = !passFlag"
        />
        <div class="el-form-item-error-tip pass-error-tips" v-show="passTip">
          {{ passTip }}
        </div>
        <div class="function-wrap">
          <div>
            新用户？<span class="function-key" @click="toRegister()"
              >戳我去注册</span
            >
          </div>
          <div class="function-key" @click="toRetrieve()">忘记密码？</div>
        </div>
      </el-form-item>
      <el-button
        formType="submit"
        class="submit btn-primary"
        type="primary"
        :loading="sending"
        @click.stop="formSubmit"
      >
        登录
      </el-button>
    </el-form>
  </div>
</template>

<script>
import { vailPhone } from "@/utils/utils";
import api from "@/plugins/api";
import loginHandle from "@/mixins/login-handle";

export default {
  name: "LoginPassword",
  mixins: [loginHandle],
  props: {
    isDialog: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      sending: false,
      passFlag: true,
    };
  },
  methods: {
    toRegister() {
      this.$closeLoginDialog();
      this.$router.push({
        path: "/register/reg",
        query: {
          preRoute:
            this.$route.name === "Login"
              ? this.$route.query?.preRoute
              : this.$route.fullPath,
        },
      });
    },
    toRetrieve() {
      this.$closeLoginDialog();
      this.$router.push({
        path: "/register/retrieve",
        query: {
          preRoute:
            this.$route.name === "Login"
              ? this.$route.query?.preRoute
              : this.$route.fullPath,
        },
      });
    },
    formSubmit() {
      if (!this.phone) {
        this.phoneTip = "请输入手机号";
        return false;
      } else if (!vailPhone(this.phone, false)) {
        this.phoneTip = "手机号码格式错误";
        return false;
      } else if (!this.password) {
        this.passTip = "请填写密码";
        return false;
      }
      this.loginAction();
      return true;
    },
    // 密码登录
    loginAction() {
      this.sending = true;
      const params = {
        mobile: this.phone,
        password: this.password,
      };
      api
        .loginByPassword(params)
        .then((data) => {
          this.sending = false;
          if (data.data.code === 1) {
            this.$emit("login-success", data.data, this.phone);
            if (!this.isDialog) {
              this.toPreRoute();
            }
          } else {
            this.$message.error(data.data.message);
          }
        })
        .catch(() => {
          this.sending = false;
        });
    },
  },
};
</script>
<style lang="scss" src="./common.scss" scoped></style>
<style lang="scss" scoped>
$primary-color: #242424;
$white: #fff;
$title-color: #242424;
$error-color: #ef4d3c;
.loginPass-components {
  .input-placeholder {
    font-size: 15px;
    font-weight: 400;
    color: rgba($primary-color, 0.24);
    line-height: 21px;
  }

  .submit {
    margin: 8px 0 16px;
    border-radius: 8px;
    width: 100%;
    background-color: $chiral-btn-color;
    font-size: 15px;
    line-height: 15px;
    font-weight: 500;
    color: $white;
    padding: 16px 0;
    height: 48px;
  }
  .clearfix {
    &::after {
      display: table;
      content: " ";
      clear: both;
    }
  }
  .function-wrap {
    display: flex;
    justify-content: space-between;
    color: #afafaf;
    font-size: 12px;
    line-height: 17px;
    padding-top: 4px;
  }
  .function-key {
    font-weight: 400;
    color: $chiral-btn-color;
    cursor: pointer;
  }
  .el-form-item-error-tip.pass-error-tips {
    position: static;
  }
  .el-button--primary:focus,
  .el-button--primary:hover {
    background: $chiral-btn-color;
    border-color: $chiral-btn-color;
  }
  .el-button--primary.is-disabled,
  .el-button--primary.is-disabled:active,
  .el-button--primary.is-disabled:focus,
  .el-button--primary.is-disabled:hover {
    background: #cbcbcb;
    border-color: #cbcbcb;
  }
  .el-form-item-error-tip {
    position: absolute;
    padding-left: 10px;
    padding-top: 4px;
    position: absolute;
    top: 100%;
    left: 0;
    line-height: 1;
    color: #ed4014;
  }
  .pass-form-item {
    position: relative;
  }
  .icon-view-tag {
    position: absolute;
    right: 16px;
    top: 8px;
    width: 24px;
    height: 24px;
  }
}
// 适配移动端
@media screen and (max-width: 767px) {
  .loginPass-components .submit {
    margin: 0.2rem 0 0.4rem;
  }
}
</style>
<style lang="scss">
.loginPass-components {
  .el-form-item-error input {
    border: 1px solid $error-color;
  }
}
</style>
