<template>
  <div class="content wrong-wrap">
    <subjectNav
      class="subject-nav"
      :defaultSubject="defaultSubject"
      @change="changeSubject"
    />
    <div class="clearfix">
      <wrongBook
        class="wrong-book"
        :total_num="total_num"
        :num="num"
        :wrongList="wrongList"
        @change="changeKnowledge"
        :defaultActiveId="activeKowledgeId"
      />
      <!-- 错题表格 -->
      <div class="table-wrap" v-if="questionsList.length > 0">
        <!-- 操作区 -->
        <div class="table-top clearfix">
          <!-- 排序 -->
          <div class="table-sort" v-show="!editVisible">
            <el-button size="medium" class="sort" @click="changeSort"
              >添加时间<i class="el-icon-sort el-icon--right"
            /></el-button>
            <el-button size="medium" @click="editVisible = true"
              >编辑</el-button
            >
          </div>
          <!-- 编辑 -->
          <div class="table-edit" v-show="editVisible">
            <el-checkbox
              class="checkbox"
              @change="selAll"
              v-model="checkAll"
              :indeterminate="isIndeterminate"
              >全选</el-checkbox
            >
            <el-button type="text" class="delete" @click="fnDelSome"
              ><i class="el-icon-delete" />批量删除</el-button
            >
            <el-button size="medium" @click="fnHideEdit">退出编辑</el-button>
          </div>
        </div>
        <!-- 表格 -->
        <!-- 添加key--解决切换编辑时，表格闪烁问题 -->
        <el-table
          :data="questionsList"
          v-loading="loading"
          :key="editVisible"
          ref="multipleTable"
          @selection-change="handleSelectionChange"
          @row-click="openDetails"
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column v-if="editVisible" type="selection" width="55">
          </el-table-column>
          <el-table-column prop="skill_tree_name" label="知识点名称">
            <template slot-scope="scope">
              <span class="skill-name">{{ scope.row.skill_tree_name }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="question_id" label="题目ID" />
          <el-table-column label="错题来源">
            <template slot-scope="scope">
              <span v-if="Number(scope.row.type) === 0">刷题</span>
              <span v-if="Number(scope.row.type) === 1">考试</span>
              <span v-if="Number(scope.row.type) === 2">知识点题</span>
            </template>
          </el-table-column>
          <el-table-column prop="create_at" label="添加时间">
            <template slot-scope="scope">
              {{
                scope.row.create_at
                  ? moment(scope.row.create_at * 1000).format(
                      "YYYY-MM-DD HH:mm"
                    )
                  : "-"
              }}
            </template>
          </el-table-column>
          <el-table-column v-if="editVisible" width="100">
            <template slot-scope="scope">
              <el-button
                class="del-con"
                type="text"
                @click.stop="deleteOne(scope.row.question_id)"
                ><i class="el-icon-delete" />删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          background
          layout="prev, pager, next"
          :total="pageData.total"
          :page-size="pageData.pageSize"
          :current-page="pageData.curPage"
          hide-on-single-page
          @current-change="currentChange"
        >
        </el-pagination>
      </div>
      <el-empty
        v-else
        description="暂无内容，快去刷题吧~"
        :image="require('../../assets/img/brash-questions/wrong-empty.png')"
      ></el-empty>
    </div>
  </div>
</template>

<script>
import {
  getSkillTree,
  getQuestionNum,
  getWrongQuota,
  getWrongQuestionList,
  delWrongQuestion,
} from "@/api/wrongQuestions.js";
import subjectNav from "@/components/subject-nav/Main.vue";
import wrongBook from "@/components/brash-questions/WrongBook.vue";
import moment from "moment";
export default {
  name: "WrongQuestions",
  components: { subjectNav, wrongBook },
  data() {
    return {
      isIndeterminate: false,
      checkAll: false,
      loading: false,
      moment,
      defaultSubject: "PHY",
      wrongList: [],
      activeKowledgeId: null,
      questionsList: [],
      editVisible: false,
      pageData: {
        total: 0,
        curPage: 1,
        pageSize: 10,
      },
      total_num: 500,
      num: 0,
      order: 0,
    };
  },
  mounted() {
    // 初始化跳转数据
    this.defaultSubject = this.$route.query.sub ? this.$route.query.sub : "PHY";
    this.activeKowledgeId = this.$route.query.kid
      ? Number(this.$route.query.kid)
      : null;
    // 获取错题本容量信息并处理登录状态
    this.fnGetWrongQuota();
    // 获取学科下有错题的知识点列表
    this.fnGetWrongList();
  },
  methods: {
    fnHideEdit() {
      this.editVisible = false;
      this.checkAll = false;
      this.isIndeterminate = false;
    },
    openDetails(row) {
      this.$router.push(
        `/quiz-detail/${row.pskill_tree_name}/${row.skill_tree_name}/${row.skill_tree_id}/${row.online}/${this.defaultSubject}?qid=${row.question_id}`
      );
    },
    handleSelectionChange(val) {
      if (val.length === 0) {
        this.checkAll = false;
        this.isIndeterminate = false;
      }
      if (val.length > 0 && val.length < this.questionsList.length) {
        this.checkAll = false;
        this.isIndeterminate = true;
      }
      if (val.length === this.questionsList.length) {
        this.checkAll = true;
        this.isIndeterminate = false;
      }
    },
    // 批量删除
    fnDelSome() {
      if (this.$refs.multipleTable.selection.length > 0) {
        let delIds = this.$refs.multipleTable.selection.map((item) => {
          return item.question_id;
        });
        this.$chiralConfirmLoading(
          `您确定将选中的${delIds.length}道题从错题本中删除吗？`,
          "删除",
          (val) => {
            if (val) {
              // 删除
              this.fndelWrongQuestion(delIds);
            }
          }
        );
      } else {
        this.$message.warning("请勾选您要删除的数据~");
      }
    },
    // 全选
    selAll() {
      if (
        this.$refs.multipleTable.selection.length < this.questionsList.length
      ) {
        this.checkAll = true;
        this.isIndeterminate = false;
      } else {
        this.checkAll = false;
        this.isIndeterminate = false;
      }
      // 切换所有行的选中状态
      this.$refs.multipleTable.toggleAllSelection();
    },
    deleteOne(id) {
      this.$chiralConfirmLoading(
        `您确定要将题目ID${id}从错题本中删除吗？`,
        "删除",
        (val) => {
          if (val) {
            // 删除
            this.fndelWrongQuestion([id]);
          }
        }
      );
    },
    fndelWrongQuestion(id) {
      let params = {
        question_id: id,
      };
      delWrongQuestion(params).then((res) => {
        if (res.code == 1) {
          this.$message.success("删除成功");
          // 刷新
          this.fnGetWrongQuota();
          this.fnGetWrongList();
        }
      });
    },
    currentChange(num) {
      this.pageData.curPage = num;
      this.fnGetWrongQuestionList();
    },
    changeSort() {
      this.order ? (this.order = 0) : (this.order = 1);
      this.fnGetWrongQuestionList();
    },
    fnGetWrongList() {
      let params = {
        site_belong: "chiral",
        subject_type: this.defaultSubject,
      };
      // 获取错题数
      getQuestionNum(params).then((numList) => {
        if (numList.code == 1) {
          if (JSON.stringify(numList.item) === "{}") {
            this.wrongList = [];
            this.questionsList = [];
            return;
          }
          let params2 = {
            site_belong: "开罗尔",
            subject_type: this.defaultSubject,
          };
          //获取知识点
          getSkillTree(params2).then((res) => {
            if (res.code == 1) {
              this.wrongList = res.skill_tree.nodes.filter((item) => {
                for (let key in numList.item) {
                  if (item.id === Number(key)) {
                    item.wrongNum = numList.item[key];
                    return item.id === Number(key);
                  }
                }
              });
              if (!this.activeKowledgeId && this.wrongList.length) {
                this.activeKowledgeId = this.wrongList[0].id;
              }
              this.fnGetWrongQuestionList();
            }
          });
        }
      });
    },
    changeKnowledge(kowledgeId) {
      this.activeKowledgeId = kowledgeId;
      this.pageData = {
        total: 0,
        curPage: 1,
        pageSize: 10,
      };
      this.isIndeterminate = false;
      this.checkAll = false;
      this.editVisible = false;
      this.order = 0;
      // 获取错题列表
      this.fnGetWrongQuestionList();
    },
    changeSubject(subjectValue) {
      this.defaultSubject = subjectValue;
      this.total_num = 500;
      this.num = 0;
      this.wrongList = [];
      this.questionsList = [];
      this.activeKowledgeId = null;
      // 获取错题本容量信息
      this.fnGetWrongQuota();
      // 获取学科下有错题的知识点列表
      this.fnGetWrongList();
    },
    fnGetWrongQuota() {
      getWrongQuota({
        site_belong: "chiral",
        subject_type: this.defaultSubject,
      }).then((res) => {
        if (res.code == 1) {
          this.total_num = res.item.total_num;
          this.num = res.item.num;
        } 
      });
    },
    fnGetWrongQuestionList() {
      this.loading = true;
      let params = {
        parent_id: this.activeKowledgeId,
        is_detail: 0,
        subject_type: this.defaultSubject,
        site_belong: "chiral",
        page_size: this.pageData.pageSize,
        current_page: this.pageData.curPage,
        order: this.order,
      };
      getWrongQuestionList(params)
        .then((res) => {
          if (res.code == 1) {
            this.questionsList = res.item;
            this.pageData.total = Number(res.totals);
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrong-wrap {
  padding: 38px 0px 44px;
  .subject-nav {
    margin-bottom: 24px;
  }
  .wrong-book {
    float: left;
    margin-right: 8px;
  }
  .table-wrap {
    float: left;
    width: calc(100% - 324px);
    min-height: 684px;
    padding: 20px 18px 27px;
    border-radius: 8px;
    background-color: #fff;
    .el-icon-delete {
      margin-right: 3px;
    }
    .skill-name {
      display: inline-block;
      max-width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    :deep .el-checkbox__inner {
      border-radius: 50%;
      width: 15px;
    }
    .table-top {
      height: 38px;
      margin-bottom: 22px;
    }
    .table-sort {
      padding-left: 7px;
    }
    .sort {
      margin-right: 15px;
    }
    .table-edit {
      float: right;
      .checkbox {
        color: #2c2d2e;
        margin-right: 30px;
      }
      .delete {
        margin-right: 25px;
      }
      .delete:hover {
        color: $chiral-free-color;
      }
    }
    .el-pagination {
      margin-top: 22px;
      text-align: center;
    }
    .del-con:hover {
      color: $chiral-free-color;
    }
  }
  .el-empty {
    float: left;
    width: calc(100% - 324px);
    height: 684px;
    background-color: #fff;
  }
  .clearfix {
    &::after {
      display: block;
      content: "";
      clear: both;
    }
  }
  ::v-deep .el-table th.el-table__cell > .cell .el-checkbox__input {
    display: none;
  }
  ::v-deep .el-table__body tr:hover > td {
    background: $chiral-hover-color !important;
  }
  ::v-deep .el-table thead th {
    height: 32px;
    line-height: 32px;
    font-weight: 400;
    font-size: 13px;
    background-color: #fafbfc;
    color: #757880;
  }
  ::v-deep .el-table td {
    font-weight: 400;
    font-size: 13px;
    color: #2c2d2e;
  }
  ::v-deep .el-table .el-table__cell {
    padding: 0px;
  }
  ::v-deep .el-table tbody tr .cell {
    height: 50px;
    line-height: 50px;
  }
  ::v-deep .el-table tbody tr:hover {
    td {
      background: $chiral-hover-color;
      cursor: pointer;
    }
    td:first-child {
      border-radius: 8px 0 0 8px;
    }
    td:last-child {
      border-radius: 0 8px 8px 0;
    }
  }

  .el-button {
    color: #2c2d2e;
  }
}
</style>
<style>
.confirm-btn .large-confirm-btn {
  width: 70px;
  line-height: 32px;
  font-size: 14px;
  padding: 0;
}
.confirm-btn .large-confirm-btn.el-button--primary {
  margin-left: 40px;
}
.confirm-btn .el-message-box__title {
  font-size: 18px;
  font-weight: 500;
  color: #2c2d2e;
  line-height: 25px;
}
.confirm-btn .el-message-box__header {
  padding: 30px 30px 10px 30px;
}
.confirm-btn .el-message-box__headerbtn .el-message-box__close {
  display: none;
}
.confirm-btn .el-message-box__headerbtn {
  background-image: url("../../assets/img/brash-questions/confirm-close.png");
  background-size: 100%;
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
}
</style>
