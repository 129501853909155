<template>
  <Login-page-wrap
    class="login-page"
    :formTitle="formTitle"
    @change-active="handleChange"
  >
    <login-code v-show="activeId === 1" class="msg-panel" />
    <login-password v-show="activeId === 2" />
    <UserAgreement :autoRegTip="activeId === 1" />
  </Login-page-wrap>
</template>

<script>
import LoginPageWrap from "@/components/login/LoginPageWrap.vue";
import LoginCode from "@/components/login/LoginCode.vue";
import UserAgreement from "@/components/login/UserAgreement.vue";
import LoginPassword from "@/components/login/LoginPassword.vue";
export default {
  name: "Login",
  components: {
    LoginPageWrap,
    LoginCode,
    UserAgreement,
    LoginPassword,
  },
  data() {
    return {
      activeId: 1,
      formTitle: [
        { id: 1, title: "验证码登录" },
        { id: 2, title: "密码登录" },
      ],
    };
  },
  methods: {
    handleChange(id) {
      this.activeId = id;
    },
  },
};
</script>
