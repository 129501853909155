import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/plugins/elementUI.js";
import "@/plugins/directive.js";
import "amfe-flexible/index.js";
import "@/components/login/index";
import "@/components/message/index.js";
//模版页面
import defaultPage from "../layout/default";
import agreementPage from "../layout/agreement";
import "@/assets/font/zhixin/iconfont.css";
import "@/assets/font/zhixin-web/iconfont.css";
// 全局组件
import "@/components/overall/index.js";
//过滤器
import "@/utils/filters";
import "../public/stylesheets/element-variables.scss";
Vue.config.productionTip = false;
Vue.component("default-page", defaultPage);
Vue.component("agreement-page", agreementPage);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
