<template>
  <!-- 错题本列表 -->
  <div class="book-wrap">
    <div class="book-card">
      <p class="book-title">错题本&nbsp;></p>
      <p class="book-tips">要勤于练习，我们的目标是把错题移出去哦～</p>
      <p class="pro-text">{{ num + "/" + total_num }}</p>
      <el-progress
        :percentage="(num / total_num) * 100"
        :stroke-width="6"
        color="#4A36FA"
        :show-text="false"
        define-back-color="#EBEBEB"
      ></el-progress>
    </div>
    <ul
      class="book-list"
      v-if="wrongList && wrongList.length > 0"
      :class="wrongList.length > 10 ? 'book-list-scroll' : ''"
    >
      <li
        v-for="item in wrongList"
        :id="item.id"
        :key="item.id"
        class="list-item"
        @click="changeActiveId(item.id)"
        :class="activeId == item.id ? 'active-item' : ''"
      >
        <div
          class="item-wrap"
          :class="activeId == item.id ? 'active-wrap' : ''"
        >
          <span class="item-left">{{ item.text }}</span>
          <span class="item-right">{{ item.wrongNum + "道题" }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "WrongBook",
  props: {
    // 错题本容量
    total_num: {
      type: Number,
      default: 500,
    },
    // 错题数
    num: {
      type: Number,
      default: 0,
    },
    // 选中的item-id
    defaultActiveId: {
      type: Number,
      default: null,
    },
    wrongList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  watch: {
    wrongList: {
      handler() {
        // 定位到当前节点
        if (this.wrongList.length > 10 && this.defaultActiveId) {
          this.$nextTick(() => {
            let element = document.getElementById(this.defaultActiveId);
            element.scrollIntoView();
          });
        }
      },
      deep: true,
    },
  },
  computed: {
    activeId: {
      get() {
        return this.defaultActiveId;
      },
      set(val) {
        this.$emit("change", val);
      },
    },
  },
  methods: {
    changeActiveId(id) {
      this.activeId = id;
    },
  },
};
</script>

<style lang="scss" scoped>
.book-wrap {
  width: 316px;
  background-color: #fff;
  border-radius: 8px;
  .book-card {
    height: 120px;
    padding: 11px 152px 0 16px;
    background-image: url("../../assets/img/brash-questions/wrong-book.png");
    background-repeat: no-repeat;
    background-size: 100%;
    font-family: PingFangSC-Medium, PingFang SC;
    cursor: pointer;
    .book-title {
      font-size: 15px;
      font-weight: 500;
      color: $chiral-btn-color;
      line-height: 21px;
      margin-bottom: 7px;
    }
    .book-tips {
      font-size: 11px;
      font-weight: 400;
      color: rgba($color: $chiral-btn-color, $alpha: 0.6);
      line-height: 16px;
      margin-bottom: 8px;
    }
    .pro-text {
      font-size: 12px;
      font-weight: 400;
      color: $chiral-btn-color;
      line-height: 17px;
      margin-bottom: 4px;
    }
  }
  .book-list {
    .list-item {
      height: 45px;
      padding: 10px 0;
      cursor: pointer;
      box-sizing: border-box;
      .item-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        padding: 0 23px 0 17px;
      }
      .active-wrap {
        border-left: 3px solid $chiral-btn-color;
      }
      .item-left {
        font-size: 13px;
        font-weight: 400;
        color: #757880;
        width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .item-right {
        font-size: 12px;
        font-weight: 400;
        color: $chiral-btn-color;
      }
    }
    .list-item:hover {
      background: $chiral-hover-color;
      border-radius: 2px;
    }
    .active-item {
      background: $chiral-hover-color;
      border-radius: 2px;
      .item-left {
        color: $chiral-color;
        font-weight: 500;
      }
    }
  }
  .book-list-scroll {
    height: 450px;
    overflow-y: scroll;
  }
}
</style>
