<template>
  <div id="comp_calender">
    <div class="calender_title">
      <div
        v-if="hasStudyPlan"
        class="study-plan"
        :class="titleTab ? 'title-selected' : ''"
        @click="changeTab(1)"
      >
        学习计划
      </div>
      <div
        class="timetable"
        :class="titleTab ? '' : 'title-selected'"
        @click="changeTab(0)"
      >
        课表
      </div>
      <div class="calender_now" @click="getTodaySubList()">今天</div>
    </div>
    <Calendar
      v-on:choseDay="clickDay"
      v-on:changeMonth="changeMonth"
      :markDateMore="subclassList"
      :key="key"
    ></Calendar>
    <div class="calender_week">
      <div>{{ cardDate | formatSelfTime("MM月DD日") }}</div>
      <div>{{ cardDate | formatWeek }}</div>
    </div>
    <!-- 课表详情 -->
    <div class="calender_info" v-show="!titleTab">
      <div v-if="classArr.length > 0">
        <div class="classtext" v-for="(item, index) in classArr" :key="index">
          <div>
            <div class="sub-time">{{ item.time }}&nbsp;{{ item.subinfo }}</div>
            <div class="sub-title">所属课程:{{ item.classSubject }}</div>
            <div>第{{ item.subClass }}次课</div>
            <el-button
              type="primary"
              :disabled="[3, 4].includes(item.btnState)"
              @click="toLiveRoom(item)"
              >{{ courseStateBtn[item.btnState] }}</el-button
            >
          </div>
        </div>
      </div>
      <div class="empty-content" v-else>
        <empty-component
          v-if="!classArr.length"
          :jumpLink="{
            href: `${chiralUrl}/lecture-center`,
            text: '讲座列表选择合适的课程',
          }"
          text="您暂无课程，可前往"
        ></empty-component>
      </div>
    </div>
    <!-- 学习计划详情 -->
    <div class="calender_info" v-show="titleTab">
      <div v-if="studyPlanArr.length > 0">
        <div
          class="classtext"
          v-for="(item, index) in studyPlanArr"
          :key="index"
        >
          <div>
            <div class="sub-title">{{ item.classSubject }}</div>
            <div>{{ item.subinfo }}</div>
            <el-button
              type="primary"
              :disabled="item.btnState.disabled"
              @click="planToLiveRoom(item)"
              >{{ item.btnState.btnText }}</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Calendar from "vue-calendar-component";
import api from "@/plugins/api";
import filter from "@/utils/filters";
import moment from "moment";
import { COURSE_STATE_BTN } from "@/utils/constants.js";

export default {
  name: "MyCalendar",
  data() {
    return {
      titleTab: 0, //0:课表  1：学习计划
      studyPlanArr: [],
      courseStateBtn: COURSE_STATE_BTN,
      classInfo: {},
      classArr: [],
      subclassList: [],
      buyOfflineSubclass: [],
      // 卡片显示的日期
      moment,
      key: new Date().getTime(),
      cardDate: "",
      searchParams: {
        begin_at: "",
      },
      chiralUrl: process.env.VUE_APP_CHIRAL_URL,
      hasStudyPlan: false,
    };
  },
  methods: {
    hasUserStudyCalendar() {
      api.hasUserStudyCalendar().then((res) => {
        let item = res.data;
        if (item.code === 1) {
          this.hasStudyPlan = item.data.user_study_calendar;
        }
      });
    },
    // 切换
    changeTab(num) {
      this.titleTab = num;
      this.getTodaySubList();
    },
    //获取本月份数据（包含前后2个月的数据）
    getCurrentMonthCourse(titleTab) {
      let params = {
        begin_at: this.searchParams.begin_at,
      };
      // 获取学习计划
      let fn = "getUserStudyCalendar";
      if (!titleTab) {
        // 获取课表
        params.course_belong = "开罗尔";
        fn = "getMyAllCourse";
      }
      api[fn](params).then((res) => {
        let item = res.data;
        if (item.code === 1) {
          let subList = item.data || [];
          for (let i = 0; i < subList.length; i++) {
            this.buyOfflineSubclass[i] = subList[i].subclass_id;
          }
          this.subclassList = subList.map((item) => {
            const date = filter.formatSelfTime(item.begin_at, "YYYY/M/D");
            const belong = item.course_belong;
            const subinfo = item.subject;
            const classSubject = item.class_subject;
            const className = "mark1";
            const time =
              filter.formatSubclassEndTime(item.begin_at) +
              "-" +
              filter.formatSubclassEndTime(item.end_at);
            const subClass = item.sub_index + "/" + item.subclass_count;
            const classType = item.goods_type;
            let btnState = "";
            if (titleTab) {
              btnState = this.subPlanBtnText(item);
            } else {
              btnState = this.subclassBtnText(item);
            }
            return {
              classType,
              subinfo,
              className,
              classSubject,
              date,
              time,
              btnState,
              subClass,
              belong,
              begin_at: item.begin_at,
              end_at: item.begin_at,
              deadline_at: item.deadline_at,
              class_id: item.class_id,
              subclass_id: item.subclass_id,
            };
          });
          if (titleTab) {
            this.studyPlanArr = this.organize();
          } else {
            this.classArr = this.organize();
          }
        } else {
          this.$message.error(item.msg);
        }
      });
    },
    planToLiveRoom(subObj) {
      let liveState = subObj.btnState.btnText === "看直播" ? "live" : "replay";
      this.$router.push({
        path: `/live/${subObj.class_id}/${subObj.subclass_id}/${liveState}`,
      });
    },
    toLiveRoom(subObj) {
      let courseState = subObj.btnState;
      if (courseState < 3) {
        let liveState = courseState === 1 ? "live" : "replay";
        this.$router.push({
          path: `/live/${subObj.class_id}/${subObj.subclass_id}/${liveState}`,
        });
      }
      // 过期课--跳转课程详情
      if (courseState === 6) {
        this.$router.push({
          path: `/course-detail/${subObj.class_id}`,
        });
      }
    },
    // 点击日期
    clickDay(data) {
      this.cardDate = parseInt(new Date(data).getTime() / 1000);
      if (this.titleTab) {
        this.studyPlanArr = this.organize();
      } else {
        this.classArr = this.organize();
      }
    },
    //切换月份
    changeMonth(data) {
      // console.log("左右点击切换月份", data);
      this.handleDate(data);
    },
    //获取今日数据
    getTodaySubList() {
      this.cardDate = parseInt(new Date().getTime() / 1000);
      this.key = new Date().getTime();
      let nowDate = moment(new Date().getTime()).format("YYYY/M/D");
      this.handleDate(nowDate);
    },
    // 整理数据 显示日历下方的课程列表
    organize() {
      let currentDate = moment(this.cardDate * 1000).format("YYYY/M/D");
      let classArr = [];
      this.subclassList.forEach((item) => {
        if (item.date === currentDate) {
          classArr.push(JSON.parse(JSON.stringify(item)));
        }
      });
      return classArr;
    },
    //处理查询列表的参数 取当前月份的1号的时间戳
    handleDate(date) {
      let dateReplace = date.split("/");
      dateReplace[2] = "1";
      let beginningOfMonth = dateReplace.join("/");
      this.searchParams.begin_at = parseInt(
        new Date(beginningOfMonth).getTime() / 1000
      );
      this.getCurrentMonthCourse(this.titleTab);
    },
    //课表-按钮文案
    subclassBtnText(subObj) {
      /*
        返回状态
        直播(进行中)  1
        回放         2
        直播(未开始)  3
        过期         4 
        没有购买      5 
        过期，可重新报名      6
      */
      let sub_begin = subObj.begin_at;
      let sub_end = subObj.end_at;
      let sub_deadline = subObj.deadline_at;
      let now = parseInt(new Date().getTime() / 1000);
      if (sub_deadline && sub_deadline < now) {
        if (subObj.price) {
          return 4;
        } else {
          return 6;
        }
      } else if (now > sub_end) {
        return 2;
      } else if (now + 1800 >= sub_begin && now <= sub_end) {
        return 1;
      } else {
        return 3;
      }
    },
    //学习计划-按钮
    subPlanBtnText(subObj) {
      let sub_begin = subObj.subclass_begin_at;
      let sub_end = subObj.subclass_end_at;
      let now = parseInt(new Date().getTime() / 1000);
      if (now > sub_end) {
        return { btnText: "看回放" };
      } else if (now + 30 * 60 < sub_begin) {
        return { btnText: "看直播", disabled: true };
      } else {
        return { btnText: "看直播" };
      }
    },
  },
  mounted() {
    this.getTodaySubList();
    this.hasUserStudyCalendar();
  },
  components: {
    Calendar,
  },
};
</script>
<style lang="scss">
#comp_calender {
  .wh_content_item {
    margin-bottom: 4px;
  }
  .el-button--primary {
    padding: 8px 16px;
  }
  .mark1:after {
    position: absolute;
    bottom: 0;
    height: 5px;
    width: 5px;
    content: "";
    background: white;
    border-radius: 50%;
  }
  .wh_item_date:hover {
    background-color: $chiral-login-btn;
    border-radius: 50%;
  }
  .wh_content_all {
    background-color: rgba(88, 120, 182);
    .wh_chose_day,
    .wh_chose_day:hover {
      color: $chiral-btn-color;
      background-color: $avatar-color;
    }
    .wh_content {
      justify-content: space-around;
    }
  }
  .wh_isToday {
    color: $avatar-color;
    background-color: $chiral-login-btn;
  }
  // .mark1{
  //     background-color: rgba(3,199,191);
  // }
  .calender_title {
    height: 38px;
    background: #456399;
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
    line-height: 38px;
    font-size: 18px;
    position: relative;
    .study-plan {
      position: absolute;
      top: 0;
      left: 13px;
      cursor: pointer;
    }
    .timetable {
      cursor: pointer;
    }
    .title-selected {
      color: white;
    }
    .calender_now {
      position: absolute;
      top: 10px;
      right: 13px;
      font-size: 12px;
      color: $chiral-btn-color;
      font-weight: 500;
      background-color: $avatar-color;
      height: 20px;
      line-height: 20px;
      width: 40px;
      border-radius: 3px;
      cursor: pointer;
    }
  }
  .calender_week {
    display: flex;
    color: #333;
    font-weight: 600;
    padding: 20px 15px;
    background: white;
    font-size: 14px;
  }
  .calender_info {
    padding-bottom: 12px;
    background: white;
    // border-top: 1px solid #e8eaed;
    border-radius: 0 0 4px 4px;
    position: relative;
    .empty-content {
      min-height: 230px;
      img {
        width: 42px;
      }
      .empty-info {
        font-size: 14px;
      }
    }
    .classtext {
      padding: 15px 15px 0px 15px;
      border-top: 1px solid #e8eaed;
      font-size: 14px;
      .sub-time {
        color: $chiral-free-color;
      }
      .sub-title {
        font-size: 16px;
        font-weight: 500;
        color: #2c2d2e;
      }
      div {
        margin-bottom: 22px;
      }
    }
    .noclass_img {
      text-align: center;
    }
    .warning {
      text-align: center;
      span {
        color: #809bc5;
      }
    }
  }
}
</style>
