<!-- 购课区域 -->
<template>
  <div class="buy-area">
    <!-- 免费课 -->
    <div
      v-if="!classInfo.price"
      class="buy-container"
      :class="classPurchase ? 'purchase-container' : ''"
    >
      <span class="course-type">免费课程</span>
      <div v-if="classPurchase">
        <img
          v-if="subStudyProcess < classInfo.subclass_count"
          src="@/assets/img/live/youchuo-yiyuyue.png"
          alt="预约成功"
        />
        <img
          v-else
          src="@/assets/img/live/youchuo-yibaoming.png"
          alt="报名成功"
        />
      </div>
      <button v-else class="buy-btn" @click="booking('free')">
        {{ buyBtnText }}
      </button>
    </div>
    <!-- 付费课 -->
    <div v-else>
      <!-- 团购课 -->
      <div v-if="groupTag" class="buy-group">
        <!-- 已报名 -->
        <div
          v-if="classPurchase"
          class="buy-container"
          :class="classPurchase ? 'purchase-container' : ''"
        >
          <span class="real-price"><i>¥</i>{{ classInfo.price / 100 }}</span>
          <span class="virtual-price" v-if="classInfo.virtual_price"
            >原价¥{{ classInfo.virtual_price / 100 }}</span
          >
          <img src="@/assets/img/live/youchuo-yibaoming.png" alt="报名成功" />
        </div>
        <!-- 未报名 -->
        <div v-if="!isMobile && !classPurchase">
          <!-- 已付款，还差n人成团 -->
          <div v-if="userGroup.order_state === 2">
            <div class="time-count">
              <p class="user-group-info">
                还差<span>{{ userGroup.rest_num }}</span
                >人拼团成功
              </p>
              <count-down
                key="userGroup"
                :endTime="userGroup.group_end_at"
                countdownText="剩余"
              />
            </div>
            <div class="group-price">
              <span class="text">拼团价¥</span>
              <span class="price">{{ userGroup.group_price / 100 }}</span>
              <span class="original-price"
                >原价¥{{ classInfo.price / 100 }}</span
              >
              <el-button @click="booking('shareGroup')" class="group-buy"
                >分享完成拼团</el-button
              >
            </div>
          </div>
          <!-- 未购买 -->
          <div v-else>
            <!-- 拼团倒计时 -->
            <div v-if="classInfo.group_end_time" class="time-count">
              <span class="group-buy-tips">
                <i class="fa-v2 fa-xianshi"></i
                ><span class="xianshi-tuangou">限时团购</span>
              </span>
              <count-down
                key="classGroup"
                :endTime="classInfo.group_end_time"
                countdownText="距离团购结束还剩"
              />
            </div>
            <!-- 团购价格-最低价 -->
            <div class="group-price">
              <span class="num">{{ classInfo.group_num }}人成团</span>
              <span class="text">拼团价¥</span>
              <span class="price">{{ classInfo.group_price / 100 }}</span>
              <span class="original-price"
                >原价¥{{ classInfo.price / 100 }}</span
              >
            </div>
            <!-- 团购价格-最高价 -->
            <div v-if="gropPriceList.length > 1" class="group-price">
              <span class="num">{{ gropPriceList[0].num }}人成团</span>
              <span class="text">拼团价¥</span>
              <span class="price">{{ gropPriceList[0].price / 100 }}</span>
              <span class="original-price"
                >原价¥{{ classInfo.price / 100 }}</span
              >
            </div>
            <!-- 团购报名 -->
            <div class="group-button">
              <el-button @click="booking('narmalPay')" class="original-buy"
                >原价报名</el-button
              >
              <el-button @click="booking('byGroup')" class="group-buy"
                >发起拼团</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <!-- 普通付费课 -->
      <div
        v-else
        class="buy-container"
        :class="classPurchase ? 'purchase-container' : ''"
      >
        <span class="real-price"><i>¥</i>{{ classInfo.price / 100 }}</span>
        <span class="virtual-price" v-if="classInfo.virtual_price"
          >原价¥{{ classInfo.virtual_price / 100 }}</span
        >
        <img
          v-if="classPurchase"
          src="@/assets/img/live/youchuo-yibaoming.png"
          alt="报名成功"
        />
        <button v-else class="buy-btn" @click="booking('narmalPay')">
          {{ notPurchaseNum ? `报名剩余章节(${notPurchaseNum})` : "立即报名" }}
        </button>
      </div>
    </div>
    <group-list :groupList="gropPriceList" ref="groupList" />
    <share-dialog ref="shareDialog" />
  </div>
</template>

<script>
import ShareDialog from "@/components/ShareDialog.vue";
import GroupList from "@/views/course/components/GroupList.vue";
import { isMobile } from "@/utils/utils";
import CountDown from "@/views/course/components/CountDown.vue";
import booking from "@/mixins/booking";
export default {
  name: "BuyCourseArea",
  components: {
    "count-down": CountDown,
    "group-list": GroupList,
    "share-dialog": ShareDialog,
  },
  mixins: [booking],
  props: {
    classInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    classPurchase: {
      type: Boolean,
      default: false,
    },
    subStudyProcess: {
      type: Number,
      default: 0,
    },
    groupTag: {
      type: Boolean,
      default: false,
    },
    notPurchaseNum: {
      type: Number,
      default: null,
    },
    buyBtnText: {
      type: String,
      default: "立即报名",
    },
    gropPriceList: {
      type: Array,
      default() {
        return [];
      },
    },
    groupItems: {
      type: Array,
      default() {
        return [];
      },
    },
    userGroup: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isMobile: isMobile(),
    };
  },
};
</script>

<style lang="scss" scoped>
.buy-area {
  font-size: 12px;
  margin-top: 10px;
  // 团购
  .group-price {
    color: #ef4d3c;
    margin-bottom: 15px;
    .num {
      background: rgba(239, 77, 60, 0.1);
      border-radius: 10px;
      margin-right: 12px;
      padding: 3px 7px;
    }
    .text {
      font-size: 14px;
    }
    .price {
      font-size: 26px;
      margin-right: 12px;
    }
    .original-price {
      font-size: 16px;
      color: rgba(51, 51, 51, 0.3);
      margin-right: 12px;
    }
  }
  .group-button {
    .original-buy {
      color: #ef4d3c;
      border-color: #ef4d3c;
      background-color: transparent;
    }
    .original-buy:hover {
      color: #fff;
      background-color: #ef4d3c;
    }
  }
  .group-buy {
    color: #fff;
    background-color: #ef4d3c;
    border-color: #ef4d3c;
  }
  .time-count {
    font-size: 14px;
    margin-bottom: 10px;
    display: flex;
    .group-buy-tips {
      padding-left: 0;
      border-radius: 14px;
      margin-right: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ef4d3c;
      line-height: 23px;
      border-color: #ef4d3c;
      background: rgba(255, 255, 255, 0.6);
    }
    .fa-xianshi {
      margin: -3px 6px 0 0;
    }
    .xianshi-tuangou {
      font-style: italic;
      font-weight: 600;
      padding-left: 0;
      font-size: 13px;
    }
    .user-group-info {
      border: 1px solid #ef4d3c;
      border-radius: 14px;
      padding: 0 8px;
      font-size: 12px;
      font-weight: 500;
      color: #ef4d3c;
      line-height: 23px;
      margin-right: 12px;
      span {
        color: #00c7bf;
      }
    }
  }

  // 免费课/普通付费课
  .buy-container {
    display: flex;
    align-items: center;
    // position: absolute;
    // bottom: 0px;
    margin-top: 60px;
    .course-type {
      font-size: 14px;
      font-weight: 500;
      color: $chiral-free-color;
      line-height: 20px;
    }
    img {
      width: 97px;
      height: 97px;
      margin-left: 16px;
    }
    @media (max-width: 767px) {
      img {
        width: 1.6rem;
        height: 1.6rem;
        margin-left: 0.4267rem;
      }
    }
    .buy-btn {
      padding: 13px 41px;
      color: $avatar-color;
      background: $chiral-btn-color;
      border-radius: 4px;
      margin-left: 44px;
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
    }
    @media (max-width: 767px) {
      .buy-btn {
        padding: 0.2667rem 0.3733rem;
        margin-left: 0.2133rem;
      }
    }
  }
  .purchase-container {
    margin-top: 10px;
  }

  .virtual-price {
    color: $chiral-virtual-price-color;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    text-decoration: line-through;
  }
  .real-price {
    color: $chiral-price-color;
    font-size: 26px;
    margin-right: 12px;
    i {
      font-size: 16px;
      font-style: normal;
    }
  }
}

@media (max-width: 1200px) {
  .buy-area {
    .buy-container,
    .purchase-container {
      margin-top: 10px;
    }
  }
}

@media (max-width: 767px) {
  .buy-area {
    .buy-container,
    .purchase-container {
      margin-top: 0.1333rem;
    }
  }
}
</style>
