export const m = {
  // Problem.vue
  Descriptions: 'Description',
  Inputs: 'Input Description',
  Outputs: 'Output Description',
  Sample_Input: 'Sample Input',
  Sample_Output: 'Sample Output',
  Hint: 'Hint',
  Source: 'Source',
  Status: 'Status',
  Information: 'Information',
  Time_Limit: 'Time Limit',
  Memory_Limit: 'Memory Limit',
  Created: 'Created By',
  Level: 'Level',
  Score: 'Score',
  Tags: 'Tags',
  Show: 'Show',
  // About.vue
  Result_Explanation: 'Result Explanation',
  Pending_Juding: 'You solution will be judged soon, please wait for result.',
  Compile_Error: "Failed to compile your source code. Click on the link to see compiler's output.",
  Accepted: 'Congratulations. Your solution is correct.',
  Wrong_Answer: "Your program's output doesn't match judger's answer.",
  Runtime_Error: 'Your program terminated abnormally. Possible reasons are: segment fault, divided by zero or exited with code other than 0.',
  Time_Limit_Exceeded: 'The CPU time your program used has exceeded limit(Java has a triple time limit). Possible reasons are: Too high or internal code complexity issues such as death cycle',
  Real_Limit_Exceeded: 'The process time your program used has exceeded limit(Real time has a triple CPU time limit). Possible reasons are: Read or output was stuck',
  Memory_Limit_Exceeded: 'The memory your program actually used has exceeded limit.',
  System_Error: 'Oops, something has gone wrong with the judger. Please report this to administrator.',
  // FAQ.vue
  Frequently_Asked_Questions: 'Frequently Asked Questions',
  // ContestDetail.vue
  Problems: 'Problems',
  Announcements: 'Announcements',
  Submissions: 'Submissions',
  Rankings: 'Rankings',
  Overview: 'Overview',
  Admin_Helper: 'Admin Helper',
  // ContestProblemList
  Problems_List: 'Problems List',
  Contest_Home: 'Contest Home',
  Mine_Submit: 'Mine Submit',
  All_Submit: 'All Submit',
  Contest_Rank: 'Contest Ranking',
  Contest_Analysis: 'Contest Analysis',
  Statement: 'Statement',

  // NavBar.vue
  Home: 'Home',
  NavProblems: 'Problems',
  Contests: 'Contests',
  PersonalRecord: 'PersonalRecord',
  PersonalInformation: 'Personal Information',
  QuestionBank: 'Question Bank',
  MeterRecord: 'Meter records',
  NavStatus: 'Status',
  Rank: 'Rank',
  ACM_Rank: 'ACM Rank',
  OI_Rank: 'OI Rank',
  About: 'About us',
  Judger: 'Judger',
  FAQ: 'FAQ',
  Login: 'Login',
  Register: 'Register',
  MyHome: 'Home',
  MySubmissions: 'Submissions',
  Settings: 'Settings',
  Management: 'Management',
  Logout: 'Logout',
  // announcements.vue
  Refresh: 'search',
  Back: 'Back',
  No_Announcements: 'No Announcements',
  // Setting.vue
  Profile: 'Profile',
  Account: 'Account',
  Security: 'Security',
  // AccoutSetting.vue
  ChangePassword: 'Change Password',
  ChangeEmail: 'Change Email',
  Update_Password: 'Update Password',
  // ProfileSetting.vue
  Avatar_Setting: 'Avatar Setting',
  Profile_Setting: 'Profile Setting',
  Profile_Mail: 'Mail Address',
  Profile_Shown: 'Public Information',
  Address: 'Address Detail',
  School: 'School',
  UserName: 'Nick Name',
  Province: 'Province',
  Profile_PCD: 'Select Address',
  Profile_Grade: 'Grade',
  Email: 'Email',
  Mobile: 'Mobile Number',
  RegisterMobile: 'Mobile Number',
  AccountNumber: 'Account number',
  Address_Name: 'Addressee',
  Address_Mobile: 'Addressee Mobile',
  City: 'City',
  District: 'District',
  ShowContestReCord: 'Contest ReCords',

  // SecuritySettig
  Sessions: 'Sessions',
  Two_Factor_Authentication: 'Two Factor Authentication',
  // Login.vue
  LoginUsername: 'Username',
  LoginPassword: 'Password',
  TFA_Code: 'Code from your TFA app',
  No_Account: 'No account? Register now!',
  Forget_Password: 'Forget Password',
  UserLogin: 'Login',
  // Register.vue
  RegisterUsername: 'Username',
  Email_Address: 'Email Address',
  RegisterPassword: 'Password',
  Password_Again: 'Password Again',
  Captcha: 'Captcha',
  SMSCaptcha: 'SMSCaptcha',
  UserRegister: 'Register',
  Already_Registed: 'Already registed? Login now!',
  // ResetPassword.vue and ApplyResetPassword.vue
  Reset_Password: 'Lost Password',
  RPassword: 'Password',
  RPassword_Again: 'Password Again',
  RCaptcha: 'Captcha',
  ApplyEmail: 'Your Email Address',
  Send_Password_Reset_Email: 'Send Password Reset Email',
  // UserHome.vue
  UserHomeSolved: 'Solved',
  UserHomeserSubmissions: 'Submissions',
  UserHomeScore: 'Score',
  List_Solved_Problems: 'List of solved problems',
  UserHomeIntro: 'The guy is so lazy that has not solved any problem yet.',
  // new
  Config: 'Config',
  To_Sign_Up: 'Sign Up',
  Review: 'Review',
  Rating: 'Rating',
  ContestRecords: 'Contest Records',
  ContestInstructions: 'Contest Instructions',
  CurrentlyRanked: 'Currently Ranked',
  Sample: 'Sample',
  FeedBack: 'Question Feedback',
  TimeEating: 'timing',
  TotalScore: 'Total Score',
  CommitTime: 'CommitTime',
  Language: 'language',
  MemoryEat: 'MemoryEating',
  System_Message: 'System Message',
  EnterContest: 'Enter Contest',
  UpdateUserName: 'Please fill in the user name first',
  Live: 'live',
  PWDLogin: 'Passpord Login',
  QuickLogin: 'Quick Login',
  GetVerificationVode: 'Get verification code',
  SendSuccess: 'Send success',
  GraphicCaptchasRequired: 'Graphic captchas are required',
  VerifyException: 'Verify exception, please try again',
  InputMobile: 'Please enter your mobile',
  InputMobileError: 'Wrong format of mobile',
  MobileError: 'Mobile Error',
  InputPWD: 'Please enter your password',
  RegisterPWDTip: '8-40 characters, must contain Numbers and letters, case sensitive',
  PWDMinLength: 'The minimum password length is 8',
  PWDMaxLength: 'The maximum length of the password is 40',
  TwoDifferentPWSD: 'Two different passwords',
  MobileCodeRequired: 'Please enter the verification code',
  MobileCodeLength: 'The captcha length is 4',
  ValidateFields: 'please validate the error fields',
  AccountRequired: 'Please fill in your cell phone number or email address.',
  RegisterAccountRequired: 'Support the registration of mobile phone number or mailbox as account number',
  AccountError: 'The account format is wrong. Please check it.',
  MobileFormatError: 'The format of mobile phone number is incorrect. Please fill it in again.',
  EmailFormatError: 'The mailbox format is incorrect. Please fill it in again.',
  Title: 'Title',
  TimeLimit: 'Time Limit',
  MemoryLimit: 'Memory Limit',
  NewPassword: 'New Password',
  UpdatePassword: 'Update Password',
  InputNewPWD: 'Please enter your New password',
  ShowMyRank: 'show my rank',
  ShowSchool: 'show school info',
  SubInfo: 'double click to show submission detail',
  ProblemNumber: 'Problem Number',
  problem_nav: 'Problem',
  problem_solution: 'problem solution',
  user_solution: 'my problem solution'
}
