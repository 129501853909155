import Vue from "vue";
import router from "@/router/index.js";
import loginDialog from "./LoginDialog.vue";
const loginDialogConstructor = Vue.extend(loginDialog);
let Dom = null;

function openLogin(from, success) {
  Dom = new loginDialogConstructor({
    router,
    el: document.createElement("div"),
    data() {
      return {};
    },
  });
  document.body.appendChild(Dom.$el);
  Dom.show(from, success);
}

// 关闭弹窗
function closeLogin() {
  if (Dom) {
    Dom.$data.modal = false;
  }
}

function showLoginDialog() {
  Vue.prototype.$showLoginDialog = openLogin;
}

function closeLoginDialog() {
  Vue.prototype.$closeLoginDialog = closeLogin;
}

Vue.use(showLoginDialog);
Vue.use(closeLoginDialog);
// export default showLoginDialog;
