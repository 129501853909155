<template>
  <div class="agreement container">
    <section class="text-center">
      <div class="container">
        <a :href="chiralUrl">
          <img class="header-img" src="@/assets/img/logo-app.png" alt="" />
          <!-- <h1 class="header-title">开罗尔</h1> -->
        </a>
      </div>
    </section>
    <div class="agreement-content">
      <h1>开罗尔账号注销须知</h1>
      <br />
      <p>
        亲爱的用户，我们对您注销开罗尔在线帐号的决定深表遗憾。如果您仍执意注销帐号，为了保障您的权益，请在注销前详细阅读本须知的所有内容，特别是加粗部分。当您勾选“我已阅读并同意《开罗尔账号注销须知》”并点击“申请注销”或类似按钮时，您的行为表示您同意本须知的约定。
      </p>
      <br />
      <p>
        1、您应确保您有权决定该帐号的注销事宜，不侵犯任何第三方的合法权益，<b
          >如因此引发任何投诉争议，由您自行承担全部责任，开罗尔在线免责。</b
        >
      </p>
      <br />
      <p>
        2、注销开罗尔在线账号是不可逆操作，操作之前，建议您自行备份开罗尔在线帐号中的相关所有信息并请确认与本帐号相关的所有服务均已进行妥善处理。
      </p>
      <p>
        3、注销开罗尔在线帐号，除法律法规另有规定外，将视为您自行放弃该帐号及相关权益，您将无法再登录、使用此帐号或找回此帐号内的任何信息及权益，<b>包括但不限于个人身份信息、帐号信息、礼券等。</b>您确认并知悉由于成都灵动量教育科技有限公司使用的同一账号注册系统，您使用的同一手机号注册的除开罗尔在线外的其他开罗尔及质心系列产品账号，<b>包括但不限于开罗尔官网、质心教育官网、质心在线应用、质心论坛等，将被一并注销</b>。
      </p>
      <br />
      <p>
        4、在您申请注销开罗尔在线帐号前，您应当确保该帐号处于正常状态，且在注销申请提起时该帐号:
      </p>
      <p><b>(1)没有未完成的课程订单;</b></p>
      <p>
        <b>
          (2)该帐号不存在未了结的合同关系，或开罗尔在线认为注销该帐号会由此产生未了结的权利义务而产生纠纷的情况。
        </b>
      </p>
      <br />
      <p>
        5、您承诺您的开罗尔在线帐号注销申请一经提交，您不会以任何理由要求开罗尔在线予以撤销。
      </p>
      <br />
      <p>
        6、注销开罗尔在线后，我们会将依据相关法律法规对个人信息、相关记录等数据从前台系统中去除。您知晓并理解，根据相关法律规定，部分信息、记录将在开罗尔在线后台保存一定的时间，但请您知悉，<b
          >我们将严格按照相关法律的要求保存您的信息。</b
        >
      </p>
      <br />
      <p>
        7、在开罗尔在线账号注销期间，<b>如果您的开罗尔在线帐号被他人投诉、被国家机关调查或正处于诉讼、仲裁程序中</b>，开罗尔在线有权自行终止该帐号的注销流程而无需另行得到您的同意。
      </p>
      <br />
      <p>
        8、请注意，注销您的开罗尔在线帐号并不代表<b
          >此帐号注销前的帐号行为和相关责任得到豁免或减轻。</b
        >
      </p>
      <br />
      <p>
        9、如您对注销账号有任何疑惑或其他相关事宜的，可联系客服：400-0356256<b>进行询问</b>。
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "Agreement",
  data() {
    return {
      chiralUrl: process.env.VUE_APP_CHIRAL_URL,
    };
  },
};
</script>
<style scoped src="@/assets/css/zx-agreement.css"></style>

<style scoped>
.container {
  text-align: center;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
.container:after,
.container:before {
  display: table;
  content: " ";
}
.container:after {
  clear: both;
}
p,
h2 {
  text-align: left;
}
p {
  line-height: 24px;
  font-weight: 400;
}
</style>
