import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index.js";
import api from "@/plugins/api";
import Home from "../views/Home.vue";
import UserPolicy from "../views/company/UserPolicy.vue";
import UserAgreement from "../views/company/UserAgreement.vue";
import ZhangHaoZhuXiao from "../views/company/ZhangHaoZhuXiao.vue";
import LectureCenter from "../views/course/LectureCenter.vue";
import CourseDetail from "../views/course/CourseDetail.vue";
import RecommendCenter from "../views/course/RecommendCenter.vue";
import MyCourse from "../views/my-course/MyCourse.vue";
import CourseList from "../views/my-course/CourseList.vue";
import MyCourseDetail from "../views/my-course/CourseDetail.vue";
import LiveRoom from "../views/live/LiveRoom.vue";
import QuizDetail from "../views/quiz/quiz-detail.vue";
import QuizList from "../views/quiz/quiz-list.vue";
import WrongQuestions from "../views/brash-questions/WrongQuestions.vue";
import Login from "../views/login/Login.vue";
import Register from "../views/login/Register.vue";
import OrderList from "../views/my-order/OrderList.vue";
import OrderDetail from "../views/my-order/OrderDetail.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      routeTitle: "欢迎页",
      backgroudColor: "#fff",
    },
  },
  {
    path: "/quiz-detail/:par/:origin/:id/:index/:subject",
    name: "QuizDetail",
    component: QuizDetail,
    meta: {
      routeCheckedName: "QuizList",
    },
  },
  {
    path: "/quiz-list",
    name: "QuizList",
    component: QuizList,
    meta: {
      routeTitle: "刷题",
      loginReload: true,
      keepAlive: true,
    },
  },
  {
    path: "/recommend-center",
    name: "RecommendCenter",
    component: RecommendCenter,
    meta: {
      routeTitle: "首页",
      backgroudColor: "#fff",
    },
  },
  {
    path: "/lecture-center",
    name: "LectureCenter",
    component: LectureCenter,
    meta: {
      keepAlive: true,
      routeTitle: "课程",
    },
  },
  {
    path: "/course-detail/:id",
    name: "CourseDetail",
    component: CourseDetail,
    meta: {
      routeTitle: "课程详情",
      loginReload: true,
      routeCheckedName: "LectureCenter",
    },
  },
  {
    path: "/pay/:orderNo",
    name: "Pay",
    component: () => import(/* webpackChunkName: "Pay" */ "../views/pay/Pay"),
    meta: {
      routeTitle: "支付页",
      requireAuth: true,
    },
  },
  {
    path: "/group-activity/:groupNo",
    name: "GroupNo",
    component: () =>
      import(/* webpackChunkName: "GroupNo" */ "../views/group-activity/Main"),
    meta: {
      routeTitle: "团购分享",
    },
  },
  {
    path: "/order/:orderNo",
    name: "OrderNo",
    component: () =>
      import(/* webpackChunkName: "OrderNo" */ "../views/order/Main"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/my-course",
    name: "MyCourse",
    component: MyCourse,
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: "course-list",
        name: "CourseList",
        component: CourseList,
        meta: {
          routeTitle: "课程",
          loginReload: true,
          requireAuth: true,
        },
      },
      {
        path: "course-detail/:id",
        name: "MyCourseDetail",
        component: MyCourseDetail,
        meta: {
          loginReload: true,
          requireAuth: true,
        },
      },
    ],
  },
  {
    path: "/live/:classId/:subId/:action",
    name: "live",
    meta: {
      routeTitle: "课程",
      requireAuth: true,
    },
    component: LiveRoom,
  },
  {
    path: "/knowledge",
    name: "Knowledge",
    meta: {
      routeTitle: "知识点",
      routeCheckedName: "Knowledge",
      keepAlive: true,
    },
    component: () =>
      import(/* webpackChunkName: "NotFound" */ "../views/knowledge/Main"),
  },
  {
    path: "/knowledge-video/:chapter/:section/:vid/:subject",
    name: "Knowledge-Video",
    meta: {
      routeTitle: "知识点视频",
      routeCheckedName: "Knowledge",
      requireAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "NotFound" */ "../views/knowledge/Video"),
  },
  {
    path: "/policy",
    name: "Policy",
    meta: {
      routeTitle: "隐私政策",
      layout: "agreement-page",
    },
    component: UserPolicy,
  },
  {
    path: "/agreement",
    name: "Agreement",
    meta: {
      routeTitle: "用户协议",
      layout: "agreement-page",
    },
    component: UserAgreement,
  },
  {
    path: "/service/zhanghaozhuxiao",
    name: "ZhangHaoZhuXiao",
    meta: {
      routeTitle: "账号注销",
      layout: "agreement-page",
    },
    component: ZhangHaoZhuXiao,
  },
  {
    path: "/test",
    name: "Test",
    meta: {
      routeTitle: "Test",
    },
    component: () =>
      import(/* webpackChunkName: "NotFound" */ "../views/test/main"),
  },
  {
    path: "/wrong-questions",
    name: "WrongQuestions",
    component: WrongQuestions,
    meta: {
      routeTitle: "错题本",
      requireAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      layout: "agreement-page",
    },
  },
  {
    path: "/register/:type",
    name: "Register",
    component: Register,
    meta: {
      layout: "agreement-page",
    },
  },
  {
    path: "/order-list",
    name: "OrderList",
    component: OrderList,
    meta: {
      routeTitle: "我的订单",
      requireAuth: true,
      backgroudColor: "#fff",
    },
  },
  {
    path: "/order-detail/:orderNo",
    name: "OrderDetail",
    component: OrderDetail,
    meta: {
      routeTitle: "订单详情",
      requireAuth: true,
      backgroudColor: "#fff",
    },
  },
  {
    path: "/download-app",
    name: "DownloadApp",
    component: () =>
      import(
        /* webpackChunkName: "DownloadApp" */ "../views/download-app/Main.vue"
      ),
    meta: {
      routeTitle: "应用下载",
      backgroudColor: "#fff",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    meta: {
      routeTitle: "未知",
    },
    component: () =>
      import(/* webpackChunkName: "NotFound" */ "../views/error/404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach((to, from, next) => {
  // 登录拦截
  if (to.meta?.requireAuth && !store.getters.isAuthenticated) {
    let params = {
      product_name: "开罗尔",
    };
    api.getUserInfo(params).then((res) => {
      if (res.data.code === 1) {
        next();
      } else {
        next({
          path: "/login",
          query: {
            preRoute: from.fullPath === "/" ? to.fullPath : from.fullPath,
          },
        });
      }
    });
  } else {
    next();
  }
});

// 处理路由拦截重定向报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, resolve, reject) {
  if (resolve || reject)
    return originalPush.call(this, location, resolve, reject);
  return originalPush.call(this, location).catch(() => {});
};

export default router;
