<!-- 阶梯拼团弹窗 -->
<template>
  <div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      :append-to-body="true"
      :close-on-click-modal="false"
      width="310px"
    >
      <h3>请选择拼团人数</h3>
      <img
        src="@/assets/img/close.png"
        @click="dialogVisible = false"
        class="close-btn"
        alt="关闭"
      />
      <div class="group-list">
        <div
          class="group-item"
          @click="checked(index)"
          :class="index == activite ? 'activite' : ''"
          v-for="(item, index) in groupList"
          :key="index"
        >
          <div>
            ¥<span class="price">{{
              Number((Number(item.price) / 100).toFixed(2))
            }}</span>
          </div>
          <div class="num">{{ item.num }}人成团</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="save-btn" @click="startPT">确定拼团</button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    groupList: {
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      activite: 0,
      e: "",
    };
  },
  methods: {
    checked(index) {
      this.activite = index;
    },
    showGroupList() {
      this.dialogVisible = true;
    },
    startPT() {
      this.dialogVisible = false;
      this.$parent.groupToOrder(this.groupList[this.activite].num);
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
.v-modal {
  opacity: 0;
}
.el-dialog__header {
  display: none;
}
.el-dialog__body {
  padding: 0px;
}
.el-dialog__footer {
  padding: 16px 0px;
  text-align: center;
  .save-btn {
    margin: 0;
    padding: 0;
    border: none; //去除边框
    outline: none;
    display: inline-block;
    padding: 9px 36px;
    background: #6accbe linear-gradient(90deg, #f85a4a 0%, #ff7668 100%);
    box-shadow: 0px 2px 9px 0px rgba(232, 232, 232, 0.5);
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    line-height: 22px;
    text-shadow: 0px 2px 9px rgba(232, 232, 232, 0.5);
  }
}
h3 {
  margin: 0px;
  padding: 40px 0px 32px;
  font-size: 22px;
  color: #333333;
  line-height: 30px;
  text-align: center;
}
.el-dialog {
  border-radius: 8px;
}
.close-btn {
  width: 30px;
  height: 30px;
  position: absolute;
  top: -16px;
  right: -14px;
  cursor: pointer;
}
.group-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 230px;
  overflow: scroll;
  overflow-x: hidden;
  .group-item {
    cursor: pointer;
    min-width: 204px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid rgba(51, 51, 51, 0.06);
    padding: 6px 36px 6px 22px;
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    line-height: 20px;
    margin-bottom: 10px;
    .price {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      display: inline-block;
      margin: 0px 17px 0px 3px;
    }
    .num {
      padding: 4px 6px;
      background: rgba(51, 51, 51, 0.06);
      border-radius: 10px;
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
    }
  }
  .activite {
    border: 1px solid #ef4d3c;
    color: #ef4d3c;
    position: relative;
    .num {
      background: rgba(239, 77, 60, 0.1);
    }
  }
  .activite:after {
    content: "";
    width: 32px;
    height: 32px;
    position: absolute;
    right: -1px;
    bottom: 0px;
    background: url("../../../assets/img/course/group-check.png");
    background-size: 100% 100%;
    z-index: 1;
  }
}
.group-list::-webkit-scrollbar {
  /* 隐藏默认的滚动条 */
  -webkit-appearance: none;
}
.group-list::-webkit-scrollbar:vertical {
  /* 设置垂直滚动条宽度 */
  width: 5px;
}
.group-list::-webkit-scrollbar-thumb {
  border-radius: 10%;
  border: 2px solid #666;
  background-color: #666;
}
</style>
