import { request } from "./request";
const zhixinApi = process.env.VUE_APP_ZHIXIN_URL;
// const zhixinApi = "http://yapi.upho2015.com/mock/17";
// 错题本
// 获取学科下知识点
export const getSkillTree = (params) =>
  request(zhixinApi + "/v1/SkillTree/get", "get", { params });
// 获取学科下知识点错题数量
export const getQuestionNum = (params) =>
  request(zhixinApi + "/v1/Question/getSkillTree", "get", { params });
//   错题本容量
export const getWrongQuota = (params) =>
  request(zhixinApi + "/v1/Question/getWrongQuota", "get", { params });
//   错题列表
export const getWrongQuestionList = (params) =>
  request(zhixinApi + "/v1/Question/getWrongQuestionList", "get", {
    params,
  });
// 错题本--删除
export const delWrongQuestion = (data) =>
  request(zhixinApi + "/v1/Question/delWrongQuestion", "post", { data });
