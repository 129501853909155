import Vue from "vue";

Vue.directive("lazyload", {
  bind(el, binding) {
    let lazyLoadObser = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        let lazyImage = entry.target;
        if (entry.intersectionRatio > 0) {
          if (binding.value.imgUrl) {
            lazyImage.src = binding.value.imgUrl;
          }
          if (binding.value.animate) {
            el.classList.add(binding.value.animate);
          }
          lazyLoadObser.unobserve(lazyImage);
        }
      });
    });
    lazyLoadObser.observe(el);
  },
});
