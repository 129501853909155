<template>
  <div class="course-card" @click="toCourseDetail">
    <h1 class="subject">
      {{ classInfo.subject }}
    </h1>
    <div class="cardcommon__label">
      <!-- 讲座列表-课程卡片-当课程已开始时，日期只展示“课程结束日期” -->
      <span v-if="now > classInfo.begin_at">{{
        classInfo.end_at | formatDate
      }}</span>
      <span v-else
        >{{ classInfo.begin_at | formatDate }}-{{
          classInfo.end_at | formatDate
        }}</span
      >
      <span class="section">共{{ classInfo.subs_info.length }}节</span>
    </div>
    <div class="cardcommon__teachers">
      <div
        class="teacher"
        v-for="item in classInfo.teachers_info.slice(0, 4)"
        :key="item.id"
      >
        <img :src="teacherPicture(item.picture, item.gender)" alt="教师头像" />
        <p>{{ item.teacher_name }}</p>
      </div>
    </div>
    <div class="cardcommon__price">
      <div class="free" v-if="!classInfo.price">免费课程</div>
      <div v-else-if="groupBuy">
        <span class="delete-price"
          >￥{{ Number((classInfo.price / 100).toFixed(2)) }}</span
        >
        <span class="group-price"
          >￥<span class="price-number">{{
            Number((classInfo.group_setting.group_price / 100).toFixed(2))
          }}</span>
        </span>
        <span class="chiral fa-tuan tuan-icon"></span>
      </div>
      <div v-else>
        <span class="delete-price" v-if="classInfo.virtual_price"
          >￥{{ Number((classInfo.virtual_price / 100).toFixed(2)) }}</span
        >
        <span class="price-unit">￥</span
        >{{ Number((classInfo.price / 100).toFixed(2)) }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CourseCard",
  props: {
    classInfo: {
      type: Object,
    },
    courseUtm: {
      type: String,
    },
  },
  data() {
    return {
      now: parseInt(new Date().getTime() / 1000),
    };
  },
  methods: {
    toCourseDetail() {
      this.$router.push({
        path: `/course-detail/${this.classInfo.class_id}${this.courseUtm}`,
      });
    },
    // 男女老师默认头像
    teacherPicture(picture, gender) {
      if (picture) {
        return picture;
      }
      return gender === 1
        ? "//cdn.eduzhixin.cn/cdn/img/live/teacher-nan.png"
        : "//cdn.eduzhixin.cn/cdn/img/live/teacher-nv.png";
    },
  },
  computed: {
    groupBuy() {
      let item = this.classInfo.group_setting || null;
      return (
        item &&
        item.group_set === 1 &&
        (!item.group_end_time ||
          new Date().getTime() / 1000 - item.group_end_time < 0)
      );
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.course-card {
  padding: 34px 16px 21px 16px;
  background: $avatar-color;
  box-shadow: 0px 2px 8px 0px rgba(139, 152, 169, 0.05);
  border-radius: 4px;
  border-top: 2px solid #4a36fa;
  cursor: pointer;
  @media (max-width: 767px) {
    padding: 0.5333rem 0.4267rem 0.3467rem 0.4267rem;
  }
  .subject {
    font-size: 20px;
    font-weight: 500;
    color: $chiral-color;
    line-height: 30px;
    text-shadow: 0px 2px 8px rgba(139, 152, 169, 0.05);
    margin-bottom: 50px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    height: 60px;
  }
  @media (max-width: 767px) {
    .subject {
      font-size: 0.4267rem;
      margin-bottom: 0.4533rem;
      line-height: 0.5867rem;
      height: 1.1733rem;
    }
  }
  .cardcommon__label {
    font-size: 14px;
    font-weight: 400;
    color: #2c2d2e;
    line-height: 14px;
    text-shadow: 0px 2px 8px rgba(139, 152, 169, 0.05);
    display: flex;
    margin-bottom: 20px;
    .section {
      padding: 0px 8px;
      margin-left: 8px;
      color: rgba($color: $chiral-color, $alpha: 0.5);
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      text-shadow: 0px 2px 8px rgba(139, 152, 169, 0.05);
      position: relative;
    }
    .section:after {
      content: "";
      width: 1px;
      height: 12px;
      background: rgba($color: $chiral-color, $alpha: 0.5);
      position: absolute;
      left: 0px;
      top: 50%;
      transform: translate(0px, -50%);
    }
  }
  .cardcommon__teachers {
    display: flex;
    margin-bottom: 41px;
    .teacher {
      font-size: 12px;
      font-weight: 400;
      color: rgba($color: $chiral-color, $alpha: 0.8);
      line-height: 12px;
      text-align: center;
      margin-right: 21px;
      img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        margin-bottom: 4px;
      }
      p {
        overflow: hidden;
        height: 12px;
      }
    }
  }
  @media (max-width: 767px) {
    .cardcommon__teachers {
      margin-bottom: 0.16rem;
    }
  }
  .cardcommon__price {
    font-size: 26px;
    font-weight: 500;
    color: $chiral-price-color;
    line-height: 26px;
    text-align: right;
    .tuan-icon {
      font-size: 26px;
    }
    .delete-price {
      color: rgba(51, 51, 51, 0.3);
      font-size: 16px;
      font-weight: 400;
      text-decoration: line-through;
    }
    .price-unit {
      font-size: 16px;
      line-height: 14px;
    }
    .free {
      font-size: 20px;
      font-weight: 400;
      line-height: 26px;
      color: $chiral-free-color;
    }
  }
  @media (max-width: 767px) {
    .cardcommon__price {
      font-size: 0.32rem;
      line-height: 0.6933rem;
      .price-unit {
        font-size: 0.32rem;
      }
      .free {
        font-size: 0.32rem;
        line-height: 0.6933rem;
      }
    }
  }
}
</style>
