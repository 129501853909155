<!-- 登录弹窗 -->
<template>
  <el-dialog
    class="LoginDialog--modal"
    :visible.sync="modal"
    title=""
    :loading="loading"
    :width="width"
    :append-to-body="false"
    @closed="removeDom"
  >
    <!-- <a class="el-dialog__headerbtn" @click="close"
      ><i class="el-dialog__close el-icon el-icon-close"></i
    ></a> -->
    <div
      class="LoginDialog"
      :class="{ 'LoginDialog--mobile': isMobile }"
      :style="loginDialogBg"
    >
      <div
        class="mobile-bg visible-xs"
        v-if="loginInfo.picMobile"
        :style="'background-image:url(' + loginInfo.picMobile + ');'"
      ></div>
      <div class="logo-container">
        <img src="@/assets/img/chirallogo@2x.png" alt="logo" />
        <p class="info">开罗尔 — 连接你与竞赛的桥梁</p>
      </div>
      <div class="login-page-wrap">
        <!-- 切换登录方式 pc-->
        <div class="flex-wrp toggle-login-wrap-pc" v-if="!isMobile">
          <div>
            <el-button
              type="text"
              class="toggle-login btn-text"
              :class="{ active: isByMsg }"
              @click.stop="toggleLogin(true)"
            >
              验证码登录
            </el-button>
            <el-button
              type="text"
              class="toggle-login"
              :class="{ active: !isByMsg }"
              @click.stop="toggleLogin(false)"
            >
              密码登录
            </el-button>
          </div>
          <!-- <div
            class="active-line"
            :style="{
              left: isByMsg ? '0' : '119px',
              width: isByMsg ? '82px' : '66px',
            }"
          ></div>
          <hr class="divider-h hidden-xs" /> -->
        </div>
        <!-- 切换登录方式 mobile-->
        <!-- <h4
          class="visible-xs text-center"
          style="font-size: 18px"
          v-if="isMobile"
        >
          登录
        </h4> -->
        <div class="flex-wrp toggle-login-wrap" v-if="isMobile">
          <el-button
            type="text"
            class="toggle-login btn-text"
            :class="{ active: isByMsg }"
            @click.stop="toggleLogin(true)"
          >
            验证码登录
          </el-button>
          <!-- <div class="divider-v" /> -->
          <el-button
            type="text"
            class="toggle-login"
            :class="{ active: !isByMsg }"
            @click.stop="toggleLogin(false)"
          >
            密码登录
          </el-button>
        </div>
        <!-- 手机号验证码登录 -->
        <login-code
          v-show="isByMsg"
          class="msg-panel"
          register-position="欢迎页"
          @login-success="loginSuccess"
          :isDialog="true"
        />
        <!-- 账号密码登录 -->
        <login-password
          v-show="!isByMsg"
          register-position="欢迎页"
          @login-success="loginSuccess"
          :isDialog="true"
        />
        <UserAgreement :autoRegTip="isByMsg" />
        <div class="hidden-xs" style="height: 24px"></div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import UserAgreement from "./UserAgreement.vue";
import LoginCode from "./LoginCode.vue";
import LoginPassword from "./LoginPassword.vue";
import { isMobile } from "@/utils/utils";

export default {
  name: "LoginDialog",
  components: {
    LoginCode,
    UserAgreement,
    LoginPassword,
  },
  data() {
    return {
      isByMsg: true,
      modal: false,
      loading: false,
      width: "790px",
      isMobile: isMobile(),
      success: null,
      from: "",
      loginInfo: {
        picName: "",
        pic: "",
        picMobile: "",
      },
    };
  },
  computed: {
    loginDialogBg() {
      return this.loginInfo.pic
        ? "background-image:url(" + this.loginInfo.pic + ")"
        : "";
    },
  },
  methods: {
    //删除dom
    removeDom() {
      this.$el.parentNode.removeChild(this.$el);
    },
    toggleLogin(value) {
      this.isByMsg = value;
    },
    // from: [登录/注册|发帖|点赞|进行回复|评论|查看题目|私信|举报]
    show(params = { from: "", loginInfo: {} }, success) {
      this.from = params.from;
      this.loginInfo = params.loginInfo || {};
      if (window.innerWidth > 767) {
        this.isMobile = false;
        this.width = 594 + "px";
        // if (window.innerWidth > 780) {
        //   this.width = 780 + "px";
        // } else {
        //   this.width = window.innerWidth * 0.9 + "px";
        // }
      } else {
        this.width = "100%";
        this.isMobile = true;
      }
      this.modal = true;
      // this.setZhuGeEvent(this.zhugeEvents.PopupLoginRegister, [
      //   params.from,
      //   this.loginInfo.picName || "默认",
      // ]);
      if (success) {
        this.success = success;
      }
    },
    close() {
      this.modal = false;
      // this.setZhuGeEvent(this.zhugeEvents.PopupCloseClick, [
      //   this.from,
      //   "登录注册弹窗",
      // ]);
    },
    loginSuccess(data, mobile) {
      // if (process.env.NODE_ENV === 'development') {
      //   cookie.setCookie('Authorization', `Bearer${data.data.assess_token}`)
      // }
      this.$message.success("登录成功");
      this.modal = false;
      if (this.success) {
        this.success(mobile, data.data);
      } else {
        location.reload();
      }
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
$primary-color: #242424;
$white: #fff;
$title-color: #242424;
$error-color: #ef4d3c;

.LoginDialog--modal {
  .ivu-modal-content,
  .el-dialog {
    border-radius: 16px;
  }
  .el-dialog {
    @media screen and (max-width: 767px) {
      width: 90%;
      max-width: 350px;
      border-radius: 0.26rem;
      margin: 0;
      margin-top: 0 !important;
    }
  }
  .ivu-modal-body,
  .el-dialog__body {
    padding: 0;
  }

  .ivu-modal-footer,
  .el-dialog__header,
  .el-dialog__footer {
    display: none;
  }
}
.LoginDialog {
  width: 100%;
  max-width: 594px;
  // max-width: 780px;
  // background: url(../../assets/img/welcome-login-logo.png) no-repeat left center;
  background-size: 300px 100%;
  background-color: #fff;
  border-radius: 16px;
  .login-page-wrap {
    // margin-left: 300px;
    // margin-left: calc(100% - 480px);
    background: #fff;
    border-radius: 16px;
    // border-top-right-radius: 16px;
    // border-bottom-right-radius: 16px;
    height: 100%;
    padding: 40px 130px 27px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  // 切换登录方式
  .toggle-login-wrap {
    text-align: center;
    margin-bottom: 0.59rem;
    .el-button {
      margin-right: 2.08rem;
    }
    .el-button:last-child {
      margin-right: 0;
    }
  }
  .toggle-login-wrap-pc {
    justify-content: flex-start;
    align-items: center;
    margin: 0 36px 40px;
    flex-direction: column;
    display: none;
    position: relative;
    .active-line {
      position: absolute;
      bottom: 22px;
      transition: left 0.36s cubic-bezier(0.39, 0.58, 0.57, 1), width 0.1s ease;
      height: 2px;
      border-radius: 2px;
      background: $title-color;
    }
    @media screen and (min-width: 767px) {
      display: flex;
    }
    .divider-h {
      width: 100%;
      height: 1px;
      border: none;
      background: rgba($primary-color, 0.24);
      border-radius: 2px;
      margin: 22px 0;
    }
    button.toggle-login:nth-child(1) {
      margin-right: 78px;
    }
  }
  button.toggle-login {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #8b8f97;
    line-height: 22px;
    margin: 0;
    height: auto;
    background: transparent;
    padding: 0;
    &.active {
      position: relative;
      color: $primary-color;
      font-weight: 600;
    }
    &.active::after {
      position: absolute;
      left: 50%;
      top: 90%;
      transform: translate(-50%, 0);
      border-radius: 1px;
      content: "";
      display: block;
      width: 66px;
      height: 4px;
      border-radius: 2px;
      background: rgba($color: $chiral-btn-color, $alpha: 0.5);
    }
  }

  // 输入框
  .form-control {
    padding: 0;
    background: transparent;
    border: none;
    box-shadow: none;
    height: 48px;
  }
  .form-control input {
    font-size: 15px;
    font-weight: 500;
    color: $primary-color;
    line-height: 15px;
    background: $white;
    border-radius: 8px;
    padding: 16px 24px;
    border-color: transparent;
    background: #f5f6f6;
    height: 42px;
    &::placeholder {
      color: rgba(36, 36, 36, 0.24);
      font-weight: normal;
    }
  }
  .el-form-item-error input {
    border: 1px solid $error-color;
  }
  .el-form-item-error-tip {
    position: absolute;
    padding-left: 10px;
    padding-top: 4px;
    position: absolute;
    top: 100%;
    left: 0;
    line-height: 1;
    color: #ed4014;
  }
  .btn-primary {
    border-color: $chiral-btn-color;
  }
  .btn-primary.is-disabled {
    background: #cbcbcb;
    border-color: #cbcbcb;
  }
}
.logo-container {
  margin: auto;
  width: fit-content;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #161515;
  line-height: 20px;
  img {
    margin: 57px 0px 17px;
    width: 300px;
  }
}
@media (max-width: 767px) {
  .logo-container {
    font-size: 0.22rem;
    line-height: 0.32rem;
    img {
      margin: 0.72rem 0px 0.27rem;
      width: 200px;
    }
  }
}
// 适配手机端
.LoginDialog--mobile {
  width: 100%;
  .mobile-bg {
    width: 100%;
    height: 100px;
    position: absolute;
    top: -100px;
    border-radius: 16px 16px 0 0;
    background: transparent no-repeat center center;
    background-size: cover;
  }
  .login-page-wrap {
    padding: 0.61rem 0.61rem 0.8rem;
  }
}
</style>
<style>
@media screen and (max-width: 767px) {
  .el-dialog__wrapper.LoginDialog--modal {
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: static;
  }
}
</style>
