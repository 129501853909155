import Vue from "vue";
import Confirm from "./Main.vue";

// 组件构造器，构造出一个 vue组件实例
const ConfirmConstructor = Vue.extend(Confirm);
/*

from_source 订单来源 1:购课单 3:课程详情页  4:拼团活动页 5:联报活动页
*/
function ConfirmOrder(classList, $id, groupInfo, from_source = 3) {
  const dom = new ConfirmConstructor({
    el: document.createElement("div"),
    data() {
      return {
        showOrder: false,
        classList: [],
        groupInfo: null,
        from_source: 3,
      };
    },
  });
  dom.classList = classList;
  dom.showOrder = true;
  dom.groupInfo = groupInfo || null;
  dom.from_source = from_source;
  if ($id) {
    document.getElementById($id).appendChild(dom.$el);
  } else {
    document.body.appendChild(dom.$el);
  }
}

// 全局注册
export default () => {
  Vue.prototype.$confirmOrder = ConfirmOrder;
};
