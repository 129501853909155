import Vue from "vue";
import {
  Loading,
  Empty,
  Checkbox,
  Button,
  Row,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Dialog,
  Form,
  FormItem,
  Input,
  Message,
  Skeleton,
  SkeletonItem,
  Drawer,
  Progress,
  Pagination,
  TabPane,
  Tabs,
  Popover,
  RadioGroup,
  Radio,
  Select,
  Table,
  TableColumn,
  Collapse,
  Option,
  CollapseItem,
  Switch,
  MessageBox,
  Cascader,
  CascaderPanel,
} from "element-ui";
// import "element-ui/lib/theme-chalk/index.css";
Vue.use(Loading);
Vue.use(Empty);
Vue.use(Cascader);
Vue.use(CascaderPanel);
Vue.use(Option);
Vue.use(Switch);
Vue.use(Checkbox);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Button);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Select);
Vue.use(Row);
Vue.use(TabPane);
Vue.use(Tabs);
Vue.use(Col);
Vue.use(Popover);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Dialog);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Skeleton);
Vue.use(SkeletonItem);
Vue.use(Drawer);
Vue.use(Progress);
Vue.use(Pagination);
Vue.prototype.$message = Message;
Vue.use(Loading.directive);
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$confirm = MessageBox.confirm;

Vue.prototype.$loading = Loading.service;
