function keyMirror(obj) {
  if (obj instanceof Object) {
    const _obj = Object.assign({}, obj);
    const _keyArray = Object.keys(obj);
    _keyArray.forEach((key) => {
      _obj[key] = key;
    });
    return _obj;
  }
}

export default keyMirror({
  CHANGE_PROFILE: null,
  CHANGE_MODAL_STATUS: null,
  UPDATE_WEBSITE_CONF: null,
  NOW: null,
  NOW_ADD_1S: null,
  CHANGE_CONTEST: null,
  CHANGE_CONTEST_PROBLEMS: null,
  CHANGE_CONTEST_ITEM_VISIBLE: null,
  CHANGE_RANK_FORCE_UPDATE: null,
  CHANGE_CONTEST_RANK_LIMIT: null,
  CONTEST_ACCESS: null,
  CLEAR_CONTEST: null,
  USER_MESSAGE: null,
  RECEIVE_USER: null,
  CHANGE_TASK_COUNT: null,
  USER_RANK_DATA: null,
  ACTIVITY_LIVES_CHAT: null,
  CHANGE_LIVE_LIST: null,
  RECEIVE_RANKINGANDAC: null,
  PROBLEM_INFO: null,
  SUBMISSION_EXISTS: null,
  CONTEST_RANK: null,
  CONTEST_SIGN_UP: null,
  CONTEST_TOTAL: null,
  IS_UPDATE_CONTEST_PROBLEMS: null,
  RECEIVE_NOTIFY_LIST: null,
  CHANGE_CATEGORY: null,
  SHOW_DISCUSS_EDITOR: null,
  SEARCH_TITLE: null,
  SEARCH_TYPE: null,
  MESSAGE_STATE: null,
  CHANGE_MESSAGE_STATE: null,
  OPEN_DRAINAGE: null,
});
