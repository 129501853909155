<template>
  <div class="course-nav">
    <ul v-if="subjectList">
      <li>学科：</li>
      <li
        v-for="(item, index) in subjectList"
        :key="index"
        class="nav-item"
        :class="subject === index ? 'activity' : ''"
        @click="changeSubject(index)"
      >
        {{ item }}
      </li>
    </ul>
  </div>
</template>
<script>
import { SUBJECT } from "@/utils/constants.js";
export default {
  name: "subjectNav",
  props: {
    defaultSubject: {
      type: String,
      default: "PHY",
    },
  },
  data() {
    return {
      subjectList: SUBJECT,
    };
  },
  computed: {
    subject: {
      get() {
        return this.defaultSubject;
      },
      set(val) {
        this.$emit("change", val);
      },
    },
  },
  methods: {
    changeSubject(index) {
      this.subject = index;
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.course-nav {
  ul {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    margin-bottom: 22px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    li:nth-child(1) {
      min-width: 70px;
    }
    @media (max-width: 767px) {
      li:nth-child(1) {
        min-width: auto;
      }
    }
    .nav-item {
      margin-right: 40px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
      cursor: pointer;
      border-radius: 4px;
      padding: 4px 12px;
    }
    @media (max-width: 767px) {
      .nav-item {
        margin-right: 6px;
      }
    }
    .activity {
      background: #f4f2ff;
      color: $chiral-btn-color;
    }
  }
  ul:nth-last-child(1) {
    margin-bottom: 0px;
  }
}
</style>
