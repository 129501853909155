<template>
  <div class="content">
    <div class="lecture-center">
      <!-- 讲座筛选 -->
      <div class="course-nav">
        <ul v-if="navMap">
          <li>学科：</li>
          <li
            v-for="(item, index) in navMap"
            :key="index"
            class="nav-item"
            :class="defaultSubject === index ? 'activity' : ''"
            @click="changeSubject(index)"
          >
            {{ item.name }}
          </li>
        </ul>
        <ul v-if="navMap">
          <li>课程阶段：</li>
          <li
            class="nav-item"
            @click="changeStage('')"
            :class="!defaultStage ? 'activity' : ''"
          >
            全部
          </li>
          <li
            v-for="(item, index) in navMap[defaultSubject].stageList"
            :key="index"
            class="nav-item"
            :class="defaultStage === item.id ? 'activity' : ''"
            @click="changeStage(item.id)"
          >
            {{ item.label_name }}
          </li>
        </ul>
      </div>
      <!-- 课程列表 -->
      <h3 class="lecture-title" v-if="courseList.length">讲座</h3>
      <div class="course-container">
        <el-row>
          <el-col
            :xs="24"
            :sm="12"
            :md="12"
            :lg="8"
            :xl="8"
            v-for="item in courseList"
            :key="item.class_id"
          >
            <course-card :classInfo="item" :courseUtm="courseUtm"></course-card>
          </el-col>
        </el-row>
      </div>
      <h3 class="lecture-title" v-if="overdueCourseList.length">往期讲座</h3>
      <div class="course-container">
        <el-row>
          <el-col
            :xs="24"
            :sm="12"
            :md="12"
            :lg="8"
            :xl="8"
            v-for="item in overdueCourseList"
            :key="item.class_id"
          >
            <course-card :classInfo="item" :courseUtm="courseUtm"></course-card>
          </el-col>
        </el-row>
      </div>
      <div
        class="content empty-content"
        v-if="!courseList.length && !overdueCourseList.length"
      >
        <empty-component></empty-component>
      </div>
    </div>
  </div>
</template>
<script>
import { SUBJECT } from "@/utils/constants.js";
import CourseCard from "@/components/course/CourseCard";
import api from "@/plugins/api";

export default {
  name: "LectureCenter",
  data() {
    return {
      defaultSubject: "PHY",
      defaultStage: "",
      subjectList: SUBJECT,
      navMap: null,
      labelsList: [{ name: "测试1", value: "1", subject: "PHY" }],
      courseList: [],
      overdueCourseList: [],
      page: 1,
      onPullDown: false,
      isEnd: false,
      pageSize: 12,
      courseUtm: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.sensorsTrack();
    });
  },
  methods: {
    sensorsTrack() {
      if (window.sensors?.track) {
        window.sensors.track("NavigationBarView", {
          XueKe: SUBJECT[this.defaultSubject],
          ButtonName: "讲座",
        });
      }
    },
    getUtmconfig() {
      let params = {
        type: "开罗尔官网",
        page_type: 3,
      };
      api.getUtmconfig(params).then((res) => {
        console.log("log", res);
        let item = res.data;
        if (item.code === 1) {
          if (item.list && item.list.length) {
            let utmParams = item.list[0];
            this.courseUtm = `?utm_campaign=${utmParams.utm_campaign}&utm_content=${utmParams.utm_content}&utm_medium=${utmParams.utm_medium}&utm_source=${utmParams.utm_source}`;
          }
        }
      });
    },
    getLectureList(isEnd, page = 1) {
      this.page = page;
      let params = {
        page: page,
        num: isEnd === 2 ? 50 : this.pageSize,
        type: "免费课",
        course_belong: "开罗尔",
        is_end: isEnd,
        subject_type: this.defaultSubject,
        labels: this.defaultStage,
      };
      if (isEnd === 1) {
        this.onPullDown = true;
      }
      api.getLectureList(params).then((res) => {
        if (res.data.code === 1) {
          if (isEnd === 2) {
            this.courseList = res.data.items;
          } else {
            this.isEnd = res.data.items.length < this.pageSize;
            this.page += 1;
            let courseItems = res.data.items || [];
            this.overdueCourseList.push(...courseItems);
          }
        }
        this.onPullDown = false;
      });
    },
    changeSubject(subject) {
      this.defaultSubject = subject;
      this.defaultStage = "";
      this.initLectureList();
      this.sensorsTrack();
    },
    changeStage(stage) {
      this.defaultStage = stage;
      this.initLectureList();
    },
    handleNav() {
      let subjectMap = JSON.parse(JSON.stringify(this.subjectList));
      for (let k in subjectMap) {
        subjectMap[k] = {
          name: subjectMap[k],
          stageList: [],
        };
      }
      this.labelsList.forEach((item) => {
        for (let k in subjectMap) {
          if (item.subject.indexOf(k) > -1) {
            subjectMap[k].stageList.push(item);
          }
        }
      });
      this.navMap = subjectMap;
    },
    initLectureList() {
      this.overdueCourseList = [];
      this.getLectureList(1);
      this.getLectureList(2);
    },
    scrollLoadCourse() {
      var scrollTop =
        document.documentElement.scrollTop + document.body.scrollTop;
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      if (this.onPullDown || this.isEnd) {
        return false;
      }
      if (scrollTop + windowHeight + 500 > scrollHeight) {
        this.getLectureList(1, this.page);
      }
    },
    //获取课程阶段
    getClassLabels() {
      api.getClassLabels().then((res) => {
        let item = res.data;
        if (item.code === 1) {
          this.labelsList = item.items || [];
          this.handleNav();
        }
      });
    },
  },
  components: {
    "course-card": CourseCard,
  },
  mounted() {
    this.initLectureList();
    this.getClassLabels();
    this.getUtmconfig();
    window.addEventListener("scroll", this.scrollLoadCourse, true); // 监听（绑定）滚轮滚动事件
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollLoadCourse, true);
  },
};
</script>
<style lang="scss" scoped>
.lecture-center {
  .course-nav {
    padding: 38px 0px 44px;
    ul {
      display: flex;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      margin-bottom: 22px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      li:nth-child(1) {
        min-width: 70px;
      }
      @media (max-width: 767px) {
        li:nth-child(1) {
          min-width: auto;
        }
      }
      .nav-item {
        margin-right: 40px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        cursor: pointer;
        border-radius: 4px;
        padding: 4px 12px;
      }
      @media (max-width: 767px) {
        .nav-item {
          margin-right: 6px;
        }
      }
      .activity {
        background: #f4f2ff;
        color: $chiral-btn-color;
      }
    }
    ul:nth-last-child(1) {
      margin-bottom: 0px;
    }
  }
  @media (max-width: 767px) {
    .course-nav {
      padding: 0.64rem 0px 0.8rem;
    }
  }
  .lecture-title {
    font-size: 24px;
    font-weight: 500;
    color: $chiral-color;
    line-height: 24px;
    margin-bottom: 26px;
  }
  @media (max-width: 767px) {
    .lecture-title {
      font-size: 0.4267rem;
      font-weight: 600;
      margin-bottom: 0.5067rem;
    }
  }
  .course-container {
    margin: 0px -8.5px;
    display: flex;
    flex-wrap: wrap;
    .el-row {
      width: 100%;
      flex-wrap: wrap;
      display: flex;
    }
    .course-card {
      margin: 0px 8.5px;
      margin-bottom: 26px;
    }
    .course-card:hover {
      transform: translateY(-5px);
      transition: 0.3s;
    }
  }
  .empty-content {
    background: $avatar-color;
    margin-bottom: 20px;
  }
}
</style>
