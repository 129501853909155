<!-- 移动端底部购课区域 -->
<template>
  <div class="mobile-buy-area">
    <!-- 免费课 -->
    <div v-if="!classInfo.price" class="btn-area">
      <div class="course-info">免费课程</div>
      <button class="bg-chiral-btn" @click="booking('free')">
        {{ buyBtnText }}
      </button>
    </div>
    <!-- 付费课 -->
    <div v-else>
      <!-- 团购课 -->
      <div v-if="groupTag">
        <!-- 已付款，还差n人成团 -->
        <div v-if="userGroup.order_state === 2" class="group-course-area">
          <p class="group-price">
            拼团价¥<span>{{ userGroup.group_price / 100 }}</span>
          </p>
          <button @click="booking('shareGroup')" class="group-btn">
            <span>{{
              `分享完成拼团（${userGroup.group_num - userGroup.rest_num}/${
                userGroup.group_num
              }）`
            }}</span>
            <count-down
              key="userGroup"
              :endTime="userGroup.group_end_at"
              countdownText="剩余"
              :type="2"
            />
          </button>
        </div>
        <!-- 未购买 -->
        <div v-else>
          <!-- 有团购结束时间 -->
          <div v-if="classInfo.group_end_time" class="group-course-area">
            <count-down
              class="class-group-count"
              key="classGroup"
              :endTime="classInfo.group_end_time"
              countdownText="距离团购结束还剩"
              :type="2"
            />
            <div class="group-btn-wrap">
              <button
                @click="booking('narmalPay')"
                class="group-btn-white two-btn-left"
              >
                <span>¥{{ classInfo.price / 100 }}</span>
                <span>原价报名</span>
              </button>
              <button
                @click="booking('byGroup')"
                class="group-btn two-btn-right"
              >
                <span>¥{{ classInfo.group_price / 100 }}</span>
                <span>发起拼团</span>
              </button>
            </div>
          </div>
          <!-- 无团购结束时间 -->
          <div v-else class="group-course-area">
            <div class="left-area">
              <p class="group-price">
                拼团价¥<span>{{ classInfo.group_price / 100 }}</span>
              </p>
              <p class="price">原价¥{{ classInfo.price / 100 }}</p>
            </div>
            <div class="group-btn-wrap">
              <button
                @click="booking('narmalPay')"
                class="group-btn-white two-btn-left"
              >
                原价报名
              </button>
              <button
                @click="booking('byGroup')"
                class="group-btn two-btn-right"
              >
                发起拼团
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- 普通付费课 -->
      <div v-else class="pay-course-area">
        <div class="left-area">
          <p v-if="classInfo.virtual_price" class="virtual-price">
            原价¥{{ classInfo.virtual_price / 100 }}
          </p>
          <p class="price">
            ¥<span>{{ classInfo.price / 100 }}</span>
          </p>
        </div>
        <button @click="booking('narmalPay')" class="bg-chiral-btn">
          {{ notPurchaseNum ? `报名剩余章节(${notPurchaseNum})` : "立即报名" }}
        </button>
      </div>
    </div>
    <group-list :groupList="gropPriceList" ref="groupList" />
  </div>
</template>

<script>
import GroupList from "@/views/course/components/GroupList.vue";
import CountDown from "@/views/course/components/CountDown.vue";
import booking from "@/mixins/booking";
export default {
  name: "MobileBuyArea",
  mixins: [booking],
  components: {
    "count-down": CountDown,
    "group-list": GroupList,
  },
  props: {
    notPurchaseNum: {
      type: Number,
      default: null,
    },
    buyBtnText: {
      type: String,
      default: "",
    },
    classInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    groupTag: {
      type: Boolean,
      default: false,
    },
    userGroup: {
      type: Object,
      default() {
        return {};
      },
    },
    gropPriceList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 767px) {
  .mobile-buy-area {
    font-size: 12px;
    width: 100%;
    position: fixed;
    bottom: 0px;
    background: $avatar-color;
    box-shadow: 0px -4px 8px -3px rgba(190, 190, 190, 0.5);
    z-index: 2;
  }
  // 免费课
  .btn-area {
    display: flex;
    .course-info {
      flex: 1;
      text-align: right;
      font-size: 0.32rem;
      font-weight: 500;
      color: $chiral-btn-color;
      line-height: 0.3733rem;
      padding: 0.48rem 0.56rem 0.48rem 0px;
    }
  }
  //团购课
  .class-group-count.countdown {
    flex-direction: column;
    ::v-deep .count-text {
      color: $chiral-virtual-price-color !important;
    }
  }
  .group-btn-wrap {
    display: flex;
    .two-btn-left {
      border-radius: 0.11rem 0 0 0.11rem;
    }
    .two-btn-right {
      border-radius: 0 0.11rem 0.11rem 0;
    }
  }
  .group-course-area {
    display: flex;
    justify-content: space-between;
    padding: 0.21rem 0.4rem;
    align-items: center;

    .group-price {
      color: #ef4d3c;
      span {
        font-size: 0.53rem;
      }
    }
    .price {
      color: $chiral-virtual-price-color;
      line-height: 0.53rem;
    }
  }
  // 普通付费课
  .pay-course-area {
    display: flex;
    padding-left: 0.53rem;
    justify-content: space-between;

    .virtual-price {
      color: $chiral-virtual-price-color;
      text-decoration: line-through;
      line-height: 0.53rem;
      font-weight: 600;
    }
    .price {
      color: $chiral-price-color;
      span {
        font-size: 0.48rem;
      }
    }
  }

  // 公共
  .left-area {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .bg-chiral-btn {
    padding: 0.48rem 0.7733rem;
    background: $chiral-btn-color;
    color: $avatar-color;
    font-size: 0.4rem;
    font-weight: 500;
    line-height: 0.3733rem;
  }
  .group-btn-white {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.11rem;
    border: 1px solid #ef4d3c;
    min-width: 2.93rem;
    min-height: 1.28rem;
    background: transparent;
    color: #ef4d3c;
    font-size: 0.37rem;
    font-weight: 500;
    padding: 0.13rem 0.27rem;
  }
  .group-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.11rem;
    min-width: 2.93rem;
    min-height: 1.28rem;
    background: #ef4d3c;
    color: #fff;
    font-size: 0.37rem;
    font-weight: 500;
    padding: 0.13rem 0.27rem;
  }
}
</style>
