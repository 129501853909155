<!-- 短信验证码登录 -->
<template>
  <div class="loginCode-components">
    <el-form class="form-container">
      <!-- 手机号 -->
      <el-form-item
        class="phone-wrap"
        :class="{ 'el-form-item-error': !!phonetip }"
      >
        <el-input
          v-model="phone"
          class="form-control phone"
          placeholder="请填写手机号码"
          maxlength="11"
          @input="bindInputPhone"
        />
        <div class="el-form-item-error-tip" v-show="phonetip">
          {{ phonetip }}
        </div>
      </el-form-item>
      <!-- 验证码 -->
      <el-form-item :class="{ 'el-form-item-error': codetip }">
        <div class="clearfix">
          <el-input
            v-model="code"
            class="form-control code"
            placeholder="请填写验证码"
            maxlength="4"
            @input="bindInputCode"
          />

          <el-button
            size="large"
            class="btn-send-code btn-primary"
            type="primary"
            :disabled="sending"
            @click.stop="sendCode"
          >
            {{ buttonText }}
          </el-button>
        </div>
        <div class="el-form-item-error-tip" v-show="codetip">{{ codetip }}</div>
      </el-form-item>
      <el-button
        size="large"
        formType="submit"
        class="submit btn-primary"
        type="primary"
        :loading="logining"
        @click.stop="formSubmit"
      >
        {{ submitButtonText ? submitButtonText : "登录" }}
      </el-button>
    </el-form>
  </div>
</template>

<script>
import { vailPhone, parse } from "@/utils/utils";
import api from "@/plugins/api";
import loginHandle from "@/mixins/login-handle";

export default {
  name: "LoginCode",
  mixins: [loginHandle],
  props: {
    submitButtonText: {
      type: String,
      default: "",
    },
    registerPosition: {
      type: String,
      default: "",
    },
    isDialog: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      phone: "",
      code: "",
      verify: "",
      checkphone: true,
      checkcode: true,
      checkpic: true,
      sending: false,
      buttonText: "获取验证码",
      time: 60,
      needVerify: false,
      codeurl: "",
      phonetip: "",
      codetip: "",
      buttonTimer: null,
      logining: false,
    };
  },
  computed: {
    checksubmit() {
      if (this.needVerify) {
        return this.checkphone || this.checkcode || this.checkpic;
      }
      return this.checkphone || this.checkcode;
    },
  },
  beforeDestroy() {
    clearInterval(this.buttonTimer);
  },
  methods: {
    formSubmit() {
      if (!this.phone) {
        this.phonetip = "请填写手机号";
        return false;
      } else if (this.checkphone) {
        this.phonetip = "手机号码格式错误";
        return false;
      } else if (!this.code) {
        this.codetip = "请填写验证码";
        return false;
      } else if (this.checkcode) {
        this.codetip = "验证码为4位数字";
        return false;
      }
      this.loginAction();
      return true;
    },
    bindInputPhone() {
      this.checkphone = !vailPhone(this.phone);
      if (this.checkphone && this.phone.length === 11) {
        this.phonetip = "";
      } else {
        this.phonetip = "";
      }
    },
    bindInputCode() {
      this.checkcode = this.code.length !== 4;
      this.codetip = "";
    },
    bindInputVerify() {
      this.checkpic = this.verify.length !== 5;
      this.pictip = "";
    },
    sendCode() {
      if (this.checkphone) {
        this.phonetip = "手机号码格式错误";
        return;
      }
      const params = {
        mobile: this.phone,
        code: this.verify,
        scene: "quick-login",
        need_check: 0,
      };
      this.sending = true;
      api.getSms(params).then((data) => {
        if (data.data.code === 1) {
          this.eventGetCode();
          this.$message.success("验证码已发送");
          this.timer();
        } else {
          if (data.data.code === 30000) {
            this.loadImage();
            this.sending = false;
            this.$message.warning("需要图形验证码");
            return false;
          }
          if (data.data.code === 30001) {
            this.pictip = true;
            this.checkcode = true;
            this.sending = false;
            this.loadImage();
            return false;
          }
          this.$message.error(data.data.msg);
          this.sending = false;
        }
        return true;
      });
    },
    timer() {
      this.buttonTimer = setInterval(() => {
        if (this.time < 1) {
          clearInterval(this.buttonTimer);
          this.buttonText = "获取验证码";
          this.sending = false;
          this.time = 60;
        } else {
          this.time = this.time - 1;
          this.buttonText = `${this.time}s后重新发送`;
        }
      }, 1000);
    },
    // 阿里图形验证码
    loadImage() {},
    loginAction() {
      this.logining = true;
      const dataOpt = parse(window.location.search.substring(1)) || {};
      const params = {
        auto_login: 1,
        action: "quick-login",
        mobile: this.phone,
        mobile_code: this.code,
        product_name: "开罗尔",
        registe_method: "快捷注册",
        register_position: this.registerPosition,
        login_method: "验证码登录",
        // pic_code: this.pic_code, 图形验证码
        // is_utm=0或不传，不要=1
        utm_source: dataOpt.utm_source || "",
        utm_campaign: dataOpt.utm_campaign || "",
        utm_medium: dataOpt.utm_medium || "",
        utm_content: dataOpt.utm_content || "",
      };
      api.loginByCode(params).then((data) => {
        this.logining = false;
        if (data.data.code === 1) {
          // 登录成功
          this.$emit("login-success", data.data, this.phone);
          if (!this.isDialog) {
            this.toPreRoute();
          }
        } else {
          this.$message.error(data.data.message);
        }
        return true;
      });
    },
    eventGetCode() {},
    eventSignIn() {
      // Taro.reportAnalyticsRewrite('signin', {
      //   mobilephone: this.phone,
      // });
    },
  },
};
</script>

<style lang="scss" src="./common.scss" scoped></style>
<style lang="scss" scoped>
$primary-color: #242424;
$white: #fff;
$error-color: #ef4d3c;
.loginCode-components {
  .input-placeholder {
    font-size: 15px;
    font-weight: 400;
    color: rgba($primary-color, 0.24);
    line-height: 21px;
  }

  .submit {
    margin: 24px 0 20px;
    border-radius: 8px;
    width: 100%;
    background-color: $chiral-btn-color;
    font-size: 15px;
    line-height: 15px;
    font-weight: 500;
    color: $white;
    padding: 16px 0;
    height: 48px;
  }
  .clearfix {
    &::after {
      display: table;
      content: " ";
      clear: both;
    }
  }
  .form-control.code {
    float: left;
    width: calc(100% - 115px - 16px);
  }
  .verify {
    width: 64%;
    display: inline-block;
  }
  .verifypic {
    width: 100px;
    height: 40px;
    margin-left: 10px;
  }
  .btn-send-code {
    background-color: $chiral-btn-color;
    border-radius: 8px;
    font-size: 15px;
    line-height: 15px;
    font-weight: 400;
    color: $white;
    width: 115px;
    padding: 13px 0;
    float: right;
    height: 42px;
  }
  .el-button--primary:focus,
  .el-button--primary:hover {
    background: $chiral-btn-color;
    border-color: $chiral-btn-color;
  }
  .el-button--primary.is-disabled,
  .el-button--primary.is-disabled:active,
  .el-button--primary.is-disabled:focus,
  .el-button--primary.is-disabled:hover {
    background: #cbcbcb;
    border-color: #cbcbcb;
  }
  .tips {
    color: #fd8216;
    font-size: 26px;
  }
  .checkerr {
    border-bottom: 2px solid #fd8216;
  }
  .el-form-item-error-tip {
    position: absolute;
    padding-left: 10px;
    padding-top: 4px;
    position: absolute;
    top: 100%;
    left: 0;
    line-height: 1;
    color: #ed4014;
  }
}
// 适配移动端
@media screen and (max-width: 767px) {
  .loginCode-components .submit {
    margin: 0.2rem 0 0.4rem;
  }
}
</style>
<style lang="scss">
.loginCode-components {
  .el-form-item-error input {
    border: 1px solid $error-color;
  }
}
</style>
