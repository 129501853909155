<template>
  <Login-page-wrap class="login-page" :formTitle="formTitle">
    <register-form :type="type" />
    <UserAgreement :autoRegTip="false" />
  </Login-page-wrap>
</template>

<script>
import LoginPageWrap from "@/components/login/LoginPageWrap.vue";
import UserAgreement from "@/components/login/UserAgreement.vue";
import RegisterForm from "@/components/login/RegisterForm.vue";
export default {
  name: "Register",
  components: {
    LoginPageWrap,
    UserAgreement,
    RegisterForm,
  },
  data() {
    return {
      activeId: 1,
      formTitle: [],
      type: "",
    };
  },
  created() {
    if (this.$route.params.type === "reg") {
      this.formTitle = [{ id: 1, title: "账号注册" }];
      this.type = "register";
    }
    if (this.$route.params.type === "retrieve") {
      this.formTitle = [{ id: 1, title: "找回密码" }];
      this.type = "reset-password";
    }
  },
};
</script>
