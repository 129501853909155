<!-- 订单列表-每条订单 -->
<template>
  <div class="order-item">
    <!-- 头 -->
    <div class="order-top">
      <div class="order-info">
        <i class="chiral fa-tuan" v-if="isGroup"></i>
        <span class="order-no" @click="goOrderDetail(item.order_no)"
          >订单号：{{ item.order_no }}</span
        >
        <span v-if="!isMobile" class="order-"
          >下单时间：{{
            moment(item.order_at * 1000).format("YYYY-MM-DD HH:mm:ss")
          }}</span
        >
      </div>
      <div class="order-state" :class="stateClass">
        <i class="status-divider-v"></i>
        <span>{{ ORDER_STATE[item.state] }}</span>
        <!-- 待支付--倒计时 -->
        <span v-if="item.state === 1">剩余 {{ payCountDown(timestamp) }}</span>
      </div>
    </div>
    <!-- 内容 -->
    <div class="order-cont">
      <ul class="order-course">
        <li
          v-for="course in item.various.slice(0, 4)"
          :key="course.goods_id"
          @click="goCourseDetail(course)"
        >
          <img
            v-if="!isMobile"
            class="course-img"
            :src="course.cover || require('@/assets/img/live/course.png')"
            alt="课程图片"
          />
          <p>{{ course.subject }}</p>
        </li>
        <li v-if="item.various.length > 4">
          ...余{{ item.various.length - 4 }}项
        </li>
      </ul>
      <div class="order-price" v-if="!isMobile">
        <span v-if="isGroup" class="total-price"
          >¥{{ item.total_price / 100 }}</span
        >
        <span class="real-price group-real-price"
          >¥{{ item.real_price / 100 }}</span
        >
        <span v-if="isGroup" class="group-price-text">团购价</span>
      </div>
      <div class="order-price" v-else>
        <p>
          <span v-if="isGroup" class="group-price-text">团购价</span>
          <span class="real-price group-real-price"
            >¥{{ item.real_price / 100 }}</span
          >
        </p>
        <span v-if="isGroup" class="total-price"
          >¥{{ item.total_price / 100 }}</span
        >
      </div>
      <!-- 操作列 -->
      <div class="order-operate" v-if="!isMobile">
        <el-button
          v-if="item.state === 1"
          @click="goPay(item.order_no)"
          type="primary"
          size="mini"
          >去支付</el-button
        >
        <el-button
          v-if="showShareGroupBtn"
          @click="shareGroup(item.various[0].activity_id)"
          class="share-btn"
          type="primary"
          size="mini"
          >分享团购信息</el-button
        >
        <el-button
          v-if="item.state === 1"
          @click="cancePay(item.order_no)"
          class="cancel-btn"
          type="text"
          size="mini"
          >取消</el-button
        >
        <el-button
          class="detail-btn"
          type="text"
          size="mini"
          @click="goOrderDetail(item.order_no)"
          >订单详情</el-button
        >
      </div>
      <div class="order-operate" v-else>
        <el-button
          v-if="item.state === 1"
          @click="cancePay(item.order_no)"
          class="cancel-btn"
          type="text"
          size="mini"
          >取消</el-button
        >
        <el-button
          class="detail-btn"
          type="text"
          size="mini"
          @click="goOrderDetail(item.order_no)"
          >订单详情</el-button
        >
        <el-button
          v-if="item.state === 1"
          @click="goPay(item.order_no)"
          type="primary"
          size="mini"
          >去支付</el-button
        >
        <el-button
          v-if="showShareGroupBtn"
          @click="shareGroup(item.various[0].activity_id)"
          class="share-btn"
          type="primary"
          size="mini"
          >分享团购信息</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import orderHandle from "@/mixins/order-handle";
import { cancelOrder } from "@/api/my-order.js";
import { isMobile } from "@/utils/utils";
import moment from "moment";
import { ORDER_STATE } from "@/utils/constants.js";
export default {
  name: "OrderItem",
  mixins: [orderHandle],
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      moment,
      ORDER_STATE,
      timestamp: "", //订单支付倒计时
      timer: null,
      isMobile: isMobile(),
    };
  },
  methods: {
    cancePay(orderNo) {
      this.$showDialog("确认取消订单", () => {
        cancelOrder({ order_no: orderNo }).then((res) => {
          if (res.code === 1) {
            this.$message.success("取消订单成功");
            this.$emit("cancelPay");
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    // 支付倒计时
    startTimer() {
      if (Number(this.item.state) !== 1) return;
      const payEndTime = Number(this.item.order_at) + 30 * 60;
      this.timestamp =
        Math.floor(payEndTime) - Math.floor(new Date().getTime() / 1000);
      if (Number(this.timestamp) < 1) {
        this.item.state = 3;
      }
      this.timer = setInterval(() => {
        if (Number(this.timestamp) < 1) {
          this.item.state = 3;
          clearInterval(this.timer);
        } else {
          this.timestamp -= 1;
        }
      }, 1000);
    },
    // 跳转详情
    goOrderDetail(orderNo) {
      this.$router.push({
        path: `/order-detail/${orderNo}`,
      });
    },
  },
  mounted() {
    // 开始订单倒计时
    if (this.item.state === 1) {
      this.startTimer();
    }
  },
  computed: {
    // 分享团购按钮是否展示
    showShareGroupBtn() {
      let btnState = false;
      if (this.item.state === 2 && this.item.various.length) {
        let variou = this.item.various[0];
        btnState =
          variou.activity_type === 1 &&
          variou.activity_id &&
          variou.activity_info?.state === 1;
      }
      return btnState;
    },
    // 是否为团购课
    isGroup() {
      return this.item.various.some((item) => {
        return (
          item.goods_type === 2 && item.activity_id && item.activity_type === 1
        );
      });
    },
    // 订单状态样式
    stateClass() {
      if ([1, 5].includes(this.item.state)) return "text-warning";
      if ([2].includes(this.item.state)) return "text-success";
      return "text-default";
    },
  },
  beforeDestroy() {
    this.timer && clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
.order-item {
  font-size: 12px;
  margin-bottom: 25px;
  border-radius: 5px;
  border: 1px solid rgba(51, 51, 51, 0.1);
  .order-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    color: rgba(44, 45, 46, 0.5);
    background-color: #fafafa;
    border-bottom: 1px solid rgba(51, 51, 51, 0.1);
  }
  .order-info {
    .fa-tuan {
      font-size: 24px;
      color: red;
      vertical-align: middle;
      margin-right: 6px;
    }
    .order-no {
      cursor: pointer;
      margin-right: 6px;
    }
    .order-no:hover {
      text-decoration: underline;
    }
  }
  .order-state {
    .status-divider-v {
      display: inline-block;
      width: 2px;
      height: 12px;
      border-radius: 1px;
      vertical-align: middle;
      margin: -3px 5px 0;
    }
  }
  //   内容
  .order-cont {
    display: flex;
  }
  //   .order-cont > ul,
  //   div {
  //     padding: 20px;
  //     // border
  //   }
  .order-course {
    display: flex;
    flex: 1;
    align-items: center;
    margin: 20px;
    img {
      width: 140px;
    }
    li {
      cursor: pointer;
      margin-right: 24px;
    }
    p {
      line-height: 30px;
      width: 140px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
    }
  }
  .order-price {
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-left: 1px solid rgba(51, 51, 51, 0.1);
    border-right: 1px solid rgba(51, 51, 51, 0.1);
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
  }
  .total-price {
    color: rgba(51, 51, 51, 0.3);
    text-decoration: line-through;
  }
  .real-price {
    color: #ffa50d;
  }
  .group-price-text {
    text-align: center;
    width: 40px;
    height: 16px;
    font-size: 12px;
    color: #ef4d3c;
    background: rgba(239, 77, 60, 0.12);
  }
  .group-real-price {
    color: #ef4d3c;
  }
  .order-operate {
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    .el-button {
      font-size: 14px;
      margin-left: 0px;
    }
    .cancel-btn {
      color: $chiral-virtual-price-color;
    }
    .detail-btn {
      color: $chiral-text-color;
    }
    .share-btn,
    .share-btn:hover {
      background-color: #ef4d3c !important;
      color: #fff;
      border: none;
    }
    // ::v-deep .share-btn:hover {
    //   background-color: #ef4d3c;
    //   color: #fff;
    // }
  }

  .text-warning {
    color: #ffc954;
    i {
      background: #ffc954;
    }
  }
  .text-success {
    color: $chiral-btn-color;
    i {
      background: $chiral-btn-color;
    }
  }
  .text-default {
    color: #c1c1c1;
    i {
      background: #c1c1c1;
    }
  }
}

@media (max-width: 1200px) {
  .order-course {
    width: 330px;
    flex-wrap: wrap;
  }
}
@media (max-width: 767px) {
  .order-item {
    .order-cont {
      position: relative;
      flex-direction: column;
      padding: 0 0 50px 0;
    }
    .order-course {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      margin: 0;
      padding: 15px 20px;
      border-bottom: 1px solid rgba(51, 51, 51, 0.1);
    }
    .order-price {
      position: absolute;
      bottom: 0;
      left: 10px;
      width: auto;
      height: 50px;
      border-left: none;
      border-right: none;
      padding: 0;
    }
    .order-operate {
      position: absolute;
      bottom: 0;
      right: 10px;
      height: 50px;
      flex-direction: row;
      width: auto;
      padding: 0;
      .el-button {
        margin-right: 10px;
      }
    }
    .group-price-text {
      margin-right: 8px;
    }
    .total-price {
      font-size: 12px;
    }
  }
}
</style>
