<!-- 注册/找回密码 -->
<template>
  <div class="register-components">
    <el-form class="form-container">
      <!-- 手机号 -->
      <el-form-item :class="{ 'el-form-item-error': !!phoneTip }">
        <el-input
          v-model="phone"
          class="form-control phone"
          placeholder="请输入手机号码"
          maxlength="11"
          @input="bindInputPhone"
        />
        <div class="el-form-item-error-tip" v-show="phoneTip">
          {{ phoneTip }}
        </div>
      </el-form-item>
      <!-- 验证码 -->
      <el-form-item :class="{ 'el-form-item-error': codeTip }">
        <div class="clearfix">
          <el-input
            v-model="code"
            class="form-control code"
            placeholder="请输入验证码"
            maxlength="4"
            @input="bindInputCode"
          />

          <el-button
            size="large"
            class="btn-send-code btn-primary"
            type="primary"
            :disabled="sending"
            @click.stop="sendCode"
          >
            {{ buttonText }}
          </el-button>
        </div>
        <div class="el-form-item-error-tip" v-show="codeTip">{{ codeTip }}</div>
      </el-form-item>
      <!-- 新密码 -->
      <el-form-item
        :class="{ 'el-form-item-error': !!passTip }"
        class="pass-form-item"
      >
        <el-input
          v-model="password"
          class="form-control"
          :placeholder="type === 'register' ? '设置密码' : '设置新密码'"
          maxlength="40"
          @input="bindInputPass"
          :type="passFlag ? 'password' : 'text'"
        />
        <img
          v-show="!passFlag"
          class="icon-view-tag"
          src="@/assets/img/login/icon-view-open.png"
          alt=""
          @click="passFlag = !passFlag"
        />
        <img
          v-show="passFlag"
          class="icon-view-tag"
          src="@/assets/img/login/icon-view-close.png"
          alt=""
          @click="passFlag = !passFlag"
        />
        <div class="el-form-item-error-tip pass-error-tips" v-show="passTip">
          {{ passTip }}
        </div>
        <div class="pass-tip">8-40个字符，必须包含数字、大写字母和小写字母</div>
      </el-form-item>
      <el-button
        size="large"
        formType="submit"
        class="submit btn-primary"
        type="primary"
        :loading="loading"
        @click.stop="formSubmit"
      >
        {{ type === "register" ? "注册并登录" : "重置密码并登录" }}
      </el-button>
    </el-form>
  </div>
</template>

<script>
import { vailPhone, vailPassWord } from "@/utils/utils";
import api from "@/plugins/api";
import loginHandle from "@/mixins/login-handle";

export default {
  name: "RegisterForm",
  mixins: [loginHandle],
  props: {
    // form标题
    type: {
      type: String,
      default: () => "register", //register:注册，reset-password：找回密码
    },
  },
  data() {
    return {
      code: "",
      codeTip: "",
      sending: false,
      buttonText: "获取验证码",
      time: 60,
      buttonTimer: null,
      loading: false,
      passFlag: true,
    };
  },
  beforeDestroy() {
    clearInterval(this.buttonTimer);
  },
  methods: {
    formSubmit() {
      // 校验
      if (!this.phone) {
        this.phoneTip = "请填写手机号";
        return false;
      } else if (!vailPhone(this.phone)) {
        this.phoneTip = "手机号码格式错误";
        return false;
      } else if (!this.code) {
        this.codeTip = "请填写验证码";
        return false;
      } else if (this.code.length !== 4) {
        this.codeTip = "验证码错误";
        return false;
      } else if (!this.password) {
        this.passTip = "请填写密码";
        return false;
      } else if (!vailPassWord(this.password)) {
        this.passTip = "密码格式错误";
        return false;
      }
      this.fnRegister();
      return true;
    },
    bindInputCode() {
      this.code = this.code.replace(/\s+/g, "");
      if (this.code) {
        this.codeTip = "";
      }
    },
    sendCode() {
      // 校验phone
      if (this.phone) {
        if (!vailPhone(this.phone)) {
          this.phoneTip = "手机号码格式错误";
          return;
        }
      } else {
        this.phoneTip = "请填写手机号";
        return;
      }
      const params = {
        mobile: this.phone,
        scene: this.type,
      };
      if (this.type === "register") {
        params.need_check = 0;
      }
      this.sending = true;
      api.getSms(params).then((data) => {
        if (data.data.code === 1) {
          this.eventGetCode();
          this.$message.success("验证码已发送");
          this.timer();
        } else {
          if (data.data.code === 30000) {
            this.loadImage();
            this.sending = false;
            this.$message.warning("需要图形验证码");
            return false;
          }
          if (data.data.code === 30001) {
            this.pictip = true;
            this.checkcode = true;
            this.sending = false;
            this.loadImage();
            return false;
          }
          this.$message.error(data.data.msg);
          this.sending = false;
        }
        return true;
      });
    },
    timer() {
      this.buttonTimer = setInterval(() => {
        if (this.time < 1) {
          clearInterval(this.buttonTimer);
          this.buttonText = "获取验证码";
          this.sending = false;
          this.time = 60;
        } else {
          this.time = this.time - 1;
          this.buttonText = `${this.time}s后重新发送`;
        }
      }, 1000);
    },
    fnRegister() {
      this.loading = true;
      let params = {
        mobile: this.phone,
        password: this.password,
        mobile_code: this.code,
        auto_login: 1,
      };
      let fun = "";
      if (this.type === "register") {
        fun = "registerApi";
      } else {
        fun = "resetPassword";
      }
      api[fun](params)
        .then((data) => {
          this.loading = false;
          if (data.data.code === 1) {
            // 登录成功
            this.toPreRoute();
          } else {
            this.$message.error(data.data.message);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 阿里图形验证码
    loadImage() {},
    eventGetCode() {},
    eventSignIn() {},
  },
};
</script>

<style lang="scss" src="./common.scss" scoped></style>
<style lang="scss" scoped>
$primary-color: #242424;
$white: #fff;
.register-components {
  .input-placeholder {
    font-size: 15px;
    font-weight: 400;
    color: rgba($primary-color, 0.24);
    line-height: 21px;
  }

  .submit {
    margin: 24px 0 20px;
    border-radius: 8px;
    width: 100%;
    background-color: $chiral-btn-color;
    font-size: 15px;
    line-height: 15px;
    font-weight: 500;
    color: $white;
    padding: 16px 0;
    height: 48px;
  }
  .clearfix {
    &::after {
      display: table;
      content: " ";
      clear: both;
    }
  }
  .form-control.code {
    float: left;
    width: calc(100% - 115px - 16px);
  }
  .verify {
    width: 64%;
    display: inline-block;
  }
  .verifypic {
    width: 100px;
    height: 40px;
    margin-left: 10px;
  }
  .btn-send-code {
    background-color: $chiral-btn-color;
    border-radius: 8px;
    font-size: 15px;
    line-height: 15px;
    font-weight: 400;
    color: $white;
    width: 115px;
    padding: 13px 0;
    float: right;
    height: 42px;
  }
  .pass-tip {
    font-size: 12px;
    font-weight: 400;
    color: #afafaf;
    line-height: 17px;
    padding-top: 4px;
  }
  .el-button--primary:focus,
  .el-button--primary:hover {
    background: $chiral-btn-color;
    border-color: $chiral-btn-color;
  }
  .el-button--primary.is-disabled,
  .el-button--primary.is-disabled:active,
  .el-button--primary.is-disabled:focus,
  .el-button--primary.is-disabled:hover {
    background: #cbcbcb;
    border-color: #cbcbcb;
  }
  .tips {
    color: #fd8216;
    font-size: 26px;
  }
  .checkerr {
    border-bottom: 2px solid #fd8216;
  }
  .el-form-item-error-tip {
    position: absolute;
    padding-left: 10px;
    padding-top: 4px;
    position: absolute;
    top: 100%;
    left: 0;
    line-height: 1;
    color: #ed4014;
  }
  .pass-form-item {
    position: relative;
  }
  .icon-view-tag {
    position: absolute;
    right: 16px;
    top: 8px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  .el-form-item-error-tip.pass-error-tips {
    position: static;
  }
}
</style>
<style lang="scss">
.register-components {
  .el-form-item-error input {
    border: 1px solid $error-color;
  }
}
</style>
