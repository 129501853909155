<!-- 退费弹窗 -->
<template>
  <div class="refund-handle">
    <el-dialog
      custom-class="group-dialog qr-pay"
      :visible.sync="dialogVisible"
      :close-on-press-escape="false"
      title=""
      :close-on-click-modal="false"
      center
    >
      <p class="desp">
        {{ order.desp_1 }}<br />
        应退金额中的现金部分会原路退还到您的支付宝／微信账户
      </p>
      <el-collapse v-if="order.subclasses" accordion>
        <el-collapse-item :title="order.subject">
          <div class="panel-collapse collapse" id="o-subclasses-wrap">
            <div class="subclass-container">
              <table>
                <tr
                  v-for="subclass in order.subclasses"
                  :key="subclass.subclass_id"
                  :class="'table-row ' + subclass.className"
                >
                  <td class="table-cell">{{ subclass.subject }}</td>
                  <td class="table-cell" align="right" width="50px">
                    {{ subclass.statusText }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
      <p v-else class="class-subject order-entry-panel">{{ order.subject }}</p>
      <table>
        <tr class="table-row">
          <td class="table-cell" style="width: 86px">订单金额</td>
          <td class="table-cell">
            <span>￥{{ Number((order.price / 100).toFixed(2)) }}</span>
          </td>
        </tr>
        <tr class="table-row">
          <td class="table-cell" colspan="2">
            <p class="desp">{{ order.priceText }}</p>
          </td>
        </tr>
        <tr class="table-row">
          <td class="table-cell">扣除费用</td>
          <td class="table-cell">
            <span
              >-￥{{ Number((order.deduct_expenses / 100).toFixed(2)) }}</span
            >
          </td>
        </tr>
        <tr class="table-row">
          <td class="table-cell" colspan="2">
            <p class="desp">{{ order.serviceChargeText }}</p>
          </td>
        </tr>
        <tr class="table-row">
          <td class="table-cell">应退金额</td>
          <td class="table-cell text-success">
            <span
              >￥{{
                Number(
                  (
                    (order.refund_cash + order.refund_quark * 100) /
                    100
                  ).toFixed(2)
                )
              }}</span
            >
          </td>
        </tr>
        <tr class="table-row">
          <td class="table-cell" colspan="2">
            <p class="desp" style="margin-bottom: -5px">
              {{ order.refundPriceText }}
            </p>
          </td>
        </tr>
      </table>
      <div slot="footer">
        <el-button class="msg-btn" @click="dialogVisible = false"
          >取消</el-button
        >
        <el-button
          type="danger"
          :loading="btnLoading"
          :disabled="order.refund_cash + order.refund_quark * 100 <= 0"
          @click="goRefund"
          class="msg-btn"
          >退款
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      custom-class="dialog-600"
      :visible.sync="liveRefundTip"
      title="退款说明"
      center
    >
      <p>因某些用户恶意退款刷单，微信/支付宝原路退款暂时关闭</p>
      <p>请等待微信/支付宝原路退款功能重新开放</p>
      <div slot="footer">
        <el-button type="primary" @click="liveRefundTip = false"
          >好的</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      custom-class="dialog-420"
      :visible.sync="expressVisible"
      title=""
      center
    >
      <div class="text-center">
        <img
          src="@/assets/img/orders/tuihuo.png"
          width="124"
          height="67"
          alt="质心教育"
        />
        <div style="color: #ffc039; margin: 25px 0 20px">
          请退还课程资料后退款
        </div>
      </div>
      <el-input
        v-model="expressNo"
        size="mini"
        placeholder="资料寄出后，请填写快递单号"
      ></el-input>
      <h3 class="text-left title">质心收货地址说明</h3>
      <div style="margin-bottom: 4px">收件人：质心教育</div>
      <div style="margin-bottom: 4px">联系电话：400-0111037</div>
      <div style="margin-bottom: 4px">
        收货地址：北京市朝阳区来广营西路8号国创产业园E门208
      </div>
      <div>邮政编码：100086</div>
      <div slot="footer">
        <el-button type="primary" :disabled="!expressNo" @click="expressReturn"
          >资料已寄回</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      custom-class="dialog-420"
      :visible.sync="msgVisible"
      :title="succeed ? '退款说明' : '退款成功'"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <slot v-if="succeed">
        <p>您的退款请求已收到，质心将在5个工作日内完成退款操作</p>
        <p>退款操作成功后，您会收到质心教育的短信通知</p>
        <p>钱款具体到账时间，以微信 / 支付宝 / 银行的通知为准</p>
      </slot>
      <slot v-else>
        <p>钱款到账时间与您的订单支付渠道有关</p>
        <p>请以支付宝 / 微信 / 银行的通知为准</p>
      </slot>
      <div slot="footer">
        <el-button type="primary" @click="btnClick">好的</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getRefundAmount, refund, saveExpressNo } from "@/api/my-order.js";
import { productTypeMap } from "@/utils/constants";

export default {
  name: "refundDialog",
  props: {
    refundSuccess: Function,
  },
  data() {
    return {
      order: {},
      succeed: false,
      expressNo: "",
      liveRefundTip: false,
      expressVisible: false,
      dialogVisible: false,
      msgVisible: false,
      btnLoading: false,
    };
  },
  methods: {
    getRefundAmount(orderNo, order) {
      getRefundAmount({ order_no: orderNo }).then((res) => {
        if (res.code === 1) {
          let curOrder = {};
          curOrder =
            order.various &&
            order.various.length &&
            order.various.find((el) => {
              return el.order_no === orderNo + "";
            });
          this.renderRefundDetail(Object.assign(curOrder, res));
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    renderRefundDetail(order) {
      var curOrder = JSON.parse(JSON.stringify(order));
      var now = new Date().getTime() / 1000;
      order.product_type =
        Number(curOrder.product_type) === 7
          ? Number(curOrder.various[0].product_type)
          : Number(curOrder.product_type);
      order.subject = curOrder.subject;
      order.price = order.charged_cash + order.charged_quark * 100;
      order.deduct_expenses = order.service_charge;
      order.serviceChargeText =
        "手续费: ￥" + Number((order.service_charge / 100).toFixed(2));
      if (
        Number(order.product_type) === 3 ||
        Number(order.product_type) === 2
      ) {
        order.subclasses = curOrder.various;
        order.subclasses.forEach((subclass) => {
          var liveStatus = this.getLiveStatusName(
            now,
            subclass.begin_at,
            subclass.end_at
          );
          subclass.statusText = liveStatus.text;
          subclass.className = liveStatus.className;
        });
        order.desp_1 = "直播课开始前30分钟以上，可以退款，否则不可退款";
        order.deduct_expenses =
          order.price - order.refund_cash - order.refund_quark * 100;
        order.serviceChargeText =
          "不支持退款的课程费用：￥" +
          Number((order.deduct_expenses / 100).toFixed(2));
      }
      if (Number(order.product_type) === 9) {
        order.desp_1 =
          "食宿费退款时会扣除手续费￥" +
          Number((order.service_charge / 100).toFixed(2));
      } else if (Number(order.product_type) === 1) {
        if (
          Number(curOrder.class_type) === 3 ||
          Number(curOrder.class_type) === 4
        ) {
          order.desp_1 =
            "实验培训班级退款时会扣除手续费￥" +
            Number((order.service_charge / 100).toFixed(2));
        } else {
          order.desp_1 =
            "冬/夏令营退款时会扣除手续费￥" +
            Number((order.service_charge / 100).toFixed(2));
        }
      }
      if (Number(order.product_type) === 11) {
        order.priceText =
          "质心币付款： ￥" + Number((order.charged_cash / 100).toFixed(2));
        order.refundPriceText =
          "退还质心币： " + Number((order.refund_cash / 100).toFixed(2)) + "个";
      }
      order.priceText =
        "现金付款： ￥" + Number((order.charged_cash / 100).toFixed(2));
      if (order.charged_quark !== 0) {
        order.priceText += " + 夸克抵扣： ￥" + order.charged_quark;
      }
      order.refundPriceText =
        "退还现金： ￥" + Number((order.refund_cash / 100).toFixed(2));
      if (order.refund_quark !== 0) {
        order.refundPriceText += " + 退还夸克：" + order.refund_quark + "个";
      }
      this.order = order;
      if (
        curOrder.various &&
        curOrder.various[0] &&
        curOrder.various[0].have_materials
      ) {
        this.expressVisible = true;
      } else {
        this.dialogVisible = true;
      }
    },
    getLiveStatusName(now, beginAt, endEt) {
      let statusName = "";
      let className = "light";
      if (now < beginAt) {
        statusName = "未开始";
        className = "";
      } else if (now > endEt) {
        statusName = "已结束";
      } else {
        statusName = "直播中";
      }
      return {
        text: statusName,
        className: className,
      };
    },
    getProductType: function (orderNo, order) {
      let productType = order.product_type;
      let variousProductType = order.product_type;
      let various = order.various;
      if (productType === productTypeMap.MULTI_ORDER && order.various) {
        order.various.forEach((suborder) => {
          if (suborder.order_no === orderNo) {
            productType = suborder.product_type;
            various = suborder.various;
          }
        });
      }
      variousProductType = productType;
      if (Number(productType) === productTypeMap.VARIOUS_LIVE_CLASS) {
        variousProductType = various[0].product_type;
      }
      return variousProductType;
    },
    btnClick() {
      this.msgVisible = false;
      this.refundSuccess && this.refundSuccess();
    },
    goRefund() {
      this.btnLoading = true;
      refund({ order_no: this.order.order_no }).then(
        (res) => {
          if (res.code === 1) {
            this.dialogVisible = false;
            this.succeed = res.succeed === false;
            this.msgVisible = true;
          } else {
            if (res.code === 20100) {
              res.msg = "该订单中的课程都已开始或已结束，无法退款";
            }
            this.$chiralAutoMessage(res.msg);
          }
          this.btnLoading = false;
        },
        () => {
          this.btnLoading = false;
          this.$message.error("无法连接到服务器, 请刷新页面后重试");
        }
      );
    },
    expressReturn() {
      saveExpressNo({
        order_no: this.order.order_no,
        express_no: this.expressNo,
      }).then((res) => {
        if (res.code === 1) {
          this.expressVisible = false;
          this.dialogVisible = true;
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss">
.refund-handle {
  .title {
    color: rgb(51, 51, 51);
    margin: 10px 0;
  }

  .table-row {
    display: table-row;
    color: #2d2d2e;

    &.light {
      color: rgba(44, 45, 46, 0.5);
    }

    .table-cell {
      display: table-cell;
      line-height: 2em;
      &.text-success {
        color: #00c7bf;
      }
      .desp {
        color: rgba(44, 45, 46, 0.5);
        font-size: 12px;
        line-height: 2em;
        margin-left: 86px;
      }
    }
  }
}
@media (max-width: 767px) {
  .refund-handle .el-dialog {
    width: 90%;
  }
}
</style>
