<template>
  <div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      :append-to-body="true"
      :close-on-click-modal="false"
      width="410px"
    >
      <img
        src="@/assets/img/close.png"
        @click="dialogVisible = false"
        class="close-btn"
        alt="关闭"
      />
      <div class="content-wrap">
        <img :src="qrcodeImg" alt="" />
        <p>请使用手机微信或QQ扫码分享哦～</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import qrcode from "qrcode";
export default {
  data() {
    return {
      dialogVisible: false,
      qrcodeImg: "",
    };
  },
  methods: {
    show(href) {
      qrcode.toDataURL(href, { width: 190 }, (err, url) => {
        if (err) throw err;
        this.qrcodeImg = url;
      });
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  margin-top: 25vh !important;
}

.close-btn {
  width: 30px;
  height: 30px;
  position: absolute;
  top: -16px;
  right: -14px;
  cursor: pointer;
}
.content-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    margin-top: 10px;
  }
}
</style>
