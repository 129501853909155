import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const languages = [
  { value: "en-US", label: "English" },
  { value: "zh-CN", label: "简体中文" },
  { value: "zh-TW", label: "繁體中文" },
];
const messages = {};

// combine admin and oj
for (const lang of languages) {
  const locale = lang.value;
  const m = require(`./oj/${locale}`).m;
  Object.assign(m, require(`./admin/${locale}`).m);
  messages[locale] = { m: m };
}
// // load language packages
// export default new VueI18n({
//   locale: 'zh-CN',
//   messages: messages
// })
//
//

export default ({ app }) => {
  // Set i18n instance on app
  // This way we can use it in middleware and pages asyncData/fetch
  app.i18n = new VueI18n({
    locale: "zh-CN",
    fallbackLocale: "en",
    messages: messages,
  });
  app.i18n.path = (link) => {
    if (app.i18n.locale === app.i18n.fallbackLocale) {
      return `/${link}`;
    }
    return `/${app.i18n.locale}/${link}`;
  };
};

export { languages };
