<template>
  <div class="footer" id="footer">
    <!-- h5的footer -->
    <div class="chiral-footer-h5" v-if="isMobile">
      <div class="footer-links">
        <div class="company-info">
          <div class="container">
            <span><a :href="`${chiralUrl}/agreement`">用户协议</a></span>
            <span><a :href="`${chiralUrl}/policy`">隐私政策</a></span>
          </div>
          <div>
            <a href="tel:400-0111037">400-0356256</a>
            <a href="mailto:chiralproduct@eduzhixin.com"
              >chiralproduct@eduzhixin.com</a
            >
          </div>
        </div>
        <div class="qr-code">
          <img alt="二维码" src="../assets/img/qq-code.png" />
          <p>关注开罗尔公众号<br />了解更多竞赛资讯</p>
        </div>
      </div>
      <div class="footer-copyright">
        <span>蜀ICP备2022022595号</span>
        <!-- <span
          ><img class="icon" src="../assets/img/record-icon.png" />京公网安备
          11010802025472号</span
        >
        <span>网络安全登记保护备案号 11010819139 - 00001</span>
        <span>教APP备1101010号</span> -->
        <p>Copyright©2022-{{ CopyrightYear }} 开罗尔</p>
      </div>
    </div>
    <!-- pc的footer -->
    <div class="chiral-footer" v-else>
      <div class="footer-links">
        <div class="company-info">
          <img
            alt="logo"
            class="logo"
            src="../assets/img/chiral_footer_logo.png"
          />
          <div class="container">
            <ul>
              公司介绍
              <li><a :href="`${chiralUrl}/agreement`">用户协议</a></li>
              <li><a :href="`${chiralUrl}/policy`">隐私政策</a></li>
            </ul>
            <ul>
              联系方式
              <li>
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 27 32"
                >
                  <path
                    fill="rgba(255,255,255,0.6)"
                    d="M22.857 21.456q0-0.196-0.036-0.286-0.054-0.143-0.688-0.527t-1.58-0.884l-0.946-0.518q-0.089-0.054-0.339-0.232t-0.446-0.268-0.375-0.089q-0.321 0-0.839 0.58t-1.018 1.17-0.786 0.589q-0.125 0-0.295-0.063t-0.277-0.116-0.304-0.17-0.25-0.152q-1.768-0.982-3.045-2.259t-2.259-3.045q-0.036-0.054-0.152-0.25t-0.17-0.304-0.116-0.277-0.063-0.295q0-0.232 0.366-0.598t0.804-0.688 0.804-0.705 0.366-0.652q0-0.179-0.089-0.375t-0.268-0.446-0.232-0.339q-0.054-0.107-0.268-0.509t-0.446-0.813-0.473-0.848-0.446-0.723-0.295-0.321-0.286-0.036q-0.857 0-1.804 0.393-0.821 0.375-1.429 1.688t-0.607 2.33q0 0.286 0.045 0.607t0.089 0.545 0.161 0.589 0.179 0.527 0.223 0.589 0.196 0.536q1.071 2.929 3.866 5.723t5.723 3.866q0.107 0.036 0.536 0.196t0.589 0.223 0.527 0.179 0.589 0.161 0.545 0.089 0.607 0.045q1.018 0 2.33-0.607t1.688-1.429q0.393-0.946 0.393-1.804zM27.429 7.581v17.143q0 2.125-1.509 3.634t-3.634 1.509h-17.143q-2.125 0-3.634-1.509t-1.509-3.634v-17.143q0-2.125 1.509-3.634t3.634-1.509h17.143q2.125 0 3.634 1.509t1.509 3.634z"
                  />
                </svg>
                <a href="tel:400-0356256">400-0356256</a>
              </li>
              <li>
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 37 32"
                >
                  <path
                    fill="rgba(255,255,255,0.6)"
                    d="M16.891 14.309c0.384 0.301 0.873 0.483 1.406 0.483s1.022-0.182 1.411-0.487l-0.005 0.004 14.583-11.269c-1.681-1.87-4.108-3.040-6.808-3.040-0.017 0-0.035 0-0.052 0h-18.283c-0.015-0-0.032-0-0.049-0-2.7 0-5.127 1.171-6.801 3.032l-0.007 0.008zM22.491 17.897c-1.149 0.899-2.614 1.441-4.206 1.441s-3.057-0.542-4.221-1.453l0.015 0.011-13.851-10.743c-0.142 0.596-0.225 1.282-0.229 1.986v13.717c0 5.049 4.093 9.143 9.143 9.143v0h18.286c5.049 0 9.143-4.093 9.143-9.143v0-13.714c-0.003-0.707-0.087-1.392-0.241-2.050l0.012 0.062z"
                  />
                </svg>
                <a href="mailto:chiralproduct@eduzhixin.com"
                  >chiralproduct@eduzhixin.com</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="qr-code">
          <img alt="二维码" src="../assets/img/qq-code.png" />
          <p>关注开罗尔公众号</p>
          <p>了解更多竞赛资讯</p>
        </div>
      </div>
      <div class="footer-copyright">
        <span>蜀ICP备2022022595号</span>
        <!-- <span
          ><img class="icon" src="../assets/img/record-icon.png" />京公网安备
          11010802025472号</span
        >
        <span>网络安全登记保护备案号 11010819139 - 00001</span>
        <span>教APP备1101010号</span> -->
        <p>Copyright©2022-{{ CopyrightYear }} 开罗尔</p>
      </div>
    </div>
  </div>
</template>
<script>
import { isMobile } from "@/utils/utils";

export default {
  data() {
    return {
      chiralUrl: process.env.VUE_APP_CHIRAL_URL,
      isMobile: isMobile(),
      CopyrightYear: new Date().getFullYear(),
    };
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.footer {
  position: relative;
  background: $chiral-footer-color;
}
.chiral-footer {
  position: relative;
  height: 100%;
  margin: auto;
  // background: url("../assets/img/footer-map.png") no-repeat;
  background-size: 100%;
  .footer-links {
    display: flex;
    justify-content: space-between;
    .company-info {
      padding-top: 28px;
      font-size: 14px;
      .logo {
        width: 110px;
        margin-bottom: 30px;
      }
      .container {
        display: flex;
        color: $avatar-color;
        ul {
          font-weight: bold;
          margin-right: 53px;
          color: rgba($color: $avatar-color, $alpha: 0.7);
        }
        li {
          font-weight: 400;
          margin-top: 10px;
          display: flex;
          color: rgba($color: $avatar-color, $alpha: 0.6);
          svg {
            margin-right: 4px;
          }
        }
        li:nth-child(1) {
          margin-top: 20px;
        }
        a {
          color: rgba($color: $avatar-color, $alpha: 0.7);
        }
        a:hover {
          color: $avatar-color;
        }
      }
    }
    .qr-code {
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      padding: 76px 6px 0px 0px;
      color: rgba($color: $btn-default-bg, $alpha: 0.6);
      img {
        width: 100px;
        height: 100px;
        border-radius: 4px;
        margin-bottom: 10px;
      }
    }
  }
  .footer-copyright {
    width: 100%;
    text-align: center;
    color: rgba($color: $avatar-color, $alpha: 0.5);
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 0px);
    .icon {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
    span {
      margin-right: 16px;
    }
    p {
      margin: 6px 0px 9px;
    }
  }
}
//h5css
.chiral-footer-h5 {
  padding: 0.32rem 0.48rem 1.6rem 0.4267rem;
  position: relative;
  .footer-links {
    display: flex;
    .company-info {
      flex: 1;
      font-size: 0.32rem;
      color: rgba($color: $btn-default-bg, $alpha: 0.6);
      .container {
        margin-bottom: 0.2933rem;
        span {
          padding: 0px 0.3733rem;
          border-left: 1px solid rgba($color: $btn-default-bg, $alpha: 0.6);
        }
        span:nth-child(1) {
          padding-left: 0px;
          border-left: none;
        }
        a {
          font-weight: bold;
        }
      }
    }
    .qr-code {
      display: flex;
      font-size: 0.3733rem;
      font-weight: 400;
      align-items: center;
      color: rgba($color: $btn-default-bg, $alpha: 0.6);
      img {
        width: 1.1467rem;
        height: 1.1467rem;
        margin-right: 0.2133rem;
        border-radius: 3px;
      }
    }
  }
  .footer-copyright {
    width: 95%;
    text-align: center;
    color: rgba($color: $avatar-color, $alpha: 0.5);
    font-size: 0.32rem;
    font-weight: 400;
    line-height: 0.32rem;
    position: absolute;
    bottom: 0.2133rem;
    left: 50%;
    transform: translate(-50%, 0px);
    .icon {
      width: 0.5333rem;
      height: 0.5333rem;
      margin-right: 0.2133rem;
    }
    span {
      margin-right: 0.16rem;
    }
    p {
      margin-top: 0.16rem;
    }
  }
}
//适配h5
@media screen and (max-width: 767px) {
  .chiral-footer {
    width: 100%;
    min-width: auto;
  }
}
//适配pad
@media (min-width: 768px) {
  .footer {
    height: 236px;
  }
  .chiral-footer {
    width: 750px;
    .footer-links .qr-code {
      padding: 20px 0px 0px 0px;
    }
  }
}
//适配pc
@media (min-width: 1200px) {
  .footer {
    height: 300px;
  }
  .chiral-footer {
    width: 1140px;
    .footer-links .qr-code {
      padding: 76px 6px 0px 0px;
    }
  }
}
</style>
