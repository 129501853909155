import Vue from "vue";
import { MessageBox } from "element-ui";

const chiralConfirmLoading = function (msg, confirmButtonText, callback = "") {
  MessageBox.confirm("", msg, {
    center: true,
    showCancelButton: true,
    confirmButtonText: confirmButtonText,
    customClass: "confirm-btn",
    confirmButtonClass:'large-confirm-btn',
    cancelButtonClass:'large-confirm-btn',
    beforeClose: (action, instance, done) => {
      if (action === "confirm") {
        instance.confirmButtonLoading = true;
        done();
        setTimeout(() => {
          instance.confirmButtonLoading = false;
        }, 300);
      } else {
        done();
      }
    },
  })
    .then(() => {
      callback && callback(true);
    })
    .catch(() => {
      callback && callback(false);
    });
};

const chiralConfirmMessage = function (msg, callback = "") {
  MessageBox.confirm("", msg, {
    center: true,
    showCancelButton: false,
    confirmButtonText: "好的",
    customClass: "confirm-message",
    confirmButtonClass: "msg-btn",
  })
    .then(() => {
      callback && callback();
    })
    .catch(() => {
      callback && callback();
    });
};

const chiralAutoMessage = function (msg, setTime) {
  MessageBox.confirm(msg, "", {
    center: true,
    customClass: "auto-message",
    showCancelButton: false,
    showConfirmButton: false,
    dangerouslyUseHTMLString: true,
  });
  if (setTime) {
    setTimeout(() => {
      MessageBox.close(false); //重要
    }, setTime);
  }
};

const showDialog = (msg, callback = "") => {
  return MessageBox.confirm("", msg, {
    center: true,
    confirmButtonText: "确认",
    confirmButtonClass: "msg-btn",
    cancelButtonClass: "msg-btn",
  })
    .then(() => {
      callback && callback();
    })
    .catch(() => {});
};
const msgDialog = function (msg, callback = "") {
  MessageBox.confirm("", msg, {
    center: true,
    showCancelButton: false,
    confirmButtonText: "好的",
    confirmButtonClass: "msg-btn",
  })
    .then(() => {
      callback && callback();
    })
    .catch(() => {
      callback && callback();
    });
};
Vue.prototype.$chiralConfirmLoading = chiralConfirmLoading;
Vue.prototype.$chiralConfirmMessage = chiralConfirmMessage;
Vue.prototype.$chiralAutoMessage = chiralAutoMessage;
Vue.prototype.$showDialog = showDialog;
Vue.prototype.$msgDialog = msgDialog;
