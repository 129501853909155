import Vue from "vue";
import axios from "axios";
import qs from "qs";

Vue.prototype.$http = axios;
axios.defaults.baseURL = process.env.VUE_APP_PROXY_URL + "/v1/";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

// const gatewayApi = process.env.VUE_APP_GATEWAY_URL;
// const zhixinApi = process.env.VUE_APP_ZHIXIN_URL;
// const zhixinApi = "http://yapi.upho2015.com/mock/17";
// const adminApi = process.env.VUE_APP_ADMIN_URL
// const passportApi = process.env.VUE_APP_PASSPORT_URL;
// const staticApi = process.env.VUE_APP_STATIC_URL
// const navigationApi = process.env.VUE_APP_NAVIGATION_URL
// const yapiApi = "https://yapi.upho2015.com/mock/17/v1";

const request = function request(url, method, options) {
  const { params = {}, data = {}, json } = options || {};
  return new Promise((resolve, reject) => {
    axios.defaults.withCredentials = true;
    axios.defaults.headers["X-Requested-With"] = "XMLHttpRequest";
    axios.defaults.headers["X-Site-Belong"] = "chiral";
    if (
      params.msgUrl ||
      data.msgUrl ||
      url.indexOf("upload-images") > -1 ||
      url.indexOf("/admin/") > -1
    ) {
      delete params.msgUrl;
      delete data.msgUrl;
      delete axios.defaults.headers["X-Requested-With"];
    }
    axios({ url, method, params, data: json || qs.stringify(data) }).then(
      (res) => {
        resolve(res.data);
      },
      (error) => {
        reject(error);
      }
    );
  });
};
export { request };
